/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useCallback } from 'react';
import IntlProvider from 'icm-core/lib/intlProvider';
import defaultMessages from './localizations/messages.properties';
import {
	ComponentVisibilityProvider,
	RefreshComponentProvider,
} from './context';
import { QueryClient, QueryClientProvider } from 'react-query';

const enableLocaleMessageFallback: boolean = (
	(window as any).PAYEE_WEB_CONFIG || (window as any).ICM_CONFIG
)?.ENABLE_LOCALE_MESSAGE_FALLBACK;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 6000,
		},
	},
});

export default ({ children }) => {
	const localeLoader = useCallback(
		(code) =>
			import(
				`./localizations/messages${code === 'en' ? '' : `_${code}`}.properties`
			),
		[]
	);
	return (
		<QueryClientProvider client={queryClient}>
			<IntlProvider
				loadLocale={localeLoader}
				defaultMessages={
					enableLocaleMessageFallback ? defaultMessages : undefined
				}
			>
				<RefreshComponentProvider>
					<ComponentVisibilityProvider>{children}</ComponentVisibilityProvider>
				</RefreshComponentProvider>
			</IntlProvider>
		</QueryClientProvider>
	);
};
