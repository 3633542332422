/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext } from 'react';
import { Varicent } from 'icm-rest-client';
import { ReportContext, ValueStore, LiveDataPayeeContext } from '../../context';
import { defineMessages } from 'react-intl';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { useSelector } from 'react-redux';
import {
	formattedDateString,
	formattedNumberString,
	DateFormatOption,
	ValueFormatConfig,
	ThousandsSeparatorFormat,
} from '../../utils/dataGridStyling';

const ValueDataType =
	Varicent.Domain.PresenterFlex.PresenterFlexValue.PresenterFlexValueDataType;
const messages = defineMessages({
	noUser: {
		id: 'components.text.text.noUser',
		defaultMessage: 'No user',
	},
	valueNotDefinedYet: {
		id: 'components.text.text.valueNotDefinedYet',
		defaultMessage: 'Value not defined yet.',
	},
});

export const ValueElement: React.FC<{
	valueId?: number;
	special?: string;
}> = ({ valueId, special, children }) => {
	const values = useContext(ValueStore);
	const { values: reportValues } = useContext(ReportContext);
	const { payeeId: liveDataWebUser } = useContext(LiveDataPayeeContext);
	const intl = useIntl();
	const userId = useSelector((state: any) => state?.user?.payeeId);
	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);
	// Used for values created before styling options were added
	const defaultStoredValueFormat: ValueFormatConfig = {
		decimalPlaces: 2,
		thousandsSeparatorFormat: ThousandsSeparatorFormat.COMMA_PERIOD,
	};
	// Used for parameters that can't be styled (i.e. picklists)
	const defaultParameterFormat: ValueFormatConfig = {
		decimalPlaces: 0,
		useThousandsSeparator: false,
		thousandsSeparatorFormat: undefined,
	};

	let content: React.ReactElement | null | string = null;

	if (valueId !== undefined) {
		let value = values[valueId];
		const valueDetails = reportValues?.find((val) => val.valueId === valueId);
		const isParameter = !!valueDetails?.config?.parameter;
		const defaultFormatting = isParameter
			? defaultParameterFormat
			: defaultStoredValueFormat;
		const valueFormatConfig = isParameter
			? valueDetails?.config?.parameter
			: valueDetails?.config?.stored;

		if (valueDetails?.config?.parameter?.useCurrentDate)
			value = new Date().toString();

		const emptyValue =
			value === '' && valueDetails?.dataType !== ValueDataType.String;
		if (value != null && valueDetails != null && !emptyValue) {
			content =
				valueDetails?.dataType === ValueDataType.Date
					? formatDate(value, valueFormatConfig?.formatDateOption, intl)
					: !Number.isNaN(Number(value)) &&
					  valueFormatConfig !== undefined &&
					  valueDetails?.dataType === ValueDataType.Numeric
					? formattedNumberString(
							Number(value),
							{
								valueFormat: {
									...defaultFormatting,
									...(valueFormatConfig as ValueFormatConfig),
								},
							},
							intl
					  )
					: value;
		} else {
			content = intl.formatMessage(messages.valueNotDefinedYet);
		}
	} else if (
		special ===
		Varicent.Domain.PresenterFlex.PresenterFlexSourceFilter.Value.Special
			.CurrentWebUser
	) {
		content =
			previewPayeeId ??
			userId ??
			liveDataWebUser ??
			intl.formatMessage(messages.noUser);
	} else if (
		special ===
		Varicent.Domain.PresenterFlex.PresenterFlexSourceFilter.Value.Special
			.CurrentDate
	) {
		content = intl.formatDate(new Date());
	}

	const childrenWithProps = React.Children.map(children, (child) => {
		/*
		 * Checking isValidElement is the safe way and avoids a typescript
		 * error too.
		 */
		if (React.isValidElement(child)) {
			return React.cloneElement(child, { text: content?.toString() });
		}
		return child;
	});
	// Only displaying one element of the children if they have different styles (SPM-76465)
	return <>{childrenWithProps && childrenWithProps[0]}</>;
};

const formatDate = (value, formatDateOption, intl) => {
	return formattedDateString(
		!Number.isNaN(Number(value)) ? Number(value) : Number(new Date(value)),
		{
			valueFormat: {
				formatDateOption:
					DateFormatOption[
						formatDateOption ?? DateFormatOption.MM_DD_YYYY.toString()
					],
			},
		},
		intl
	);
};

export default Text;
