/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Divider } from '@blueprintjs/core';
import React from 'react';
import { ContentState, DraftBlockType } from 'draft-js';
import { css } from 'emotion';
import { EditorPlugin } from 'draft-js-plugins-editor';
import createListPlugin from 'draft-js-list-plugin';
import { ValueElement } from './valueElement';
import { colorGray2, colorDarkGray3, HTMLLink } from '@varicent/components';
import {
	findEntityRanges,
	makeDecoratorsForPlugins,
} from 'icm-core/lib/utils/draftJsUtils';
import { useLinkComponent } from '../../utils/links';
import Link from 'redux-first-router-link';
import {
	VALUE_ENTITY,
	DIVIDER_ENTITY,
	LINK_ENTITY,
	isEntityLink,
	isEntityValue,
} from './entities';

const listPlugin = createListPlugin();

const fontColorPlugin: EditorPlugin = {
	customStyleFn: (style) => {
		const colorStyle = style.find(
			(_v, key) => !!key?.startsWith('CUSTOM_COLOR_')
		);
		if (colorStyle) {
			return {
				color: colorStyle.replace('CUSTOM_COLOR_', ''),
			} as any;
		}
		return {};
	},
};

const fontFamilyPlugin: EditorPlugin = {
	customStyleFn: (style) => {
		const fontStyle = style.find(
			(_v, key) => !!key?.startsWith('CUSTOM_FONT_')
		);
		if (fontStyle) {
			return {
				fontFamily: fontStyle.replace('CUSTOM_FONT_', ''),
			} as any;
		}
		return {};
	},
};

export type DecoratorComponentProps = {
	contentState: ContentState;
	entityKey: string;
	blockKey: string;
	decoratedText: string;
	dir: null | string;
	start: number;
	end: number;
	offsetKey: string;
};

const ValueComponent: React.FC<DecoratorComponentProps> = ({
	contentState,
	entityKey,
	children,
}) => {
	const { valueId, special } = contentState.getEntity(entityKey).getData();

	return (
		<ValueElement valueId={valueId} special={special}>
			{children}
		</ValueElement>
	);
};

const ValueOrLinkComponent: React.FC<DecoratorComponentProps> = (props) => {
	const entity = props.contentState.getEntity(props.entityKey);
	let { children } = props;
	if (isEntityValue(entity)) {
		children = <ValueComponent {...props}>{children}</ValueComponent>;
	}
	if (isEntityLink(entity)) {
		children = <LinkComponent {...props}>{children}</LinkComponent>;
	}
	return <>{children}</>;
};

const valuesPlugin: EditorPlugin = {
	decorators: [
		{
			strategy: findEntityRanges(VALUE_ENTITY),
			component: ValueOrLinkComponent,
		},
	],
};

export const LinkComponent: React.FC<DecoratorComponentProps> = ({
	contentState,
	entityKey,
	children,
}) => {
	const { linkId } = contentState.getEntity(entityKey).getData();
	const linkInfo = useLinkComponent({
		linkId,
	});
	if (!linkInfo) {
		return <>{children}</>;
	}
	return linkInfo.clientSide ? (
		<Link {...linkInfo.props}>{children}</Link>
	) : (
		<HTMLLink {...linkInfo.props}>{children}</HTMLLink>
	);
};

const readonlyLinksPlugin: EditorPlugin = {
	decorators: [
		{
			strategy: findEntityRanges(LINK_ENTITY),
			component: ValueOrLinkComponent,
		},
	],
};

const alignmentOptions = {
	center: css`
		> .public-DraftStyleDefault-block {
			text-align: center;
		}
	`,
	left: css`
		> .public-DraftStyleDefault-block {
			text-align: left;
		}
	`,
	right: css`
		> .public-DraftStyleDefault-block {
			text-align: right;
		}
	`,
};

const applyColorGray2 = css`
	color: rgb(${colorGray2}) !important;
`;

const applyColorDarkGray3 = css`
	color: rgb(${colorDarkGray3}) !important;
`;

const alignmentPlugin: EditorPlugin = {
	blockStyleFn: (block) => {
		const data = block.getData();
		const alignment = data.get('alignment');

		if (alignment) {
			return alignmentOptions[alignment];
		}
		return '';
	},
};

const supportCustomStyleFnPlugin: EditorPlugin = {
	customStyleFn: (style, block, pluginFns) => {
		return pluginFns.getPlugins().reduce((acc, p) => {
			if (p === supportCustomStyleFnPlugin) {
				return acc;
			}
			if (p.customStyleFn) {
				return {
					...acc,
					...p.customStyleFn(style, block, pluginFns),
				};
			}
			return acc;
		}, {} as any);
	},
};

const dividerPlugin: EditorPlugin = {
	blockRendererFn: (block, { getEditorState }) => {
		if (block.getType() === 'atomic') {
			const contentState = getEditorState().getCurrentContent();
			const entity = block.getEntityAt(0);
			if (!entity) return null;
			const type = contentState.getEntity(entity).getType();
			if (type === DIVIDER_ENTITY) {
				return {
					component: () => <Divider />,
					editable: false,
				};
			}
		}
		return undefined;
	},
};

const additionalEntityClassesPlugin: EditorPlugin = {
	blockStyleFn: (block) => {
		const blockType = block.getType();
		const styleOption = styleOptions.find((o) => o.blockType === blockType);
		return styleOption?.additionalClasses ?? '';
	},
};

export const plugins = [
	supportCustomStyleFnPlugin,
	listPlugin,
	fontColorPlugin,
	fontFamilyPlugin,
	alignmentPlugin,
	dividerPlugin,
	additionalEntityClassesPlugin,
];

export const readonlyPlugins = [...plugins, readonlyLinksPlugin, valuesPlugin];

export const styleOptions: {
	tag: keyof React.ReactHTML;
	title: string;
	blockType: DraftBlockType;
	additionalClasses?: string;
}[] = [
	{
		tag: 'p',
		title: 'NORMAL',
		blockType: 'unstyled',
		additionalClasses: 'unstyled',
	},
	{
		tag: 'h1',
		title: 'TITLE',
		blockType: 'header-one',
		additionalClasses: `heading ${applyColorDarkGray3}`,
	},
	{
		tag: 'h2',
		title: 'SUBTITLE',
		blockType: 'header-two',
		additionalClasses: `heading ${applyColorDarkGray3}`,
	},
	{
		tag: 'h3',
		title: 'HEADING_1',
		blockType: 'header-three',
		additionalClasses: `heading ${applyColorDarkGray3}`,
	},
	{
		tag: 'h4',
		title: 'HEADING_2',
		blockType: 'header-four',
		additionalClasses: `heading ${applyColorGray2}`,
	},
	{
		tag: 'h5',
		title: 'HEADING_3',
		blockType: 'header-five',
		additionalClasses: `heading ${applyColorGray2}`,
	},
];

export const readonlyDecorators = makeDecoratorsForPlugins(readonlyPlugins);
