/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, {
	memo,
	useContext,
	useEffect,
	useRef,
	useMemo,
	useState,
	useCallback,
} from 'react';
import { Varicent } from 'icm-rest-client';
import { SharedComponentProps } from '../../types';
import useDataSource, {
	RowStreamResult,
	ChartGridInfo,
	useLivePreviewInfo,
} from '../../utils/useDataSource';
import { Fit, useMeasure } from '../../utils/contentRect';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import addHighchartsMore from 'highcharts/highcharts-more';
import addTreemapModule from 'highcharts/modules/treemap';
import addHeatMapModule from 'highcharts/modules/heatmap';
import addSolidGaugeModule from 'highcharts/modules/solid-gauge';
import addDumbbellModule from 'highcharts/modules/dumbbell';
import addHistogramBellCurveModule from 'highcharts/modules/histogram-bellcurve';
import addBulletModule from 'highcharts/modules/bullet';
import { InjectedIntlProps } from 'react-intl';
import { css, cx } from 'emotion';
import Placeholder, { MissingDataPlaceholder } from '../../utils/placeholder';
import {
	ComponentVisibility,
	PublishMode,
	ReportContext,
	PaletteContext,
	LiveDataPayeeContext,
} from '../../context';
import { barColChartOptions } from './chartOptions/barCol';
import { clusteredBarColChartOptions } from './chartOptions/clusteredBarCol';
import { stackedBarColChartOptions } from './chartOptions/stackedBarCol';
import { percentStackedBarColChartOptions } from './chartOptions/percentStackedBarCol';
import { pieChartOptions } from './chartOptions/pie';
import { treemapChartOptions } from './chartOptions/treemap';
import { activityGaugeChartOptions } from './chartOptions/activityGauge';
import { dumbbellChartOptions } from './chartOptions/dumbbell';
import { lineChartOptions } from './chartOptions/line';
import { areaChartOptions } from './chartOptions/area';
import { scatterChartOptions } from './chartOptions/scatter';
import { combinationChartOptions } from './chartOptions/combination';
import { waterfallChartOptions } from './chartOptions/waterfall';
import { bulletChartOptions } from './chartOptions/bullet';
import { multiLineChartOptions } from './chartOptions/multiLine';
import { multiScatterChartOptions } from './chartOptions/multiScatter';
import { multiAreaChartOptions } from './chartOptions/multiArea';
import { simpleGaugeChartOptions } from './chartOptions/simpleGauge';
import { donutChartOptions } from './chartOptions/donut';
import { multiCombinationChartOptions } from './chartOptions/multiCombination';
import { bubbleChartOptions } from './chartOptions/bubble';
import { radarChartOptions } from './chartOptions/radar';
import { heatMapChartOptions } from './chartOptions/heatMap';
import KPI from './kpi';
import {
	ChartType,
	ColumnNameToTypeMap,
	formatDateUTC,
	getChartTypeFromConfig,
	invertedFontColor,
	messages,
	isEmptyOrNil,
	maxRowLimit,
	isDataInsufficient,
	ChartErrorPlaceholder,
	isChartDataEmpty,
	simplifiedFormatNumber,
} from './chartUtil';
import R, { isEmpty, uniqBy } from 'ramda';
import { fallbackPalette } from '../../utils/colors';
import { FlexComponentTypes } from '../../componentTypes';
import { useRefreshData } from '../componentHooks';
import { withErrorBoundary, ErrorBoundary } from 'react-error-boundary';
import usePrevious from 'react-use/esm/usePrevious';
import { flattenObject } from 'icm-core/lib/utils/arrayUtils';

declare module 'highcharts' {
	let seriesTypes: Highcharts.Dictionary<typeof Highcharts.Series>;
}

addHighchartsMore(Highcharts);
addDumbbellModule(Highcharts);
addHeatMapModule(Highcharts);
addTreemapModule(Highcharts);
addSolidGaugeModule(Highcharts);
addHistogramBellCurveModule(Highcharts);
addBulletModule(Highcharts);

Highcharts.setOptions({
	chart: {
		style: {
			fontFamily: 'inherit',
		},
		animation: false,
	},
	loading: {
		labelStyle: {
			fontFamily: 'Inter',
		},
	},
});

type DefaultHighchartParams = {
	columnNameToType: ColumnNameToTypeMap;
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO;
	paletteId?: number;
	isLoading: boolean;
};

type MultiComboOverload = {
	data: RowStreamResult[];
	chartType: 'multiCombination';
	preview?: boolean;
} & DefaultHighchartParams &
	InjectedIntlProps;

type ChartOverload = {
	data: RowStreamResult;
	chartType: Exclude<ChartType, 'multiCombination'>;
	preview?: boolean;
} & DefaultHighchartParams &
	InjectedIntlProps;

const HighChart: React.FC<ChartOverload | MultiComboOverload> = (props) => {
	const {
		config,
		chartType,
		data,
		columnNameToType,
		intl,
		paletteId,
		preview,
		isLoading,
	} = props;
	const [ref, contentRect] = useMeasure();

	const { title, hideTitle, hideTile } = config[chartType];
	const isPublishMode = useContext(PublishMode);
	const { metadata } = useContext(ReportContext);
	const fallBackState = useFallbackState();

	const { isLiveDataActive } = useLivePreviewInfo({ preview });
	const chartRef = useRef<Highcharts.Chart>();

	useEffect(() => {
		if (!config.extra?.hideOnEmpty) {
			if (isLoading) chartRef.current?.showLoading();
			else chartRef.current?.hideLoading();
		}
	}, [isLoading]);

	// Work around to resize the chart when printing to fit container, used by publisher only
	useEffect(() => {
		if (isPublishMode && window.matchMedia) {
			const mediaQueryList = window.matchMedia('print');
			mediaQueryList.addEventListener('change', (mql) => {
				if (mql.matches && chartRef.current) chartRef.current.reflow();
			});
		}
	}, [isPublishMode]);

	// Work around for Activity Gauge's legend to get the correspoding color for it's symbol
	useEffect(() => {
		Highcharts.wrap(
			Highcharts.Legend.prototype,
			'colorizeItem',
			function colorizeItem(proceed, item, visible) {
				proceed.apply(this, [item, visible]);
				item.legendGroup[visible ? 'removeClass' : 'addClass'](
					'highcharts-legend-item-hidden'
				);
				if (this.chart && !this.chart.styledMode) {
					const { legend } = this.chart.options;
					const { legendItem, legendLine, legendSymbol } = item;
					const legendVisible = item.userOptions?.showInLegend;
					const hiddenColor = legend.itemHiddenStyle.color;
					const textColor =
						(visible || legendVisible) && legend.itemStyle
							? legend.itemStyle.color
							: hiddenColor;
					const symbolColor =
						visible || legendVisible
							? item.color ||
							  item.userOptions?.color ||
							  item.userOptions?.marker?.fillColor ||
							  hiddenColor
							: hiddenColor;
					const markerOptions = item.options && item.options.marker;
					let symbolAttr = {
						fill: symbolColor,
						stroke: undefined,
					};
					if (legendItem) {
						legendItem.css({
							fill: textColor,
							color: textColor, // #1553, oldIE
						});
					}
					if (legendLine) {
						legendLine.attr({
							stroke: symbolColor,
						});
					}
					if (legendSymbol) {
						// Apply marker options
						if (markerOptions) {
							/*
							 * #585
							 *causes issues with simpleGauge config
							 */
							if (item.pointAttribs && chartType !== 'simpleGauge') {
								symbolAttr = item.pointAttribs();
								if (!(visible || legendVisible)) {
									// #6769
									symbolAttr.stroke = symbolAttr.fill = hiddenColor;
								}
							}
						}
						legendSymbol.attr(symbolAttr);
					}
				}
				Highcharts.fireEvent(this, 'afterColorizeItem', {
					item,
					visible,
				});
			}
		);
	}, [chartType]);

	const hideAllTile = metadata?.extra?.hideAllTile;
	const invertFontColors = metadata?.extra?.invertFontColors;

	const palettes = useContext(PaletteContext);
	const palette =
		palettes.find((p) => p.paletteId === paletteId) ?? fallbackPalette;

	let colorAxis: Highcharts.ColorAxisOptions | undefined;
	if (palette?.config?.divergent) {
		colorAxis = {
			stops: [
				[0, palette.config.divergent.minimum],
				[0.5, palette.config.divergent.middle],
				[1, palette.config.divergent.maximum],
			],
		};
	} else if (palette?.config?.sequential) {
		colorAxis = {
			minColor: palette.config.sequential.minimum,
			maxColor: palette.config.sequential.maximum,
			stops: undefined,
		};
	}

	let options: Highcharts.Options = {
		title: {
			text: '',
			align: 'left',
		},
		chart: {
			/*
			 * Storing our varicent chartType here
			 * The actual Highcharts type is configured in each series
			 */
			type: chartType,
			plotShadow: false,
			height: isPublishMode ? null : contentRect.height,
			width: isPublishMode ? null : contentRect.width,
			backgroundColor: hideAllTile || hideTile ? 'transparent' : '#ffffff',
		},
		credits: {
			enabled: false,
		},
		legend: {
			enabled: false,
		},
		colorAxis,
		tooltip: {
			/*
			 * Bar/Col, Line, Area uses this
			 * The rest overrides this formatter
			 */
			formatter() {
				const isDate =
					(
						this.series.chart.options.xAxis?.[0] ??
						this.series.chart.options.xAxis
					).type === 'datetime' || (this as any).point.isDate;
				const yValue = simplifiedFormatNumber({
					value: this.y,
					config,
				});
				const xValue = this.x ?? this.key;

				return `${
					isDate ? formatDateUTC(xValue, intl) : xValue
				}: <strong>${yValue}</strong>`;
			},
		},
	};

	if (isDataInsufficient(chartType, config, data, columnNameToType)) {
		return <Placeholder type={FlexComponentTypes.chart} />;
	}

	if (
		(!Array.isArray(data) && data.rows.length > maxRowLimit) ||
		(Array.isArray(data) && data.some((x) => x.rows.length > maxRowLimit))
	) {
		const errorMessage = intl.formatMessage(messages.totalRowCountExceeded, {
			max: maxRowLimit,
		});

		return (
			<ChartErrorPlaceholder
				className={cx('card', 'chart-card')}
				chartType={chartType}
				errorMessage={errorMessage}
			/>
		);
	}

	if (
		(!preview || (preview && isLiveDataActive)) &&
		chartType === 'activityGauge' &&
		!Array.isArray(data) &&
		data.rows.length > 5
	) {
		const chartTypeString = intl.formatMessage(messages.activityGauge);
		const errorMessage = intl.formatMessage(messages.rowCountExceeded, {
			chartType: chartTypeString,
			max: 5,
		});

		return (
			<ChartErrorPlaceholder
				className={cx('card', 'chart-card')}
				chartType={chartTypeString}
				errorMessage={errorMessage}
			/>
		);
	}

	if (chartType === 'bullet' || chartType === 'simpleGauge') {
		const chartTypeString = intl.formatMessage(messages[chartType]);

		if (
			(!preview || (preview && isLiveDataActive)) &&
			!Array.isArray(data) &&
			data.rows.length > 1
		) {
			const errorMessage = intl.formatMessage(messages.rowCountExceeded, {
				chartType: chartTypeString,
				max: 1,
			});

			return (
				<ChartErrorPlaceholder
					className={cx('card', 'chart-card')}
					chartType={chartTypeString}
					errorMessage={errorMessage}
				/>
			);
		}

		let invalidRange: boolean | 0 = false;
		if (config[chartType].useCustomRange) {
			const minRange = {
				min: config[chartType].minRangeMin,
				max: config[chartType].minRangeMax,
			};
			const medRange = {
				min: config[chartType].medRangeMin,
				max: config[chartType].medRangeMax,
			};
			const maxRange = {
				min: config[chartType].maxRangeMin,
				max: config[chartType].maxRangeMax,
			};

			invalidRange =
				(!isEmptyOrNil(minRange.min) &&
					!isEmptyOrNil(minRange.max) &&
					Number(minRange.min) >= Number(minRange.max)) ||
				(!isEmptyOrNil(minRange.max) &&
					!isEmptyOrNil(medRange.min) &&
					Number(minRange.max) > Number(medRange.min)) ||
				(!isEmptyOrNil(medRange.min) &&
					!isEmptyOrNil(medRange.max) &&
					Number(medRange.min) >= Number(medRange.max)) ||
				(!isEmptyOrNil(medRange.max) &&
					!isEmptyOrNil(maxRange.min) &&
					Number(medRange.max) > Number(maxRange.min)) ||
				(!isEmptyOrNil(maxRange.min) &&
					!isEmptyOrNil(maxRange.max) &&
					Number(maxRange.min) >= Number(maxRange.max));
		} else if (!Array.isArray(data) && data.rows.length === 1) {
			const minCol = config[chartType].minimumColumn;
			const minVal =
				minCol && !Array.isArray(data) ? data.rows[0][minCol] : null;
			const medCol = config[chartType].mediumColumn;
			const medVal =
				medCol && !Array.isArray(data) ? data.rows[0][medCol] : null;
			const maxCol = config[chartType].maximumColumn;
			const maxVal =
				maxCol && !Array.isArray(data) ? data.rows[0][maxCol] : null;

			invalidRange =
				(!preview || (preview && !!isLiveDataActive)) &&
				((minVal && medVal && minVal >= medVal) ||
					(minVal && maxVal && minVal >= maxVal) ||
					(medVal && maxVal && medVal >= maxVal));
		}

		if (invalidRange) {
			const errorMessage = intl.formatMessage(messages.invalidRange);
			return (
				<ChartErrorPlaceholder
					className={cx('card', 'chart-card')}
					chartType={chartTypeString}
					errorMessage={errorMessage}
				/>
			);
		}
	}

	if (props.chartType === 'heatMap') {
		const uniqueRows = uniqBy(
			(r) =>
				r[config[props.chartType].labelColumn] +
				r[config[props.chartType].categoryColumn],
			props.data?.rows ?? []
		);
		if (props.data.rows.length > uniqueRows.length) {
			const chartTypeString = intl.formatMessage(messages.heatMap);
			const errorMessage = intl.formatMessage(messages.rowsDuplicated, {
				chartType: chartTypeString,
			});
			return (
				<ChartErrorPlaceholder
					className={cx('card', 'chart-card')}
					chartType={chartTypeString}
					errorMessage={errorMessage}
				/>
			);
		}
	}

	if (
		!preview &&
		config[chartType].showYMinMax &&
		config[chartType].yAxisMin !== null &&
		config[chartType].yAxisMax !== null &&
		parseInt(config[chartType].yAxisMin, 10) >=
			parseInt(config[chartType].yAxisMax, 10)
	) {
		const chartTypeString = intl.formatMessage(messages[chartType]);
		const errorMessage = intl.formatMessage(messages.invalidYAxisMinMax);
		return (
			<ChartErrorPlaceholder
				className={cx('card', 'chart-card')}
				chartType={chartTypeString}
				errorMessage={errorMessage}
			/>
		);
	}

	if (
		(!preview || (preview && isLiveDataActive)) &&
		config[chartType].showXMinMax &&
		config[chartType].xAxisMin !== null &&
		config[chartType].xAxisMax !== null &&
		parseInt(config[chartType].xAxisMin, 10) >=
			parseInt(config[chartType].xAxisMax, 10)
	) {
		const chartTypeString = intl.formatMessage(messages[chartType]);
		const errorMessage = intl.formatMessage(messages.invalidXAxisMinMax);
		return (
			<ChartErrorPlaceholder
				className={cx('card', 'chart-card')}
				chartType={chartTypeString}
				errorMessage={errorMessage}
			/>
		);
	}

	const defaultArgs = {
		existingOptions: options,
		intl,
		invertFontColors,
		palette,
	};

	switch (props.chartType) {
		case 'bar':
			options = barColChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				barColType: 'column',
			});
			break;
		case 'horizontalBar':
			options = barColChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				barColType: 'bar',
			});
			break;
		case 'line':
			options = lineChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'multiLine':
			options = multiLineChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'area':
			options = areaChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'multiArea':
			options = multiAreaChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				areaType: 'regular',
			});
			break;
		case 'stackedArea':
			options = multiAreaChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				areaType: 'stacked',
			});
			break;
		case 'scatter':
			options = scatterChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
			});
			break;
		case 'multiScatter':
			options = multiScatterChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'pie':
			options = pieChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'donut':
			options = donutChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'treemap':
			options = treemapChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'dumbbell':
			options = dumbbellChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'waterfall':
			options = waterfallChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'bullet':
			options = bulletChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
			});
			break;
		case 'activityGauge':
			options = activityGaugeChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'simpleGauge':
			options = simpleGaugeChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				contentRect,
			});
			break;
		case 'combination':
			options = combinationChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'multiCombination':
			options = multiCombinationChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'clusteredColumn':
			options = clusteredBarColChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				barColType: 'column',
			});
			break;
		case 'clusteredHorizontalBar':
			options = clusteredBarColChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				barColType: 'bar',
			});
			break;
		case 'stackedColumn':
			options = stackedBarColChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				barColType: 'column',
			});
			break;
		case 'stackedHorizontalBar':
			options = stackedBarColChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				barColType: 'bar',
			});
			break;
		case 'percentStackedColumn':
			options = percentStackedBarColChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				barColType: 'column',
			});
			break;
		case 'percentStackedHorizontalBar':
			options = percentStackedBarColChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
				barColType: 'bar',
			});
			break;
		case 'bubble':
			options = bubbleChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'heatMap':
			options = heatMapChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		case 'radar':
			options = radarChartOptions({
				...defaultArgs,
				data: props.data,
				config: config[props.chartType],
				columnNameToType,
			});
			break;
		default:
	}

	// Disable animation for all charts when publishing
	options.plotOptions = {
		...options.plotOptions,
		series: {
			animation: !isPublishMode,
			turboThreshold: 0,
		},
	};

	const chartTypeChanged =
		options.chart?.type &&
		chartRef.current?.userOptions.chart?.type &&
		options.chart?.type !== chartRef.current?.userOptions.chart?.type;

	return (
		<div
			className={cx('card', 'chart-card')}
			css={css`
				display: flex;
				flex-direction: column;
				position: relative;
				width: 100%;
				flex: 1 1 auto;
				overflow: auto;
				border-radius: 0.25rem;

				${hideAllTile || hideTile
					? `&.card {
						background-color: transparent !important;
						box-shadow: none !important;
						padding: 0.5rem !important;
					}`
					: ''}

				.bx--data-table-v2-header {
					margin: 0 0 1rem;
					font-weight: normal;
					font-size: 1rem;
					${invertFontColors ? `color: ${invertedFontColor}` : ''}
				}
			`}
		>
			{!hideTitle && <h4 className="bx--data-table-v2-header">{title}</h4>}
			<div
				css={css`
					position: relative;
					flex: 1 1 auto;
					width: 100%;
					height: 100%;
				`}
			>
				<Fit innerRef={ref}>
					{contentRect.width !== 0 && contentRect.height !== 0 && (
						<ErrorBoundary
							key={fallBackState.key}
							fallbackRender={fallBackState.fallbackRenderer}
							onError={fallBackState.onError}
						>
							<HighchartsReact
								// eslint-disable-next-line no-return-assign
								ref={(arg) => (chartRef.current = arg?.chart)}
								highcharts={Highcharts}
								options={options}
								containerProps={{ style: { height: '100%', width: '100%' } }}
								immutable={chartTypeChanged}
							/>
						</ErrorBoundary>
					)}
				</Fit>
			</div>
		</div>
	);
};

const useFallbackState = () => {
	const [errorCount, setErrorCount] = React.useState(0);
	const lastError = React.useRef<any>();
	const [key, setKey] = React.useState(0);
	const fallbackRenderer = () => {
		return null;
	};
	const onError = React.useCallback((error: any) => {
		lastError.current = error;
		setErrorCount((prev) => prev + 1);
	}, []);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (errorCount === 1) {
			setKey(errorCount);
		} else if (errorCount < 4) {
			const timeout = setTimeout(() => setKey(errorCount), errorCount * 100);
			return () => clearTimeout(timeout);
		}
	}, [errorCount]);

	if (errorCount > 4) throw new Error(lastError.current);
	return {
		key,
		errorCount,
		fallbackRenderer,
		onError,
	};
};

const Chart: React.FC<
	SharedComponentProps<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO> & {
		paletteId?: number;
	}
> = ({
	config,
	source,
	preview,
	reportId,
	sourceSchema,
	intl,
	componentId,
	paletteId,
	hasErrors,
}) => {
	const { isLiveDataActive, isUsingLiveData } = useLivePreviewInfo({ preview });

	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);

	// Handles default case where no chart has been selected yet
	const chartType = isEmpty(config) ? 'bar' : getChartTypeFromConfig(config);
	const prevChartType = usePrevious(chartType);
	const chartTypeChanged = chartType !== prevChartType;

	const {
		actualColumn,
		targetColumn,
		minimumColumn,
		maximumColumn,
		labelColumn,
		valueColumn,
		categoryColumn,
		highColumn,
		lowColumn,
		categorySizeColumn,
		areaValueColumn,
		columnValueColumn,
		lineValueColumn,
		series,
		xAxisMin,
		xAxisMax,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		labelFormattingDecimalPlaces,
		showYMinMaxColumn,
		yAxisMinColumn,
		yAxisMaxColumn,
		labelFormattingDecimalPlacesColumn,
		showYMinMaxLine,
		yAxisMinLine,
		yAxisMaxLine,
		labelFormattingDecimalPlacesLine,
	} = config[chartType] ?? {};

	const pivotInfoArgs = useMemo(() => {
		return getPivotInfoForChart(
			chartType,
			labelColumn,
			valueColumn,
			categoryColumn,
			series
		);
	}, [chartType, labelColumn, valueColumn, categoryColumn, series]);

	const aggregateInfoArgs = useMemo(() => {
		return getChartInfoForChart({
			chartType,
			actualColumn,
			targetColumn,
			minimumColumn,
			maximumColumn,
			labelColumn,
			valueColumn,
			lowColumn,
			highColumn,
			categoryColumn,
			categorySizeColumn,
			areaValueColumn,
			columnValueColumn,
			lineValueColumn,
			series,
		});
	}, [
		chartType,
		actualColumn,
		targetColumn,
		minimumColumn,
		maximumColumn,
		labelColumn,
		valueColumn,
		lowColumn,
		highColumn,
		categoryColumn,
		categorySizeColumn,
		areaValueColumn,
		columnValueColumn,
		lineValueColumn,
		series,
	]);

	const fakeDataSize = isUsingLiveData
		? 0
		: config.scatter
		? 100
		: config.bullet || config.simpleGauge
		? 1
		: 5;

	const fakeParams = useMemo(() => {
		if (chartType === 'combination' || chartType === 'multiCombination') {
			return getFakeParamForChart(xAxisMin, xAxisMax, [
				{
					yAxisMin: showYMinMaxColumn ? yAxisMinColumn : undefined,
					yAxisMax: showYMinMaxColumn ? yAxisMaxColumn : undefined,
					decimalPlaces: labelFormattingDecimalPlacesColumn,
				},
				{
					yAxisMin: showYMinMaxLine ? yAxisMinLine : undefined,
					yAxisMax: showYMinMaxLine ? yAxisMaxLine : undefined,
					decimalPlaces: labelFormattingDecimalPlacesLine,
				},
			]);
		}
		return getFakeParamForChart(xAxisMin, xAxisMax, [
			{
				yAxisMin: showYMinMax ? yAxisMin : undefined,
				yAxisMax: showYMinMax ? yAxisMax : undefined,
				decimalPlaces: labelFormattingDecimalPlaces,
			},
		]);
	}, [
		chartType,
		xAxisMin,
		xAxisMax,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		labelFormattingDecimalPlaces,
		showYMinMaxColumn,
		yAxisMinColumn,
		yAxisMaxColumn,
		labelFormattingDecimalPlacesColumn,
		showYMinMaxLine,
		yAxisMinLine,
		yAxisMaxLine,
		labelFormattingDecimalPlacesLine,
	]);

	const [refresh, setRefresh] = useState(false);
	useRefreshData(
		componentId,
		useCallback(() => setRefresh((prev) => !prev), [])
	);

	const additionalDataSourceArgs = useMemo(() => {
		return getDataSourceArgsForChart(chartType, config);
	}, [chartType, config]);

	const { data, columnNameToType } = useDataSource({
		source,
		preview,
		previewPayeeId,
		reportId,
		sourceSchema,
		fakeDataSize,
		fakeParams,
		refresh,
		isChart: true,
		hasErrors,
		...pivotInfoArgs,
		...aggregateInfoArgs,
		...additionalDataSourceArgs,
	});

	const { set: setComponentVisibility } = useContext(ComponentVisibility);

	let totalRows = Array.isArray(data.data)
		? data.data?.reduce((a, b) => a + b?.rows?.length, 0)
		: data.data?.schemaInfo?.totalRows ?? data.data?.rows?.length ?? 0;

	if (totalRows === 1 && !Array.isArray(data.data)) {
		/*
		 *we need to check if the only result is just empty columns
		 *if so, mark total rows as 0
		 */
		const singleRow = data.data?.rows?.[0];
		if (isDataRowEmpty(singleRow)) {
			totalRows = 0;
		}
	}

	useEffect(() => {
		Highcharts.setOptions({
			lang: {
				loading: intl.formatMessage(messages.loading),
			},
		});
	}, []);

	const hasAllValues = Array.isArray(data.data)
		? data.data?.every((v) => v !== null)
		: !!data.data;

	useEffect(() => {
		if (
			config.extra?.hideOnEmpty &&
			(!preview || (preview && isUsingLiveData))
		) {
			setComponentVisibility(
				componentId,
				data.isLoading
					? config.extra?.placeholderOnEmpty ?? false
					: totalRows > 0
			);
		}
	}, [
		data.isLoading,
		totalRows,
		config,
		preview,
		componentId,
		setComponentVisibility,
		isUsingLiveData,
	]);

	if (
		(!data.data ||
			!hasAllValues ||
			!isChartDataEmpty({ data: data.data, chartType, columnNameToType })) &&
		config.extra?.hideOnEmpty &&
		!config.extra?.placeholderOnEmpty &&
		!preview
	) {
		// prevent flash of placeholder
		return null;
	}

	if (
		chartTypeChanged ||
		data.isLoading ||
		!data.data ||
		!hasAllValues ||
		(preview && isUsingLiveData && (!isLiveDataActive || hasErrors))
	) {
		return <Placeholder type={FlexComponentTypes.chart} />;
	}
	if ((!preview || (preview && isLiveDataActive)) && totalRows === 0) {
		if (config.extra?.placeholderOnEmpty)
			return <MissingDataPlaceholder className={cx('card', 'chart-card')} />;

		if (preview && isLiveDataActive && config.extra?.hideOnEmpty)
			return (
				<MissingDataPlaceholder
					className={cx('card', 'chart-card')}
					message={intl.formatMessage(messages.liveDataHideData)}
				/>
			);
	}

	if (chartType === 'kpi' && !Array.isArray(data.data)) {
		return (
			<KPI
				data={data.data}
				config={config}
				chartType={chartType}
				columnNameToType={columnNameToType}
				intl={intl}
				preview={preview}
			/>
		);
	}

	if (chartType === 'multiCombination' && Array.isArray(data.data)) {
		return (
			<HighChart
				data={data.data}
				config={config}
				chartType={chartType}
				columnNameToType={columnNameToType}
				intl={intl}
				paletteId={paletteId}
				preview={preview}
				isLoading={data.isFetching}
			/>
		);
	}
	if (chartType !== 'multiCombination' && !Array.isArray(data.data)) {
		return (
			<HighChart
				data={data.data}
				config={config}
				chartType={chartType}
				columnNameToType={columnNameToType}
				intl={intl}
				paletteId={paletteId}
				preview={preview}
				isLoading={data.isFetching}
			/>
		);
	}
	return null;
};

const getDataSourceArgsForChart = (
	chartType: ChartType,
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO
): {
	orderBy?: string;
	orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
	chartGridInfo?: ChartGridInfo;
} => {
	let orderBy: string | undefined;
	let axisOrder: string | undefined;
	let chartGridInfo: ChartGridInfo | undefined;
	switch (chartType) {
		case 'bar':
		case 'horizontalBar':
		case 'clusteredColumn':
		case 'clusteredHorizontalBar':
		case 'stackedColumn':
		case 'stackedHorizontalBar':
		case 'percentStackedColumn':
		case 'percentStackedHorizontalBar':
		case 'line':
		case 'multiLine':
		case 'area':
		case 'multiArea':
		case 'stackedArea':
		case 'waterfall':
			orderBy =
				config[chartType]?.yAxisOrder !== 'NONE'
					? config[chartType]?.valueColumn
					: config[chartType]?.labelColumn;
			axisOrder =
				config[chartType]?.xAxisOrder !== 'NONE'
					? config[chartType]?.xAxisOrder
					: config[chartType]?.yAxisOrder;
			break;
		case 'combination':
		case 'multiCombination':
			orderBy =
				config[chartType]?.yAxisOrderColumn !== 'NONE'
					? config.combination?.series?.find(
							(s) => s.seriesChartType === 'Column'
					  )?.valueColumn
					: config[chartType]?.yAxisOrderLine !== 'NONE'
					? config.combination?.series?.find(
							(s) => s.seriesChartType === 'Line'
					  )?.valueColumn
					: config.combination?.series[0].labelColumn;
			axisOrder =
				config[chartType]?.yAxisOrderColumn !== 'NONE'
					? config[chartType]?.yAxisOrderColumn
					: config[chartType]?.yAxisOrderLine !== 'NONE'
					? config[chartType]?.yAxisOrderLine
					: config[chartType]?.xAxisOrder;
			break;
		case 'activityGauge':
			orderBy =
				config.activityGauge?.labelColumn ?? config[chartType]?.valueColumn;
			axisOrder = config[chartType]?.yAxisOrder;
			break;
		case 'dumbbell':
		case 'donut':
			orderBy = config.dumbbell?.labelColumn ?? config[chartType]?.valueColumn;
			axisOrder = config[chartType]?.yAxisOrder;
			break;
		case 'radar':
			orderBy = config.radar?.labelColumn;
			axisOrder = config.radar?.xAxisOrder;
			break;
		case 'heatMap':
			chartGridInfo = {
				xColumn: config[chartType].categoryColumn,
				yColumn: config[chartType].labelColumn,
				valueColumn: config[chartType].valueColumn,
			} as ChartGridInfo;
			break;
		case 'bubble':
			/*
			 * Sorting from Largest bubble to smallest bubble
			 * so that small ones will always on top for each series
			 */
			orderBy = config[chartType].categorySizeColumn;
			axisOrder = 'DESC';
			break;
		default:
	}
	const orderDirection =
		(chartType !== 'dumbbell' && axisOrder === 'ASC') ||
		(chartType === 'dumbbell' && axisOrder === 'DESC')
			? Varicent.Domain.SQL.OrderItem.OrderDirection.Ascending
			: (chartType !== 'dumbbell' && axisOrder === 'DESC') ||
			  (chartType === 'dumbbell' && axisOrder === 'ASC')
			? Varicent.Domain.SQL.OrderItem.OrderDirection.Descending
			: undefined;

	if (chartType === 'multiCombination') {
		return {
			orderBy: orderDirection ? orderBy : undefined,
			orderDirection,
		};
	}

	return {
		orderBy: orderDirection ? orderBy : undefined,
		orderDirection,
		chartGridInfo,
	};
};

const getChartInfoForChart = (options: {
	chartType: ChartType;
	actualColumn: string;
	targetColumn:
		| string
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO;
	minimumColumn:
		| string
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO;
	maximumColumn:
		| string
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO;
	labelColumn: string;
	valueColumn: string;
	lowColumn: string;
	highColumn: string;
	categoryColumn: string;
	categorySizeColumn: string;
	areaValueColumn: string;
	columnValueColumn: string;
	lineValueColumn: string;
	series: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartCombinationSeriesDTO[];
}):
	| {
			aggregateInfo?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
	  }
	| {
			aggregateInfo: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO[];
	  } => {
	let aggregateInfo;
	switch (options.chartType) {
		case 'bar':
		case 'horizontalBar':
		case 'pie':
		case 'donut':
		case 'treemap':
		case 'activityGauge':
		case 'waterfall':
		case 'area':
			aggregateInfo = {
				groupByNames: [options.labelColumn],
				valueNames: [options.valueColumn],
			} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
			break;
		case 'dumbbell':
			aggregateInfo = {
				groupByNames: [options.labelColumn],
				valueNames: [options.lowColumn, options.highColumn],
			} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
			break;
		case 'combination':
			aggregateInfo = {
				groupByNames: [options.series?.[0]?.labelColumn],
				valueNames: options.series?.map((s) => s.valueColumn),
			} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
			break;
		case 'multiCombination':
			aggregateInfo =
				(options.series?.map((x) => {
					return {
						groupByNames: [x.labelColumn],
						valueNames: [x.valueColumn],
					} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
				}) as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO[]) ??
				undefined;
			break;
		case 'bullet':
		case 'simpleGauge':
			aggregateInfo = undefined;
			break;
		case 'bubble':
			aggregateInfo = {
				groupByNames: [options.categoryColumn],
				valueNames: [
					options.labelColumn,
					options.valueColumn,
					options.categorySizeColumn,
				],
			} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
			break;
		case 'radar':
			aggregateInfo = {
				groupByNames: [options.labelColumn],
				valueNames: [
					options.areaValueColumn,
					options.columnValueColumn,
					options.lineValueColumn,
				],
			} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
			break;
		case 'heatMap':
			aggregateInfo = {
				groupByNames: [options.labelColumn, options.categoryColumn],
				valueNames: [options.valueColumn],
			} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
			break;
		case 'kpi': {
			const valueNames = [options.actualColumn];
			if (typeof options.targetColumn === 'string')
				valueNames.push(options.targetColumn);
			if (typeof options.minimumColumn === 'string')
				valueNames.push(options.minimumColumn);
			if (typeof options.maximumColumn === 'string')
				valueNames.push(options.maximumColumn);
			aggregateInfo = {
				groupByNames: [],
				valueNames,
			} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
			break;
		}
		default:
			break;
	}

	return { aggregateInfo };
};

const getPivotInfoForChart = (
	chartType: ChartType,
	labelColumn: string,
	valueColumn: string,
	categoryColumn: string,
	series: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartMultiCombinationSeriesDTO[]
):
	| {
			pivotInfo?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPivotColumnInfoDTO;
	  }
	| {
			pivotInfo: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPivotColumnInfoDTO[];
	  } => {
	if (chartType === 'multiCombination') {
		return {
			pivotInfo: series?.map((s) => {
				return {
					groupByName: s.labelColumn,
					categoryName: s.categoryColumn,
					valueName: s.valueColumn,
				};
			}) ?? [
				{
					groupByName: undefined,
					categoryName: undefined,
					valueName: undefined,
				},
			],
		};
	}

	const pivotInfo =
		chartType === 'multiLine' ||
		chartType === 'multiScatter' ||
		chartType === 'multiArea' ||
		chartType === 'stackedArea' ||
		chartType === 'clusteredColumn' ||
		chartType === 'clusteredHorizontalBar' ||
		chartType === 'stackedColumn' ||
		chartType === 'stackedHorizontalBar' ||
		chartType === 'percentStackedColumn' ||
		chartType === 'percentStackedHorizontalBar'
			? {
					groupByName: labelColumn,
					categoryName: categoryColumn,
					valueName: valueColumn,
			  }
			: undefined;

	return { pivotInfo };
};

const getFakeParamForChart = (
	xAxisMin: string,
	xAxisMax: string,
	yAxis: {
		yAxisMin?: string;
		yAxisMax?: string;
		decimalPlaces?: number;
	}[]
): {
	xMinimum?: number;
	xMaximum?: number;
	yAxisOptions: {
		yMinimum?: number;
		yMaximum?: number;
		decimal?: number;
	}[];
} => {
	const xMin =
		xAxisMin !== undefined && !isEmpty(xAxisMin) ? Number(xAxisMin) : undefined;
	const xMax =
		xAxisMax !== undefined && !isEmpty(xAxisMax) ? Number(xAxisMax) : undefined;
	return {
		xMinimum: xMin,
		xMaximum: xMax,
		yAxisOptions: yAxis.map((axis) => {
			return {
				yMinimum:
					axis.yAxisMin !== undefined && !isEmpty(axis.yAxisMin)
						? Number(axis.yAxisMin)
						: undefined,
				yMaximum:
					axis.yAxisMax !== undefined && !isEmpty(axis.yAxisMax)
						? Number(axis.yAxisMax)
						: undefined,
				decimal: axis.decimalPlaces,
			};
		}),
	};
};

export const isDataRowEmpty: (arg?: Record<string, any>) => boolean = R.pipe(
	flattenObject,
	R.pickBy(R.complement(isEmptyOrNil)), // remove any '' or [] or {}
	R.keys,
	R.length,
	R.equals(0)
);

export default withErrorBoundary(memo(Chart), {
	FallbackComponent: ({ error }) => {
		return <MissingDataPlaceholder message={error?.message} />;
	},
});
