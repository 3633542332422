/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import useDataSource, { RowStreamResult } from '../../utils/useDataSource';
import { InjectedIntl, defineMessages } from 'react-intl';
import { Varicent } from 'icm-rest-client';
import { MissingDataPlaceholder } from '../../utils/placeholder';
import R from 'ramda';
import {
	NumberFormatAbbreviation,
	ThousandsSeparatorFormat,
	NumberFormatOption,
} from '../../utils/dataGridStyling';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import {
	Activity20,
	Analytics20,
	ArrowDown20,
	ArrowLeft20,
	ArrowUp20,
	ArrowRight20,
	Calendar20,
	Checkmark20,
	Dashboard20,
	FaceDissatisfied20,
	FaceNeutral20,
	FaceSatisfied20,
	Gift20,
	MeterAlt20,
	Model20,
	PiggyBank20,
	Sprout20,
	Star20,
	User20,
} from '@carbon/icons-react';
import { Icon } from '@varicent/components';
import {
	applyThousandsSeparator,
	getAbbreviation,
	buildNumberFormatProps,
	getDefaultValueFormat,
} from '../../utils/valueFormatUtils';
import { NormalizedColumnType } from 'icm-core/lib/utils/dbUtils';

export const defaultFontColor = '#666666';
export const invertedFontColor = '#FFFFFF';
export const emptyDateString = 'None';
export const maxRowLimit = 5000;

export type ChartType = keyof Omit<
	Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO,
	'extra'
>;

export type ColumnNameToTypeMap = ReturnType<
	typeof useDataSource
>['columnNameToType'];

export type ChartOptionArg = {
	existingOptions: Highcharts.Options;
	data: RowStreamResult;
	intl: InjectedIntl;
	invertFontColors: boolean | undefined;
	palette?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPaletteDTO;
};

export type FormatValueType = 'bold' | 'italic' | 'underline' | 'strikeout';

export type IconType =
	| 'calendar'
	| 'user'
	| 'sprout'
	| 'gift'
	| 'dashboard'
	| 'meter'
	| 'analytics'
	| 'activity'
	| 'piggybank'
	| 'checkmark'
	| 'star'
	| 'model'
	| 'up'
	| 'right'
	| 'down'
	| 'left'
	| 'satisfied'
	| 'neutral'
	| 'dissatisfied';

export type AlignmentType =
	| 'left'
	| 'horizontalcenter'
	| 'right'
	| 'top'
	| 'verticalcenter'
	| 'bottom';

interface NumberFormatProps {
	value: number;
	valueFormat?: string | undefined;
	currencyCode?: string | undefined;
	prefix?: string | undefined;
	suffix?: string | undefined;
	decimalPlaces?: number | undefined;
	displayUnits?: NumberFormatAbbreviation | undefined;
	thousandsSeparator?: ThousandsSeparatorFormat | undefined;
}

const DeepHideCheckCharts = ['kpi'];

export const formatDateUTC: (value: any, intl: InjectedIntl) => string = (
	value,
	intl
) => {
	if (value === emptyDateString || R.isEmpty(value)) {
		return intl.formatMessage(messages.emptyDateLabel);
	}

	return intl.formatDate(value, {
		timeZone: 'UTC',
	});
};

export const hasUndefinedEmptyColumns: (
	cols: (string | undefined)[]
) => boolean = (cols) => {
	return cols.includes(undefined) || cols.includes('');
};

export const hasMissingColumns: (
	cols: (string | undefined)[],
	data: object[],
	columnNameToType: ColumnNameToTypeMap
) => boolean = (cols, data, columnNameToType) => {
	if (hasUndefinedEmptyColumns(cols)) {
		return true;
	}
	if (data.length === 0) {
		return false;
	}
	return !!cols.find(
		(col) =>
			col !== undefined &&
			(data[0][col] === undefined || !columnNameToType[col])
	);
};

export const hasMissingTargetColumn: (
	cols: (string | undefined)[],
	data: object[],
	columnNameToType: ColumnNameToTypeMap,
	config: Partial<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO>,
	type: any
) => boolean = (cols, data, columnNameToType, config, type) => {
	const chartConfig = config[type];
	if (chartConfig?.useCustomTarget) {
		return !chartConfig?.customTarget;
	}
	return hasMissingColumns(cols, data, columnNameToType);
};

export const hasMissingRangeColumns: (
	cols: (string | undefined)[],
	data: object[],
	columnNameToType: ColumnNameToTypeMap,
	config: Partial<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO>,
	type: any
) => boolean = (cols, data, columnNameToType, config, type) => {
	const chartConfig = config[type];
	if (chartConfig?.useCustomRange) {
		return (
			!chartConfig?.minRangeMin ||
			!chartConfig?.minRangeMax ||
			!chartConfig?.medRangeMin ||
			!chartConfig?.medRangeMax ||
			!chartConfig?.maxRangeMin ||
			!chartConfig?.maxRangeMax
		);
	}
	return hasMissingColumns(cols, data, columnNameToType);
};

export const getChartTypeFromConfig = (
	config: Partial<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO>
) => {
	if (config.bar) {
		return 'bar' as const;
	}
	if (config.horizontalBar) {
		return 'horizontalBar' as const;
	}
	if (config.line) {
		return 'line' as const;
	}
	if (config.multiLine) {
		return 'multiLine' as const;
	}
	if (config.area) {
		return 'area' as const;
	}
	if (config.multiArea) {
		return 'multiArea' as const;
	}
	if (config.stackedArea) {
		return 'stackedArea' as const;
	}
	if (config.pie) {
		return 'pie' as const;
	}
	if (config.scatter) {
		return 'scatter' as const;
	}
	if (config.multiScatter) {
		return 'multiScatter' as const;
	}
	if (config.treemap) {
		return 'treemap' as const;
	}
	if (config.activityGauge) {
		return 'activityGauge' as const;
	}
	if (config.dumbbell) {
		return 'dumbbell' as const;
	}
	if (config.clusteredColumn) {
		return 'clusteredColumn' as const;
	}
	if (config.clusteredHorizontalBar) {
		return 'clusteredHorizontalBar' as const;
	}
	if (config.stackedColumn) {
		return 'stackedColumn' as const;
	}
	if (config.stackedHorizontalBar) {
		return 'stackedHorizontalBar' as const;
	}
	if (config.percentStackedColumn) {
		return 'percentStackedColumn' as const;
	}
	if (config.percentStackedHorizontalBar) {
		return 'percentStackedHorizontalBar' as const;
	}
	if (config.combination) {
		return 'combination' as const;
	}
	if (config.multiCombination) {
		return 'multiCombination' as const;
	}
	if (config.waterfall) {
		return 'waterfall' as const;
	}
	if (config.bullet) {
		return 'bullet' as const;
	}
	if (config.simpleGauge) {
		return 'simpleGauge' as const;
	}
	if (config.donut) {
		return 'donut' as const;
	}
	if (config.bubble) {
		return 'bubble' as const;
	}
	if (config.radar) {
		return 'radar' as const;
	}
	if (config.heatMap) {
		return 'heatMap' as const;
	}
	if (config.kpi) {
		return 'kpi' as const;
	}
	throw new Error(`Unknown chart type`);
};

export const getMultiColNames = (chartType: string) => {
	switch (chartType) {
		case 'multiScatter':
		case 'scatter':
			return ['X', 'Y'];
		case 'bubble':
			return ['X', 'Y', 'Z'];
		case 'combination':
		case 'multiCombination':
			return ['Column', 'Line'];
		case 'simpleGauge':
			return ['actual', ''];
		case 'donut':
		case 'pie':
		case 'treemap':
			return ['AbsValue', 'PctValue'];
		default:
			return [''];
	}
};

export const valueFormatTypes = ['percentage', 'number', 'currency', 'custom'];

export const getValueFormat = (
	valueFormat: string | undefined,
	valueFormatType: string | undefined,
	defaultFormat?: string
) => {
	return valueFormatType && valueFormatTypes.includes(valueFormatType)
		? valueFormatType
		: valueFormat && valueFormat !== 'numeric'
		? 'currency'
		: defaultFormat ?? 'number';
};

export const getCurrencySymbol: (
	currencyCode: string | undefined
) => string | undefined = (currencyCode) => {
	return (
		currencyCode &&
		Intl.NumberFormat('en', {
			style: 'currency',
			currency: currencyCode,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0,
		})
			.format(0)
			.replace('0', '')
	);
};

export const getFormattedLabels: (
	rows: Record<string, any>[],
	labelColumn: string
) => any = (rows, labelColumn) => {
	return rows.map((row) => {
		if (typeof row[labelColumn] === 'number') {
			return Number(row[labelColumn].toFixed());
		}

		return row[labelColumn];
	});
};

export const simplifiedFormatNumber: (props: {
	value: number;
	config: any;
	multiCol?: string;
	usePrefixSuffix?: boolean;
	expectNumber?: boolean;
	defaultValueFormatType?: string;
}) => string | number = (props) => {
	const {
		value,
		config,
		multiCol = '',
		usePrefixSuffix = true,
		expectNumber = false,
		defaultValueFormatType = getDefaultValueFormat(config),
	} = props;

	const {
		valueFormat,
		decimalPlaces,
		displayUnits,
		thousandsSeparator,
		prefix,
		suffix,
		currencyCode,
	} = buildNumberFormatProps(config, multiCol, defaultValueFormatType);

	const values: NumberFormatProps = {
		value,
		valueFormat,
		currencyCode,
		decimalPlaces,
		displayUnits,
		thousandsSeparator,
	};

	if (
		expectNumber &&
		(valueFormat === 'number' || valueFormat === 'custom') &&
		!prefix &&
		!suffix
	) {
		return Number(formatNumber({ ...values }));
	}

	if (usePrefixSuffix) {
		values.prefix = prefix;
		values.suffix = suffix;
	}

	return formatNumber(values);
};

export const formatNumber = (props: NumberFormatProps) => {
	const {
		value,
		valueFormat,
		currencyCode,
		prefix = '',
		suffix = '',
		decimalPlaces = valueFormat === NumberFormatOption.PERCENTAGE ||
		valueFormat === NumberFormatOption.CURRENCY
			? 2
			: 0,
		displayUnits,
		thousandsSeparator,
	} = props;
	const { adjustedValue = undefined, abbrevSymbol = '' } =
		valueFormat !== NumberFormatOption.PERCENTAGE && displayUnits
			? getAbbreviation(value, displayUnits)
			: {};

	// Forcing an English locale here to preserve numeric formatting
	const intlFormattedValue = Intl.NumberFormat('en', {
		style:
			valueFormat === NumberFormatOption.PERCENTAGE
				? 'percent'
				: valueFormat === NumberFormatOption.CURRENCY
				? 'currency'
				: undefined,
		currency: currencyCode,
		minimumFractionDigits: decimalPlaces,
		maximumFractionDigits: decimalPlaces,
	}).format(adjustedValue ?? value);

	const formattedValue = thousandsSeparator
		? applyThousandsSeparator(intlFormattedValue, thousandsSeparator)
		: intlFormattedValue;

	return valueFormat === NumberFormatOption.PERCENTAGE
		? formattedValue
		: valueFormat === NumberFormatOption.CUSTOM
		? `${prefix}${formattedValue}${abbrevSymbol}${suffix}`
		: `${formattedValue}${abbrevSymbol}`;
};

export const convertXAxisOrientation: (
	xAxisOrientation: string | number | undefined
) => number = (xAxisOrientation) => {
	return Number.isNaN(Number(xAxisOrientation))
		? xAxisOrientation === 'vertical'
			? -90
			: xAxisOrientation === 'horizontal'
			? 0
			: xAxisOrientation === 'slanted'
			? -45
			: 0
		: Number(xAxisOrientation);
};

export const isEmptyOrNil = (value: any) => {
	return R.isNil(value) || R.isEmpty(value);
};

export const isDataInsufficient = (
	chartType: ChartType,
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO,
	data: RowStreamResult | RowStreamResult[],
	columnNameToType: ColumnNameToTypeMap
) => {
	let undefinedColumnsToCheck = [] as string[];
	let missingColumnsToCheck = [] as string[];
	switch (chartType) {
		case 'bar':
		case 'horizontalBar':
		case 'pie':
		case 'donut':
		case 'treemap':
		case 'activityGauge':
		case 'waterfall':
		case 'line':
		case 'scatter':
		case 'area':
			missingColumnsToCheck = [
				config[chartType].labelColumn,
				config[chartType].valueColumn,
			];
			break;
		case 'dumbbell':
			missingColumnsToCheck = [
				config[chartType].labelColumn,
				config[chartType].lowColumn,
				config[chartType].highColumn,
			];
			break;
		case 'multiLine':
		case 'multiScatter':
		case 'multiArea':
		case 'stackedArea':
		case 'clusteredColumn':
		case 'clusteredHorizontalBar':
		case 'stackedColumn':
		case 'stackedHorizontalBar':
		case 'percentStackedColumn':
		case 'percentStackedHorizontalBar':
		case 'heatMap':
			missingColumnsToCheck = [config[chartType].labelColumn];
			undefinedColumnsToCheck = [
				config[chartType].valueColumn,
				config[chartType].categoryColumn,
			];
			break;
		case 'combination':
			missingColumnsToCheck = config[chartType].series?.reduce(
				(acc, series) => acc.concat([series.labelColumn, series.valueColumn]),
				[] as string[]
			) ?? [undefined];
			break;
		case 'multiCombination':
			missingColumnsToCheck = [config[chartType].series?.[0].labelColumn];
			undefinedColumnsToCheck = config[chartType].series?.reduce(
				(acc, series) =>
					acc.concat([series.valueColumn, series.categoryColumn]),
				[] as string[]
			) ?? [undefined];
			break;
		case 'kpi':
		case 'bullet':
		case 'simpleGauge':
			missingColumnsToCheck = [config[chartType].actualColumn];
			break;
		case 'bubble':
			missingColumnsToCheck = [config[chartType].labelColumn];
			undefinedColumnsToCheck = [
				config[chartType].valueColumn,
				config[chartType].categoryColumn,
				config[chartType].categorySizeColumn,
			];
			break;
		case 'radar':
			missingColumnsToCheck = [
				config[chartType].labelColumn,
				config[chartType].columnValueColumn,
				config[chartType].lineValueColumn,
				config[chartType].areaValueColumn,
			];
			break;
		default:
			break;
	}
	return (
		(missingColumnsToCheck.length &&
			(Array.isArray(data)
				? data.some((d) =>
						hasMissingColumns(missingColumnsToCheck, d.rows, columnNameToType)
				  )
				: hasMissingColumns(
						missingColumnsToCheck,
						data.rows,
						columnNameToType
				  ))) ||
		(undefinedColumnsToCheck.length &&
			hasUndefinedEmptyColumns(undefinedColumnsToCheck))
	);
};

export const getCssAlignment = (type: AlignmentType) => {
	switch (type) {
		case 'horizontalcenter':
		case 'verticalcenter':
			return 'center';
		case 'left':
		case 'top':
			return 'flex-start';
		case 'right':
		case 'bottom':
			return 'flex-end';
		default:
			throw new Error(`Unknown alignment type`);
	}
};

export const isChartDataEmpty = (props: {
	data: RowStreamResult | RowStreamResult[];
	chartType: string;
	columnNameToType: Record<string, NormalizedColumnType>;
}) => {
	const { data, chartType, columnNameToType } = props;

	if (!DeepHideCheckCharts.includes(chartType)) {
		return true;
	}

	if (Array.isArray(data)) {
		return data.every((v) =>
			isEmptyValueByColumnType({ rows: v.rows, columnNameToType })
		);
	}

	return isEmptyValueByColumnType({ rows: data.rows, columnNameToType });
};

const isEmptyValueByColumnType = (props: {
	rows: Record<string, any>[];
	columnNameToType: Record<string, NormalizedColumnType>;
}) => {
	const { rows, columnNameToType } = props;

	return rows.every((r) => {
		let notEmptyValue = true;
		for (const key in columnNameToType) {
			if (r[key] !== undefined) {
				switch (columnNameToType[key]) {
					case 'numeric':
						notEmptyValue = r[key] !== '';
						break;
					case 'date':
					case 'datetime':
					case 'text':
					default:
						notEmptyValue = true;
				}
			}
		}
		return notEmptyValue;
	});
};

export const getIcon = (type: IconType, customStyle?: React.CSSProperties) => {
	return (
		<Icon style={customStyle}>
			{type === 'calendar' ? (
				<Calendar20 />
			) : type === 'user' ? (
				<User20 />
			) : type === 'sprout' ? (
				<Sprout20 />
			) : type === 'gift' ? (
				<Gift20 />
			) : type === 'dashboard' ? (
				<Dashboard20 />
			) : type === 'meter' ? (
				<MeterAlt20 />
			) : type === 'analytics' ? (
				<Analytics20 />
			) : type === 'activity' ? (
				<Activity20 />
			) : type === 'piggybank' ? (
				<PiggyBank20 />
			) : type === 'checkmark' ? (
				<Checkmark20 />
			) : type === 'star' ? (
				<Star20 />
			) : type === 'model' ? (
				<Model20 />
			) : type === 'up' ? (
				<ArrowUp20 />
			) : type === 'right' ? (
				<ArrowRight20 />
			) : type === 'down' ? (
				<ArrowDown20 />
			) : type === 'left' ? (
				<ArrowLeft20 />
			) : type === 'satisfied' ? (
				<FaceSatisfied20 />
			) : type === 'neutral' ? (
				<FaceNeutral20 />
			) : (
				<FaceDissatisfied20 />
			)}
		</Icon>
	);
};

export const ChartErrorPlaceholder: React.FC<
	{
		chartType: string;
		errorMessage: string;
	} & React.HTMLAttributes<HTMLDivElement>
> = ({ chartType, errorMessage, ...otherProps }) => {
	const intl = useIntl();
	const errorTitle = intl.formatMessage(messages.unableToRenderChart, {
		chartType,
	});

	return (
		<MissingDataPlaceholder message={errorMessage} {...otherProps}>
			{errorTitle}
			<br /> <br />
			{errorMessage}
		</MissingDataPlaceholder>
	);
};

export const messages = defineMessages({
	rowCountExceeded: {
		id: 'components.chart.rowCountExceeded',
		defaultMessage:
			'Too many rows provided for chart type {chartType}, the maximum allowed is {max}.',
	},
	totalRowCountExceeded: {
		id: 'components.chart.totalRowCountExceeded',
		defaultMessage: 'Too many rows provided, the maximum allowed is {max}.',
	},
	liveDataHideData: {
		id: 'components.chart.liveDataHideData',
		defaultMessage:
			'This object will be hidden on Sales Portal since there is no data to display.',
	},
	emptyDateLabel: {
		id: 'components.chart.emptyDateLabel',
		defaultMessage: 'None',
	},
	unableToRenderChart: {
		id: 'components.chart.unableToRenderChart',
		defaultMessage: 'Unable to render {chartType}',
	},
	invalidRange: {
		id: 'components.chart.invalidRange',
		defaultMessage: 'Invalid range values provided.',
	},
	invalidYAxisMinMax: {
		id: 'components.chart.invalidYAxisMinMax',
		defaultMessage: 'Invalid Y-Axis min and max values provided.',
	},
	invalidXAxisMinMax: {
		id: 'components.chart.invalidXAxisMinMax',
		defaultMessage: 'Invalid X-Axis min and max values provided.',
	},
	minimumRange: {
		id: 'components.chart.minimumRange',
		defaultMessage: 'Minimum range',
	},
	mediumRange: {
		id: 'components.chart.mediumRange',
		defaultMessage: 'Middle range',
	},
	maximumRange: {
		id: 'components.chart.maximumRange',
		defaultMessage: 'Maximum range',
	},
	target: {
		id: 'components.chart.target',
		defaultMessage: 'Target',
	},
	rowsDuplicated: {
		id: 'components.chart.rowDuplicated',
		defaultMessage: 'Duplicated rows provided for chart type {chartType}.',
	},
	activityGauge: {
		id: 'components.chart.activityGauge',
		defaultMessage: 'Activity Gauge',
	},
	bullet: {
		id: 'components.chart.bullet',
		defaultMessage: 'Bullet',
	},
	simpleGauge: {
		id: 'components.chart.simpleGauge',
		defaultMessage: 'Simple Gauge',
	},
	column: {
		id: 'components.chart.column',
		defaultMessage: 'Column',
	},
	bar: {
		id: 'components.chart.bar',
		defaultMessage: 'Bar',
	},
	clusteredColumn: {
		id: 'components.chart.clusteredColumn',
		defaultMessage: 'Clustered Column',
	},
	clusteredBar: {
		id: 'components.chart.clusteredBar',
		defaultMessage: 'Clustered Bar',
	},
	stackedColumn: {
		id: 'components.chart.stackedColumn',
		defaultMessage: 'Stacked Column',
	},
	stackedBar: {
		id: 'components.chart.stackedBar',
		defaultMessage: 'Stacked Bar',
	},
	percentStackedColumn: {
		id: 'components.chart.percentStackedColumn',
		defaultMessage: '100% Stacked Column',
	},
	percentStackedBar: {
		id: 'components.chart.percentStackedBar',
		defaultMessage: '100% Stacked Bar',
	},
	line: {
		id: 'components.chart.line',
		defaultMessage: 'Line',
	},
	multiLine: {
		id: 'components.chart.multiLine',
		defaultMessage: 'Multiple lines',
	},
	area: {
		id: 'components.chart.area',
		defaultMessage: 'Area',
	},
	multiArea: {
		id: 'components.chart.multiArea',
		defaultMessage: 'Multiple area',
	},
	stackedArea: {
		id: 'components.chart.stackedArea',
		defaultMessage: 'Stacked area',
	},
	combination: {
		id: 'components.chart.combination',
		defaultMessage: 'Line and column',
	},
	multiCombination: {
		id: 'components.chart.multiCombination',
		defaultMessage: 'Lines and columns',
	},
	pie: {
		id: 'components.chart.pie',
		defaultMessage: 'Pie',
	},
	donut: {
		id: 'components.chart.donut',
		defaultMessage: 'Donut',
	},
	scatter: {
		id: 'components.chart.scatter',
		defaultMessage: 'Scatter',
	},
	multiScatter: {
		id: 'components.chart.multiScatter',
		defaultMessage: 'Multiple scatter',
	},
	treeMap: {
		id: 'components.chart.treeMap',
		defaultMessage: 'Treemap',
	},
	dumbbell: {
		id: 'components.chart.dumbbell',
		defaultMessage: 'Dumbbell',
	},
	waterfall: {
		id: 'components.chart.waterfall',
		defaultMessage: 'Waterfall',
	},
	bubble: {
		id: 'components.chart.bubble',
		defaultMessage: 'Bubble',
	},
	radar: {
		id: 'components.chart.radar',
		defaultMessage: 'Radar',
	},
	heatMap: {
		id: 'components.chart.heatMap',
		defaultMessage: 'Heat map',
	},
	kpi: {
		id: 'components.chart.kpi',
		defaultMessage: 'KPI',
	},
	loading: {
		id: 'components.chart.loading',
		defaultMessage: 'Loading...',
	},
});
