/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { generateColorRange } from '../../../utils/colors';
import { NumberFormatOption } from '../../../utils/dataGridStyling';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	formatDateUTC,
	simplifiedFormatNumber,
	invertedFontColor,
} from '../chartUtil';

declare module 'highcharts' {
	export interface Legend {
		legendWidth: number;
		legendHeight: number;
	}
}

export const activityGaugeChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartActivityGaugeDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
		palette,
	} = props;
	const {
		labelColumn,
		valueColumn,
		legendPosition,
		showLegend,
		showYMinMax,
		yAxisMax,
	} = config;
	const type = 'solidgauge';

	// solidgauge and gauge don't support colorAxis
	const getColor = generateColorRange(
		data.rows.map((row) => row[labelColumn]),
		palette?.config.classification?.paletteColors
	);
	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const tooltip: Highcharts.TooltipOptions = {
		borderWidth: 0,
		backgroundColor: 'none',
		shadow: false,
		style: {
			fontSize: '16px',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		pointFormatter: function formatter() {
			const value = this.y
				? simplifiedFormatNumber({
						value: this.y || 0,
						config,
						defaultValueFormatType: NumberFormatOption.PERCENTAGE,
				  })
				: null;

			return `${this.series.name}<br><span style="font-size:2em; color: ${this.color}; font-weight: bold;">${value}</span>`;
		},
		positioner(labelWidth, labelHeight) {
			const xLegendOffset = showLegend
				? legendPosition === 'left'
					? this.chart.legend.legendWidth / 2
					: legendPosition === 'right'
					? -this.chart.legend.legendWidth / 2
					: 0
				: 0;
			const yLegendOffset =
				showLegend && legendPosition === 'top'
					? this.chart.legend.legendHeight
					: 0;
			return {
				x: (this.chart.chartWidth - labelWidth) / 2 + xLegendOffset,
				y: (this.chart.plotHeight - (2 * labelHeight) / 3) / 2 + yLegendOffset,
			};
		},
	};

	const yAxis: Highcharts.YAxisOptions = {
		min: 0,
		max: showYMinMax && yAxisMax && yAxisMax !== '' ? Number(yAxisMax) : null,
		lineWidth: 0,
		tickPositions: [],
	};

	const plotOptions: Highcharts.PlotOptions = {
		solidgauge: {
			dataLabels: {
				enabled: false,
			},
			linecap: 'round',
			stickyTracking: false,
			rounded: true,
			showInLegend: showLegend,
		},
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? false,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormat: '{name}',
		layout:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? 'vertical'
				: 'horizontal',
		align:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? legendPosition
				: 'center',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: legendPosition === 'left' || legendPosition === 'right'
				? 'middle'
				: 'bottom',
	};

	const maxGaugeSize = 115;
	const radiusThickness = 15;
	const getOuterRadius = (index: number) => {
		return maxGaugeSize - radiusThickness * index;
	};
	const getInnerRadius = (index: number) => {
		return maxGaugeSize - radiusThickness * (index + 1);
	};

	const seriesOptions = data.rows.map((row: any, index) => {
		const radius = getOuterRadius(index);
		const innerRadius = getInnerRadius(index);
		const series: Highcharts.SeriesOptionsType = {
			type: type as any,
			name: labelIsDateBased
				? `${formatDateUTC(row[labelColumn], intl)}`
				: row[labelColumn],
			marker: {
				fillColor: getColor(row[labelColumn]),
			},
			data: [
				{
					radius: `${radius}%`,
					innerRadius: `${innerRadius}%`,
					y: row[valueColumn],
					color: getColor(row[labelColumn]),
				},
			],
		};
		return series;
	}) as Highcharts.SeriesOptionsType[];

	const backgroundOptions = data.rows.map((row: any, index) => {
		const outerRadius = getOuterRadius(index);
		const innerRadius = getInnerRadius(index);
		const bgOptions: Highcharts.PaneBackgroundOptions = {
			outerRadius: `${outerRadius}%`,
			innerRadius: `${innerRadius}%`,
			backgroundColor: new Highcharts.Color(getColor(row[labelColumn]))
				.setOpacity(0.3)
				.get(),
			borderWidth: 0,
		};

		return bgOptions;
	}) as Highcharts.PaneBackgroundOptions[];

	const options = {
		...existingOptions,
		pane: {
			startAngle: 0,
			endAngle: 360,
			background: backgroundOptions,
		},
		yAxis,
		tooltip,
		plotOptions,
		legend,
		series: seriesOptions,
	};
	return options;
};
