/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	NumberFormatAbbreviation,
	ThousandsSeparatorFormat,
	NumberFormatOption,
} from './dataGridStyling';

export const applyThousandsSeparator = (
	value: string,
	separator: ThousandsSeparatorFormat
) => {
	const regex = new RegExp(/[,.]/g);
	switch (separator) {
		case ThousandsSeparatorFormat.PERIOD_COMMA:
			return value.replace(regex, (c) => {
				if (c === '.') {
					return ',';
				}
				if (c === ',') {
					return '.';
				}
				return c;
			});
		case ThousandsSeparatorFormat.SPACE_COMMA:
			return value.replace(regex, (c) => {
				if (c === '.') {
					return ',';
				}
				if (c === ',') {
					return ' ';
				}
				return c;
			});
		case ThousandsSeparatorFormat.SPACE_PERIOD:
			return value.replace(regex, (c) => {
				if (c === ',') {
					return ' ';
				}
				return c;
			});
		case ThousandsSeparatorFormat.COMMA_PERIOD:
			return value.replace(regex, (c) => {
				if (c === ',') {
					return ',';
				}
				return c;
			});
		case ThousandsSeparatorFormat.DEFAULT:
		default:
			return value.replace(regex, (c) => {
				if (c === ',') {
					return '';
				}
				return c;
			});
	}
};

export const getCurrencyCode: (
	currency: string | undefined
) => string | undefined = (currency) => {
	return currency?.startsWith('currency_')
		? (currency.slice('currency_'.length) as string)
		: undefined;
};

export const getAbbreviation = (
	value: number,
	abbreviation: NumberFormatAbbreviation
) => {
	switch (abbreviation) {
		case NumberFormatAbbreviation.THOUSANDS:
			return {
				adjustedValue: value / 1000,
				abbrevSymbol: 'K',
			};
		case NumberFormatAbbreviation.MILLIONS:
			return {
				adjustedValue: value / 1000000,
				abbrevSymbol: 'M',
			};
		case NumberFormatAbbreviation.BILIIONS:
			return {
				adjustedValue: value / 1000000000,
				abbrevSymbol: 'B',
			};
		case NumberFormatAbbreviation.NONE:
		default:
			return {
				adjustedValue: value,
				abbrevSymbol: '',
			};
	}
};

export const getDefaultValueFormat = (config) => {
	if (config.prefix || config.suffix) {
		return NumberFormatOption.CUSTOM;
	}

	if (config.valueFormat?.includes('currency_')) {
		return NumberFormatOption.CURRENCY;
	}

	return NumberFormatOption.NUMBER;
};

const getValueFormatValue = (
	chartConfig: any,
	field: string,
	multiCol: string
) => chartConfig[`${field}${multiCol}`] ?? chartConfig[`${field}`];

export const buildNumberFormatProps = (
	config: any,
	multiCol: string,
	defaultValueFormatType: string
) => {
	const valueFormat = (
		getValueFormatValue(config, 'valueFormatType', multiCol) ??
		defaultValueFormatType
	).toUpperCase();
	const decimalPlaces =
		getValueFormatValue(config, 'labelFormattingDecimalPlaces', multiCol) ?? 2;
	const displayUnits = getValueFormatValue(
		config,
		'labelFormattingDisplayUnits',
		multiCol
	);

	const prefix = getValueFormatValue(config, 'valueFormatPrefix', multiCol);
	const suffix = getValueFormatValue(config, 'valueFormatSuffix', multiCol);
	const currencyCode = getCurrencyCode(
		getValueFormatValue(config, 'valueFormat', multiCol)
	);

	const useThousandsSeparator = getValueFormatValue(
		config,
		'useThousandsSeparator',
		multiCol
	);

	const thousandsSeparator = useThousandsSeparator
		? getValueFormatValue(config, 'thousandsSeparatorFormat', multiCol)
		: ThousandsSeparatorFormat.DEFAULT;

	return {
		valueFormat,
		decimalPlaces,
		displayUnits,
		useThousandsSeparator,
		thousandsSeparator,
		prefix,
		suffix,
		currencyCode,
	};
};
