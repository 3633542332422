/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { forwardRef, useContext, useState } from 'react';
import R, { uniqBy } from 'ramda';
import { ReportContext, LiveDataPayeeContext } from '../context';
import useDataSource from '../utils/useDataSource';
import { Varicent } from 'icm-rest-client';
import { AGDropdownCellEditor, isValueWithError } from '@varicent/components';
import { ICellEditorParams } from '@ag-grid-community/core';
import { makeLikeFilter } from 'icm-core/lib/utils/filterUtils';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { formatValueText } from './pickList';

const MAX_ROW_COUNT = 50;

const AgGridDropdown = forwardRef<
	any,
	ICellEditorParams & {
		picklistConfig: Varicent.RESTAPI.v1.DTOs.PresenterFlex.RowInputColumnDTO.PicklistDetails;
		sourceId: Varicent.ID;
		preview?: boolean;
		additionalDataSourceArgs:
			| {
					reportId: Varicent.ID;
			  }
			| {
					formId: Varicent.ID;
			  };
		/**
		 * Special placeholder item, basically we know the items id but not the display.
		 * A litle cludgy but it works.
		 */
		additionalItem?: any;
		onChange?: (event: any) => void;
		dataGridPayeeId?: string | undefined;
	}
>((props, ref) => {
	const {
		picklistConfig: config,
		sourceId: sourceID,
		additionalDataSourceArgs,
		preview,
		onChange,
	} = props;

	const [query, setQuery] = useState<undefined | string>();

	const intl = useIntl();
	const { sources, sourceSchemas } = useContext(ReportContext);
	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);
	const source = sources?.find((s) => s.sourceId === sourceID);
	const sourceSchema =
		source && sourceSchemas ? sourceSchemas[source.sourceId] : undefined;

	const { data, columnNameToType } = useDataSource({
		source,
		preview,
		previewPayeeId,
		sourceSchema,
		pageSize: MAX_ROW_COUNT,
		filterBy: query ? makeLikeFilter(config.displayColumn, query) : undefined,
		idColumn: config.idColumn,
		orderBy: config.sort === 'Display' ? config.displayColumn : config.idColumn,
		orderDirection:
			config.order === 'DESC'
				? Varicent.Domain.SQL.OrderItem.OrderDirection.Descending
				: Varicent.Domain.SQL.OrderItem.OrderDirection.Ascending,
		...additionalDataSourceArgs,
	});

	const idIsDateBased = columnNameToType[config?.idColumn]?.startsWith('date');
	const additionalItem = data.data &&
		data.data.rows.length > 0 &&
		!data.data.rows.find((r) =>
			r[config.idColumn] === isValueWithError(props.value)
				? props.value.value
				: props.value
		) && {
			[config.idColumn]: isValueWithError(props.value)
				? props.value.value
				: props.value,
			[config.displayColumn]: isValueWithError(props.value)
				? props.value.value
				: props.value,
		};
	const rowsWithAdditionalEntry =
		additionalItem && data.data?.rows
			? [additionalItem, ...data.data.rows]
			: data.data?.rows;

	const options =
		rowsWithAdditionalEntry !== undefined
			? uniqBy(
					(r) => r[config.idColumn] + r[config.displayColumn],
					rowsWithAdditionalEntry ?? []
			  )
			: [];

	if (R.isNil(config)) {
		return null;
	}

	const itemToIdOrText = (isID: boolean) => (item: any) => {
		const displayText =
			'reportId' in (props?.additionalDataSourceArgs ?? {})
				? item[config.displayColumn]
				: item[config.idColumn];

		return formatValueText(
			isID && props?.picklistConfig ? displayText : item[config.displayColumn],
			item[config.idColumn],
			additionalItem &&
				item[config.idColumn] === additionalItem[config.idColumn]
				? true
				: config.hideIDColumn,
			idIsDateBased,
			intl
		);
	};

	return (
		<AGDropdownCellEditor
			itemToId={itemToIdOrText(true)}
			itemToText={itemToIdOrText(false)}
			items={options}
			queryDelay={400}
			setQuery={setQuery}
			queryIsLoading={data.isLoading}
			{...props}
			onChange={(event) => {
				/*
				 * the value begin saved needs to only be id instead of the formated value of the picklist
				 * we will parse it out here.
				 */
				const regExp = /\(([^)]+)\)/;
				const matches = regExp.exec(event.newValue);
				if (matches && onChange) onChange({ ...event, newValue: matches[1] });
			}}
			ref={ref}
		/>
	);
});

export default AgGridDropdown;
