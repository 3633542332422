/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext } from 'react';
import { AnchorButton, Tooltip } from '@blueprintjs/core';
import { defineMessages } from 'react-intl';
import { css } from 'emotion';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { Icon } from '@varicent/components';
import { Forum16 } from '@carbon/icons-react';
import { ColDef, GridApi } from '@ag-grid-community/core';
import { useSelector } from 'react-redux';
import {
	formattedDateString,
	formattedNumberString,
} from '../../utils/dataGridStyling';
import { ValueStore } from '../../context';

const messages = defineMessages({
	copyToRowTitle: {
		id: 'components.datatablecomponents.copytoRow.title',
		defaultMessage: 'Copy the row into an inquiry',
	},
	selectOneRowTitle: {
		id: 'components.datatablecomponents.copytoRow.selectOneRowTitle',
		defaultMessage: 'Select a single row to copy into an inquiry',
	},
});

const HASH_ALIAS = '_HashAlias';

const CopyRowToInquiry: React.FC<{
	gridApi: React.MutableRefObject<GridApi | undefined>;
	oneRowSelected: boolean;
	columnDefs: ColDef[];
}> = ({ gridApi, oneRowSelected, columnDefs }) => {
	const intl = useIntl();
	const title = intl.formatMessage(
		oneRowSelected ? messages.copyToRowTitle : messages.selectOneRowTitle
	);
	const reportValues = useContext(ValueStore);

	const addInquiry = useSelector((state: any) => state.addInquiry);
	const colDefs = columnDefs.map((col) => col.field);
	return (
		<Tooltip content={title}>
			<AnchorButton
				className={css`
					margin-top: 0.313rem;
					margin-left: 0.313rem;
				`}
				minimal
				disabled={
					!addInquiry ||
					!oneRowSelected ||
					!(
						(window as any).ReactNativeWebView ??
						addInquiry?.workflowInquiryComponents?.components?.length
					)
				}
				onClick={() => {
					const selectedRows = gridApi.current?.getSelectedRows();
					let rowValue = '';
					const formColumnMapping = {};
					const copiedRowArray: { columnName: string; columnValue: string }[] =
						[];
					if (gridApi.current && selectedRows?.length === 1) {
						const row = selectedRows[0];
						rowValue += `[${Object.entries(row)
							.sort((a, b) => colDefs.indexOf(a[0]) - colDefs.indexOf(b[0]))
							.filter(([key]) => {
								const columnDef = gridApi.current?.getColumnDef(key);
								return key !== HASH_ALIAS;
							})
							.map(([key, value]: any) => {
								const columnDef = gridApi.current?.getColumnDef(key);
								const columnName =
									columnDef?.cellRendererParams.columnDTO.columnName;
								const columnType =
									columnDef?.cellRendererParams.columnNameToType[columnName];
								const columnConfig = columnDef?.cellRendererParams.columnConfig;
								const columnDisplayName =
									columnDef?.headerValueGetter &&
									typeof columnDef?.headerValueGetter === 'function' &&
									columnDef?.headerValueGetter();
								let colDefValueParsed = value;
								if (columnDef && value) {
									switch (columnType) {
										case 'date':
											colDefValueParsed = formattedDateString(
												value,
												columnConfig,
												intl
											);
											break;
										case 'datetime':
											colDefValueParsed = `${formattedDateString(
												value,
												columnConfig,
												intl
											)} ${intl.formatTime(new Date(value), {
												timeZone: 'UTC',
											})}`;
											break;
										case 'numeric':
											colDefValueParsed = formattedNumberString(
												value as any,
												columnConfig,
												intl
											);
											break;
										case 'picklist':
											colDefValueParsed =
												typeof value === 'string' ? value : '';
											break;
										case 'text':
										default:
											colDefValueParsed = value;
											break;
									}
								}
								if (!columnDef?.hide) {
									copiedRowArray.push({
										columnName: columnDisplayName,
										columnValue: colDefValueParsed,
									});
								}
								if (columnDef?.cellRendererParams.parameterName) {
									formColumnMapping[
										columnDef?.cellRendererParams.parameterName
									] = columnType === 'numeric' ? value : colDefValueParsed;
								}
								return !columnDef?.hide
									? `${columnDisplayName || ''}: ${colDefValueParsed}`
									: '';
							})
							.join(' ')}]`;
					}
					gridApi.current?.deselectAll();
					if ((window as any).ReactNativeWebView) {
						(window as any).ReactNativeWebView.postMessage(
							JSON.stringify({
								action: 'CopyInquiryRow',
								data: { formColumnMapping, copiedRowArray },
							})
						);
					} else {
						window.postMessage(
							{
								messageType: 'PresenterAdaptiveAppendInquiry',
								payload: rowValue,
							},
							'*'
						);
						window.postMessage(
							{
								messageType: 'PresenterAdaptivePopulateFields',
								payload: { formColumnMapping, copiedRowArray },
							},
							'*'
						);
					}
				}}
				aria-label={title}
				icon={
					<Icon>
						<Forum16 title={title} />
					</Icon>
				}
			/>
		</Tooltip>
	);
};

export default CopyRowToInquiry;
