/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { identity, map, pipe, sortBy } from 'ramda';
import { generateColorRange } from '../../../utils/colors';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	formatDateUTC,
	simplifiedFormatNumber,
	invertedFontColor,
} from '../chartUtil';

export const combinationChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartCombinationDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
		palette,
	} = props;
	const {
		xAxisTitle,
		hideXAxisTitle,
		legendPosition,
		showLegend,
		xAxisOrentation,
		xAxisOrder,
		hideXAxisLine,
		hideGridLines,
		markerShape,
		hideMarkers,
		smoothLine,
	} = config;

	const { series } = config;
	// All series have the same label column
	const { labelColumn } = series[0];
	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	/*
	 * allow colorAxis to set colors
	 * in case of sequential or divergent palette
	 */
	const nothing = (_) => undefined;
	const getColor = existingOptions.colorAxis
		? nothing // handled by colorAxis
		: generateColorRange(
				series.map((s) => s.seriesChartType),
				palette?.config.classification?.paletteColors
		  );

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const chartType = this.series.options.id;

			const xValue = labelIsDateBased ? formatDateUTC(this.x, intl) : this.x;
			const yValue = simplifiedFormatNumber({
				value: this.y,
				config,
				multiCol: chartType,
			});
			return `${xValue}: <strong>${yValue}</strong>`;
		},
	};

	const lineOptions = {
		marker: {
			enabled: !hideMarkers ?? true,
			symbol: markerShape ?? undefined,
		},
		allowPointSelect: true,
		cursor: 'pointer',
	};

	const xAxis: Highcharts.XAxisOptions = {
		type: labelIsDateBased ? 'datetime' : undefined,
		categories: !labelIsDateBased
			? data.rows.map((row) => row[labelColumn])
			: undefined,
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		reversed: labelIsDateBased ? xAxisOrder === 'DESC' : false,
		lineWidth: hideXAxisLine ? 0 : 1,
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? false,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormat: '{name}',
		layout:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? 'vertical'
				: 'horizontal',
		align:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? legendPosition
				: 'center',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: legendPosition === 'left' || legendPosition === 'right'
				? 'middle'
				: 'bottom',
	};

	const yAxis: Highcharts.YAxisOptions[] = [];
	const seriesOptions: Highcharts.SeriesOptionsType[] = [];

	series.forEach((s) => {
		const { seriesChartType, valueColumn } = s;
		const type =
			seriesChartType === 'Column' ? 'column' : smoothLine ? 'spline' : 'line';

		let yAxisOptions: Highcharts.YAxisOptions = {};

		const cCurrencyCode = getCurrencyCode(
			config[`valueFormat${seriesChartType}`]
		);
		const cCurrencySymbol =
			cCurrencyCode &&
			intl
				.formatNumber(9, {
					style: 'currency',
					currency: cCurrencyCode,
					maximumFractionDigits: 0,
					minimumFractionDigits: 0,
				})
				.replace('9', '');

		const cShowYMinMax = config[`showYMinMax${seriesChartType}`];
		const cYAxisMin = config[`yAxisMin${seriesChartType}`];
		const cYAxisMax = config[`yAxisMax${seriesChartType}`];
		const cHideYAxisLine = config[`hideYAxisLine${seriesChartType}`];
		const showYAxisMin = cShowYMinMax && !!cYAxisMin && cYAxisMin !== '';
		const showYAxisMax = cShowYMinMax && !!cYAxisMax && cYAxisMax !== '';
		yAxisOptions = {
			title: {
				text: config[`hideYAxisTitle${seriesChartType}`]
					? undefined
					: `${config[`yAxisTitle${seriesChartType}`] || valueColumn}${
							cCurrencySymbol ? ` (${cCurrencySymbol})` : ''
					  }`,
				style: {
					color: invertFontColors ? invertedFontColor : defaultFontColor,
				},
			},
			labels: {
				style: {
					color: invertFontColors ? invertedFontColor : defaultFontColor,
				},
			},
			startOnTick: !showYAxisMin,
			min: showYAxisMin ? Number(cYAxisMin) : null,
			endOnTick: !showYAxisMax,
			max: showYAxisMax ? Number(cYAxisMax) : null,
			gridLineWidth: seriesChartType === 'Line' || hideGridLines ? 0 : 1,
			lineWidth: cHideYAxisLine || cHideYAxisLine === undefined ? 0 : 1,
			opposite: seriesChartType !== 'Column',
		};
		yAxis.push(yAxisOptions);

		const dataRows = pipe(
			map((row: any) => {
				const baseColor = getColor(seriesChartType);
				return {
					name: row[labelColumn],
					x: labelIsDateBased ? row[labelColumn] : undefined,
					y: row[valueColumn],
					color: baseColor,
				};
			}),
			labelIsDateBased ? sortBy((row: any) => row.x) : identity
		)(data.rows);

		const seriesOption: Highcharts.SeriesOptionsType = {
			id: seriesChartType,
			type: type as any,
			name: valueColumn,
			yAxis: seriesChartType === 'Column' ? 0 : 1,
			data: dataRows,
			dataLabels: {},
			color: getColor(seriesChartType),
			marker: {
				fillColor: getColor(seriesChartType),
			},
		};

		const cLabelPosition = config[`labelPosition${seriesChartType}`];

		seriesOption.dataLabels = {
			enabled: config[`showDataValues${seriesChartType}`] ?? false,
			style: {
				fontWeight: 'normal',
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			inside: !!(seriesChartType === 'Column' && cLabelPosition === 'below'),
			align:
				cLabelPosition === 'left'
					? 'right'
					: cLabelPosition === 'right'
					? 'left'
					: 'center',
			verticalAlign:
				cLabelPosition === 'below' || cLabelPosition === 'bottom'
					? 'top'
					: cLabelPosition === 'left' || cLabelPosition === 'right'
					? 'middle'
					: 'bottom',
			formatter: function formatter() {
				return simplifiedFormatNumber({
					value: this.y ?? 0,
					config,
					multiCol: seriesChartType,
				});
			},
		};
		seriesOptions.push(seriesOption);
	});

	const options = {
		...existingOptions,
		plotOptions: {
			spline: lineOptions,
			line: lineOptions,
		},
		xAxis,
		yAxis,
		tooltip,
		legend,
		series: seriesOptions,
	};

	return options;
};
