/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export const VALUE_ENTITY = 'VALUE';
export const DIVIDER_ENTITY = 'DIVIDER';
export const LINK_ENTITY = 'LINK';

export const isEntityLink = (entity?: any) => {
	if (entity?.data) {
		return entity.data?.linkId !== undefined;
	}
	if (entity?.getData) {
		return entity.getData()?.linkId !== undefined;
	}
	return false;
};

const isDataValue = (data: any) => {
	return (
		data.valueId !== undefined ||
		data.rowValue !== undefined ||
		data.special !== undefined
	);
};

export const isEntityValue = (entity?: any) => {
	if (entity?.data) {
		return isDataValue(entity.data);
	}
	if (entity?.getData) {
		const data = entity.getData();
		if (data) {
			return isDataValue(entity.getData());
		}
	}
	return false;
};
