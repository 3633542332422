/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useEffect, useContext, useState } from 'react';
import { Varicent } from 'icm-rest-client';
import { SharedComponentProps } from '../types';
import { css, cx } from 'emotion';
import Placeholder from '../utils/placeholder';
import {
	ReportContext,
	ValueStore,
	ComponentDataLoadedContext,
	LiveDataPayeeContext,
} from '../context';
import usePrevious from 'react-use/esm/usePrevious';
import { Fit, useMeasure } from '../utils/contentRect';
import { deserializeAlignment } from '../utils/objectAlignment';
import { useSelector } from 'react-redux';
import { User20 } from '@carbon/icons-react';
import { useLinkComponent } from '../utils/links';
import Link from 'redux-first-router-link';
import { HTMLLink, colorCobalt2 } from '@varicent/components';
import { FlexComponentTypes } from '../componentTypes';
import { getPayeeProfilePictureFromID } from 'icm-rest-client/lib/controllers/users';

const ConditionalLink: React.FC<
	{
		linkId: number | undefined;
		disableLink?: boolean;
	} & React.HTMLProps<any>
> = ({ linkId, disableLink, ...otherProps }) => {
	const linkInfo = useLinkComponent({
		linkId,
	});

	if (linkInfo && !disableLink) {
		if (linkInfo.clientSide) {
			return <HTMLLink as={Link} {...otherProps} {...linkInfo.props} />;
		}
		const newProps = {
			...otherProps,
			...linkInfo.props,
		};
		return React.createElement(HTMLLink, newProps);
	}
	return React.createElement('div', otherProps);
};

const Image: React.FC<
	SharedComponentProps<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentImageDTO>
> = ({ config, preview }) => {
	const [ref, contentRect] = useMeasure();
	const [profilePicture, setProfilePicture] = useState<string | null>();
	const [payeeName, setPayeeName] = useState<string | null>();
	const { decrementDataLoadedCount, incrementDataLoadedCount } = useContext(
		ComponentDataLoadedContext
	);

	const { metadata } = useContext(ReportContext);
	const useLiveData = metadata?.extra?.useLiveData;

	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);
	const adminPayeeId = previewPayeeId;

	const valuesMap = useContext(ValueStore);
	const previousValuesMap = usePrevious(valuesMap);

	const currentWebUserName = useSelector((state: any) => {
		return state?.user?.payeeDetails?.name ?? null;
	});
	const currentProfilePicture = useSelector((state: any) => {
		return state?.user?.profilePicture ?? null;
	});

	const { special, valueId, useProfilePicture, webUserId } = config;

	useEffect(() => {
		if (useProfilePicture) {
			if (preview && !useLiveData) {
				if (payeeName !== null) {
					setPayeeName(null);
				}
				if (profilePicture !== null) {
					setProfilePicture(null);
				}
			} else if (special === 'CurrentWebUser' && adminPayeeId) {
				getPayeeProfilePictureFromID(adminPayeeId).then((res) => {
					if (payeeName !== res.name) {
						setPayeeName(res.name ?? null);
					}
					const newProfilePicture = res.image
						? `data:image/png;base64,${res.image}`
						: null;
					if (profilePicture !== newProfilePicture) {
						setProfilePicture(newProfilePicture);
					}
				});
			} else if (
				special === 'CurrentWebUser' ||
				(!special && !webUserId && !valueId) // default case
			) {
				if (payeeName !== currentWebUserName) {
					setPayeeName(currentWebUserName);
				}
				if (profilePicture !== currentProfilePicture) {
					setProfilePicture(currentProfilePicture);
				}
			}
		}
	}, [
		useProfilePicture,
		preview,
		profilePicture,
		setProfilePicture,
		special,
		valueId,
		webUserId,
		currentWebUserName,
		currentProfilePicture,
		payeeName,
		adminPayeeId,
		useLiveData,
	]);

	useEffect(() => {
		// Decrement the data loaded context after fetching data of a component
		const changed =
			!previousValuesMap ||
			(valueId && previousValuesMap
				? valuesMap[valueId] !== previousValuesMap[valueId]
				: false);
		if (changed) {
			const payeeID = valueId ? valuesMap[valueId] : webUserId ?? null;
			if (useProfilePicture && payeeID) {
				incrementDataLoadedCount();
				getPayeeProfilePictureFromID(payeeID).then((res) => {
					setProfilePicture(
						res.image ? `data:image/png;base64,${res.image}` : null
					);
					setPayeeName(res.name ?? null);
					decrementDataLoadedCount();
				});
			}
		}
	}, [
		useProfilePicture,
		profilePicture,
		setProfilePicture,
		valueId,
		valuesMap,
		previousValuesMap,
		webUserId,
		decrementDataLoadedCount,
		incrementDataLoadedCount,
		adminPayeeId,
	]);

	if (
		config.url === '' ||
		(useProfilePicture &&
			profilePicture === undefined &&
			payeeName === undefined) ||
		(preview && useLiveData && !adminPayeeId)
	) {
		return <Placeholder type={FlexComponentTypes.image} />;
	}

	if (useProfilePicture) {
		const diameter = Math.min(contentRect.height, contentRect.width);
		const alignment = deserializeAlignment(config.alignment as any);
		const { v, h } = alignment;
		return (
			<Fit
				innerRef={ref}
				css={css`
					display: flex;
					flex-direction: column;
					justify-content: ${v === 'center'
						? 'center'
						: v === 'bottom'
						? 'flex-end'
						: 'flex-start'};
					align-items: ${h === 'center'
						? 'center'
						: h === 'right'
						? 'flex-end'
						: 'flex-start'};

					.profile-template {
						color: #fff;
						background-color: rgba(${colorCobalt2});
						text-align: center;
						text-transform: uppercase;
						font-weight: 600;
						.profile-size {
							display: inline-block;
							margin: 1.5rem 5.5rem 0.5rem 5.5rem;
							font-size: 28px;
							border-radius: 64px;
							height: 64px;
							width: 64px;
							line-height: 64px;
						}
						.icon {
							margin: 0px;
							font-size: 8px;
							border-radius: 20px;
							height: 20px;
							width: 20px;
							line-height: 20px;
						}
					}
				`}
			>
				{profilePicture && (
					<ConditionalLink
						linkId={config.linkId}
						disableLink={preview}
						css={css`
							border-radius: ${diameter}px;
							height: ${diameter}px;
							width: ${diameter}px;
						`}
					>
						<img
							css={css`
								height: 100%;
								width: 100%;
								object-fit: contain;
							`}
							src={profilePicture}
							draggable={false}
							alt="unknown"
						/>
					</ConditionalLink>
				)}
				{!profilePicture && payeeName && (
					<ConditionalLink
						linkId={config.linkId}
						disableLink={preview}
						className="profile-template"
						css={css`
							font-size: ${diameter / 2}px;
							border-radius: ${diameter}px;
							height: ${diameter}px;
							width: ${diameter}px;
							line-height: ${diameter}px;
						`}
					>
						{getInitials(payeeName)}
					</ConditionalLink>
				)}
				{!profilePicture && !payeeName && (
					<ConditionalLink
						linkId={config.linkId}
						disableLink={preview}
						css={css`
							background-color: #a8a8a8;
							border-radius: ${diameter}px;
							height: ${diameter}px;
							width: ${diameter}px;
							line-height: ${diameter}px;
							display: flex;
							justify-content: center;
							align-items: center;
						`}
					>
						<User20
							height={0.75 * (diameter / 2)}
							width={0.75 * (diameter / 2)}
							fill="#ffffff"
						/>
					</ConditionalLink>
				)}
			</Fit>
		);
	}

	return (
		<ConditionalLink
			linkId={config.linkId}
			className={cx('card', 'image-card')}
			disableLink={preview}
			css={css`
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				max-height: 100%;
				flex: 1 1 auto;
				overflow: auto;
				border-radius: 0.25rem;

				${metadata?.extra?.hideAllTile || config.hideTile
					? `&.card {
						background-color: transparent !important;
						box-shadow: none !important;
						padding: 0.5rem !important;
					}`
					: ''}
			`}
		>
			<img
				css={css`
					height: 100%;
					width: 100%;
					object-fit: contain;
				`}
				src={config.url}
				draggable={false}
				alt="unknown"
			/>
		</ConditionalLink>
	);
};

const getInitials = (payeeName: string) => {
	let initials = '';

	if (payeeName) {
		const names = payeeName.split(' ');

		if (names.length === 1) {
			initials += names[0].charAt(0);
		} else if (names.length > 1) {
			initials += names[0].charAt(0) + names[names.length - 1].charAt(0);
		}
	}

	return initials;
};

export default Image;
