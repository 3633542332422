/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { ContentState, Modifier, SelectionState } from 'draft-js';
import { Varicent } from 'icm-rest-client';
import { deserializeAlignment } from '../../utils/objectAlignment';
import { VALUE_ENTITY } from './entities';

const oldFontPresetToBlockType = {
	paragraph: 'unstyled',
	header1: 'header-one',
	header2: 'header-two',
	header3: 'header-three',
	header4: 'header-four',
	header5: 'header-five',
};

const convertTextToRichText: (
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentTextDTO,
	values: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[]
) => ContentState = (config, values) => {
	if (
		!config.contentType ||
		config.contentType === 'text' ||
		config.contentType === 'value'
	) {
		const value =
			config.contentType === 'value'
				? `[${
						config.valueId
							? values.find((v) => v.valueId === config.valueId)?.name
							: config.special ===
							  Varicent.Domain.PresenterFlex.PresenterFlexSourceFilter.Value
									.Special.CurrentDate
							? 'CurrentDate'
							: 'CurrentWebUser'
				  }]`
				: config.value;
		// basic text
		const oldAlignment = deserializeAlignment(config.alignment as any);
		let content = ContentState.createFromText(value);
		// create a selection of all the data
		const firstBlock = content.getBlockMap().first();
		const lastBlock = content.getBlockMap().last();
		const firstBlockKey = firstBlock.getKey();
		const lastBlockKey = lastBlock.getKey();
		const lengthOfLastBlock = lastBlock.getLength();

		const selection = new SelectionState({
			anchorKey: firstBlockKey,
			anchorOffset: 0,
			focusKey: lastBlockKey,
			focusOffset: lengthOfLastBlock,
		});
		const blockData = content.getBlockForKey(firstBlockKey).getData();
		/*
		 * set horizontal alignment
		 * vertical alignment is generally kept as is
		 */
		const newData = blockData.set('alignment', oldAlignment.h);
		content = Modifier.setBlockData(content, selection, newData);
		// next is the style, this is determined fontPreset
		if (config.fontPreset && config.fontPrest !== 'paragraph') {
			const blockType = oldFontPresetToBlockType[config.fontPreset];
			content = Modifier.setBlockType(content, selection, blockType);
		}
		if (config.contentType === 'value') {
			const contentStateWithEntity = content.createEntity(
				VALUE_ENTITY,
				'IMMUTABLE',
				{
					valueId: config.valueId,
					special: config.special,
				}
			);
			const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

			content = Modifier.applyEntity(content, selection, entityKey);
		}
		return content;
	}
	return ContentState.createFromText('');
};

export default convertTextToRichText;
