/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { identity, isEmpty, map, pipe, sortBy } from 'ramda';
import { generateColorRange } from '../../../utils/colors';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	defaultFontColor,
	formatDateUTC,
	simplifiedFormatNumber,
	getCurrencySymbol,
	invertedFontColor,
} from '../chartUtil';

export const radarChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartRadarDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
		palette,
	} = props;
	const {
		labelColumn,
		columnValueColumn,
		lineValueColumn,
		areaValueColumn,
		valueFormat,
		legendPosition,
		showLegend,
		smoothLine,
		linehideMarkers,
		linemarkerShape,
		areahideMarkers,
		areamarkerShape,
	} = config;

	const valueColumns = {
		column: columnValueColumn,
		[smoothLine ? 'spline' : 'line']: lineValueColumn,
		area: areaValueColumn,
	};

	const currencyCode = getCurrencyCode(valueFormat);
	const currencySymbol = getCurrencySymbol(currencyCode);

	/*
	 * allow colorAxis to set colors
	 * in case of sequential or divergent palette
	 */
	const nothing = (_) => undefined;
	const getColor = existingOptions.colorAxis
		? nothing // handled by colorAxis
		: generateColorRange(
				Object.keys(valueColumns),
				palette?.config.classification?.paletteColors
		  );

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const chartType = this.series.options.id ?? '';
			const xValue = labelIsDateBased ? formatDateUTC(this.x, intl) : this.x;
			const yValue = simplifiedFormatNumber({
				value: this.y,
				config,
			});
			const tooltipTitle = config[`${chartType}tooltipTitle`];
			return `<strong>${xValue}</strong><br>${
				tooltipTitle && !isEmpty(tooltipTitle)
					? tooltipTitle
					: valueColumns[chartType]
			}: <strong>${yValue}</strong>`;
		},
	};

	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const xAxis: Highcharts.XAxisOptions = {
		categories: data.rows.map((row) =>
			labelIsDateBased
				? formatDateUTC(row[labelColumn], intl)
				: row[labelColumn]
		),
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		tickmarkPlacement: 'on',
	};

	const yAxis: Highcharts.YAxisOptions = {
		labels: {
			formatter: function formatter() {
				return `${
					currencySymbol ? ` ${currencySymbol}` : ''
				}${this.axis.defaultLabelFormatter.call(this)}`;
			},
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
				textOutline: '1px contrast',
			},
		},
	};

	const lineOptions = {
		marker: {
			enabled: !linehideMarkers ?? true,
			symbol: linemarkerShape ?? 'circle',
		},
		allowPointSelect: true,
		cursor: 'pointer',
	};

	const areaOptions = {
		marker: {
			enabled: !areahideMarkers ?? true,
			symbol: areamarkerShape ?? 'circle',
		},
		allowPointSelect: true,
		cursor: 'pointer',
	};

	const plotOptions: Highcharts.PlotOptions = {
		column: {
			pointPadding: 0,
			groupPadding: 0,
		},
		area: areaOptions,
		spline: lineOptions,
		line: lineOptions,
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? true,
		labelFormatter() {
			const chartType = this.options.id;
			const tooltipTitle = config[`${chartType}tooltipTitle`];
			return tooltipTitle && !isEmpty(tooltipTitle) ? tooltipTitle : this.name;
		},
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		layout:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'horizontal'
				: 'vertical',
		align:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'center'
				: legendPosition ?? 'right',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: 'middle',
	};

	const seriesOptions: Highcharts.SeriesOptionsType[] = [];

	Object.keys(valueColumns).forEach((valueColumn) => {
		const dataRows = pipe(
			map((row: any) => {
				return {
					name: labelIsDateBased
						? `${formatDateUTC(row[labelColumn], intl)}`
						: row[labelColumn],
					y: row[valueColumns[valueColumn]],
				};
			}),
			labelIsDateBased ? sortBy((row: any) => row.x) : identity
		)(data.rows);

		const seriesOption: Highcharts.SeriesOptionsType = {
			id: valueColumn,
			type: valueColumn as any,
			name: valueColumns[valueColumn],
			data: dataRows,
			dataLabels: {},
			color: getColor(valueColumn),
			marker: {
				fillColor: getColor(valueColumn),
			},
			pointPlacement: valueColumn === 'column' ? 'between' : 'on',
		};
		seriesOptions.push(seriesOption);
	});

	const options = {
		...existingOptions,
		chart: {
			...existingOptions.chart,
			polar: true,
		},
		pane: {
			startAngle: 0,
			endAngle: 360,
		},
		xAxis,
		yAxis,
		plotOptions,
		tooltip,
		legend,
		series: seriesOptions,
	};
	return options;
};
