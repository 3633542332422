/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { identity, isEmpty, map, pipe, sortBy } from 'ramda';
import { generateColorRange } from '../../../utils/colors';
import { NumberFormatOption } from '../../../utils/dataGridStyling';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	formatDateUTC,
	simplifiedFormatNumber,
	getCurrencySymbol,
	invertedFontColor,
} from '../chartUtil';

type HorBarOverload = ChartOptionArg & {
	columnNameToType: ColumnNameToTypeMap;
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartStackedHorizontalBarDTO;
	barColType: 'bar';
};
type ColOverload = ChartOptionArg & {
	columnNameToType: ColumnNameToTypeMap;
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartStackedColumnDTO;
	barColType: 'column';
};

export const stackedBarColChartOptions = (
	props: HorBarOverload | ColOverload
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
		barColType,
		palette,
	} = props;
	const {
		labelColumn,
		valueColumn,
		categoryColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		legendPosition,
		showLegend,
		xAxisOrentation,
		xAxisOrder,
		showDataValues,
		hideTotalDataValue,
		valueFormat,
		valueFormatType,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		hideYAxisLine,
		hideGridLines,
	} = config;

	const type = barColType;

	const currencyCode = getCurrencyCode(valueFormat);
	const currencySymbol = getCurrencySymbol(currencyCode);
	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	// Get all the categories
	const categories = data.schemaInfo.columns.slice(
		1,
		data.schemaInfo.columns.length
	);

	/*
	 * allow colorAxis to set colors
	 * in case of sequential or divergent palette
	 */
	const nothing = (_) => undefined;
	const getColor = existingOptions.colorAxis
		? nothing // handled by colorAxis
		: generateColorRange(
				categories,
				palette?.config.classification?.paletteColors
		  );

	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');
	const catIsDateBased = columnNameToType[categoryColumn].startsWith('date');
	const catIsNum = columnNameToType[categoryColumn].startsWith('numeric');

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const xValue = labelIsDateBased ? formatDateUTC(this.x, intl) : this.x;
			const yValue = simplifiedFormatNumber({
				value: this.y ?? 0,
				config,
				defaultValueFormatType: NumberFormatOption.NUMBER,
			});
			const catValue = catIsDateBased
				? formatDateUTC(this.series.name, intl)
				: catIsNum
				? Number(this.series.name)
				: this.series.name;
			const totalValue = simplifiedFormatNumber({
				value: this.point.total ?? 0,
				config,
				defaultValueFormatType: NumberFormatOption.NUMBER,
			});
			return `${xValue}: <strong>${yValue}</strong><br>${
				config.categoryColumn
			}: <strong>${catValue}</strong>
			${
				(hideTotalDataValue === undefined ? false : !hideTotalDataValue)
					? `<br>Total: <strong>${totalValue}</strong>`
					: ''
			} `;
		},
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? true,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormatter: function formatter() {
			return catIsDateBased
				? `${formatDateUTC(this.name, intl)}`
				: catIsNum
				? `${Number(this.name)}`
				: this.name;
		},
		layout:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'horizontal'
				: 'vertical',
		align:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'center'
				: legendPosition ?? 'right',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: 'middle',
	};

	const plotOptions: Highcharts.PlotOptions = {
		column: {
			stacking: 'normal',
		},
		bar: {
			stacking: 'normal',
		},
	};

	const xAxis: Highcharts.XAxisOptions = {
		type: labelIsDateBased ? 'datetime' : undefined,
		categories: labelIsDateBased
			? undefined
			: data.rows.map((row) => row[labelColumn]),
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		reversed: labelIsDateBased ? xAxisOrder === 'DESC' : false,
	};
	const showYAxisMin = showYMinMax && !!yAxisMin && yAxisMin !== '';
	const showYAxisMax = showYMinMax && !!yAxisMax && yAxisMax !== '';
	const yAxis: Highcharts.YAxisOptions = {
		title: {
			text: hideYAxisTitle
				? undefined
				: `${yAxisTitle || valueColumn}${
						currencySymbol ? ` (${currencySymbol})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		stackLabels: {
			enabled: hideTotalDataValue === undefined ? false : !hideTotalDataValue,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			formatter: function formatter() {
				return `${simplifiedFormatNumber({
					value: this.total,
					config,
					defaultValueFormatType: NumberFormatOption.NUMBER,
				})}`;
			},
		},
		startOnTick: !showYAxisMin,
		min: showYAxisMin ? Number(yAxisMin) : null,
		endOnTick: !showYAxisMax,
		max: showYAxisMax ? Number(yAxisMax) : null,
		gridLineWidth: hideGridLines ? 0 : 1,
		lineWidth: hideYAxisLine || hideYAxisLine === undefined ? 0 : 1,
	};

	let dataLabels:
		| Highcharts.PlotBarDataLabelsOptions
		| Highcharts.PlotColumnDataLabelsOptions = {
		enabled: showDataValues ?? false,
		style: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : defaultFontColor,
		},
		inside: true,
		formatter: function formatter() {
			return `${simplifiedFormatNumber({
				value: this.y ?? 0,
				config,
				defaultValueFormatType: NumberFormatOption.NUMBER,
			})}`;
		},
	};

	if (props.barColType === 'bar') {
		const { labelPosition } = props.config;
		dataLabels = {
			...dataLabels,
			align:
				labelPosition === 'insidebase'
					? 'left'
					: labelPosition === 'center'
					? 'center'
					: 'right',
			verticalAlign: 'middle',
		};
	} else {
		const { labelPosition } = props.config;
		dataLabels = {
			...dataLabels,
			align: 'center',
			verticalAlign:
				labelPosition === 'insidebase'
					? 'bottom'
					: labelPosition === 'center'
					? 'middle'
					: 'top',
		};
	}

	const seriesOptions = categories.map((cat) => {
		const dataRows = pipe(
			map((row: any) => {
				return {
					x: labelIsDateBased ? row[labelColumn] : undefined,
					y: isEmpty(row[cat]) ? null : Number(row[cat]),
					color: getColor(cat),
				};
			}),
			labelIsDateBased ? sortBy((row: any) => row.x) : identity
		)(data.rows);
		const series: Highcharts.SeriesOptionsType = {
			type: type as any,
			name: cat,
			data: dataRows,
			dataLabels,
			color: getColor(cat),
		};
		return series;
	});

	// if there's no data present, push a dummy series so the chart will still build the axes
	if (seriesOptions.length < 1) {
		seriesOptions.push({ type: type as any, data: [] });
	}

	const options = {
		...existingOptions,
		xAxis,
		yAxis,
		plotOptions,
		tooltip,
		legend,
		series: seriesOptions,
	};
	return options;
};
