/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { min } from 'ramda';
import { UseMeasureRect } from 'react-use/lib/useMeasure';
import { getSingleColor } from '../../../utils/colors';
import {
	ChartOptionArg,
	invertedFontColor,
	messages,
	simplifiedFormatNumber,
} from '../chartUtil';

export const simpleGaugeChartOptions = (
	props: ChartOptionArg & {
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartSimpleGaugeDTO;
		contentRect: UseMeasureRect;
	}
) => {
	const { config, existingOptions, data, intl, invertFontColors, contentRect } =
		props;
	const {
		actualColumn,
		targetColumn,
		minimumColumn,
		mediumColumn,
		maximumColumn,
		legendPosition,
		showLegend,
		showDataValues,
		showDataPercentages,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		gaugeAxisTitle,
		hideGaugeAxisTitle,
		useCustomRange,
		minRangeMin,
		minRangeMax,
		medRangeMin,
		medRangeMax,
		maxRangeMin,
		maxRangeMax,
		useCustomTarget,
		customTarget,
		targetColor,
		minimumColor,
		mediumColor,
		maximumColor,
	} = config;

	const type = 'solidgauge';
	const ACTUAL = 'actual';

	const selectedTargetColor = targetColor ?? '#122C6A';
	const selectedMinimumColor = minimumColor ?? '#7EABFF';
	const selectedMediumColor = mediumColor ?? '#9EC9FF';
	const selectedMaximumColor = maximumColor ?? '#BDE8FF';

	const dataRow = data.rows.length ? data.rows[0] : {};

	const plotOptions: Highcharts.PlotOptions = {
		solidgauge: {
			dataLabels: {
				y: -50,
				borderWidth: 0,
				useHTML: true,
			},
			tooltip: {
				followPointer: true,
			},
		},
		gauge: {
			pivot: {
				radius: 0,
			},
			dataLabels: {},
			tooltip: {
				followPointer: true,
			},
		},
	};

	const showYAxisMin = showYMinMax && !!yAxisMin && yAxisMin !== '';
	const showYAxisMax = showYMinMax && !!yAxisMax && yAxisMax !== '';

	const yAxis: Highcharts.YAxisOptions = {
		gridLineWidth: 0,
		min: showYAxisMin ? Number(yAxisMin) : null,
		max: showYAxisMax ? Number(yAxisMax) : dataRow[targetColumn],
		lineWidth: 0,
		tickWidth: 0,
		minorTickInterval: null,
		tickInterval: 0,
		startOnTick: false,
		endOnTick: false,
		tickAmount: 0,
		title: undefined,
		labels: {
			distance: '100%',
			y: 16,
			formatter() {
				const yValue = simplifiedFormatNumber({
					value: this.value ?? 0,
					config,
				});

				return `${yValue}`;
			},
		},
		softMin: showYAxisMin ? Number(yAxisMin) : 0,
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? false,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		// since we are adding non-series items to the legend, mask hidden styling
		itemHiddenStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormat: '{name}',
		layout:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? 'vertical'
				: 'horizontal',
		align:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? legendPosition
				: 'center',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: legendPosition === 'left' || legendPosition === 'right'
				? 'middle'
				: 'bottom',
	};

	const target = useCustomTarget ? Number(customTarget) : dataRow[targetColumn];
	const useDataPercentages = showDataPercentages && target;
	const actualValue = dataRow[actualColumn];

	const minRange = useCustomRange
		? { min: Number(minRangeMin), max: Number(minRangeMax) }
		: { min: 0, max: dataRow[minimumColumn] ?? 0 };
	const medRange = useCustomRange
		? { min: Number(medRangeMin), max: Number(medRangeMax) }
		: { min: minRange.max, max: dataRow[mediumColumn] ?? minRange.max };
	const maxRange = useCustomRange
		? { min: Number(maxRangeMin), max: Number(maxRangeMax) }
		: { min: medRange.max, max: dataRow[maximumColumn] ?? medRange.max };
	const color =
		actualValue < minRange.max
			? selectedMinimumColor
			: actualValue >= medRange.min && actualValue < medRange.max
			? selectedMediumColor
			: actualValue >= maxRange.min
			? selectedMaximumColor
			: getSingleColor();

	const maxGaugeSize = 115;
	const radiusThickness = 30;
	const outerRadius = maxGaugeSize - radiusThickness * 0;
	const innerRadius = maxGaugeSize - radiusThickness * 1;
	const chartPadding = 70;
	const minLength = min(contentRect.width, contentRect.height) - chartPadding;
	const initFontSize = 6;
	const chartFontRatio = 20;
	const fontSize = initFontSize + minLength / chartFontRatio;
	const seriesOptions = [] as Highcharts.SeriesOptionsType[];
	const series: Highcharts.SeriesOptionsType = {
		type: type as any,
		data: [
			{
				y: actualValue,
				percentage: (actualValue / target) * 100,
				innerRadius: `${innerRadius}%`,
				radius: `${outerRadius}%`,
				name: actualColumn,
				color,
			},
		],
		dataLabels: {
			enabled:
				(minLength > 0 && (showDataValues || !hideGaugeAxisTitle)) ?? false,
			style: {
				fontWeight: 'normal',
				textOutline: '0',
				textAlign: 'center',
			},
			align: 'center',
			verticalAlign: 'center',
			y: -Math.abs(fontSize * 3),
			formatter() {
				let format = '';

				if (showDataValues) {
					const yValue = simplifiedFormatNumber({
						value: useDataPercentages
							? Number(this.point.percentage)
							: Number(this.point.y) ?? 0,
						config,
						multiCol: ACTUAL,
					});

					format += useDataPercentages ? `<b>${yValue}</b>` : `${yValue}`;
				}

				const formatString = `<span style="font-size:${fontSize}px; font-weight: bold;">${format}</span><br/><span style="font-size:${
					fontSize / 2
				}px;font-weight: normal;">${
					hideGaugeAxisTitle ? '' : gaugeAxisTitle ?? ''
				}</span>`;

				return formatString;
			},
		},
		name: actualColumn,
		color,
		// since we are adding non-series items to the legend, disable click feature
		events: {
			legendItemClick: () => {
				return false;
			},
		},
	};
	seriesOptions.push(series);

	// adds the target value marker to the series
	if (target) {
		const dialSeries: Highcharts.SeriesOptionsType = {
			data: [
				{
					y: target,
					name: targetColumn ?? intl.formatMessage(messages.target),
				},
			],
			type: 'gauge',
			dial: {
				baseLength: '100%',
				radius: '120%',
				rearLength: '-65%',
				baseWidth: 6,
				backgroundColor: selectedTargetColor,
			},
			dataLabels: {
				enabled: false,
			},
			color: selectedTargetColor,
		};
		seriesOptions.push(dialSeries);
	}

	// set of dummy series for the legend
	const minSeries: Highcharts.SeriesOptionsType = {
		type: type as any,
		dataLabels: {},
		showInLegend: true,
		name: intl.formatMessage(messages.minimumRange),
		visible: false,
		color: selectedMinimumColor,
		// since we are adding non-series items to the legend, disable click feature
		events: {
			legendItemClick: () => {
				return false;
			},
		},
	};
	seriesOptions.push(minSeries);

	const medSeries: Highcharts.SeriesOptionsType = {
		...minSeries,
		name: intl.formatMessage(messages.mediumRange),
		color: selectedMediumColor,
	};
	seriesOptions.push(medSeries);

	const maxSeries: Highcharts.SeriesOptionsType = {
		...minSeries,
		name: intl.formatMessage(messages.maximumRange),
		color: selectedMaximumColor,
	};
	seriesOptions.push(maxSeries);

	const targetSeries: Highcharts.SeriesOptionsType = {
		...minSeries,
		name: intl.formatMessage(messages.target),
		color: selectedTargetColor,
	};
	seriesOptions.push(targetSeries);

	const pane = {
		center: ['50%', '75%'],
		startAngle: -90,
		endAngle: 90,
		background: [
			{
				backgroundColor: '#EEE',
				innerRadius: `${innerRadius}%`,
				outerRadius: `${outerRadius}%`,
				shape: 'arc',
				borderWidth: 0,
			},
		] as Highcharts.PaneBackgroundOptions[],
	};

	const options = {
		...existingOptions,
		colorAxis: undefined,
		yAxis,
		plotOptions,
		legend,
		series: seriesOptions,
		pane,
	};
	return options;
};
