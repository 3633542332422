/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { ascend, descend, sort } from 'ramda';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	invertedFontColor,
	formatDateUTC,
	simplifiedFormatNumber,
} from '../chartUtil';

export const heatMapChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartHeatMapDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
	} = props;
	const {
		labelColumn,
		valueColumn,
		categoryColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		xAxisOrentation,
		showDataValues,
		showLegend,
		legendPosition,
		valueTitle,
		xAxisOrder,
		yAxisOrder,
	} = config;

	const type = 'heatmap';
	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');
	const labelIsNum = columnNameToType[labelColumn].startsWith('numeric');
	const catIsDateBased = columnNameToType[categoryColumn].startsWith('date');

	let xCategories = [
		...new Set(
			data.rows.map((row) =>
				labelIsDateBased
					? formatDateUTC(row[labelColumn], intl)
					: row[labelColumn]
			)
		),
	];
	if (xAxisOrder) {
		const orderOp = xAxisOrder === 'ASC' ? ascend : descend;
		xCategories = sort(
			orderOp((c) => (labelIsDateBased ? new Date(c) : c)),
			xCategories
		);
	}
	const xAxis: Highcharts.XAxisOptions = {
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		categories: xCategories,
	};

	let yCategories = [
		...new Set(
			data.rows.map((row) =>
				catIsDateBased
					? formatDateUTC(row[categoryColumn], intl)
					: row[categoryColumn]
			)
		),
	];
	if (yAxisOrder) {
		const orderOp = yAxisOrder === 'ASC' ? ascend : descend;
		yCategories = sort(
			orderOp((c) => (catIsDateBased ? new Date(c) : c)),
			yCategories
		);
	}
	const yAxis: Highcharts.YAxisOptions = {
		title: {
			text: hideYAxisTitle ? undefined : `${yAxisTitle || categoryColumn}`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		categories: yCategories,
	};

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const value = simplifiedFormatNumber({
				value: Number(this.point.options.value) || 0,
				config,
			});

			return `${xAxisTitle ?? labelColumn}: <strong>${
				(this.point.options as any).xVal
			}</strong><br>${yAxisTitle ?? categoryColumn}: <strong>${
				(this.point.options as any).yVal
			}</strong><br>${valueTitle ?? valueColumn}: <strong>${value}</strong>`;
		},
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? true,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormatter: function formatter() {
			return labelIsDateBased
				? `${formatDateUTC(this.name, intl)}`
				: labelIsNum
				? `${Number(this.name)}`
				: this.name;
		},
		layout:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'horizontal'
				: 'vertical',
		align:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'center'
				: legendPosition ?? 'right',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: 'middle',
	};

	const dataLabels: Highcharts.DataLabelsOptions = {
		enabled: showDataValues ?? true,
		style: {
			fontWeight: 'bold',
			fontSize: '1.1rem',
			color: invertFontColors ? invertedFontColor : defaultFontColor,
		},
		formatter: function formatter() {
			return `${simplifiedFormatNumber({
				value: this.point.options.value ?? 0,
				config,
			})}`;
		},
	};

	const dataRows = data.rows.map((row: any) => {
		const labelCol = labelIsDateBased
			? formatDateUTC(row[labelColumn], intl)
			: row[labelColumn];
		const catCol = catIsDateBased
			? formatDateUTC(row[categoryColumn], intl)
			: row[categoryColumn];
		return {
			x: xCategories.indexOf(labelCol),
			y: yCategories.indexOf(catCol),
			value: row[valueColumn],
			xVal: labelCol,
			yVal: catCol,
		};
	});

	const series: Highcharts.SeriesOptionsType = {
		type: type as any,
		data: dataRows,
		borderWidth: 1,
		dataLabels,
	};

	const options = {
		...existingOptions,
		xAxis,
		yAxis,
		legend,
		series: [series],
		tooltip,
	};

	return options;
};
