/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Varicent } from 'icm-rest-client';
import { SharedComponentProps } from '../../types';
import { cx } from 'emotion';
import styled from 'react-emotion';
import { ReportContext, LiveDataPayeeContext } from '../../context';
import Editor from 'draft-js-plugins-editor';
import { convertFromRaw, EditorState, ContentState, Modifier } from 'draft-js';
import convertTextToRichText from './convertTextToRichText';
import { readonlyDecorators, readonlyPlugins } from './draftjsPlugins';
import {
	deserializeAlignment,
	HorizontalAlignmentOptions,
	VerticalAlignmentOptions,
} from '../../utils/objectAlignment';
import {
	getFullContentSelection,
	getSelectionCustomInlineStyles,
	resetSelection,
} from 'icm-core/lib/utils/draftJsUtils';

const TextElement = styled.div<{
	v: VerticalAlignmentOptions;
	h: HorizontalAlignmentOptions;
	invertFontColors: boolean;
	hideTile: boolean;
	preview: boolean | undefined;
	previewPayeeId: Varicent.ID | undefined;
}>`
	display: flex;
	flex-direction: column;
	cursor: default;
	border-radius: 0.25rem;

	justify-content: ${({ v }) =>
		v === 'center' ? 'center' : v === 'bottom' ? 'flex-end' : 'flex-start'};
	height: 100%;
	${({ invertFontColors }) => {
		if (invertFontColors) {
			return `
				color: #ffffff;

				.heading {
					color: #ffffff !important;
				}
			`;
		}
		return '';
	}}
	${({ hideTile }) => {
		if (hideTile) {
			return `
				&.card {
					background-color: transparent !important;
					box-shadow: none !important;
					padding: 0rem !important;
				}
			`;
		}

		return `
			&.card {
		  		padding: 1rem !important;
		 	}
		  `;
	}}
	${({ preview, previewPayeeId }) => {
		if (preview && !previewPayeeId) {
			return `a {
				pointer-events: none;
			}`;
		}
		return '';
	}}

	.unstyled {
		font-size: 0.875rem;
	}

	figure {
		margin: 0;

		.bp3-divider {
			margin: 0.5rem 0;
		}
	}

	.DraftEditor-root,
	.DraftEditor-editorContainer,
	.public-DraftEditor-content {
		${!!(window as any).MSInputMethodContext && !!(document as any).documentMode
			? `white-space: normal;`
			: ''}
		height: auto;
		overflow: auto;

		ol,
		ul {
			padding-left: 1rem;
		}
	}
`;

const Text: React.FC<
	SharedComponentProps<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentTextDTO>
> = ({ config, preview }) => {
	const alignment = deserializeAlignment(config.alignment as any);

	const { metadata, values } = useContext(ReportContext);
	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);
	const invertFontColors = metadata?.extra?.invertFontColors ?? false;
	const [richText, setRichText] = useState(config.richText);

	const editorState = useMemo(() => {
		const richTextSource = invertFontColors ? richText : config.richText;

		const content = config.richText
			? convertFromRaw(richTextSource)
			: convertTextToRichText(config, values ?? []);

		return EditorState.createWithContent(content, readonlyDecorators);
	}, [config, values, richText]);

	let nextEditorState: EditorState = editorState;

	useEffect(() => {
		const contentState = nextEditorState.getCurrentContent();
		const blocks = contentState.getBlocksAsArray();
		let nextContentState = ContentState.createFromBlockArray(
			blocks,
			contentState.getEntityMap()
		);

		if (metadata?.extra?.invertFontColors) {
			nextEditorState = EditorState.forceSelection(
				editorState,
				getFullContentSelection(editorState)
			);

			const customColors = getSelectionCustomInlineStyles(nextEditorState, [
				'CUSTOM_COLOR_',
			]);

			customColors.forEach((customColor) => {
				nextContentState = Modifier.removeInlineStyle(
					nextContentState,
					nextEditorState.getSelection(),
					customColor
				);
			});

			nextContentState = nextContentState.merge({
				selectionAfter: resetSelection(nextEditorState),
			});
		}

		setRichText({
			...richText,
			blocks: nextContentState.getBlocksAsArray(),
		});

		nextEditorState = EditorState.push(
			nextEditorState,
			nextContentState,
			'change-inline-style'
		);
	}, [metadata?.extra?.invertFontColors]);

	return (
		<TextElement
			className={cx('card', 'text-card')}
			invertFontColors={invertFontColors}
			hideTile={metadata?.extra?.hideAllTile || config.hideTile}
			preview={preview}
			previewPayeeId={previewPayeeId}
			{...alignment}
		>
			<Editor
				editorState={nextEditorState}
				onChange={() => {}}
				plugins={readonlyPlugins}
				readOnly
			/>
		</TextElement>
	);
};

export default Text;
