/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { createContext, useState, useMemo } from 'react';

type isFeatureFlagEnabledFunction = (feature: any, token?: string) => boolean;

/**
 * Copied from Payee-shared
 */
export enum FeatureFlags {
	rapidReports = 'rapidReports',
	queryTool = 'queryTool',
	nps = 'nps',
	dataDiscovery = 'dataDiscovery',
	presenterMobile = 'presenterMobile',
	modernInquirySubmissions = 'modernInquirySubmissions',
}

export interface FeatureFlagContextShape {
	isFeatureFlagEnabled?: isFeatureFlagEnabledFunction;
	setIsFeatureFlagEnabled: React.Dispatch<
		React.SetStateAction<isFeatureFlagEnabledFunction | undefined>
	>;
}

const featureFlagContext = createContext<FeatureFlagContextShape>({
	isFeatureFlagEnabled: undefined,
	setIsFeatureFlagEnabled: () => {},
});

export default featureFlagContext;

export const FeatureFlagProvider: React.FC = ({ children }) => {
	const [isFeatureFlagEnabled, setIsFeatureFlagEnabled] =
		useState<isFeatureFlagEnabledFunction>();
	const providedValue = useMemo(
		() => ({
			isFeatureFlagEnabled,
			setIsFeatureFlagEnabled,
		}),
		[isFeatureFlagEnabled, setIsFeatureFlagEnabled]
	);
	return (
		<featureFlagContext.Provider value={providedValue}>
			{children}
		</featureFlagContext.Provider>
	);
};
