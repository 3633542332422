/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/* eslint-disable import/no-extraneous-dependencies */ import { scaleOrdinal } from '@vx/scale';
import { Varicent } from 'icm-rest-client';
// import { paletteSet18 } from 'capital-components/lib/support/vizColors';

const defaultColors = [
	'#5B8FF9',
	'#5AD8A6',
	'#5D7092',
	'#F6BD16',
	'#E86452',
	'#6DC8EC',
	'#945FB9',
	'#FF9845',
	'#1E9493',
	'#FF99C3',
];

type OrdinalScale = (key: string) => string;

export const generateColorRange = <T = any>(
	domainBasis: T[],
	colors: string[] = defaultColors
) => {
	const colorScale = scaleOrdinal({
		domain: domainBasis,
		range: colors,
	}) as OrdinalScale;

	return colorScale;
};

export const getSingleColor = (colors: string[] = defaultColors) => colors[0];

export const fallbackPalette = {
	paletteId: undefined,
	paletteType: 'Classification',
	isDefault: false,
	isSystem: true,
	config: { classification: { paletteColors: defaultColors } },
} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPaletteDTO;
