/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/* eslint-disable import/no-extraneous-dependencies */ import React, {
	useState,
	useMemo,
} from 'react';
import styled from 'react-emotion';
import useIsomorphicLayoutEffect from 'react-use/esm/useIsomorphicLayoutEffect';
import ResizeObserver from 'resize-observer-polyfill';

/*
 * Assumes a relative parent to scale to.
 * Need this to get around some behavior in safari that sticks
 * to a specific interpretation of height that is bypassed
 * by an absolutely positioned element.
 */
export const Fit = styled('div')`
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	position: absolute;
`;

const ContentRect: React.SFC<{
	className?: string;
	children: (arg: {
		contentRect: Pick<
			DOMRectReadOnly,
			'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
		>;
	}) => React.ReactNode;
}> = ({ children, className }) => {
	const [ref, contentRect] = useMeasure();
	return (
		<Fit innerRef={ref} className={className}>
			{children({ contentRect })}
		</Fit>
	);
};

export default ContentRect;

export type UseMeasureRect = Pick<
	DOMRectReadOnly,
	'x' | 'y' | 'top' | 'left' | 'right' | 'bottom' | 'height' | 'width'
>;
export type UseMeasureRef<E extends HTMLElement = HTMLElement> = (
	element: E
) => void;
export type UseMeasureResult<E extends HTMLElement = HTMLElement> = [
	UseMeasureRef<E>,
	UseMeasureRect
];

const defaultState: UseMeasureRect = {
	x: 0,
	y: 0,
	width: 0,
	height: 0,
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
};

export const useMeasure = <
	E extends HTMLElement = HTMLElement
>(): UseMeasureResult<E> => {
	const [element, ref] = useState<E | null>(null);
	const [rect, setRect] = useState<UseMeasureRect>(defaultState);

	const observer = useMemo(
		() =>
			new ResizeObserver((entries) => {
				if (entries[0]) {
					const { x, y, width, height, top, left, bottom, right } =
						entries[0].contentRect;
					setRect({ x, y, width, height, top, left, bottom, right });
				}
			}),
		[]
	);

	useIsomorphicLayoutEffect(() => {
		if (!element) return;
		observer.observe(element);
		// eslint-disable-next-line consistent-return
		return () => {
			observer.disconnect();
		};
	}, [element]);

	return [ref, rect];
};
