/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext } from 'react';
import { Varicent } from 'icm-rest-client';
import ExportPanel from './exportPanel';
import CopyRowToInquiry from './copyRowToInquiry';
import { ColDef, GridApi } from '@ag-grid-community/core';
import { Divider } from '@blueprintjs/core';
import featureFlagContext, {
	FeatureFlags,
} from '../../utils/featureFlagContext';
import { Filter } from 'icm-core/lib/components/datatable';

const DataTableComponents: React.FC<{
	reportId: Varicent.ID;
	source: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO;
	componentId: Varicent.ID;
	sourceSchema: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
	preview: boolean;
	previewPayeeId: Varicent.ID;
	gridApi: React.MutableRefObject<GridApi | undefined>;
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridDTO;
	oneRowSelected: boolean;
	columnDefs: ColDef[];
	aggregateInfo: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
	groupByInfo: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexGroupByInfoDTO;
	sortOptions: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridSortOptionDTO[];
	filterModel?: Record<string, Filter>;
}> = ({
	preview,
	source,
	componentId,
	sourceSchema,
	reportId,
	previewPayeeId,
	gridApi,
	config,
	oneRowSelected,
	columnDefs,
	aggregateInfo,
	sortOptions,
	filterModel,
}) => {
	const { isFeatureFlagEnabled } = useContext(featureFlagContext);
	const isModernInquiryEnabled = isFeatureFlagEnabled
		? isFeatureFlagEnabled(FeatureFlags.modernInquirySubmissions)
		: false;

	const aggregateInfoNoCalcs = {
		...aggregateInfo,
		groupByNames: aggregateInfo?.groupByNames.filter((name) =>
			source.columns.some((c) => c.columnName === name && !c.formula)
		),
	};

	return (
		<>
			{config.copyToRow && isModernInquiryEnabled && (
				<CopyRowToInquiry
					gridApi={gridApi}
					oneRowSelected={oneRowSelected}
					columnDefs={columnDefs}
				/>
			)}
			{config.copyToRow && isModernInquiryEnabled && config.excelExport && (
				<Divider />
			)}
			{config.excelExport && (
				<ExportPanel
					preview={preview}
					source={source}
					componentId={componentId}
					sourceSchema={sourceSchema}
					reportId={reportId}
					previewPayeeId={previewPayeeId}
					aggregateInfo={aggregateInfoNoCalcs}
					sortOptions={sortOptions}
					filterModel={filterModel}
				/>
			)}
		</>
	);
};

export default DataTableComponents;
