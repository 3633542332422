/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import {
	getExportForPayee,
	getExportForAdmin,
} from 'icm-rest-client/lib/controllers/presenterFlex';
import { saveAs } from 'filesaverjs';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
	datagrid: {
		id: 'utils.callapi.datagrid',
		defaultMessage: 'Datagrid',
	},
});

// eslint-disable-next-line consistent-return
export async function exportGrid(
	reportId,
	componentId,
	context,
	previewPayeeId,
	intl,
	setError,
	filterBy
) {
	if ((window as any).ReactNativeWebView) {
		(window as any).ReactNativeWebView.postMessage(
			JSON.stringify({
				action: 'NoMobileSupport',
			})
		);
		return null;
	}
	let response = null;
	try {
		if (previewPayeeId) {
			response = await getExportForAdmin(reportId, componentId, context, {
				payeeId: previewPayeeId,
				filterBy,
			});
		} else {
			response = await getExportForPayee(reportId, componentId, context, {
				filterBy,
			});
		}
	} catch (e) {
		if (e.response.status !== 200) {
			setError(e.response.data.Message);
		}
	}
	if (!response) {
		return null;
	}

	const base64String = response;
	const binaryString = window.atob(base64String);
	const buf = new ArrayBuffer(binaryString.length);
	const byteArray = new Uint8Array(buf);

	for (let i = 0; i !== binaryString.length; ++i) {
		/* tslint:disable-next-line: no-bitwise */
		// eslint-disable-next-line no-bitwise
		byteArray[i] = binaryString.charCodeAt(i) & 0xff;
	}

	const file = new Blob([buf], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});

	saveAs(
		file,
		`${intl.formatMessage(messages.datagrid)}-${new Date()
			.toLocaleString()
			.replace(',', '-')
			.replace(/\s/g, '')}.xlsx`
	);
}
