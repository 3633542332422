/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Varicent } from 'icm-rest-client';
import {
	getSourceRowsForPayee,
	getSourceRows,
	getSourceRowsForPreview,
	getPickListSourceRowsForPayee,
	getPickListSourceRowsForPreview,
	getPickListSourceRows,
} from 'icm-rest-client/lib/controllers/presenterFlex';
import { parseJSON } from 'date-fns';
import {
	NormalizedColumnType,
	getNormalizedTypeForDbType,
} from 'icm-core/lib/utils/dbUtils';
import { buildFilteredByFromAgFilterModel } from 'icm-core/lib/components/datatable';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
	ComponentDataLoadedContext,
	ValueStore,
	ReportContext,
	LiveDataPayeeContext,
	PublishMode,
} from '../context';
import getUsedValues from './getUsedValues';
import { isObject } from 'highcharts';
import { isNil, isEmpty as RisEmpty } from 'ramda';
import isEmpty from 'lodash.isempty';
import { IServerSideDatasource } from '@ag-grid-community/core';
import {
	dateToSQLDate,
	makeEqualsFilter,
} from 'icm-core/lib/utils/filterUtils';
import { useDeepCompareEffect } from 'react-use';
import { getSourceRows as formsGetSourceRows } from 'icm-rest-client/lib/controllers/adaptiveForms';
import { Aggregate, IConditionProps } from './dataGridStyling';
import { possibleAggregationFunc } from './aggregationFormulaExtraction';
import { useQuery, UseQueryResult, useQueryClient } from 'react-query';

/*
 * use this sparingly, only for when we definitely don't want things changing for
 * fetching purposes
 */
const useDeepMemo: <T = unknown>(value: T) => T = (value) => {
	const [storedValue, setStoredValue] = useState(value);
	useDeepCompareEffect(() => {
		setStoredValue(value);
	}, [{ value }]);
	return storedValue;
};

export const publishMaxRows = 2500;

export const useDataSourceDependencies = ({
	source,
	sourceSchema,
	presFlexValues,
}: {
	source?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO;
	sourceSchema?: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
	presFlexValues?:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[]
		| undefined;
}) => {
	const values = useContext(ValueStore);
	const { values: reportValues, sources } = useContext(ReportContext);
	const memoizedParams = useDeepMemo(
		useMemo(() => {
			const usedValues = getUsedValues(
				source?.sourceFilters.filters,
				sources,
				reportValues
			);
			return reportValues
				? usedValues.reduce((acc, constraint) => {
						const { valueId } = constraint.value;
						if (valueId) {
							return {
								...acc,
								[valueId]: values[valueId],
							};
						}
						return acc;
				  }, {} as Record<number, string>)
				: {};
		}, [source, reportValues, values, sources])
	);

	const columnNameToType: Record<string, NormalizedColumnType> = useMemo(
		() =>
			Object.assign(
				(sourceSchema?.columns ?? []).reduce((acc, col) => {
					const dataType = getNormalizedTypeForDbType(col.type);
					if (!dataType) return acc;
					return {
						...acc,
						[col.name]: dataType,
					};
				}, {} as Record<string, NormalizedColumnType>),
				(presFlexValues ?? []).reduce((acc, value) => {
					if (value.valueType === 'Parameter') {
						const headerValueType = getNormalizedTypeForDbType(
							Varicent.Domain.Schema.DbColumnType[value.dataType]
						);
						if (!headerValueType) return acc;
						return {
							...acc,
							[value.valueId]: headerValueType,
						};
					}

					return acc;
				}, {} as Record<string, NormalizedColumnType>)
			) as Record<string, NormalizedColumnType>,
		[sourceSchema]
	);
	return {
		params: memoizedParams,
		columnNameToType,
	};
};

export const useLivePreviewInfo = ({ preview }: { preview?: boolean }) => {
	const values = useContext(ValueStore);
	const { values: reportValues, sources, metadata } = useContext(ReportContext);
	const { payeeId } = useContext(LiveDataPayeeContext);

	const previewInfo = useDeepMemo(
		useMemo(() => {
			return preview &&
				metadata?.extra?.useLiveData &&
				payeeId &&
				sources?.length
				? ({
						context: { params: values },
						sources,
						values: reportValues ?? [],
						payeeId: payeeId ?? '',
				  } as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourcePreviewInfoDTO)
				: undefined;
		}, [
			reportValues,
			preview,
			metadata?.extra?.useLiveData,
			payeeId,
			values,
			sources,
		])
	);

	const isUsingLiveData = metadata?.extra?.useLiveData;

	const isLiveDataActive = metadata?.extra?.useLiveData && !isNil(payeeId);

	return {
		previewInfo,
		isUsingLiveData,
		isLiveDataActive,
	};
};

type CommonDataSource = {
	source:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO
		| undefined;
	preview?: boolean;
	previewPayeeId?: Varicent.ID;
	// either fetch for report data or form data
	reportId?: Varicent.ID;
	formId?: Varicent.ID;
	sourceSchema?: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO | undefined;
	pageSize?: number;
	currentPage?: number;
	fakeDataSize?: number;
	fakeParams?: FakeParams;
	filterBy?: string;
	idColumn?: string;
	selectedValue?: string;
	orderBy?: string;
	orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
	chartGridInfo?: ChartGridInfo;
	refresh?: boolean; // Flipping the boolean value will trigger a refresh
	isChart?: boolean; // check if the component fetching is a chart
	hasErrors?: boolean;
	column1?: string;
	column2?: string;
	aggregateInfo?:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO[];
};

export type SingleDataOverload = CommonDataSource & {
	pivotInfo?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPivotColumnInfoDTO;
};

export type MultiDataOverload = CommonDataSource & {
	pivotInfo: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPivotColumnInfoDTO[];
};

export type ChartGridInfo = {
	valueColumn?: string;
	xColumn: string;
	yColumn: string;
};

export type FakeParams = {
	xMinimum?: number;
	xMaximum?: number;
	yAxisOptions: {
		yMinimum?: number;
		yMaximum?: number;
		decimal?: number;
	}[];
};

// initializing advance filters during live data triggers set source columns. we need to validate this before fetching as it will not be read during preview
export function hasInvalidSources(
	sources: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO[]
) {
	for (const previewSource of sources) {
		const andFilter =
			previewSource.sourceFilters.filters &&
			previewSource.sourceFilters.filters.filter(
				(x) => x.metadata?.type === 'advanced'
			)?.[0];
		if (
			andFilter &&
			andFilter.and &&
			andFilter.and?.filters.filter((x) => x.constraint?.columnName === '')
				.length > 0
		) {
			return true;
		}
	}
	return false;
}

export function hasInvalidSourceColumns(
	source: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO,
	previewSources: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO[],
	pivotInfo:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPivotColumnInfoDTO
		| undefined,
	aggregateInfo:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO
		| undefined
) {
	const previewSource = previewSources.find(
		(s) => s.sourceId === source.sourceId
	);
	// Check if previewSource exists
	if (!previewSource) return true;
	// Check Source and PreviewSource Columns are the same
	if (
		source.columns.some(
			(column) =>
				!previewSource.columns.some(
					(prevColumn) => prevColumn.columnName === column.columnName
				)
		)
	)
		return true;
	// Check Pivot Info's columns are in source columns
	if (
		pivotInfo &&
		(!previewSource.columns.some(
			(c) => c.columnName === pivotInfo.categoryName
		) ||
			!previewSource.columns.some(
				(c) => c.columnName === pivotInfo.groupByName
			) ||
			!previewSource.columns.some((c) => c.columnName === pivotInfo.valueName))
	)
		return true;
	// Check Aggregate Info's columns are in source columns
	if (
		aggregateInfo &&
		(aggregateInfo.groupByNames.some(
			(columnName) =>
				!previewSource.columns.some(
					(prevColumn) => prevColumn.columnName === columnName
				)
		) ||
			aggregateInfo.valueNames.some(
				(columnName) =>
					!previewSource.columns.some(
						(prevColumn) => prevColumn.columnName === columnName
					)
			))
	)
		return true;
	return false;
}

function useDataSource(args: SingleDataOverload): {
	columnNameToType: Record<string, NormalizedColumnType>;
	data: UseQueryResult<RowStreamResult>;
};
function useDataSource(args: MultiDataOverload): {
	columnNameToType: Record<string, NormalizedColumnType>;
	data: UseQueryResult<RowStreamResult[]>;
};
function useDataSource(args: SingleDataOverload | MultiDataOverload): {
	columnNameToType: Record<string, NormalizedColumnType>;
	data: UseQueryResult<RowStreamResult | RowStreamResult[]>;
};
function useDataSource({
	source,
	preview,
	previewPayeeId,
	reportId,
	formId,
	sourceSchema,
	pageSize,
	currentPage,
	fakeDataSize,
	fakeParams,
	filterBy,
	idColumn,
	selectedValue,
	orderBy,
	orderDirection,
	pivotInfo,
	aggregateInfo,
	chartGridInfo,
	refresh,
	isChart,
	hasErrors,
	column1,
	column2,
}: SingleDataOverload | MultiDataOverload) {
	const { columnNameToType, params } = useDataSourceDependencies({
		source,
		sourceSchema,
	});

	const { previewInfo } = useLivePreviewInfo({ preview });

	const { decrementDataLoadedCount, incrementDataLoadedCount } = useContext(
		ComponentDataLoadedContext
	);
	const internalPublish = useContext(PublishMode);

	const selectedBy =
		idColumn && selectedValue
			? columnNameToType[idColumn]?.startsWith('date')
				? makeEqualsFilter(
						idColumn,
						dateToSQLDate(new Date(Number.parseInt(selectedValue, 10)))
				  )
				: makeEqualsFilter(idColumn, selectedValue)
			: undefined;

	const rows = useQuery(
		[
			'presenter-adaptive-datasource',
			{
				reportId,
				formId,
				source,
				preview,
				previewPayeeId,
				sourceSchema,
				pageSize,
				currentPage,
				params,
				filterBy,
				idColumn,
				selectedValue,
				orderBy,
				orderDirection,
				pivotInfo,
				fakeParams,
				refresh,
				previewInfo,
				hasErrors,
			},
		],
		async () => {
			// fetching rows for non pivot charts or where pivot is not a multi combination series
			if (!pivotInfo || !Array.isArray(pivotInfo)) {
				if (!Array.isArray(aggregateInfo)) {
					return fetchDataSourceRows({
						preview,
						previewPayeeId,
						source,
						columnNameToType,
						fakeDataSize,
						fakeParams: {
							xMinimum: fakeParams?.xMinimum,
							xMaximum: fakeParams?.xMaximum,
							yMinimum: fakeParams?.yAxisOptions[0]?.yMinimum,
							yMaximum: fakeParams?.yAxisOptions[0]?.yMaximum,
							decimal: fakeParams?.yAxisOptions[0]?.decimal,
						},
						pageSize,
						currentPage,
						reportId,
						formId,
						params,
						filterBy,
						selectedBy,
						orderBy,
						orderDirection,
						pivotInfo,
						aggregateInfo,
						decrementDataLoadedCount,
						incrementDataLoadedCount,
						internalPublish,
						chartGridInfo,
						isChart,
						previewInfo,
						column1,
						column2,
						hasErrors,
					});
				}
				// fetching data rows for multi series
				return Promise.all(
					aggregateInfo.map((ci, i) => {
						return fetchDataSourceRows({
							preview,
							previewPayeeId,
							source,
							columnNameToType,
							fakeDataSize,
							fakeParams: {
								xMinimum: fakeParams?.xMinimum,
								xMaximum: fakeParams?.xMaximum,
								yMinimum: fakeParams?.yAxisOptions[i]?.yMinimum,
								yMaximum: fakeParams?.yAxisOptions[i]?.yMaximum,
								decimal: fakeParams?.yAxisOptions[i]?.decimal,
							},
							pageSize,
							currentPage,
							reportId,
							params,
							filterBy,
							selectedBy,
							orderBy,
							orderDirection,
							pivotInfo,
							aggregateInfo: ci,
							decrementDataLoadedCount,
							incrementDataLoadedCount,
							internalPublish,
							chartGridInfo,
							previewInfo,
							hasErrors,
						});
					})
				);
			}

			// fetching for multicombination pivoting chart.
			return Promise.all(
				pivotInfo.map((pi, i) => {
					return fetchDataSourceRows({
						preview,
						previewPayeeId,
						source,
						columnNameToType,
						fakeDataSize,
						fakeParams: {
							xMinimum: fakeParams?.xMinimum,
							xMaximum: fakeParams?.xMaximum,
							yMinimum: fakeParams?.yAxisOptions[i]?.yMinimum,
							yMaximum: fakeParams?.yAxisOptions[i]?.yMaximum,
							decimal: fakeParams?.yAxisOptions[i]?.decimal,
						},
						pageSize,
						currentPage,
						reportId,
						formId,
						params,
						filterBy,
						selectedBy,
						orderBy,
						orderDirection,
						pivotInfo: pi,
						decrementDataLoadedCount,
						incrementDataLoadedCount,
						internalPublish,
						chartGridInfo,
						previewInfo,
						hasErrors,
					});
				})
			);
		},
		{
			keepPreviousData: true,
		}
	);

	if (rows.isError) {
		// allow error boundary to catch these errors
		const message =
			(rows.error as any)?.response?.data?.Message ??
			(rows.error as any).message;
		throw new Error(message);
	}

	return {
		columnNameToType,
		data: rows as any,
	};
}

export type RowStreamResult = {
	schemaInfo: {
		totalRows: number | null;
		columns: string[];
		aggregateResults: { [key: string]: number };
	};
	rows: Record<string, any>[];
};

const militaryAlphabet = {
	A: 'Alpha',
	B: 'Bravo',
	C: 'Charlie',
	D: 'Delta',
	E: 'Echo',
	F: 'Foxtrot',
	G: 'Golf',
	H: 'Hotel',
	I: 'India',
	J: 'Juliet',
	K: 'Kilo',
	L: 'Lima',
	M: 'Mike',
	N: 'November',
	O: 'Oscar',
	P: 'Papa',
	Q: 'Quebec',
	R: 'Romeo',
	S: 'Sierra',
	T: 'Tango',
	U: 'Uniform',
	V: 'Victor',
	W: 'Whiskey',
	X: 'X-ray',
	Y: 'Yankee',
	Z: 'Zulu',
};

const militaryAlphabetValues = Object.values(militaryAlphabet);

async function fetchDataSourceRows({
	preview,
	previewPayeeId,
	source,
	columnNameToType,
	fakeDataSize,
	fakeParams,
	pageSize,
	currentPage,
	reportId,
	params,
	filterBy,
	selectedBy,
	orderBy,
	orderDirection,
	pivotInfo,
	aggregateInfo,
	groupByInfo,
	aggregateColumns,
	sortOptions,
	decrementDataLoadedCount,
	incrementDataLoadedCount,
	internalPublish,
	chartGridInfo,
	isChart,
	formId,
	previewInfo,
	column1,
	column2,
	hasErrors,
	requestQueryOptions,
}: {
	preview?: boolean;
	previewPayeeId?: Varicent.ID;
	source?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO;
	columnNameToType: Record<string, NormalizedColumnType>;
	fakeDataSize?: number;
	fakeParams?: {
		xMinimum?: number;
		xMaximum?: number;
		yMinimum?: number;
		yMaximum?: number;
		decimal?: number;
	};
	pageSize?: number;
	currentPage?: number;
	reportId?: Varicent.ID;
	formId?: Varicent.ID;
	params?: Record<number, string>;
	filterBy?: string;
	selectedBy?: string;
	orderBy?: string;
	orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
	pivotInfo?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPivotColumnInfoDTO;
	aggregateInfo?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
	groupByInfo?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexGroupByInfoDTO;
	sortOptions?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridSortOptionDTO[];
	aggregateColumns?: { [key: string]: string[] };
	decrementDataLoadedCount: () => void;
	incrementDataLoadedCount: () => void;
	internalPublish: boolean;
	chartGridInfo?: ChartGridInfo;
	isChart?: boolean;
	previewInfo?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourcePreviewInfoDTO;
	column1?: string;
	column2?: string;
	hasErrors?: boolean;
	requestQueryOptions?: {
		limit?: number;
		offset?: number;
		orderBy?: string;
		filterBy?: string;
		selectedBy?: string;
		orderDirection?: Varicent.Domain.SQL.OrderItem.OrderDirection;
		expensiveIncludeTotalRows?: boolean;
	};
}) {
	/*
	 * Used for pivot data
	 * Get the sum of the array without the first element
	 */
	const getSum = (numArr) => {
		const numArrKeys = Object.keys(numArr);
		return numArrKeys.reduce((total, key) => {
			return key !== pivotInfo?.groupByName ? total + numArrKeys[key] : total;
		});
	};

	const compareWithSortOption = (a, b, idx) => {
		if (!sortOptions || !sortOptions[idx]) return 0;

		const aVal = a[sortOptions[idx].columnName];
		const bVal = b[sortOptions[idx].columnName];

		if (pivotInfo && pivotInfo.valueName === sortOptions[idx].columnName) {
			const sumA = getSum(a);
			const sumB = getSum(b);
			if (sumA === sumB) return compareWithSortOption(a, b, idx + 1);

			return sortOptions[idx].type === 'ASC' ? sumA > sumB : sumB > sumA;
		}

		if (aVal === bVal) return compareWithSortOption(a, b, idx + 1);
		if (typeof aVal === 'number')
			return sortOptions[idx].type === 'ASC' ? aVal - bVal : bVal - aVal;

		return sortOptions[idx].type === 'ASC'
			? aVal.localeCompare(bVal, 'en', { sensitivity: 'base' })
			: bVal.localeCompare(aVal, 'en', { sensitivity: 'base' });
	};

	const sortBySortOptions = (a, b) => {
		if (sortOptions && sortOptions.length)
			return compareWithSortOption(a, b, 0);

		return 0;
	};

	const hasInvalidPivotInfo =
		pivotInfo &&
		(!pivotInfo.groupByName || !pivotInfo.valueName || !pivotInfo.categoryName);

	try {
		incrementDataLoadedCount();
		if (
			preview &&
			!previewPayeeId &&
			!previewInfo &&
			source &&
			columnNameToType
		) {
			if (hasInvalidPivotInfo) return null;
			// fake some data!
			const columns = pivotInfo
				? [
						pivotInfo.groupByName,
						...generateRandomUniqueValues(
							columnNameToType[pivotInfo.categoryName],
							3
						),
				  ]
				: groupByInfo && groupByInfo.rowGroupCols.length > 0
				? [
						groupByInfo.rowGroupCols[0],
						'ChildCount',
						...groupByInfo.valueCols.map((c) => c.id),
				  ]
				: source.columns.map((c) => c.columnName);

			let fakeRows;
			if (chartGridInfo) {
				const size = fakeDataSize ?? 5;
				fakeRows = [];
				const xValues = generateRandomUniqueValues(
					columnNameToType[chartGridInfo.xColumn],
					size
				);
				const yValues = generateRandomUniqueValues(
					columnNameToType[chartGridInfo.yColumn],
					size
				);

				xValues.forEach((xVal) => {
					yValues.forEach((yVal) => {
						const fakeRow = {
							[chartGridInfo.xColumn as string | number]: xVal,
							[chartGridInfo.yColumn as string | number]: yVal,
						};
						if (chartGridInfo.valueColumn) {
							fakeRow[chartGridInfo.valueColumn as string | number] =
								generateRandomValue(
									columnNameToType[chartGridInfo.valueColumn]
								);
						}
						fakeRows.push(fakeRow);
					});
				});
			} else {
				fakeRows = new Array(
					fakeDataSize && pageSize && currentPage
						? fakeDataSize - pageSize * currentPage > 0
							? pageSize
							: fakeDataSize % pageSize
						: fakeDataSize ?? 5
				)
					.fill(0)
					.map(() =>
						columns
							.map((c, index) => {
								if (c === 'ChildCount')
									return generateRandomValue('numeric', {
										minimum: 1,
										maximum: 10,
										decimal: 0,
									});
								const aggregateCol = groupByInfo?.valueCols.find(
									(column) => column.id === c
								);
								if (aggregateCol && aggregateCol.aggFunc === Aggregate.Count) {
									return generateRandomValue(columnNameToType[c], {
										minimum: 1,
										maximum: 10,
										decimal: 0,
									});
								}
								const decimalPlaces =
									groupByInfo?.valueCols.find((col) => col.id === c)
										?.aggFunc === Aggregate.Count
										? 0
										: fakeParams?.decimal;
								return pivotInfo && index !== 0
									? generateRandomValue(columnNameToType[pivotInfo.valueName], {
											minimum: fakeParams?.yMinimum,
											maximum: fakeParams?.yMaximum,
											decimal: fakeParams?.decimal,
									  })
									: index === 0
									? generateRandomValue(columnNameToType[c], {
											minimum: fakeParams?.xMinimum,
											maximum: fakeParams?.xMaximum,
											decimal: decimalPlaces,
									  })
									: generateRandomValue(columnNameToType[c], {
											minimum: fakeParams?.yMinimum,
											maximum: fakeParams?.yMaximum,
											decimal: decimalPlaces,
									  });
							})
							.reduce(
								(obj, item, index) => ({
									...obj,
									[columns[index] as string | number]: item,
								}),
								{} as any
							)
					)
					.sort(sortBySortOptions);
			}
			const aggregateResults =
				groupByInfo && groupByInfo.valueCols.length > 0
					? groupByInfo.valueCols.reduce((obj, item) => {
							const options =
								item.aggFunc === Aggregate.Count
									? groupByInfo.rowGroupCols.length > 0
										? { minimum: 10, maximum: 50, decimal: 0 }
										: { minimum: 2000, decimal: 0 }
									: item.aggFunc === Aggregate.Minimum
									? { minimum: 0, maximum: 50 }
									: item.aggFunc === Aggregate.Maximum
									? { minimum: 1000, maximum: 1500 }
									: item.aggFunc === Aggregate.Average
									? { minimum: 750, maximum: 1250 }
									: { minimum: 2000 };
							return {
								...obj,
								[`${item.id}_${item.aggFunc}`]: generateRandomValue(
									'numeric',
									options
								),
							};
					  }, {})
					: {};
			return Promise.resolve({
				schemaInfo: {
					totalRows: fakeDataSize ?? 5,
					columns,
					aggregateResults,
				},
				rows: fakeRows,
			} as RowStreamResult);
		}
		if (
			(reportId === undefined && formId === undefined) ||
			source?.sourceId === undefined ||
			isEmpty(source?.columns) ||
			columnNameToType === undefined ||
			(preview &&
				previewInfo &&
				(hasInvalidPivotInfo ||
					hasInvalidSources(previewInfo.sources) ||
					previewInfo.sources.some((s) => isEmpty(s.columns)) ||
					hasInvalidSourceColumns(
						source,
						previewInfo.sources,
						pivotInfo,
						aggregateInfo
					) ||
					hasErrors))
		) {
			return null;
		}

		const options = {
			limit: isChart ? 5001 : internalPublish ? publishMaxRows : 100,
			filterBy: filterBy?.trim() || undefined,
			selectedBy: selectedBy?.trim() || undefined,
			orderBy,
			orderDirection,
			column1,
			column2,
			...(previewPayeeId && { payeeId: previewPayeeId }),
			...(previewInfo &&
				previewInfo.payeeId && { payeeId: previewInfo.payeeId }),
			...(pageSize && {
				limit: pageSize,
				offset: currentPage ? (currentPage - 1) * pageSize : 0,
				expensiveIncludeTotalRows: true,
			}),
			...(requestQueryOptions || {}),
		};
		let resultString:
			| string
			| {
					totalRows: number | null;
					columns: string[];
					aggregateResults: { [key: string]: number };
			  } = '';
		if (formId) {
			resultString = await formsGetSourceRows(
				formId,
				source.sourceId,
				{
					params: params ?? {},
				},
				options
			);
		} else if (preview && previewInfo) {
			// We can't add these values in the upper scope since they may be in arrays
			const expandedPreviewInfo = {
				...previewInfo,
				context: {
					...previewInfo.context,
					aggregateInfo,
					pivotInfo,
					groupByInfo,
					aggregateColumns,
					sortOptions,
				},
			};
			resultString = await (column1 && column2 && filterBy
				? getPickListSourceRowsForPreview
				: getSourceRowsForPreview)(
				reportId === 'new' ? -1 : (reportId as any),
				source.sourceId,
				expandedPreviewInfo as any,
				options
			);
		} else if (column1 && column2 && filterBy && reportId) {
			resultString = await (preview || previewPayeeId
				? getPickListSourceRows
				: getPickListSourceRowsForPayee)(
				reportId,
				source.sourceId,
				{ params: params ?? {}, pivotInfo, aggregateInfo, sortOptions },
				options
			);
		} else if (reportId) {
			resultString = await (preview || previewPayeeId
				? getSourceRows
				: getSourceRowsForPayee)(
				reportId,
				source.sourceId,
				{
					params: params ?? {},
					pivotInfo,
					aggregateInfo,
					groupByInfo,
					aggregateColumns,
					sortOptions,
				},
				options
			);
		}

		const baseResult: RowStreamResult = {
			schemaInfo: {
				totalRows: null,
				columns: [],
				aggregateResults: {},
			},
			rows: [],
		};

		if (typeof resultString !== 'string') {
			return {
				...baseResult,
				schemaInfo: {
					...resultString,
					// totalRows is an expensive operation on the API, so it's null if not requested
					totalRows: resultString.totalRows,
					aggregateResults: resultString.aggregateResults ?? {},
				} as RowStreamResult['schemaInfo'],
			};
		}

		const result: RowStreamResult = resultString
			.split('\n')
			.reduce((acc, item, index) => {
				if (!item) {
					return acc;
				}
				const parsed = JSON.parse(item);
				if (index === 0) {
					return {
						...acc,
						schemaInfo: {
							...parsed,
							// totalRows is an expensive operation on the API, so it's null if not requested
							totalRows: parsed.totalRows,
							aggregateResults: parsed.aggregateResults ?? {},
						},
					};
				}
				/*
				 * convert list into keys by object because reordering of columns is something we support purely
				 * on the frontend.
				 */
				const obj = (parsed as any[]).reduce(
					(colMap, col, colIndex) => ({
						...colMap,
						[acc.schemaInfo.columns[colIndex]]:
							(isObject(col) && isEmpty(col)) || col === '' // check the case of empty set {} or empty string
								? ''
								: columnNameToType[acc.schemaInfo.columns[colIndex]] ===
										'date' ||
								  columnNameToType[acc.schemaInfo.columns[colIndex]] ===
										'datetime'
								? parseJSON(col).getTime()
								: col,
					}),
					{}
				);

				return {
					...acc,
					rows: [...acc.rows, obj],
				};
			}, baseResult);

		return result;
	} finally {
		decrementDataLoadedCount();
	}
}

export function generateRandomValue(
	type: NormalizedColumnType,
	options?: {
		minimum?: number;
		maximum?: number;
		decimal?: number;
	}
) {
	switch (type) {
		case 'numeric': {
			const dec = options?.decimal ?? 2;
			const decimalPlaces = dec ? 10 ** dec : 1;
			let min = options?.minimum ?? 0;
			let max = options?.maximum ?? 1500;
			if (options?.minimum === undefined && options?.maximum !== undefined) {
				min =
					options?.maximum < 1500 && options?.maximum > 0
						? 0
						: options?.maximum - 1500;
			} else if (
				options?.minimum !== undefined &&
				options?.maximum === undefined
			) {
				max =
					options?.minimum < 0 && options?.minimum > -1500
						? 0
						: options?.minimum + 1500;
			}
			min *= decimalPlaces;
			max *= decimalPlaces;
			return Math.round(Math.random() * (max - min) + min) / decimalPlaces;
		}
		case 'text':
			return militaryAlphabetValues[
				Math.floor(Math.random() * militaryAlphabetValues.length)
			];
		case 'date':
		case 'datetime':
			// eslint-disable-next-line no-case-declarations
			const start = Date.UTC(2012, 0, 1);
			// eslint-disable-next-line no-case-declarations
			const end = Date.now();
			return start + Math.random() * (end - start);
		default:
			return '';
	}
}

export function generateRandomUniqueValues(
	type: NormalizedColumnType,
	size: number
) {
	const nums = new Set<string | number>();
	if (type) {
		while (nums.size !== size) {
			nums.add(generateRandomValue(type));
		}
	}
	return nums;
}

export default useDataSource;

export const useAgGridDataSource = ({
	source,
	preview,
	previewPayeeId,
	reportId,
	sourceSchema,
	fakeDataSize,
	onParamsChange,
	cellConditionSettings,
	presFlexValues,
	sortOptions,
	configuredColumns,
}: {
	source?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO;
	preview?: boolean;
	previewPayeeId?: Varicent.ID;
	reportId?: Varicent.ID;
	sourceSchema?: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
	fakeDataSize?: number;
	onParamsChange?: () => void;
	cellConditionSettings?: {
		[key: string]: IConditionProps;
	};
	presFlexValues?:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[]
		| undefined;
	sortOptions?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridSortOptionDTO[];
	configuredColumns?: { columnName: string; formula?: any }[];
}) => {
	const { columnNameToType, params } = useDataSourceDependencies({
		source,
		sourceSchema,
		presFlexValues,
	});
	const columnCalcFormulas = useMemo(
		() =>
			configuredColumns &&
			configuredColumns
				.filter((col) => !!col.formula)
				.map((col) => col.formula),
		[configuredColumns]
	);

	const { previewInfo } = useLivePreviewInfo({ preview });
	const [totalRowCount, setTotalRowCount] = useState<number | null>(null);
	const [subTotalResults, setSubTotalResults] = useState<object>({});
	const queryClient = useQueryClient();

	const [aggregateInfo, setAggregateInfo] = useState<
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO
		| undefined
	>();

	const [groupByInfo, setGroupByInfo] = useState<
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexGroupByInfoDTO
		| undefined
	>();

	useEffect(() => {
		if (onParamsChange) {
			onParamsChange();
		}
	}, [params, onParamsChange]);

	const { decrementDataLoadedCount, incrementDataLoadedCount } = useContext(
		ComponentDataLoadedContext
	);
	const internalPublish = useContext(PublishMode);

	// To make the params to send to the backend from cell condition to aggregate function requirements
	const aggregateColumnsThroughCondition = useDeepMemo(
		useMemo(() => {
			const result: Record<string, string[]> = {};
			if (!isNil(cellConditionSettings)) {
				Object.values(cellConditionSettings).forEach((condition: any) => {
					let { formula } = condition;
					possibleAggregationFunc.forEach((aggregationFunc) => {
						while (formula.indexOf(aggregationFunc) !== -1) {
							const indexOfFunc = formula.indexOf(aggregationFunc);
							const func = formula.substring(
								indexOfFunc,
								formula.indexOf(')', indexOfFunc)
							);
							const columnName = func.split('(')[1].replace('Source.', '');
							result[columnName] = [
								...(result[columnName] || []),
								aggregationFunc.toLowerCase(),
							];
							formula = formula.replace(`${func})`, '');
						}
					});
				});
			}
			return result;
		}, [cellConditionSettings])
	);

	// To make the params to send to the backend from cell condition to aggregate function requirements
	const aggregateColumnsThroughCalculation = useDeepMemo(
		useMemo(() => {
			const result: Record<string, string[]> = {};
			if (columnCalcFormulas?.length) {
				columnCalcFormulas.forEach((formula: any) => {
					let newFormula = formula;
					possibleAggregationFunc.forEach((aggregationFunc) => {
						while (newFormula.indexOf(aggregationFunc) !== -1) {
							const indexOfFunc = formula.indexOf(aggregationFunc);
							const func = formula.substring(indexOfFunc, formula.indexOf(')'));
							const columnName = func.split('(')[1].replace('Source.', '');
							result[columnName] = [
								...(result[columnName] || []),
								aggregationFunc.toLowerCase(),
							];
							newFormula = newFormula.replace(`${func})`, '');
						}
					});
				});
			}
			return result;
		}, [columnCalcFormulas])
	);

	const aggregateResults = useQuery(
		[
			'ag-grid-datasource-cell-conditions',
			{
				fakeDataSize,
				params,
				preview,
				previewPayeeId,
				reportId,
				source,
				previewInfo,
				cellConditionSettings,
				columnCalcFormulas,
				aggregateColumnsThroughCondition,
				aggregateColumnsThroughCalculation,
			},
		],
		async () => {
			const results = await fetchDataSourceRows({
				preview,
				previewPayeeId,
				source,
				columnNameToType,
				fakeDataSize,
				reportId,
				params,
				groupByInfo: {
					rowGroupCols: [],
					groupKeys: [],
					valueCols: [],
				},
				aggregateColumns: {
					...aggregateColumnsThroughCondition,
					...aggregateColumnsThroughCalculation,
				},
				decrementDataLoadedCount: () => {},
				incrementDataLoadedCount: () => {},
				internalPublish,
				previewInfo,
				requestQueryOptions: { limit: 0 },
			});
			let keyedColumnConditions = {};
			if (!isNil(cellConditionSettings)) {
				Object.values(cellConditionSettings).forEach((condition: any) => {
					condition.selectedColumns.forEach((conditionForColumn) => {
						keyedColumnConditions = {
							...keyedColumnConditions,
							[conditionForColumn.columnName]: [
								...(keyedColumnConditions[conditionForColumn.columnName] || []),
								condition,
							],
						};
					});
				});
			}
			if (!results)
				return {
					keyedColumnConditions: undefined,
					aggregateResults: undefined,
				};
			return {
				keyedColumnConditions,
				aggregateResults: results.schemaInfo.aggregateResults,
			};
		},
		{
			keepPreviousData: true,
		}
	);

	// in case when getRows doesn't get triggered soon enough
	useEffect(() => {
		incrementDataLoadedCount();
	}, [incrementDataLoadedCount]);

	const datasource: IServerSideDatasource = useMemo(() => {
		return {
			getRows: async (getRowParams) => {
				incrementDataLoadedCount();
				decrementDataLoadedCount();
				const pageSize = internalPublish
					? publishMaxRows
					: getRowParams.request.endRow - getRowParams.request.startRow;

				const filterBy = buildFilteredByFromAgFilterModel(
					getRowParams.request.filterModel
				);
				// build auto aggregation
				const groupByColumns = [] as string[];
				const aggregateColumns = [] as string[];

				for (const x of source?.columns ?? []) {
					if (columnNameToType[x.columnName] === 'numeric')
						aggregateColumns.push(x.columnName);
					else groupByColumns.push(x.columnName);
				}

				const tmpAggregateInfo = !isEmpty(groupByColumns)
					? { groupByNames: groupByColumns, valueNames: aggregateColumns }
					: undefined;

				const tmpGroupByInfo = {
					rowGroupCols: getRowParams.request.rowGroupCols.map((r) => r.id),
					groupKeys: getRowParams.request.groupKeys.map((key, index) => {
						const rowColType =
							columnNameToType[getRowParams.request.rowGroupCols[index].id];
						return (rowColType === 'datetime' || rowColType === 'date') &&
							!RisEmpty(key) // using ramda isEmpty because lodash isEmpty returns true for numbers.
							? dateToSQLDate(new Date(key))
							: key;
					}),
					valueCols: getRowParams.request.valueCols
						.filter((v) => typeof v.aggFunc === 'string')
						.map((v) => {
							return { id: v.id, aggFunc: v.aggFunc ?? '' };
						}),
				};

				// getRowParams has the newest data, so get sortmodel from it.
				const newSortOptions =
					getRowParams.request.sortModel.map((s) => ({
						columnName: s.colId,
						type: s.sort.toUpperCase(),
					})) ?? [];

				setAggregateInfo(tmpAggregateInfo);
				setGroupByInfo(tmpGroupByInfo);

				const result = await queryClient.fetchQuery(
					[
						'presenter-adaptive-datasource',
						{
							preview,
							previewPayeeId,
							source,
							columnNameToType,
							fakeDataSize,
							pageSize,
							requestParams: getRowParams.request,
							reportId,
							params,
							filterBy,
							aggregateInfo,
							previewInfo,
						},
					],
					() =>
						fetchDataSourceRows({
							preview,
							previewPayeeId,
							source,
							columnNameToType,
							fakeDataSize,
							pageSize,
							currentPage:
								Math.floor(getRowParams.request.startRow / pageSize) + 1, // page numbers start at 1
							reportId,
							params,
							orderBy: undefined,
							orderDirection: undefined,
							filterBy,
							aggregateInfo: tmpAggregateInfo,
							groupByInfo: tmpGroupByInfo,
							sortOptions: newSortOptions,
							aggregateColumns: {
								...getRowParams.request.valueCols.reduce((obj, item) => {
									return {
										...obj,
										[item.id]: [item.aggFunc],
									};
								}, {}),
								...aggregateColumnsThroughCondition,
								...aggregateColumnsThroughCalculation,
							},
							decrementDataLoadedCount,
							incrementDataLoadedCount,
							internalPublish,
							previewInfo,
						})
				);
				if (!result) {
					getRowParams.successCallback([], 0);
					setTotalRowCount(null);
				} else {
					getRowParams.successCallback(
						result.rows,
						result.schemaInfo.totalRows ?? 0
					);
					setTotalRowCount(result.schemaInfo.totalRows);
					setSubTotalResults(result.schemaInfo.aggregateResults);
				}
				/*
				 * Kludge: add an extra 5 seconds per 1000 rows,
				 * and an extra second per 4 columns.
				 * Ag-grid takes a long time to render large sets.
				 * Perhaps there is a better way of doing this, but this seems to work.
				 */
				const rowTimeoutFactor = (result?.rows.length ?? 200) / 200;
				const columnTimeoutFactor =
					(result?.schemaInfo.columns.length ?? 4) / 4;

				const renderTimeoutFactor = rowTimeoutFactor + columnTimeoutFactor;
				/*
				 * delay to allow AG grid to render. Since this is for publish, we don't need to worry
				 * too much about being slightly slow, if it guarantees the rows are rendered.
				 */
				setTimeout(
					() => decrementDataLoadedCount(),
					1000 * (1 + renderTimeoutFactor)
				);
			},
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		decrementDataLoadedCount,
		incrementDataLoadedCount,
		fakeDataSize,
		params,
		preview,
		previewPayeeId,
		reportId,
		source,
		previewInfo,
		aggregateColumnsThroughCondition,
		sortOptions,
		aggregateColumnsThroughCalculation,
	]);

	const columnNameToTypeForCalc = useMemo(
		() => ({
			...columnNameToType,
			...(configuredColumns || [])
				.filter((col) => !!col.formula)
				.reduce((acc, col) => {
					if (!columnNameToType[col.columnName]) {
						return { ...acc, [col.columnName]: 'numeric' };
					}
					return { ...acc, [col.columnName]: '' };
				}, {}),
		}),
		[columnNameToType, configuredColumns]
	);

	return {
		datasource,
		columnNameToType: columnNameToTypeForCalc,
		totalRowCount,
		keyedColumnConditions: aggregateResults.data?.keyedColumnConditions,
		aggregateResults: aggregateResults.data?.aggregateResults,
		subTotalResults,
		aggregateInfo,
		groupByInfo,
	};
};
