/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { map, pipe } from 'ramda';
import { generateColorRange } from '../../../utils/colors';
import { NumberFormatOption } from '../../../utils/dataGridStyling';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	defaultFontColor,
	formatDateUTC,
	simplifiedFormatNumber,
	invertedFontColor,
} from '../chartUtil';

export const treemapChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartTreemapDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
		palette,
	} = props;
	const {
		labelColumn,
		valueColumn,
		legendPosition,
		showLegend,
		labelPosition,
		showDataValues,
		showDataLabels,
		showDataAbsValues,
		showDataPercentages,
	} = config;

	const type = 'treemap';

	const formatAbsValue = (value) =>
		simplifiedFormatNumber({
			value: value ?? 0,
			config,
			multiCol: 'AbsValue',
		});

	const getColor = generateColorRange(
		[labelColumn],
		palette?.config.classification?.paletteColors
	);
	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const xValue = labelIsDateBased
				? formatDateUTC(this.point.name, intl)
				: this.point.name;
			return `${xValue}: <strong>${formatAbsValue(
				this.point.options.value
			)}</strong>`;
		},
	};

	const divOrSeq = palette?.config.divergent || palette?.config.sequential;
	if (!divOrSeq) {
		existingOptions.colorAxis = {
			minColor: '#FFFFFF',
			maxColor: getColor(labelColumn),
			labels: {
				style: {
					color: invertFontColors ? invertedFontColor : defaultFontColor,
				},
			},
		};
	}

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? false,
		layout:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? 'vertical'
				: 'horizontal',
		align:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? legendPosition
				: 'center',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: legendPosition === 'left' || legendPosition === 'right'
				? 'middle'
				: 'bottom',
	};

	const dataRows = pipe(
		map((row: any) => {
			return {
				name: labelIsDateBased
					? `${formatDateUTC(row[labelColumn], intl)}`
					: row[labelColumn],
				value: row[valueColumn],
				colorValue: row[valueColumn],
			};
		})
	)(data.rows);

	const series: Highcharts.SeriesTreemapOptions = {
		type: type as any,
		layoutAlgorithm: 'squarified',
		data: dataRows,
		dataLabels: {},
	};

	series.dataLabels = {
		enabled: showDataValues,
		style: {
			fontWeight: 'normal',
			textOutline: '0',
		},
		align:
			labelPosition === 'topright' || labelPosition === 'bottomright'
				? 'right'
				: labelPosition === 'center'
				? 'center'
				: 'left',
		verticalAlign:
			labelPosition === 'bottomleft' || labelPosition === 'bottomright'
				? 'bottom'
				: labelPosition === 'center'
				? 'middle'
				: 'top',
		formatter() {
			let format = '';

			if (showDataLabels !== false) {
				if (labelIsDateBased)
					format += `${formatDateUTC(this.point.name, intl)}`;
				else format += this.point.name;
				if (showDataAbsValues || showDataPercentages !== false) format += ': ';
			}
			if (showDataAbsValues) {
				format += `<b>${formatAbsValue(this.point.value)}</b>`;

				if (showDataPercentages !== false) format += ', ';
			}
			if (showDataPercentages !== false && this.point.value) {
				const total = this.series.data.reduce((totalValue, currentPoint) => {
					if (currentPoint.value) return totalValue + currentPoint.value;

					return totalValue;
				}, 0);

				format += `<b>${simplifiedFormatNumber({
					value: this.point.value / total ?? 0,
					config,
					multiCol: 'PctValue',
					defaultValueFormatType: NumberFormatOption.PERCENTAGE,
				})}</b>`;
			}

			return format;
		},
	};

	const options = {
		...existingOptions,
		tooltip,
		legend,
		series: [series],
	};
	return options;
};
