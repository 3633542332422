/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { replace } from 'ramda';

export const possibleAggregationFunc = ['SUM', 'MIN', 'MAX', 'AVG', 'COUNT'];

export const parseAggregationFormula = (formula: string) => {
	let newFormula = formula;
	if (possibleAggregationFunc.some((term) => formula.includes(term)))
		possibleAggregationFunc.forEach((aggregationFunc) => {
			while (newFormula.indexOf(aggregationFunc) !== -1) {
				const indexOfFunc = newFormula.indexOf(aggregationFunc);
				const func = newFormula.substring(
					indexOfFunc,
					newFormula.indexOf(')', indexOfFunc)
				);
				const columnName = func.split('(')[1].replace('Source.', '');
				const newVar = `${columnName}_${aggregationFunc.toLowerCase()}`;
				newFormula = newFormula.replace(`${func})`, newVar);
			}
		});
	return newFormula;
};

export const parseFormula = (formula) => {
	if (formula.search(/[0-9]/g) === -1) return formula;
	let newFormula = formula;
	while (newFormula.indexOf('Source.') >= 0) {
		const regexStart = /Source./g;
		const regexEnd = /[^\w\s?]/g;
		const startIndex = newFormula.search(regexStart);
		const stringAfterSource = newFormula.substring(startIndex + 7);
		const lastIndex = stringAfterSource.search(regexEnd);
		const variableName = stringAfterSource.substring(0, lastIndex);
		const parsedVariableName = parseColumnName(variableName);
		newFormula = `${newFormula.substring(
			0,
			startIndex
		)}S@C.${parsedVariableName}${stringAfterSource.substring(lastIndex)}`;
	}
	newFormula = replace(/S@C\./g, 'Source.', newFormula);
	return newFormula;
};

export const parseColumnName = (name: string) => {
	let newName = name;
	const numberIndex = newName.search(/[0-9]/g);
	if (numberIndex > 0 && newName[numberIndex - 1] !== '_') {
		newName = `${newName.substring(0, numberIndex)}_${newName.substring(
			numberIndex
		)}`;
	}
	return newName;
};
