/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext } from 'react';
import { Varicent } from 'icm-rest-client';
import { dateToSQLDate, dateToUTCString } from 'icm-core/lib/utils/filterUtils';
import { LiveDataPayeeContext, ReportContext, ValueStore } from '../context';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import Link, { LinkProps } from 'redux-first-router-link';
import { RawDraftContentState } from 'draft-js';
import { useSelector } from 'react-redux';
import { HTMLLink } from '@varicent/components';
import { isEmpty, isNil, mapObjIndexed, zipObj } from 'ramda';
import qs from 'qs';
import { NormalizedColumnType } from 'icm-core/lib/utils/dbUtils';
import { CellRendererComponent } from './dataGridStyling';

type LinkType = Varicent.Domain.PresenterFlex.PresenterFlexLink.LinkType;
const EXTERNAL_LINK =
	Varicent.Domain.PresenterFlex.PresenterFlexLink.LinkType.External;
const CLASSIC_LINK =
	Varicent.Domain.PresenterFlex.PresenterFlexLink.LinkType.PresenterClassic;
const FLEX_LINK =
	Varicent.Domain.PresenterFlex.PresenterFlexLink.LinkType.PresenterFlex;
/**
 * Type represents possible types of links. We currently support two:
 * - clientSide = true: use client side history (only works in payee-web)
 * - clientSide = false: use standard anchor
 */
type LinkResults =
	| {
			props: LinkProps;
			clientSide: true;
	  }
	| {
			props: React.DetailedHTMLProps<
				React.AnchorHTMLAttributes<HTMLAnchorElement>,
				HTMLAnchorElement
			>;
			clientSide: false;
	  };

// From richTextEditor
type ValueEntityData = {
	valueId?: number;
	special?:
		| Varicent.Domain.PresenterFlex.PresenterFlexSourceFilter.Value.Special
		| Varicent.Domain.PresenterFlex.PresenterFlexLink.Value.Special;
	rowValue?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLinkRowValueDTO;
};

function hasRowValue(
	arg:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceFilterValueDTO
): arg is Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO {
	return (
		(
			arg as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO
		).rowValue !== undefined
	);
}

export const evaluateValue = (
	value:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceFilterValueDTO,
	options: {
		valuesMap: Record<number, any>;
		currentRowValue?: string | Date | number;
		rowValues?: Record<string, string | Date | number>;
		values?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[];
		colType?: NormalizedColumnType;
		currentUserId?: string;
		throwIfValueMissing?: boolean;
		linkType?: LinkType;
		columnNameToType?: Record<string, NormalizedColumnType>;
	}
) => {
	const {
		currentRowValue,
		rowValues,
		valuesMap,
		values,
		currentUserId,
		linkType,
		columnNameToType,
	} = options;
	const { valueId, special } = value;
	const rowValue = hasRowValue(value) ? value.rowValue : undefined;
	if (valueId !== undefined) {
		if (valuesMap[valueId] !== undefined) {
			const valueDTO = values?.find((v) => v.valueId === valueId);
			if (valueDTO?.dataType === 'Date' && valuesMap[valueId]) {
				const dateNumber = Date.parse(valuesMap[valueId]);
				return convertToDateParam(
					new Date(
						Number.isNaN(dateNumber)
							? parseInt(valuesMap[valueId], 10)
							: dateNumber
					),
					linkType
				);
			}
			return valuesMap[valueId];
		}
		if (options.throwIfValueMissing) {
			throw new Error('Missing value');
		}
	}
	if (special === 'CurrentWebUser' && currentUserId) {
		return currentUserId;
	}
	if (special === 'CurrentDate') {
		return convertToDateParam(new Date(), linkType);
	}
	if (valueId === undefined) {
		if (special === 'RowValue' && currentRowValue !== undefined)
			return currentRowValue instanceof Date
				? dateToSQLDate(currentRowValue)
				: currentRowValue;
		if (rowValue) {
			if (rowValues) {
				const val = rowValues[rowValue.columnName];
				const rowColType = columnNameToType?.[rowValue.columnName];
				return (rowColType === 'date' || rowColType === 'datetime') &&
					!isEmpty(val)
					? convertToDateParam(val, linkType)
					: val;
			}
			return currentRowValue + rowValue.columnName;
		}
	}
	if (value.date) {
		return convertToDateParam(value.date, linkType);
	}
	if (!isNil(value.numeric)) {
		return value.numeric;
	}
	if (value.text) {
		return value.text;
	}
	return undefined;
};

export const convertRichContentToUrl = (
	content: RawDraftContentState | undefined,
	options: {
		// valuesMap maps from number to the current value of a given.. value.
		valuesMap: Record<number, any>;
		currentRowValue?: string | Date | number;
		rowValues?: Record<string, string | Date | number>;
		// values is a list of the value DTOs, used mostly for getting the type of the value
		values?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[];
		currentUserId?: string;
		throwIfValueMissing?: boolean;
		linkType?: LinkType;
		columnNameToType?: Record<string, NormalizedColumnType>;
	}
) => {
	if (!content || content.blocks.length === 0) {
		return '';
	}
	const block = content.blocks[0];
	const linkDestination = block.text.replace(/\[[^\]]*\]/g, (match, offset) => {
		const entityAtRange = block.entityRanges.find((e) => e.offset === offset);
		if (entityAtRange) {
			const { valueId, special, rowValue } = content.entityMap[
				entityAtRange.key
			].data as ValueEntityData;

			const value = evaluateValue(
				{
					valueId,
					special,
					rowValue,
				},
				options
			);
			if (value !== undefined) {
				return options.linkType !== EXTERNAL_LINK
					? encodeURIComponent(value)
					: value;
			}
		}
		return match;
	});
	return linkDestination;
};

const convertToDateParam = (
	date: any,
	linkType?: Varicent.Domain.PresenterFlex.PresenterFlexLink.LinkType
) => {
	const dateObj = date instanceof Date ? date : new Date(date);

	if (linkType === CLASSIC_LINK) {
		return dateToUTCString(dateObj);
	}

	return dateToSQLDate(dateObj);
};

export const useLinkComponent: (props: {
	linkId?: number;
	currentRowValue?: string | Date | number;
	rowValues?: Record<string, string | Date | number>;
	columnNameToType?: Record<string, NormalizedColumnType>;
}) => LinkResults | undefined = ({
	linkId,
	currentRowValue,
	rowValues,
	columnNameToType,
}) => {
	const { links, values } = useContext(ReportContext);
	const valuesMap = useContext(ValueStore);
	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);

	const userId = useSelector((state: any) => state?.user?.payeeId);
	// need this to force refresh cells when using along with context.
	if (!linkId || !links || !values) {
		return undefined;
	}
	const link = links.find((l) => l.linkId === linkId);
	if (!link) {
		return undefined;
	}
	if (link.type === EXTERNAL_LINK && link.config.externalLink.richContent) {
		// only handling externals for now
		const content: RawDraftContentState = link.config.externalLink.richContent;
		const linkDestination = convertRichContentToUrl(content, {
			currentRowValue,
			rowValues,
			valuesMap,
			values,
			currentUserId: userId,
			linkType: link.type,
			columnNameToType,
		});
		const payeeWebUrl = `${window.location.hostname}${
			(window as any).PAYEE_WEB_CONFIG?.BASE_ROUTE ?? ''
		}`;
		if (linkDestination.startsWith(payeeWebUrl)) {
			if (link.config.externalLink.openWindow) {
				return {
					props: {
						target: '_blank',
						href: linkDestination.replace(window.location.hostname, ''),
					},
					clientSide: false,
				};
			}
			// local link, use client side routing
			return {
				props: {
					to: linkDestination.replace(payeeWebUrl, ''),
				},
				clientSide: true,
			};
		}

		return {
			props: {
				target: link.config.externalLink.openWindow ? '_blank' : undefined,
				href: `${link.config.externalLink.prefix}${linkDestination}`,
			},
			clientSide: false,
		};
	}
	if (link.config.internalLink) {
		let query = mapObjIndexed(
			(value) => {
				return evaluateValue(value, {
					valuesMap,
					values,
					currentUserId: userId,
					linkType: link.type,
					rowValues,
					currentRowValue,
					columnNameToType,
				});
			},
			mapKeys(
				(key) => (link.type === FLEX_LINK ? `p_${key}` : `${key}`),
				link.linkMapping
			)
		);
		let destination: string;
		if (!previewPayeeId && link.config.internalLink.webTabId) {
			let tabType: Varicent.Domain.WebTabs.WebTabTreeListNodeItem.WorkflowTabItemType;
			if (
				link.type ===
				Varicent.Domain.PresenterFlex.PresenterFlexLink.LinkType
					.PresenterClassic
			) {
				tabType =
					Varicent.Domain.WebTabs.WebTabTreeListNodeItem.WorkflowTabItemType
						.PresenterReport;
			} else if (link.type === FLEX_LINK) {
				tabType =
					Varicent.Domain.WebTabs.WebTabTreeListNodeItem.WorkflowTabItemType
						.PresenterAdaptive;
			} else {
				throw new Error(`Invalid referenced link type: ${link.type}`);
			}
			destination = `/${tabType}/${link.config.internalLink.webTabId}`;
		} else if (link.type === CLASSIC_LINK) {
			if (previewPayeeId) {
				destination = `/presenter/${link.config.internalLink.id}/preview`;
				query = {
					...query,
					payeeId: previewPayeeId,
				};
			} else {
				destination = `/ReportView`;
				query = {
					...query,
					ReportID: link.config.internalLink.id,
				};
			}
		} else if (link.type === FLEX_LINK) {
			if (previewPayeeId) {
				destination = `/presenteradaptive/${link.config.internalLink.id}/preview`;
				query = {
					...query,
					payeeId: previewPayeeId,
				};
			} else {
				destination = `/ReportView`;
				query = {
					...query,
					reportType: 'PresenterAdaptive',
					ReportID: link.config.internalLink.id,
				};
			}
		} else {
			throw new Error(`Unsupported link type: ${link.type}`);
		}
		const to = `${destination}${isEmpty(query) ? '' : '?'}${qs.stringify(
			query
		)}`;
		if (previewPayeeId) {
			if (link.config.internalLink.openWindow) {
				return {
					props: {
						target: '_blank',
						href: to,
					},
					clientSide: false,
				};
			}
			return {
				props: {
					href: to,
				},
				clientSide: false,
			};
		}
		if (
			link.config.internalLink.openWindow ||
			(window as any).PAYEE_WEB_CONFIG === undefined
		) {
			const route = process.env.IS_LOCAL
				? ''
				: (window as any).PAYEE_WEB_CONFIG?.BASE_ROUTE ?? '';
			return {
				props: {
					target: '_blank',
					href: `${route}${to}`,
				},
				clientSide: false,
			};
		}
		return {
			props: {
				to,
			},
			clientSide: true,
		};
	}
	return undefined;
};

export const ConditionalLink: CellRendererComponent = (props) => {
	const { children, columnDTO, value, data, columnNameToType } = props;

	const disableLink = (event: React.MouseEvent<any, MouseEvent>) => {
		event?.preventDefault();
		return false;
	};
	const linkComponent = useLinkComponent({
		linkId: columnDTO?.linkId,
		currentRowValue: value,
		rowValues: data,
		columnNameToType,
	});
	const { enableLinks } = useContext(ReportContext);
	if (!linkComponent) return <>{children}</>;
	if (!enableLinks) linkComponent.props.onClick = disableLink;
	if (linkComponent.clientSide) {
		return <Link {...linkComponent.props}>{children}</Link>;
	}
	return (
		<HTMLLink {...linkComponent.props} text={children}>
			{children}
		</HTMLLink>
	);
};

export function mapKeys<T extends object>(
	keyTransform: (key: string) => string,
	object: T
) {
	return zipObj(
		Object.keys(object).map(keyTransform),
		Object.values(object)
	) as T;
}
