/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext, useState } from 'react';
import { Varicent } from 'icm-rest-client';
import Placeholder from '../utils/placeholder';
import useDataSource from '../utils/useDataSource';
import { Select } from '@blueprintjs/select';
import { Spinner, MenuItem, Button, Intent } from '@blueprintjs/core';
import { css } from 'react-emotion';
import R, { uniqBy } from 'ramda';
import { PublishMode } from '../context';
import { defineMessages } from 'react-intl';
import { useIntl, CustomIntl } from 'icm-core/lib/contexts/intlContext';
import { makeLikeFilter } from 'icm-core/lib/utils/filterUtils';
import useDebounce from 'react-use/esm/useDebounce';
import highlightText from '../utils/highlightText';
import { FlexComponentTypes } from '../componentTypes';

const messages = defineMessages({
	placeholder: {
		id: 'components.gridPicklist.placeholder',
		defaultMessage: 'Select an option',
	},
	filter: {
		id: 'components.gridPicklist.filter',
		defaultMessage: 'Filter...',
	},
});

export const formatValueText = (
	displayText: string,
	idText: string,
	hideId: boolean,
	idIsDateBased: boolean,
	intl: CustomIntl
) => {
	// only ID columns can be date typed
	const formattedId =
		idIsDateBased && !R.isNil(idText)
			? intl.formatDate(idText, { timeZone: 'UTC' })
			: idText;
	return hideId ? displayText : `(${formattedId}) ${displayText}`;
};

const MAX_ROW_COUNT = 50;

const GridPicklist: React.FC<{
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.RowInputColumnDTO.PicklistDetails;
	value: any;
	source:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO
		| undefined;
	preview: boolean | undefined;
	previewPayeeId: Varicent.ID | undefined;
	reportId: undefined | string | number;
	sourceSchema: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO | undefined;
	update: (value: any) => void;
	placeholder?: string;
	width: number;
	intent?: Intent;
}> = ({
	config,
	source,
	preview,
	previewPayeeId,
	reportId,
	sourceSchema,
	value,
	update,
	placeholder,
	width,
	intent,
}) => {
	const intl = useIntl();
	const [queryString, setQueryString] = useState('');
	const [delayedQuery, setDelayedQuery] = useState(queryString);
	const isPublishMode = useContext(PublishMode);
	useDebounce(
		() => {
			setDelayedQuery(queryString);
		},
		400,
		[queryString]
	);
	const { data, columnNameToType } = useDataSource({
		source,
		preview,
		previewPayeeId,
		reportId,
		sourceSchema,
		pageSize: MAX_ROW_COUNT,
		filterBy: delayedQuery
			? makeLikeFilter(config.displayColumn, delayedQuery)
			: undefined,
		idColumn: config.idColumn,
		selectedValue: value,
		orderBy:
			config.sort === 'Display' || delayedQuery
				? config.displayColumn
				: config.idColumn,
		orderDirection:
			config.order === 'DESC'
				? Varicent.Domain.SQL.OrderItem.OrderDirection.Descending
				: Varicent.Domain.SQL.OrderItem.OrderDirection.Ascending,
	});

	const options: any[] =
		data.data !== undefined
			? uniqBy(
					(r) => r[config.idColumn] + r[config.displayColumn],
					data.data?.rows ?? []
			  )
			: [];

	const idIsDateBased = columnNameToType[config.idColumn]?.startsWith('date');

	const valueText = value;
	const totalRows = data.data?.schemaInfo.totalRows ?? 0;
	if (!source || !config.idColumn || !config.displayColumn) {
		return <Placeholder type={FlexComponentTypes.pickList} />;
	}
	const hidePicklist = config.hideValueForExport && isPublishMode;
	return (
		<>
			{!hidePicklist && (
				<Select
					className={css`
						.bp3-popover-target {
							width: ${width}%;
						}
					`}
					items={options}
					filterable={totalRows >= 1}
					onQueryChange={setQueryString}
					inputProps={{
						rightElement:
							data.isLoading || delayedQuery !== queryString ? (
								<Spinner size={16} />
							) : undefined,
						placeholder: intl.formatMessage(messages.filter),
					}}
					itemRenderer={(item, { handleClick, modifiers }) => {
						const text = formatValueText(
							item[config.displayColumn],
							item[config.idColumn],
							config.hideIDColumn,
							idIsDateBased,
							intl
						);
						return (
							<MenuItem
								onClick={handleClick}
								active={modifiers.active}
								disabled={modifiers.disabled}
								key={item[config.idColumn]}
								text={
									<>
										{queryString !== ''
											? highlightText(text, queryString)
											: text}
									</>
								}
							/>
						);
					}}
					popoverProps={{
						minimal: true,
					}}
					onItemSelect={(v) => update(v[config.idColumn])}
				>
					<Button
						className="select"
						// Withou the empty space the right icon will be centered instead
						text={valueText ?? placeholder ?? ' '}
						rightIcon="chevron-down"
						fill
						intent={intent}
					/>
				</Select>
			)}
		</>
	);
};

export default GridPicklist;
