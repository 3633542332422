/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { identity, map, pipe, sortBy } from 'ramda';
import { getSingleColor } from '../../../utils/colors';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	getCurrencySymbol,
	invertedFontColor,
	simplifiedFormatNumber,
	getFormattedLabels,
} from '../chartUtil';

export const areaChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartAreaDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		invertFontColors,
		palette,
	} = props;
	const {
		labelColumn,
		valueColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		xAxisOrentation,
		xAxisOrder,
		labelPosition,
		showDataValues,
		valueFormat,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		hideYAxisLine,
		hideGridLines,
		markerShape,
		hideMarkers,
		smoothLine,
	} = config;

	const type = smoothLine ? 'areaspline' : 'area';

	const currencyCode = getCurrencyCode(valueFormat);
	const currencySymbol = getCurrencySymbol(currencyCode);
	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const areaOptions:
		| Highcharts.PlotAreaOptions
		| Highcharts.PlotAreasplineOptions = {
		marker: {
			enabled: hideMarkers !== undefined ? !hideMarkers : false,
			symbol: markerShape ?? undefined,
		},
		allowPointSelect: true,
		cursor: 'pointer',
	};

	const xAxis: Highcharts.XAxisOptions = {
		type: labelIsDateBased ? 'datetime' : undefined,
		categories: !labelIsDateBased
			? getFormattedLabels(data.rows, labelColumn)
			: undefined,
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		reversed: labelIsDateBased ? xAxisOrder === 'DESC' : false,
	};

	const showYAxisMin = showYMinMax && !!yAxisMin && yAxisMin !== '';
	const showYAxisMax = showYMinMax && !!yAxisMax && yAxisMax !== '';
	const yAxis: Highcharts.YAxisOptions = {
		title: {
			text: hideYAxisTitle
				? undefined
				: `${yAxisTitle || valueColumn}${
						currencySymbol ? ` (${currencySymbol})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		startOnTick: !showYAxisMin,
		min: showYAxisMin ? Number(yAxisMin) : null,
		endOnTick: !showYAxisMax,
		max: showYAxisMax ? Number(yAxisMax) : null,
		gridLineWidth: hideGridLines ? 0 : 1,
		lineWidth: hideYAxisLine || hideYAxisLine === undefined ? 0 : 1,
	};

	const divOrSeq = palette?.config.divergent || palette?.config.sequential;
	const dataRows = pipe(
		map((row: any) => {
			return {
				name: row[labelColumn],
				x: labelIsDateBased ? row[labelColumn] : undefined,
				y: row[valueColumn],
				isDate: labelIsDateBased,
			};
		}),
		labelIsDateBased ? sortBy((row: any) => row.x) : identity
	)(data.rows);

	const series: Highcharts.SeriesOptionsType = {
		type: type as any,
		data: dataRows,
		dataLabels: {},
		color: divOrSeq
			? undefined // color is handled by colorAxis
			: `${getSingleColor(palette?.config.classification?.paletteColors)}99`,
	};

	series.dataLabels = {
		enabled: showDataValues ?? false,
		style: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : defaultFontColor,
		},
		align:
			labelPosition === 'left'
				? 'right'
				: labelPosition === 'right'
				? 'left'
				: 'center',
		verticalAlign:
			labelPosition === 'bottom'
				? 'top'
				: labelPosition === 'left' || labelPosition === 'right'
				? 'middle'
				: 'bottom',
		formatter: function formatter() {
			return simplifiedFormatNumber({
				value: this.y ?? 0,
				config,
			});
		},
	};

	const options = {
		...existingOptions,
		plotOptions: {
			areaspline: areaOptions,
			area: areaOptions,
		} as Highcharts.PlotOptions,
		xAxis,
		yAxis,
		series: [series],
	};
	return options;
};
