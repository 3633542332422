/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	formatDateUTC,
	getCurrencySymbol,
	invertedFontColor,
	simplifiedFormatNumber,
} from '../chartUtil';

export const dumbbellChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDumbbellDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
	} = props;
	const {
		labelColumn,
		highColumn,
		lowColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		xAxisOrentation,
		xAxisOrder,
		labelPosition,
		showDataValues,
		valueFormat,
		showXMinMax,
		xAxisMin,
		xAxisMax,
		hideYAxisLine,
		hideGridLines,
		lowColor,
		highColor,
		connectorColor,
	} = config;

	const type = 'dumbbell';

	const currencyCode = getCurrencyCode(valueFormat);
	const currencySymbol = getCurrencySymbol(currencyCode);
	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	const selectedLowColor = lowColor ?? '#597EF7';
	const selectedHighColor = highColor ?? '#FF4D4F';
	const selectedConnectorColor = connectorColor ?? '#595959';

	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const formatValue = (value) =>
		simplifiedFormatNumber({
			value: value ?? 0,
			config,
		});

	const tooltip: Highcharts.TooltipOptions = {
		shared: true,
		formatter() {
			return this.points?.[0]
				? `${this.points[0].point.name}: <strong>${formatValue(
						this.points[0].point.options.low
				  )} — ${formatValue(this.points[0].point.options.high)}</strong>`
				: `${this.point.x}: <strong>${formatValue(this.point.y)}</strong>`;
		},
	};

	const dataRows = data.rows.map((row) => {
		return {
			name: labelIsDateBased
				? `${formatDateUTC(row[labelColumn], intl)}`
				: row[labelColumn],
			low: row[lowColumn],
			high: row[highColumn],
		};
	});

	const xAxis: Highcharts.XAxisOptions = {
		title: {
			// Highchart treast x-axis for Dumbbell vertically, so we change it to y-axis in UI
			text: hideYAxisTitle ? undefined : yAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			formatter: labelIsDateBased
				? function formatter() {
						return `${formatDateUTC(this.value, intl)}`;
				  }
				: undefined,
		},
		lineWidth: hideYAxisLine ? 0 : 1,
		categories: data.rows.map((row) => row[labelColumn]),
	};

	if (labelIsDateBased) xAxis.type = 'datetime';

	const showXAxisMin = showXMinMax && !!xAxisMin && xAxisMin !== '';
	const showXAxisMax = showXMinMax && !!xAxisMax && xAxisMax !== '';
	const yAxis: Highcharts.YAxisOptions = {
		title: {
			// Highchart treast y-axis for Dumbbell horizontally, so we change it to x-axis in UI
			text: hideXAxisTitle
				? undefined
				: `${xAxisTitle || `${lowColumn} - ${highColumn}`}${
						currencySymbol ? ` (${currencySymbol})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		reversed: xAxisOrder === 'DESC',
		startOnTick: showXAxisMin ? false : undefined,
		min: showXAxisMin ? Number(xAxisMin) : undefined,
		endOnTick: !showXAxisMax,
		max: showXAxisMax ? Number(xAxisMax) : undefined,
		gridLineWidth: hideGridLines ? 0 : 1,
	};

	const series: Highcharts.SeriesDumbbellOptions = {
		type: type as any,
		data: dataRows,
		dataLabels: {},
		marker: {
			fillColor: selectedHighColor,
		},
		lowColor: selectedLowColor,
		connectorColor: selectedConnectorColor,
	};

	series.dataLabels = {
		enabled: showDataValues ?? false,
		style: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : defaultFontColor,
		},
		align:
			labelPosition === 'above' || labelPosition === 'below'
				? 'center'
				: undefined,
		verticalAlign:
			labelPosition === 'above'
				? 'top'
				: labelPosition === 'below'
				? 'bottom'
				: undefined,
		formatter: function formatter() {
			return formatValue(this.y ?? 0);
		},
	};

	const options = {
		...existingOptions,
		chart: {
			...existingOptions.chart,
			inverted: true,
		},
		xAxis,
		yAxis,
		tooltip,
		series: [series],
	};

	return options;
};
