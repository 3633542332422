/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { defineMessages } from 'react-intl';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	defaultFontColor,
	formatDateUTC,
	simplifiedFormatNumber,
	getCurrencySymbol,
	invertedFontColor,
} from '../chartUtil';

const messages = defineMessages({
	waterfallLegendTotal: {
		id: 'components.chart.chartOptions.waterfall.waterfallLegendTotal',
		defaultMessage: 'Total',
	},
	waterfallLegendPositive: {
		id: 'components.chart.chartOptions.waterfall.waterfallLegendPositive',
		defaultMessage: 'Positive',
	},
	waterfallLegendNegative: {
		id: 'components.chart.chartOptions.waterfall.waterfallLegendNegative',
		defaultMessage: 'Negative',
	},
});

interface PointOptionsObjectExtended extends Highcharts.PointOptionsObject {
	showInLegend?: boolean;
}

export const waterfallChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartWaterfallDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
	} = props;
	const {
		labelColumn,
		valueColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		legendPosition,
		showLegend,
		xAxisOrentation,
		xAxisOrder,
		labelPosition,
		showDataValues,
		valueFormat,
		hideYAxisLine,
		firstValueAsTotal,
		legendPositiveLabel,
		legendNegativeLabel,
		legendTotalLabel,
		hideConnectorLines,
		hideTotal,
		hideGridLines,
		positiveColor,
		negativeColor,
		totalColor,
	} = config;

	const type = 'waterfall';

	const currencyCode = getCurrencyCode(valueFormat);
	const currencySymbol = getCurrencySymbol(currencyCode);

	const POSITIVE_COL = intl.formatMessage(messages.waterfallLegendPositive);
	const NEGATIVE_COL = intl.formatMessage(messages.waterfallLegendNegative);
	const TOTAL_COL = intl.formatMessage(messages.waterfallLegendTotal);

	const selectedNegativeColor = negativeColor ?? '#FF4D4F';
	const selectedPositiveColor = positiveColor ?? '#597EF7';
	const selectedTotalColor = totalColor ?? '#595959';

	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const yValue = simplifiedFormatNumber({
				value: this.y,
				config,
			});
			const isDate =
				(
					this.series.chart.options.xAxis?.[0] ??
					this.series.chart.options.xAxis
				).type === 'datetime' || (this as any).point.isDate;

			return `${
				isDate ? formatDateUTC(this.point.name, intl) : this.point.name
			}: <strong>${yValue}</strong>`;
		},
	};

	const xAxis: Highcharts.XAxisOptions = {
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		type: 'category',
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: xAxisOrentation,
		},
		reversed: labelIsDateBased ? xAxisOrder === 'DESC' : false,
	} as Highcharts.XAxisOptions;

	const yAxis: Highcharts.YAxisOptions = {
		title: {
			text: hideYAxisTitle
				? undefined
				: `${yAxisTitle || valueColumn}${
						currencySymbol ? ` (${currencySymbol})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		lineWidth: hideYAxisLine || hideYAxisLine === undefined ? 0 : 1,
		gridLineWidth: hideGridLines ? 0 : 1,
	};

	const plotOptions: Highcharts.PlotOptions = {
		// since we are adding non-series items to the legend, disable click feature
		series: {
			events: {
				legendItemClick: () => {
					return false;
				},
			},
		},
		waterfall: {
			lineWidth: hideConnectorLines ? 0 : 1,
			borderWidth: 0,
		},
	};

	const seriesOptions = [] as Highcharts.SeriesWaterfallOptions[];

	const dataRows = data.rows.map((row, index) => {
		const category = row[valueColumn] >= 0 ? POSITIVE_COL : NEGATIVE_COL;
		const color =
			index === 0 && firstValueAsTotal
				? selectedTotalColor
				: category === POSITIVE_COL
				? selectedPositiveColor
				: selectedNegativeColor;

		return {
			name: labelIsDateBased
				? `${formatDateUTC(row[labelColumn], intl)}`
				: row[labelColumn],
			y: row[valueColumn],
			color,
			category,
		};
	}) as PointOptionsObjectExtended[];

	if (!hideTotal) {
		// add Total column
		dataRows.push({
			name: TOTAL_COL,
			isSum: true,
			color: selectedTotalColor,
			showInLegend: true,
		});
	}

	const series: Highcharts.SeriesWaterfallOptions = {
		type: type as any,
		data: dataRows,
		dataLabels: {},
		showInLegend: false,
	};

	seriesOptions.push(series);

	// set of dummy series for the legend
	const positiveSeries: Highcharts.SeriesWaterfallOptions = {
		type: type as any,
		dataLabels: {},
		showInLegend: true,
		name: legendPositiveLabel || POSITIVE_COL,
		visible: false,
		color: selectedPositiveColor,
		colorByPoint: false,
	};

	seriesOptions.push(positiveSeries);

	const negativeSeries: Highcharts.SeriesWaterfallOptions = {
		...positiveSeries,
		name: legendNegativeLabel || NEGATIVE_COL,
		color: selectedNegativeColor,
	};

	seriesOptions.push(negativeSeries);

	if (!hideTotal || firstValueAsTotal) {
		const totalSeries: Highcharts.SeriesWaterfallOptions = {
			...positiveSeries,
			name: legendTotalLabel || TOTAL_COL,
			color: selectedTotalColor,
		};

		seriesOptions.push(totalSeries);
	}

	series.dataLabels = {
		enabled: showDataValues ?? false,
		style: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : defaultFontColor,
		},
		inside: !!(labelPosition === 'below'),
		verticalAlign: labelPosition === 'below' ? 'top' : 'bottom',
		formatter: function formatter() {
			return simplifiedFormatNumber({
				value: this.y ?? 0,
				config,
			});
		},
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? false,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHiddenStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormat: '{name}',
		layout:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? 'vertical'
				: 'horizontal',
		align:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? legendPosition
				: 'center',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: legendPosition === 'left' || legendPosition === 'right'
				? 'middle'
				: 'bottom',
	};

	const options = {
		...existingOptions,
		colorAxis: undefined,
		xAxis,
		yAxis,
		plotOptions,
		tooltip,
		legend,
		series: seriesOptions,
	};
	return options;
};
