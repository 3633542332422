/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { identity, map, pipe, sortBy } from 'ramda';
import { generateColorRange } from '../../../utils/colors';
import { NumberFormatOption } from '../../../utils/dataGridStyling';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	defaultFontColor,
	formatDateUTC,
	invertedFontColor,
	simplifiedFormatNumber,
} from '../chartUtil';

export const donutChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDonutDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
		palette,
	} = props;
	const {
		labelColumn,
		valueColumn,
		legendPosition,
		showLegend,
		labelPosition,
		showDataValues,
		showDataLabels,
		showDataAbsValues,
		showDataPercentages,
		innerRadius,
	} = config;

	const type = 'pie';

	/*
	 * allow colorAxis to set colors
	 * in case of sequential or divergent palette
	 */
	const nothing = (_) => undefined;
	const getColor = existingOptions.colorAxis
		? nothing // handled by colorAxis
		: generateColorRange(
				data.rows.map((row) => row[labelColumn]),
				palette?.config.classification?.paletteColors
		  );
	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const yValue = simplifiedFormatNumber({
				value: Number(this.point.value),
				config,
				multiCol: 'AbsValue',
			});

			return `${
				labelIsDateBased
					? formatDateUTC(this.point.name, intl)
					: this.point.name
			}: <strong>${yValue}</strong>`;
		},
	};

	const plotOptions: Highcharts.PlotOptions = {
		pie: {
			allowPointSelect: true,
			cursor: 'pointer',
			showInLegend: showLegend,
			dataLabels: {
				enabled: showDataValues,
				connectorShape: 'crookedLine',
				style: {
					fontWeight: 'normal',
					color: invertFontColors ? invertedFontColor : defaultFontColor,
				},
				distance:
					labelPosition === 'insideend'
						? -25
						: labelPosition === 'outsideend'
						? 0
						: 30,
				formatter() {
					let format = '';
					const yValue = simplifiedFormatNumber({
						value: this.point.value ?? 0,
						config,
						multiCol: 'AbsValue',
					});
					if (showDataLabels !== false) {
						if (labelIsDateBased)
							format += `${formatDateUTC(this.x ?? this.point.x, intl)}`;
						else format += this.point.name;
						if (showDataAbsValues || showDataPercentages !== false)
							format += ': ';
					}
					if (showDataAbsValues) {
						format += `<b>${yValue}</b>`;
						if (showDataPercentages !== false) format += ', ';
					}
					if (showDataPercentages !== false)
						format += `<b>${simplifiedFormatNumber({
							value: (this.point.percentage ?? 0) / 100,
							config,
							multiCol: 'PctValue',
							usePrefixSuffix: false,
							defaultValueFormatType: NumberFormatOption.PERCENTAGE,
						})}</b>`;

					return format;
				},
			},
		},
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? false,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormatter: function formatter() {
			return labelIsDateBased
				? `${formatDateUTC((this as Highcharts.Point).x, intl)}`
				: this.name;
		},
		layout:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? 'vertical'
				: 'horizontal',
		align:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? legendPosition
				: 'center',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: legendPosition === 'left' || legendPosition === 'right'
				? 'middle'
				: 'bottom',
	};

	const dataRows = pipe(
		map((row: any) => ({
			name: row[labelColumn],
			x: labelIsDateBased ? row[labelColumn] : undefined,
			y: Math.abs(row[valueColumn]), // Pie charts use absolute values
			value: row[valueColumn],
			color: getColor(row[labelColumn]),
		})),
		labelIsDateBased ? sortBy((item: any) => item.x) : identity
	)(data.rows);

	const innerSize =
		innerRadius && !Number.isNaN(innerRadius) ? innerRadius : 75;

	const series: Highcharts.SeriesPieOptions = {
		type: type as any,
		innerSize: `${innerSize}%`,
		data: dataRows,
	};

	const options = {
		...existingOptions,
		plotOptions,
		tooltip,
		legend,
		series: [series],
	};
	return options;
};
