/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

export type VerticalAlignmentOptions = 'top' | 'center' | 'bottom';
export type HorizontalAlignmentOptions = 'left' | 'center' | 'right';
export type Alignment =
	| 'top-left'
	| 'top'
	| 'top-right'
	| 'center-left'
	| 'center'
	| 'center-right'
	| 'bottom-left'
	| 'bottom'
	| 'bottom-right';

export const serializeAlignment = ({
	v,
	h,
}: {
	v: VerticalAlignmentOptions;
	h: HorizontalAlignmentOptions;
}) => {
	const alignments: string[] = [v];
	if (h !== 'center') {
		alignments.push(h);
	}
	return alignments.join('-') as Alignment;
};

export const deserializeAlignment: (input: Alignment | undefined) => {
	v: VerticalAlignmentOptions;
	h: HorizontalAlignmentOptions;
} = (input) => {
	if (!input) {
		return {
			v: 'top',
			h: 'left',
		};
	}
	const sections = input.split('-');
	if (sections.length === 1) {
		return {
			v: sections[0] as VerticalAlignmentOptions,
			h: 'center',
		};
	}
	return {
		v: sections[0] as VerticalAlignmentOptions,
		h: sections[1] as HorizontalAlignmentOptions,
	};
};
