/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { isEmpty } from 'ramda';
import { generateColorRange } from '../../../utils/colors';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	formatDateUTC,
	simplifiedFormatNumber,
	getCurrencySymbol,
	invertedFontColor,
} from '../chartUtil';

export const multiScatterChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartMultiScatterDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
		palette,
	} = props;
	const {
		labelColumn,
		valueColumn,
		categoryColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		xAxisOrentation,
		xAxisOrder,
		yAxisOrder,
		labelPosition,
		showDataValues,
		valueFormat,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		hideYAxisLine,
		hideXAxisLine,
		hideGridLines,
		markerShape,
		showXMinMax,
		xAxisMin,
		xAxisMax,
		showLegend,
		legendPosition,
	} = config;
	const type = 'scatter';

	const currencyCode = getCurrencyCode(valueFormat);
	const currencySymbol = getCurrencySymbol(currencyCode);
	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	// Get all the categories
	const categories = data.schemaInfo.columns.slice(
		1,
		data.schemaInfo.columns.length
	);

	/*
	 * allow colorAxis to set colors
	 * in case of sequential or divergent palette
	 */
	const nothing = (_) => undefined;
	const getColor = existingOptions.colorAxis
		? nothing // handled by colorAxis
		: generateColorRange(
				categories,
				palette?.config.classification?.paletteColors
		  );

	const catIsDateBased = columnNameToType[categoryColumn].startsWith('date');
	const catIsNum = columnNameToType[categoryColumn].startsWith('numeric');

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const yValue = simplifiedFormatNumber({
				value: this.y,
				config,
				multiCol: 'Y',
			});

			const xValue = simplifiedFormatNumber({
				value: this.x,
				config,
				multiCol: 'X',
			});
			const catValue = catIsDateBased
				? formatDateUTC(this.series.name, intl)
				: catIsNum
				? Number(this.series.name)
				: this.series.name;
			return `${xValue}: <strong>${yValue}</strong><br>${config.categoryColumn}: <strong>${catValue}</strong>`;
		},
	};

	const showXAxisMin = showXMinMax && !!xAxisMin && xAxisMin !== '';
	const showXAxisMax = showXMinMax && !!xAxisMax && xAxisMax !== '';
	const xAxis: Highcharts.XAxisOptions = {
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		lineWidth: hideXAxisLine || hideXAxisLine === undefined ? 0 : 1,
		reversed: xAxisOrder === 'DESC',
		startOnTick: !showXAxisMin,
		min: showXAxisMin ? Number(xAxisMin) : null,
		endOnTick: !showXAxisMax,
		max: showXAxisMax ? Number(xAxisMax) : null,
	};

	const showYAxisMin = showYMinMax && !!yAxisMin && yAxisMin !== '';
	const showYAxisMax = showYMinMax && !!yAxisMax && yAxisMax !== '';
	const yAxis: Highcharts.YAxisOptions = {
		title: {
			text: hideYAxisTitle
				? undefined
				: `${yAxisTitle || valueColumn}${
						currencySymbol ? ` (${currencySymbol})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		startOnTick: !showYAxisMin,
		min: showYAxisMin ? Number(yAxisMin) : null,
		endOnTick: !showYAxisMax,
		max: showYAxisMax ? Number(yAxisMax) : null,
		gridLineWidth: hideGridLines ? 0 : 1,
		lineWidth: hideYAxisLine || hideYAxisLine === undefined ? 0 : 1,
		reversed: yAxisOrder === 'DESC',
	};

	const plotOptions: Highcharts.PlotOptions = {
		scatter: {
			marker: {
				symbol: markerShape ?? 'circle',
				radius: 5,
				states: {
					hover: {
						enabled: true,
						lineColor: 'rgb(100,100,100)',
					},
				},
			},
		},
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? true,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormatter: function formatter() {
			return catIsDateBased
				? `${formatDateUTC(this.name, intl)}`
				: catIsNum
				? `${Number(this.name)}`
				: this.name;
		},
		layout:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'horizontal'
				: 'vertical',
		align:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'center'
				: legendPosition ?? 'right',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: 'middle',
	};

	const dataLabels = {
		enabled: showDataValues ?? false,
		style: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : defaultFontColor,
		},
		align:
			labelPosition === 'left'
				? 'right'
				: labelPosition === 'right'
				? 'left'
				: 'center',
		verticalAlign:
			labelPosition === 'bottom'
				? 'top'
				: labelPosition === 'left' || labelPosition === 'right'
				? 'middle'
				: 'bottom',
		formatter: function formatter() {
			return simplifiedFormatNumber({
				value: this.y,
				config,
				multiCol: 'Y',
			});
		},
	};

	const series = categories.map((cat) => {
		const dataRows = data.rows.map((row) => {
			return {
				x: row[labelColumn],
				y: isEmpty(row[cat]) ? null : row[cat],
				color: getColor(cat),
			};
		});
		const categorySeries: Highcharts.SeriesOptionsType = {
			type: type as any,
			name: cat,
			data: dataRows,
			dataLabels,
			color: getColor(cat),
		};
		return categorySeries;
	});

	// if there's no data present, push a dummy series so the chart will still build the axes
	if (series.length < 1) {
		series.push({ type: type as any, data: [] });
	}

	const options = {
		...existingOptions,
		plotOptions,
		xAxis,
		yAxis,
		tooltip,
		legend,
		series,
	};
	return options;
};
