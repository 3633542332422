import { IFilter, IFilterParams } from '@ag-grid-community/core';
import {
	Button,
	Checkbox,
	Classes,
	ControlGroup,
	Divider,
	FormGroup,
	InputGroup,
	IPopoverProps,
	Menu,
	NumericInput,
	Radio,
	RadioGroup,
	Spinner,
	Text,
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { elevation2, Icon } from '@varicent/components';
import { DateInput } from 'icm-core/lib/components/datePicker';
import { Varicent } from 'icm-rest-client';
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import styled, { css } from 'react-emotion';
import { ChevronDown16, Search16, Reset16 } from '@carbon/icons-react';
import isEmpty from 'lodash.isempty';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { getLevelPeriodsForPayee } from 'icm-rest-client/lib/controllers/calendars';
import { omit } from 'ramda';
import { useAsyncThrottle } from '../componentHooks';
import { Filter, defaultMessages } from 'icm-core/lib/components/datatable';
import { useUpdateEffect } from 'react-use';

const StyledDivider = styled(Divider)`
	margin: 0 0 8px !important;
`;

const StyledFormGroup = styled(FormGroup)`
	margin-bottom: 9px;
`;

const FilterContainer = styled.div`
	display: flex;
	flex-direction: column;
	box-shadow: ${elevation2};
	background-color: white;
	padding: 8px;
`;

const TextFilterContainer = styled(FilterContainer)`
	width: 240px;
	height: 370px;
`;

const NumericFilterContainer = styled(FilterContainer)`
	width: 240px;
	height: 130px;
`;

const DateFilterContainer = styled(FilterContainer)`
	width: 280px;
	height: 125px;
`;

const PeriodFilterContainer = styled(FilterContainer)`
	width: 280px;
	height: 160px;
`;

const FilterRowList = styled.ul`
	max-height: 155px;
	overflow: scroll;

	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 20px;
	}

	&:-webkit-scrollbar-track {
		background: transparent;
		border-radius: 20px;
	}
`;

type TextFilterForm = {
	textFilter: string;
	textFilterOperator: {
		op: string;
		label: ReactIntl.FormattedMessage.MessageDescriptor;
	};
	valueQuery: string;
	valuesNotSelected: Record<string, boolean>;
};

type NumberFilterForm = {
	value1: string;
	value2: string;
	numberFilterOperator: {
		op: string;
		label: ReactIntl.FormattedMessage.MessageDescriptor;
	};
};

type DateFilterForm = {
	date1: Date | null;
	date2: Date | null;
	dateFilterOperator: {
		op: string;
		label: ReactIntl.FormattedMessage.MessageDescriptor;
	};
};

type FilterByPeriods = {
	filterByPeriodsOperator: {
		op: string;
		label: ReactIntl.FormattedMessage.MessageDescriptor;
	};
	period1: {
		periodName: string;
		start: Date | null;
		end: Date | null;
	};
	period2: {
		periodName: string;
		start: Date | null;
		end: Date | null;
	};
};

type PeriodsFilterForm = {
	filterBy: string;
	dateFilter: DateFilterForm;
	periodsFilter: FilterByPeriods;
};

const textOps = [
	{ op: 'contains', label: defaultMessages.contains },
	{ op: 'notContains', label: defaultMessages.notContains },
	{ op: 'equals', label: defaultMessages.equals },
	{ op: 'notEqual', label: defaultMessages.notEqual },
	{ op: 'startsWith', label: defaultMessages.startsWith },
	{ op: 'endsWith', label: defaultMessages.endsWith },
];

const numberOps = [
	{ op: 'equals', label: defaultMessages.equals },
	{ op: 'notEqual', label: defaultMessages.notEqual },
	{ op: 'lessThan', label: defaultMessages.lessThan },
	{ op: 'lessThanOrEqual', label: defaultMessages.lessThanOrEqual },
	{ op: 'greaterThan', label: defaultMessages.greaterThan },
	{ op: 'greaterThanOrEqual', label: defaultMessages.greaterThanOrEqual },
	{ op: 'inRange', label: defaultMessages.inRange },
];

const dateOps = [
	{ op: 'equals', label: defaultMessages.equals },
	{ op: 'greaterThan', label: defaultMessages.filterAfter },
	{ op: 'lessThan', label: defaultMessages.filterBefore },
	{ op: 'notEqual', label: defaultMessages.notEqual },
	{ op: 'inRange', label: defaultMessages.inRange },
];

const initTextFilterForm = (): TextFilterForm => ({
	textFilter: '',
	textFilterOperator: textOps[0],
	valueQuery: '',
	valuesNotSelected: {},
});

const initNumberFilterForm = (): NumberFilterForm => ({
	value1: '',
	value2: '',
	numberFilterOperator: numberOps[0],
});

const initDateFilterForm = (): DateFilterForm => ({
	date1: null,
	date2: null,
	dateFilterOperator: dateOps[0],
});

const initPeriodsFilterForm = (): PeriodsFilterForm => ({
	filterBy: 'dateFilter',
	dateFilter: initDateFilterForm(),
	periodsFilter: {
		filterByPeriodsOperator: dateOps[0],
		period1: {
			periodName: '',
			start: null,
			end: null,
		},
		period2: {
			periodName: '',
			start: null,
			end: null,
		},
	},
});

const buildTextFilterModel = (
	filterForm: TextFilterForm,
	includeSuggestedValue = true
): Filter | undefined => {
	const notSelectedValues = Object.keys(filterForm.valuesNotSelected).filter(
		(val) => !filterForm.valuesNotSelected[val]
	);
	if (isEmpty(filterForm.textFilter) && isEmpty(notSelectedValues)) {
		return undefined;
	}

	let filterModel: Filter = {
		type: filterForm.textFilterOperator.op,
		filterType: 'text',
	};

	if (!isEmpty(filterForm.textFilter)) {
		filterModel = {
			...filterModel,
			filter: filterForm.textFilter,
		};
	}
	if (!isEmpty(notSelectedValues) && includeSuggestedValue) {
		filterModel = {
			...filterModel,
			alternateFilter: notSelectedValues,
		};
	}
	return filterModel;
};

const buildNumberFilterModel = (
	filterForm: NumberFilterForm
): Filter | undefined => {
	let filterModel: Filter;

	if (filterForm.numberFilterOperator.op === 'inRange') {
		if (isEmpty(filterForm.value1) || isEmpty(filterForm.value2))
			return undefined;
		filterModel = {
			type: filterForm.numberFilterOperator.op,
			filterType: 'number',
			filter: [filterForm.value1, filterForm.value2],
		};
	} else {
		if (isEmpty(filterForm.value1)) return undefined;
		filterModel = {
			type: filterForm.numberFilterOperator.op,
			filterType: 'number',
			filter: filterForm.value1,
		};
	}
	return filterModel;
};

const buildDateFilterModel = (
	filterForm: DateFilterForm
): Filter | undefined => {
	let filterModel: Filter;

	if (filterForm.dateFilterOperator.op === 'inRange') {
		if (!filterForm.date1 || !filterForm.date2) return undefined;
		filterModel = {
			type: filterForm.dateFilterOperator.op,
			filterType: 'date',
			filter: [filterForm.date1, filterForm.date2],
		};
	} else {
		if (!filterForm.date1) return undefined;
		filterModel = {
			type: filterForm.dateFilterOperator.op,
			filterType: 'date',
			filter: filterForm.date1,
		};
	}
	return filterModel;
};

const buildPeriodsFilterModel = (
	filterForm: PeriodsFilterForm
): Filter | undefined => {
	let filterModel: Filter | undefined;

	if (filterForm.filterBy === 'dateFilter') {
		filterModel = buildDateFilterModel(filterForm.dateFilter);
	} else {
		if (
			isEmpty(filterForm.periodsFilter.period1.periodName) &&
			isEmpty(filterForm.periodsFilter.period2.periodName)
		)
			return undefined;
		let type;
		let filter;
		let alternateFilter;
		switch (filterForm.periodsFilter.filterByPeriodsOperator.op) {
			case 'equals':
				type = 'inRange';
				filter = [
					filterForm.periodsFilter.period1.start,
					filterForm.periodsFilter.period1.end,
				];
				break;
			case 'greaterThan':
				type = 'greaterThan';
				filter = filterForm.periodsFilter.period1.end;
				break;
			case 'lessThan':
				type = 'lessThan';
				filter = filterForm.periodsFilter.period1.start;
				break;
			case 'notEqual':
				type = 'notEqual';
				filter = filterForm.periodsFilter.period1.start;
				alternateFilter = filterForm.periodsFilter.period1.end;
				break;
			case 'inRange':
				if (
					isEmpty(filterForm.periodsFilter.period1.periodName) ||
					isEmpty(filterForm.periodsFilter.period2.periodName)
				)
					return undefined;
				type = 'inRange';
				filter = [
					filterForm.periodsFilter.period1.start,
					filterForm.periodsFilter.period2.end,
				];
				break;
			default:
				throw new Error('Invalid operator');
		}
		filterModel = {
			type,
			filterType: 'period',
			filter,
			alternateFilter,
		};
	}

	return filterModel;
};

const FilterTitle: React.FC<{
	columnName?: string;
	clearFnc: () => void;
}> = ({ columnName, clearFnc }) => {
	const intl = useIntl();

	return (
		<div>
			<span
				style={{ display: 'inline-block', marginTop: '6px' }}
			>{`${intl.formatMessage(
				defaultMessages.filterTitle
			)} ${columnName}`}</span>
			<Button
				className={css`
					float: right;
				`}
				minimal
				icon={
					<Icon>
						<Reset16 />
					</Icon>
				}
				onClick={clearFnc}
			/>
		</div>
	);
};

const selectPopoverProps: Partial<IPopoverProps> = {
	fill: true,
	minimal: true,
	modifiers: {
		minWidth: {
			enabled: true,
			fn: (data) => {
				return {
					...data,
					styles: {
						...data.styles,
						width: `${data.offsets.reference.width}px`,
					},
				};
			},
			order: 800,
		},
	},
	popoverClassName: `ag-custom-component-popup ${css`
		min-width: 100%;
	`}`,
	wrapperTagName: 'div',
	position: 'bottom',
	boundary: 'viewport',
};

export const AGDataGridTextFilter = forwardRef<
	IFilter,
	IFilterParams & {
		getFilterSuggestions?: (
			filter: Record<string, Filter> | undefined,
			suggestFilter: string
		) => string[];
	}
>((props, ref) => {
	const intl = useIntl();

	const { filterChangedCallback, getFilterSuggestions } = props;
	const [values, setValues] = useState(initTextFilterForm);

	useImperativeHandle(ref, () => ({
		getModel() {
			return buildTextFilterModel(values);
		},
		setModel() {},
		doesFilterPass() {
			return false;
		},
		isFilterActive() {
			return values.textFilter.length > 0 || !isEmpty(values.valuesNotSelected);
		},
	}));
	useUpdateEffect(filterChangedCallback, [
		values.textFilter,
		values.textFilterOperator,
		values.valuesNotSelected,
		filterChangedCallback,
	]);

	const suggestions = useAsyncThrottle<string[]>(
		() => {
			const filterModel = buildTextFilterModel(values, false);

			return getFilterSuggestions
				? getFilterSuggestions(
						filterModel
							? {
									[`${props.column.getColDef().field}`]: filterModel,
							  }
							: undefined,
						values.valueQuery
				  )
				: [];
		},
		500,
		[values.textFilter, values.textFilterOperator, values.valueQuery]
	);
	const suggestedValues = suggestions.value ?? [];

	return (
		<TextFilterContainer>
			<FilterTitle
				columnName={props.column.getColDef().field}
				clearFnc={() => {
					setValues(initTextFilterForm);
				}}
			/>
			<StyledDivider />
			<StyledFormGroup label={intl.formatMessage(defaultMessages.textFilter)}>
				<Select
					items={textOps}
					itemRenderer={(item, { handleClick }) => {
						return (
							<Menu.Item
								text={intl.formatMessage(item.label)}
								key={intl.formatMessage(item.label)}
								onClick={handleClick}
							/>
						);
					}}
					onItemSelect={(item) => {
						setValues({
							...values,
							textFilterOperator: item,
						});
					}}
					popoverProps={selectPopoverProps}
					filterable={false}
				>
					<Button
						className="select bp3-text-overflow-ellipsis"
						text={intl.formatMessage(values.textFilterOperator.label)}
						rightIcon={
							<Icon>
								<ChevronDown16 />
							</Icon>
						}
						fill
					/>
				</Select>
				<InputGroup
					fill
					placeholder={intl.formatMessage(
						defaultMessages.filterTextPlaceholder
					)}
					className={css`
						margin-top: 8px;
					`}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setValues({
							...values,
							textFilter: e.target.value,
						});
					}}
					value={values.textFilter}
				/>
			</StyledFormGroup>
			<StyledDivider />
			<StyledFormGroup
				label={intl.formatMessage(defaultMessages.filterValueLabel)}
				className={css`
					margin-bottom: 0;
				`}
			>
				<InputGroup
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setValues({
							...values,
							valueQuery: e.target.value,
						});
					}}
					placeholder={intl.formatMessage(
						defaultMessages.filterSearchSuggestedValues
					)}
					leftIcon={
						<Icon>
							<Search16 />
						</Icon>
					}
					value={values.valueQuery}
				/>
				{suggestions.loading && <Spinner size={40} intent="primary" />}
				{suggestedValues.length > 0 && !suggestions.loading && (
					<FilterRowList>
						{suggestedValues.map((option) => {
							if (option.length === 0) return undefined;
							return (
								<Checkbox
									checked={values.valuesNotSelected[option] ?? true}
									className={`${Classes.MENU_ITEM} ${css`
										padding: 0;
										margin-top: 8px;
									`}`}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
										if (e.target.checked) {
											setValues({
												...values,
												valuesNotSelected: omit(
													[option],
													values.valuesNotSelected
												),
											});
										} else {
											setValues({
												...values,
												valuesNotSelected: {
													...values.valuesNotSelected,
													[option]: e.target.checked,
												},
											});
										}
									}}
								>
									<Text>{option}</Text>
								</Checkbox>
							);
						})}
					</FilterRowList>
				)}
			</StyledFormGroup>
		</TextFilterContainer>
	);
});

export const AGDataGridNumericFilter = forwardRef<
	IFilter,
	IFilterParams & {
		column: Varicent.RESTAPI.v1.DTOs.Schema.ColumnSchemaDTO;
	}
>((props, ref) => {
	const intl = useIntl();

	const { filterChangedCallback } = props;
	const [values, setValues] = useState(initNumberFilterForm);

	useImperativeHandle(ref, () => ({
		getModel() {
			return buildNumberFilterModel(values);
		},
		setModel() {},
		doesFilterPass() {
			return false;
		},
		isFilterActive() {
			return values.value1.length > 0 || values.value2.length > 0;
		},
	}));
	useUpdateEffect(filterChangedCallback, [
		values.value1,
		values.value2,
		values.numberFilterOperator,
		filterChangedCallback,
	]);

	return (
		<NumericFilterContainer>
			<FilterTitle
				columnName={props.column.getColDef().field}
				clearFnc={() => {
					setValues(initNumberFilterForm);
				}}
			/>
			<StyledDivider />
			<Select
				items={numberOps}
				itemRenderer={(item, { handleClick }) => {
					return (
						<Menu.Item
							text={intl.formatMessage(item.label)}
							key={intl.formatMessage(item.label)}
							onClick={handleClick}
						/>
					);
				}}
				onItemSelect={(item) => {
					setValues({
						...values,
						numberFilterOperator: item,
						value2: '',
					});
				}}
				popoverProps={selectPopoverProps}
				filterable={false}
			>
				<Button
					className="select bp3-text-overflow-ellipsis"
					text={intl.formatMessage(values.numberFilterOperator.label)}
					rightIcon={
						<Icon>
							<ChevronDown16 />
						</Icon>
					}
					fill
				/>
			</Select>
			{values.numberFilterOperator.op !== 'inRange' ? (
				<NumericInput
					fill
					placeholder={intl.formatMessage(
						defaultMessages.filterNumericValuePlaceholder
					)}
					className={css`
						margin-top: 8px;
					`}
					onValueChange={(_, valueAsString) => {
						setValues({
							...values,
							value1: valueAsString,
						});
					}}
					value={values.value1}
					buttonPosition="none"
				/>
			) : (
				<ControlGroup>
					<NumericInput
						fill
						placeholder={intl.formatMessage(defaultMessages.filterFromValue)}
						className={css`
							margin-top: 8px;
						`}
						onValueChange={(_, valueAsString) => {
							setValues({
								...values,
								value1: valueAsString,
							});
						}}
						value={values.value1}
						buttonPosition="none"
					/>
					<NumericInput
						fill
						placeholder={intl.formatMessage(defaultMessages.filterToValue)}
						className={css`
							margin-top: 8px;
						`}
						onValueChange={(_, valueAsString) => {
							setValues({
								...values,
								value2: valueAsString,
							});
						}}
						value={values.value2}
						buttonPosition="none"
					/>
				</ControlGroup>
			)}
		</NumericFilterContainer>
	);
});

export const AGDataGridDateFilter = forwardRef<
	IFilter,
	IFilterParams & {
		column: Varicent.RESTAPI.v1.DTOs.Schema.ColumnSchemaDTO;
	}
>((props, ref) => {
	const intl = useIntl();

	const { filterChangedCallback } = props;
	const [values, setValues] = useState(initDateFilterForm);

	useImperativeHandle(ref, () => ({
		getModel() {
			return buildDateFilterModel(values);
		},
		setModel() {},
		doesFilterPass() {
			return false;
		},
		isFilterActive() {
			return !!values.date1 || !!values.date2;
		},
	}));
	useUpdateEffect(filterChangedCallback, [
		values.date1,
		values.date2,
		values.dateFilterOperator,
		filterChangedCallback,
	]);

	return (
		<DateFilterContainer>
			<FilterTitle
				columnName={props.column.getColDef().field}
				clearFnc={() => {
					setValues(initDateFilterForm);
				}}
			/>
			<StyledDivider />
			<Select
				items={dateOps}
				itemRenderer={(item, { handleClick }) => {
					return (
						<Menu.Item
							text={intl.formatMessage(item.label)}
							key={intl.formatMessage(item.label)}
							onClick={handleClick}
						/>
					);
				}}
				onItemSelect={(item) => {
					setValues({
						...values,
						dateFilterOperator: item,
					});
				}}
				popoverProps={selectPopoverProps}
				filterable={false}
			>
				<Button
					className="select bp3-text-overflow-ellipsis"
					text={intl.formatMessage(values.dateFilterOperator.label)}
					rightIcon={
						<Icon>
							<ChevronDown16 />
						</Icon>
					}
					fill
				/>
			</Select>
			{values.dateFilterOperator.op !== 'inRange' ? (
				<DateInput
					className={css`
						margin-top: 8px;
					`}
					onChange={(newDate: Date) => {
						setValues({
							...values,
							date1: newDate,
							date2: null,
						});
					}}
					value={values.date1}
					popoverProps={{
						popoverClassName: 'ag-custom-component-popup',
					}}
				/>
			) : (
				<ControlGroup>
					<DateInput
						className={css`
							margin-top: 8px;
						`}
						onChange={(newDate: Date) => {
							setValues({
								...values,
								date1: newDate,
							});
						}}
						value={values.date1}
						popoverProps={{
							popoverClassName: 'ag-custom-component-popup',
						}}
					/>
					<DateInput
						className={css`
							margin-top: 8px;
						`}
						onChange={(newDate: Date) => {
							setValues({
								...values,
								date2: newDate,
							});
						}}
						value={values.date2}
						popoverProps={{
							popoverClassName: 'ag-custom-component-popup',
						}}
					/>
				</ControlGroup>
			)}
		</DateFilterContainer>
	);
});

export const AGDataGridPeriodsFilter = forwardRef<
	IFilter,
	IFilterParams & {
		time?: Varicent.RESTAPI.v1.DTOs.TableTimeDTO;
	}
>((props, ref) => {
	const intl = useIntl();

	const { filterChangedCallback, time } = props;

	const [values, setValues] = useState(initPeriodsFilterForm);
	const [periods, setPeriods] = useState<
		Varicent.RESTAPI.v1.DTOs.Time.PeriodDTO[]
	>([]);

	useImperativeHandle(ref, () => ({
		getModel() {
			return buildPeriodsFilterModel(values);
		},
		setModel() {},
		doesFilterPass() {
			return false;
		},
		isFilterActive() {
			return (
				!!values.dateFilter.date1 ||
				!!values.dateFilter.date2 ||
				!isEmpty(values.periodsFilter.period1.periodName) ||
				!isEmpty(values.periodsFilter.period2.periodName)
			);
		},
	}));
	useUpdateEffect(filterChangedCallback, [
		values.dateFilter,
		values.periodsFilter,
		filterChangedCallback,
	]);

	useEffect(() => {
		if (time) {
			getLevelPeriodsForPayee(time?.timeId, 'Periods').then((res) => {
				setPeriods(res);
			});
		}
	}, []);

	return (
		<PeriodFilterContainer>
			<FilterTitle
				columnName={props.column.getColDef().field}
				clearFnc={() => {
					setValues(initPeriodsFilterForm);
				}}
			/>
			<StyledDivider />
			<RadioGroup
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					setValues({
						...initPeriodsFilterForm(),
						filterBy: e.currentTarget.value,
					});
				}}
				selectedValue={values.filterBy}
				inline
			>
				<Radio
					label={intl.formatMessage(defaultMessages.filterByDate)}
					value="dateFilter"
				/>
				<Radio
					label={intl.formatMessage(defaultMessages.filterByPeriod)}
					value="periodsFilter"
				/>
			</RadioGroup>
			{values.filterBy === 'dateFilter' ? (
				<>
					<Select
						items={dateOps}
						itemRenderer={(item, { handleClick }) => {
							return (
								<Menu.Item
									text={intl.formatMessage(item.label)}
									key={intl.formatMessage(item.label)}
									onClick={handleClick}
								/>
							);
						}}
						onItemSelect={(item) => {
							setValues({
								...values,
								dateFilter: {
									...values.dateFilter,
									dateFilterOperator: item,
								},
							});
						}}
						popoverProps={selectPopoverProps}
						filterable={false}
					>
						<Button
							className="select bp3-text-overflow-ellipsis"
							text={intl.formatMessage(
								values.dateFilter.dateFilterOperator.label
							)}
							rightIcon={
								<Icon>
									<ChevronDown16 />
								</Icon>
							}
							fill
						/>
					</Select>
					{values.dateFilter.dateFilterOperator.op !== 'inRange' ? (
						<DateInput
							className={css`
								margin-top: 8px;
							`}
							onChange={(newDate: Date) => {
								setValues({
									...values,
									dateFilter: {
										...values.dateFilter,
										date1: newDate,
										date2: null,
									},
								});
							}}
							value={values.dateFilter.date1}
							popoverProps={{
								popoverClassName: 'ag-custom-component-popup',
							}}
						/>
					) : (
						<ControlGroup>
							<DateInput
								className={css`
									margin-top: 8px;
								`}
								onChange={(newDate: Date) => {
									setValues({
										...values,
										dateFilter: {
											...values.dateFilter,
											date1: newDate,
										},
									});
								}}
								value={values.dateFilter.date1}
								popoverProps={{
									popoverClassName: 'ag-custom-component-popup',
								}}
							/>
							<DateInput
								className={css`
									margin-top: 8px;
								`}
								onChange={(newDate: Date) => {
									setValues({
										...values,
										dateFilter: {
											...values.dateFilter,
											date2: newDate,
										},
									});
								}}
								value={values.dateFilter.date2}
								popoverProps={{
									popoverClassName: 'ag-custom-component-popup',
								}}
							/>
						</ControlGroup>
					)}
				</>
			) : (
				<>
					<Select
						items={dateOps}
						itemRenderer={(item, { handleClick }) => {
							return (
								<Menu.Item
									text={intl.formatMessage(item.label)}
									key={intl.formatMessage(item.label)}
									onClick={handleClick}
								/>
							);
						}}
						onItemSelect={(item) => {
							setValues({
								...values,
								periodsFilter: {
									...values.periodsFilter,
									filterByPeriodsOperator: item,
								},
							});
						}}
						popoverProps={selectPopoverProps}
						filterable={false}
					>
						<Button
							className="select bp3-text-overflow-ellipsis"
							text={intl.formatMessage(
								values.periodsFilter.filterByPeriodsOperator.label
							)}
							rightIcon={
								<Icon>
									<ChevronDown16 />
								</Icon>
							}
							fill
						/>
					</Select>
					{values.periodsFilter.filterByPeriodsOperator.op !== 'inRange' ? (
						<Select
							className={css`
								margin-top: 8px;
							`}
							items={periods}
							itemRenderer={(item, { handleClick }) => {
								return (
									<Menu.Item
										text={item.name}
										key={item.name}
										onClick={handleClick}
									/>
								);
							}}
							onItemSelect={(item) => {
								setValues({
									...values,
									periodsFilter: {
										...values.periodsFilter,
										period1: {
											periodName: item.name,
											start: item.start,
											end: item.end,
										},
									},
								});
							}}
							popoverProps={selectPopoverProps}
							filterable={false}
						>
							<Button
								className="select bp3-text-overflow-ellipsis"
								text={values.periodsFilter.period1.periodName}
								rightIcon={
									<Icon>
										<ChevronDown16 />
									</Icon>
								}
								fill
							/>
						</Select>
					) : (
						<ControlGroup>
							<Select
								className={css`
									margin-top: 8px;
								`}
								items={periods}
								itemRenderer={(item, { handleClick }) => {
									return (
										<Menu.Item
											text={item.name}
											key={item.name}
											onClick={handleClick}
										/>
									);
								}}
								onItemSelect={(item) => {
									setValues({
										...values,
										periodsFilter: {
											...values.periodsFilter,
											period1: {
												periodName: item.name,
												start: item.start,
												end: item.end,
											},
										},
									});
								}}
								popoverProps={selectPopoverProps}
								filterable={false}
							>
								<Button
									className={`select bp3-text-overflow-ellipsis ${css`
										width: 132px !important;
									`}`}
									text={values.periodsFilter.period1.periodName}
									rightIcon={
										<Icon>
											<ChevronDown16 />
										</Icon>
									}
									fill
								/>
							</Select>
							<Select
								className={css`
									margin-top: 8px;
								`}
								items={periods}
								itemRenderer={(item, { handleClick }) => {
									return (
										<Menu.Item
											text={item.name}
											key={item.name}
											onClick={handleClick}
										/>
									);
								}}
								onItemSelect={(item) => {
									setValues({
										...values,
										periodsFilter: {
											...values.periodsFilter,
											period2: {
												periodName: item.name,
												start: item.start,
												end: item.end,
											},
										},
									});
								}}
								popoverProps={selectPopoverProps}
								filterable={false}
							>
								<Button
									className={`select bp3-text-overflow-ellipsis ${css`
										width: 132px !important;
									`}`}
									text={values.periodsFilter.period2.periodName}
									rightIcon={
										<Icon>
											<ChevronDown16 />
										</Icon>
									}
									fill
								/>
							</Select>
						</ControlGroup>
					)}
				</>
			)}
		</PeriodFilterContainer>
	);
});
