/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/* eslint-disable camelcase */
import {
	TextScale20,
	Image20,
	DataTable20,
	ChartBar20,
	WatsonHealthLaunchStudy_120,
	WatsonHealthTextAnnotationToggle20,
} from '@carbon/icons-react';
import { Varicent } from 'icm-rest-client';

function assertUnreachable(_x: never): never {
	throw new Error('Unknown component type');
}

export enum FlexComponentTypes {
	rowInputForm = 'rowInputForm',
	image = 'image',
	text = 'text',
	dataGrid = 'dataGrid',
	chart = 'chart',
	pickList = 'pickList',
}

export enum FlexComponentTypesLabelMessageId {
	rowInputForm = 'PRESENTER_FLEXIBLE_ROW_INPUT',
	image = 'PRESENTER_FLEXIBLE_IMAGE',
	text = 'PRESENTER_FLEXIBLE_TEXT',
	dataGrid = 'PRESENTER_FLEXIBLE_DATATABLE',
	chart = 'PRESENTER_FLEXIBLE_CHART',
	pickList = 'PRESENTER_FLEXIBLE_PICKLIST',
}

export const getComponentTypeLabelId = (
	component: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDTO
) => {
	if (component.config.image) {
		return FlexComponentTypesLabelMessageId.image;
	}
	if (component.config.text) {
		return FlexComponentTypesLabelMessageId.text;
	}
	if (component.config.dataGrid) {
		return FlexComponentTypesLabelMessageId.dataGrid;
	}
	if (component.config.chart) {
		return FlexComponentTypesLabelMessageId.chart;
	}
	if (component.config.pickList) {
		return FlexComponentTypesLabelMessageId.pickList;
	}
	if (component.config.rowInputForm) {
		return FlexComponentTypesLabelMessageId.rowInputForm;
	}
	throw new Error(
		`Unknown component type with id: ${component.componentId} and config: ${component.config}`
	);
};

export const getComponentType = (
	component: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDTO
) => {
	if (component.config.image) {
		return FlexComponentTypes.image;
	}
	if (component.config.text) {
		return FlexComponentTypes.text;
	}
	if (component.config.dataGrid) {
		return FlexComponentTypes.dataGrid;
	}
	if (component.config.chart) {
		return FlexComponentTypes.chart;
	}
	if (component.config.pickList) {
		return FlexComponentTypes.pickList;
	}
	if (component.config.rowInputForm) {
		return FlexComponentTypes.rowInputForm;
	}
	throw new Error(
		`Unknown component type with id: ${component.componentId} and config: ${component.config}`
	);
};

export const getComponentIcon = (type: FlexComponentTypes) => {
	switch (type) {
		case FlexComponentTypes.image: {
			return Image20;
		}
		case FlexComponentTypes.text: {
			return TextScale20;
		}
		case FlexComponentTypes.dataGrid: {
			return DataTable20;
		}
		case FlexComponentTypes.chart: {
			return ChartBar20;
		}
		case FlexComponentTypes.pickList: {
			return WatsonHealthLaunchStudy_120;
		}
		case FlexComponentTypes.rowInputForm:
			return WatsonHealthTextAnnotationToggle20;
		default:
			/*
			 * this should never happen since getComponentType only returns the above options.
			 * if a new option is introduced, this will cause an error here.
			 */
			return assertUnreachable(type);
	}
};
