/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { identity, map, pipe, sortBy } from 'ramda';
import { getSingleColor } from '../../../utils/colors';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	getCurrencySymbol,
	invertedFontColor,
	simplifiedFormatNumber,
	getFormattedLabels,
} from '../chartUtil';

type HorBarOverload = ChartOptionArg & {
	columnNameToType: ColumnNameToTypeMap;
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartHorizontalBarDTO;
	barColType: 'bar';
};
type ColOverload = ChartOptionArg & {
	columnNameToType: ColumnNameToTypeMap;
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartBarDTO;
	barColType: 'column';
};

export const barColChartOptions = (props: HorBarOverload | ColOverload) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		invertFontColors,
		barColType,
		palette,
	} = props;
	const {
		labelColumn,
		valueColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		xAxisOrentation,
		xAxisOrder,
		showDataValues,
		valueFormat,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		hideYAxisLine,
		hideGridLines,
	} = config;

	const type = barColType;

	const currencyCode = getCurrencyCode(valueFormat);
	const currencySymbol = getCurrencySymbol(currencyCode);

	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	const color = existingOptions.colorAxis
		? undefined // handled by colorAxis
		: getSingleColor(palette?.config.classification?.paletteColors);

	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');

	const xAxis: Highcharts.XAxisOptions = {
		type: labelIsDateBased ? 'datetime' : undefined,
		categories: !labelIsDateBased
			? getFormattedLabels(data.rows, labelColumn)
			: undefined,
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		reversed: labelIsDateBased ? xAxisOrder === 'DESC' : false,
	};

	const showYAxisMin = showYMinMax && !!yAxisMin && yAxisMin !== '';
	const showYAxisMax = showYMinMax && !!yAxisMax && yAxisMax !== '';
	const yAxis: Highcharts.YAxisOptions = {
		title: {
			text: hideYAxisTitle
				? undefined
				: `${yAxisTitle || valueColumn}${
						currencySymbol ? ` (${currencySymbol})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		startOnTick: !showYAxisMin,
		min: showYAxisMin ? Number(yAxisMin) : null,
		endOnTick: !showYAxisMax,
		max: showYAxisMax ? Number(yAxisMax) : null,
		gridLineWidth: hideGridLines ? 0 : 1,
		lineWidth: hideYAxisLine || hideYAxisLine === undefined ? 0 : 1,
	};

	const dataRows = pipe(
		map((row: any) => {
			return {
				name: row[labelColumn],
				x: labelIsDateBased ? row[labelColumn] : undefined,
				y: row[valueColumn] ? row[valueColumn] : undefined,
				isDate: labelIsDateBased,
				color,
			};
		}),
		labelIsDateBased ? sortBy((row: any) => row.x) : identity
	)(data.rows);

	const series: Highcharts.SeriesColumnOptions = {
		type: type as any,
		colorByPoint: true,
		data: dataRows,
		dataLabels: {},
	};

	series.dataLabels = {
		enabled: showDataValues ?? false,
		style: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : defaultFontColor,
		},
		formatter: function formatter() {
			return simplifiedFormatNumber({
				value: this.y ?? 0,
				config,
			});
		},
	};

	if (props.barColType === 'bar') {
		const { labelPosition } = props.config;
		series.dataLabels = {
			...series.dataLabels,
			inside: !!(labelPosition === 'insideend'),
			align: labelPosition === 'insideend' ? 'right' : 'left',
			verticalAlign: 'middle',
		};
	} else {
		const { labelPosition } = props.config;
		series.dataLabels = {
			...series.dataLabels,
			inside: !!(labelPosition === 'below'),
			align: 'center',
			verticalAlign: labelPosition === 'below' ? 'top' : 'bottom',
		};
	}

	const options = {
		...existingOptions,
		chart: {
			...existingOptions.chart,
			type,
		} as Highcharts.ChartOptions,
		xAxis,
		yAxis,
		series: [series],
	};
	return options;
};
