/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useMemo, useContext } from 'react';
import { Varicent } from 'icm-rest-client';
import convertLayoutToGrid, { Layout, Grid } from './utils/convertLayoutToGrid';
import { mapObjIndexed, indexBy, all } from 'ramda';
import styled, { css, cx } from 'react-emotion';
import { breakpoints, cols, Breakpoint, ComponentElement } from './index';
import {
	ComponentDataLoadedContext,
	ComponentVisibility,
	PublishMode,
} from './context';
import createBreakpoint from 'react-use/esm/factory/createBreakpoint';

const typedCreateBreakpoint: <A extends object>(brs: A) => () => keyof A =
	createBreakpoint as any;

const useBreakpoint = typedCreateBreakpoint(breakpoints);

type Layouts = {
	[K in keyof typeof breakpoints]: Layout[];
};

type GridLayouts = {
	[K in keyof typeof breakpoints]: Grid[];
};

const getExpandForPublish = (
	isPublishMode: Boolean,
	component: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDTO
) => {
	return isPublishMode && component.config && 'dataGrid' in component.config;
};

const reportLayoutsConverter = (
	layouts: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLayoutDTO[]
) => {
	const results = layouts.reduce((acc, l) => {
		const currentBr = acc[l.layoutType];
		const newLayout: Layout = {
			x: l.x,
			y: l.y,
			i: l.componentId.toString(),
			h: l.height,
			w: l.width,
		};
		if (currentBr) {
			currentBr.push(newLayout);
		} else {
			acc[l.layoutType] = [newLayout];
		}
		return acc;
	}, {} as Layouts);
	return mapObjIndexed((ls, key) => {
		try {
			return convertLayoutToGrid({ layouts: ls, totalWidth: cols[key] });
		} catch (e) {
			return [];
		}
	}, results) as GridLayouts;
};

const GridEl = styled.div<{
	w?: number;
	h?: number;
	flexDir?: 'column' | 'row';
	hasContent?: boolean;
	totalCols: number;
	expandForPublish: boolean;
	hidden?: boolean;
}>`
	display: flex;
	flex-direction: ${({ flexDir }) => flexDir};
	position: relative;
	flex: 0 0 auto;

	@media print {
		break-inside: avoid;
		${({ flexDir }) => flexDir === 'column' && `display: block;`}
	}

	${({ w, totalCols, flexDir }) =>
		w !== undefined &&
		flexDir !== 'row' &&
		`min-width: ${(w / totalCols) * 100}%; max-width: ${
			(w / totalCols) * 100
		}%;`}

	${({ h, expandForPublish, hidden }) => {
		if (hidden) {
			return `
				height: 0;
				overflow: hidden;
			`;
		}
		if (h === undefined || expandForPublish) {
			return '';
		}
		return `height: ${h * (60 + 8 * 2)}px;`;
	}}

	[class*="-card"] {
		margin: 0.5rem;
	}

	.card {
		width: calc(100% - 1rem) !important;
		min-width: calc(100% - 1rem) !important;
		height: calc(100% - 1rem) !important;
		min-height: calc(100% - 1rem) !important;
		overflow: auto;
		padding: 1rem;
		background-color: white;
		box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.1);
	}
`;

const isGridHidden: (
	hiddenComponents: Record<string | number, boolean>,
	grid?: Grid[],
	currentId?: string | number
) => boolean = (hiddenComponents, grid, currentId) => {
	const currentHidden =
		currentId !== undefined ? !!hiddenComponents[currentId] : true;
	const allChildrenHidden = grid
		? all(
				(g) =>
					isGridHidden(
						hiddenComponents,
						'contents' in g ? g.contents : undefined,
						'id' in g ? g.id : undefined
					),
				grid
		  )
		: currentHidden;

	return allChildrenHidden;
};

const ComponentGrid: React.FC<{
	grid?: Grid[];
	id?: string;
	width?: number;
	height?: number;
	direction?: 'column' | 'row';
	totalCols?: number;
	componentMap: Record<
		string,
		Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDTO
	>;
	breakpoint: Breakpoint;
	className?: string;
	reportId: Varicent.ID;
	preview?: boolean;
	topLevel?: boolean;
}> = ({
	grid,
	width,
	height,
	componentMap,
	breakpoint,
	direction = 'column',
	id,
	totalCols,
	className,
	reportId,
	preview,
	topLevel = false,
}) => {
	const { hiddenComponents } = useContext(ComponentVisibility);
	const actualTotalCols = totalCols ?? cols[breakpoint];
	const altDirection = direction === 'column' ? 'row' : 'column';
	const component = id !== undefined && componentMap[id];
	const isPublishMode = useContext(PublishMode);
	const expandForPublish =
		id !== undefined && getExpandForPublish(isPublishMode, componentMap[id]);
	const isHidden = isGridHidden(hiddenComponents, grid, id);
	return (
		<GridEl
			flexDir={direction}
			w={width}
			h={grid && grid.length > 0 ? undefined : height}
			hasContent={!!id}
			totalCols={actualTotalCols}
			className={className}
			expandForPublish={expandForPublish}
			hidden={isHidden}
		>
			{grid?.map((innerGrid, index) => {
				const innerId = 'id' in innerGrid ? innerGrid.id : undefined;
				// Ensure that any GridElements with a component are using column flex
				const hasComponent =
					innerId !== undefined ? componentMap[innerId] !== undefined : false;
				return (
					<ComponentGrid
						/*
						 * We do this because we literally have no other choice and these items don't move around
						 * anyways so it won't be a problem.
						 */
						// eslint-disable-next-line react/no-array-index-key
						key={index}
						grid={'contents' in innerGrid ? innerGrid.contents : undefined}
						id={innerId}
						height={innerGrid.height}
						width={innerGrid.width}
						direction={!topLevel && hasComponent ? 'column' : altDirection}
						totalCols={width ?? totalCols}
						componentMap={componentMap}
						breakpoint={breakpoint}
						reportId={reportId}
						preview={preview}
					/>
				);
			})}
			{component && ComponentElement ? (
				<ComponentElement
					component={component}
					breakpoint={breakpoint}
					reportId={reportId}
					preview={preview}
				/>
			) : null}
		</GridEl>
	);
};

export const ReportLayout: React.FC<{
	reportId: Varicent.ID;
	report: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO;
	preview?: boolean;
	edgeToEdge?: boolean;
}> = ({ reportId, report, preview, edgeToEdge }) => {
	const currentBreakpoint = useBreakpoint();
	const layouts = useMemo(() => {
		return reportLayoutsConverter(report.layouts);
	}, [report]);
	const componentMap = useMemo(() => {
		return indexBy((c) => c.componentId.toString(), report.components);
	}, [report]);
	const { loaded } = useContext(ComponentDataLoadedContext);
	// mark as loaded when all components have loaded, or no loads were initiated
	const isLoaded = componentMap && loaded;

	return (
		<ComponentGrid
			grid={layouts[currentBreakpoint]}
			componentMap={componentMap}
			breakpoint={currentBreakpoint}
			reportId={reportId}
			preview={preview}
			topLevel
			className={cx(
				css`
					${edgeToEdge
						? 'margin-left: -0.475rem; margin-right: 0;' // Leaves a 2px room for box-shadow
						: 'padding: 1.5rem;'}
					/* font-size: 16px; */
					background-color: #f8f8f8;
					flex: 1 1 auto;
					max-width: 100vw;

					&::before {
						content: '';
						flex: 1 1 auto;
						position: absolute;
						height: 100%;
						width: 100%;
						top: 0;
						left: 0;
						${report.background?.url
							? `
								background-image: url(${report.background?.url});
								${
									report.background?.metadata?.scale === 'contain'
										? `background-size: 100% auto;`
										: `background-size: ${report.background?.metadata?.scale};`
								}
								${
									report.background?.metadata?.scale === 'cover'
										? 'background-position: center;'
										: ''
								}
								opacity: ${(100 - report?.background?.metadata?.transparency) / 100.0};
							`
							: ``}
					}

					@media print {
						display: block;
						letter-spacing: 0.001px; /* this is to correct the character spacing in Adobe Acrobat */
					}
				`,
				{
					'v-presenter-flex-loaded': isLoaded,
				}
			)}
		/>
	);
};
