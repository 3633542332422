/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useState } from 'react';
import { exportGrid } from '../../utils/callApi';
import { AnchorButton, Dialog, Label, Tooltip } from '@blueprintjs/core';
import { defineMessages } from 'react-intl';
import { css } from 'emotion';
import { Varicent } from 'icm-rest-client';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { Icon } from '@varicent/components';
import { DocumentExport16 } from '@carbon/icons-react';
import { useDataSourceDependencies } from '../../utils/useDataSource';
import {
	buildFilteredByFromAgFilterModel,
	Filter,
} from 'icm-core/lib/components/datatable';

const messages = defineMessages({
	xlsx: {
		id: 'components.datatablecomponents.exportpanel.xlsx',
		defaultMessage: 'Export as Microsoft Excel',
	},
	error: {
		id: 'components.datatablecomponents.exportpanel.error',
		defaultMessage: 'Error',
	},
});

const ExportPanel: React.FC<{
	reportId: Varicent.ID;
	source: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO;
	componentId: Varicent.ID;
	sourceSchema: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
	preview: boolean;
	previewPayeeId: Varicent.ID;
	aggregateInfo: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexAggregateInfoDTO;
	sortOptions: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridSortOptionDTO[];
	filterModel?: Record<string, Filter>;
}> = ({
	preview,
	source,
	componentId,
	sourceSchema,
	reportId,
	previewPayeeId,
	aggregateInfo,
	sortOptions,
	filterModel,
}) => {
	const intl = useIntl();
	const { params } = useDataSourceDependencies({
		source,
		sourceSchema,
	});
	const [error, setError] = useState<string | null>(null);

	const title = intl.formatMessage(messages.xlsx);
	const filterBy = filterModel
		? buildFilteredByFromAgFilterModel(filterModel)
		: '';
	return (
		<>
			<Dialog
				title={intl.formatMessage(messages.error)}
				isOpen={!!error}
				className={css`
					border-color: red;
					border: 0.313rem;
				`}
				onClose={() => setError(null)}
			>
				<Label
					className={css`
						margin-left: 1.563rem;
					`}
				>
					{error}
				</Label>
			</Dialog>
			<Tooltip content={title}>
				<AnchorButton
					className={css`
						margin-top: 0.313rem;
						margin-left: 0.313rem;
					`}
					minimal
					onClick={() => {
						if (!preview) {
							exportGrid(
								reportId,
								componentId,
								{
									params: params ?? {},
									pivotInfo: {},
									aggregateInfo,
									sortOptions,
								},
								previewPayeeId,
								intl,
								(a) => setError(a),
								filterBy.trim() || undefined
							);
						}
					}}
					aria-label={title}
					icon={
						<Icon>
							<DocumentExport16 title={title} />
						</Icon>
					}
				/>
			</Tooltip>
		</>
	);
};

export default ExportPanel;
