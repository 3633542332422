/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Varicent } from 'icm-rest-client';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Query, useQueryClient } from 'react-query';
import { useThrottleFn } from 'react-use';
import { RefreshComponent, ReportContext } from '../context';

const isQueryAboutSourceId =
	(sourceId?: number) => (query: Query<any, any, any, any>) => {
		return query.queryKey[1]?.source?.sourceId === sourceId;
	};

export function useMarkSourceForUpdate() {
	const { components, sourceSchemas } = useContext(ReportContext);
	const queryClient = useQueryClient();

	const { set: setRefreshDataGrid, components: refreshedDatagrids } =
		useContext(RefreshComponent);

	return useCallback(
		(table: string) => {
			if (components && sourceSchemas) {
				for (let i = 0; i < components.length; i++) {
					const component = components[i];
					if (
						(component.config.dataGrid || component.config.chart) &&
						component.sourceId !== undefined &&
						sourceSchemas[component.sourceId].table === table &&
						!refreshedDatagrids[component.componentId]
					) {
						/*
						 * invalidate any query used by this component. Make sure to do this before trigger rerenders
						 * so the next fetches call
						 * TODO: more fine grained query invalidation and optimistic updates can be done down
						 * the line. For now, this is sufficient.
						 */
						queryClient.invalidateQueries({
							predicate: isQueryAboutSourceId(component.sourceId),
						});
						setRefreshDataGrid(component.componentId, true);
					}
				}
			}
		},
		[
			components,
			sourceSchemas,
			setRefreshDataGrid,
			refreshedDatagrids,
			queryClient,
		]
	);
}

export const useRefreshData = (
	componentId: Varicent.ID,
	callback: () => void
) => {
	const { set: setRefreshComponent, components: refreshedComponents } =
		useContext(RefreshComponent);

	useEffect(() => {
		if (refreshedComponents[componentId]) {
			setRefreshComponent(componentId, false);
			callback();
		}
	}, [refreshedComponents, componentId, setRefreshComponent, callback]);
};

type ThenArg<T> = T extends Promise<infer U>
	? U
	: T extends (...args: any[]) => Promise<infer V>
	? V
	: T;

export function useAsyncThrottle<R>(
	f: () => R,
	ms: number,
	deps: any[],
	initial?: ThenArg<R>
) {
	const [result, setResult] = useState<{
		loading: boolean;
		value?: ThenArg<R>;
		error?: any;
	}>({
		value: initial,
		loading: false,
	});
	// we've embeded the callback here because this function is virtually useless without it.
	const callback = useCallback(f, deps);
	const internalRef = useRef(0);
	const previous = useRef(result.value);
	useThrottleFn(
		async (callback) => {
			try {
				setResult({
					value: previous.current, // need to persist this to keep it around but without an explicit dependency (or you'll create an infinite loop)
					loading: true,
				});
				internalRef.current++;
				const before = internalRef.current;
				const nextRes: ThenArg<R> = (await callback()) as any;
				if (internalRef.current === before) {
					previous.current = nextRes;
					setResult({
						loading: false,
						value: nextRes,
					});
				}
			} catch (e) {
				setResult({
					value: initial,
					loading: false,
					error: e,
				});
			}
		},
		ms,
		[callback]
	);

	return result;
}
