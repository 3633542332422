/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Varicent } from 'icm-rest-client';
import { isNil } from 'ramda';

export const evaluateTextValue = (
	userValue: string | null,
	rule: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO,
	options?: {
		currentWebUserId?: number;
		valuesMap?: Record<number | string, any>;
	}
) => {
	const { operator } = rule;
	const { special, valueId, text } = rule.value ?? {};
	const { currentWebUserId, valuesMap } = options ?? {};
	if (
		!isNil(userValue) &&
		!isNil(valueId) &&
		!isNil(valuesMap) &&
		!isNil(valuesMap[valueId])
	) {
		return userValueIsValid(userValue, valuesMap[valueId], operator);
	}
	if (
		special === 'CurrentWebUser' &&
		!isNil(currentWebUserId) &&
		!isNil(userValue)
	) {
		return userValueIsValid(userValue, currentWebUserId, operator);
	}
	if (isNil(text)) {
		return userValueIsValid(userValue, null, operator);
	}
	return userValueIsValid(userValue, text, operator);
};

export const evaluateNumericValue = (
	userValue: number | null,
	rule: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO,
	options?: {
		valuesMap?: Record<number, any>;
	}
) => {
	const { numeric, valueId } = rule.value ?? {};
	const { valuesMap } = options ?? {};

	const numberValue =
		numeric ?? (valueId && valuesMap ? valuesMap[valueId] : null);

	if (isNil(userValue) || isNil(numberValue)) {
		return false;
	}
	return userValueIsValid(
		userValue,
		numeric ?? (valueId && valuesMap ? valuesMap[valueId] : undefined),
		rule.operator
	);
};

export const evaluateDateValue = (
	userValue: Date | null,
	rule: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO,
	options?: {
		valuesMap?: Record<number, any>;
		utc?: boolean;
	}
) => {
	const { operator } = rule;
	const { date, special, valueId } = rule.value ?? {};
	const { valuesMap } = options ?? {};
	const value = userValue === null ? null : userValue;
	if (special === 'CurrentDate') {
		let today = new Date();
		if (options?.utc) {
			today = new Date(
				Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
			);
		}
		today.setHours(0, 0, 0, 0);
		return evaluateDate(value, today, operator);
	}
	if (
		!isNil(value) &&
		!isNil(valueId) &&
		!isNil(valuesMap) &&
		!isNil(valuesMap[valueId])
	) {
		const dateAsNumber = parseFloat(valuesMap[valueId]);
		const dateValue =
			typeof valuesMap[valueId] === 'string' && !Number.isNaN(dateAsNumber)
				? dateAsNumber
				: valuesMap[valueId];

		return evaluateDate(value, new Date(dateValue), operator);
	}
	if (date && !isNil(value)) {
		return evaluateDate(value, new Date(date), operator);
	}
	if (isNil(date)) {
		return evaluateDate(value, null, operator);
	}
	return false;
};

const evaluateDate = (
	date1Input: Date | null,
	date2Input: Date | null,
	operator: string
) => {
	const date1 = date1Input ? Date.parse(date1Input?.toDateString()) : null;
	const date2 = date2Input ? Date.parse(date2Input?.toDateString()) : null;
	switch (operator) {
		case 'Equals':
			return date1 && date2 ? date1 === date2 : false;
		case 'NotEquals':
			return date1 && date2 ? date1 !== date2 : false;
		case 'LessThan':
			return date1 && date2 ? date1 < date2 : false;
		case 'LessThanEquals':
			return date1 && date2 ? date1 <= date2 : false;
		case 'GreaterThan':
			return date1 && date2 ? date1 > date2 : false;
		case 'GreaterThanEquals':
			return date1 && date2 ? date1 >= date2 : false;
		case 'IsNull':
			return date1 === null;
		case 'IsNotNull':
			return date1 !== null;
		default:
			return true;
	}
};

export const userValueIsValid = (
	userValue: number | string | null,
	systemValue: number | string | null,
	operator: string
) => {
	switch (operator) {
		case 'LessThan':
			return (userValue as number) < (systemValue as number);
		case 'LessThanEquals':
			return (userValue as number) <= (systemValue as number);
		case 'Equals':
			return userValue === systemValue;
		case 'NotEquals':
			return userValue !== systemValue;
		case 'GreaterThan':
			return (userValue as number) > (systemValue as number);
		case 'GreaterThanEquals':
			return (userValue as number) >= (systemValue as number);
		case 'IsNull':
			return userValue === null;
		case 'IsNotNull':
			return userValue !== null;
		// no validation, always passes
		default:
			return true;
	}
};
