/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { generateColorRange } from '../../../utils/colors';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	getCurrencySymbol,
	invertedFontColor,
	simplifiedFormatNumber,
	formatDateUTC,
} from '../chartUtil';

export const bubbleChartOptions = (
	props: ChartOptionArg & {
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartBubbleDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		intl,
		invertFontColors,
		palette,
		columnNameToType,
	} = props;
	const {
		labelColumn,
		valueColumn,
		categoryColumn,
		categorySizeColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		xAxisOrentation,
		xAxisOrder,
		yAxisOrder,
		valueFormatX,
		valueFormatY,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		hideYAxisLine,
		categorySizeTitle,
		hideXAxisLine,
		showXMinMax,
		xAxisMin,
		xAxisMax,
		showLegend,
		legendPosition,
		showXAxisPoint,
		xAxisPointValue,
		xAxisPointTitle,
		xAxisPointHideTitle,
		showYAxisPoint,
		yAxisPointValue,
		yAxisPointTitle,
		yAxisPointHideTitle,
	} = config;

	const type = 'bubble';
	const catIsDateBased = columnNameToType[categoryColumn].startsWith('date');
	const catIsNum = columnNameToType[categoryColumn].startsWith('numeric');

	const currencyCodeX = getCurrencyCode(valueFormatX);
	const currencySymbolX = getCurrencySymbol(currencyCodeX);

	const currencyCodeY = getCurrencyCode(valueFormatY);
	const currencySymbolY = getCurrencySymbol(currencyCodeY);

	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	/*
	 * allow colorAxis to set colors
	 * in case of sequential or divergent palette
	 */
	const nothing = (_) => undefined;
	const getColor = existingOptions.colorAxis
		? nothing // handled by colorAxis
		: generateColorRange(
				data.rows.map((row) => row[categoryColumn]),
				palette?.config.classification?.paletteColors
		  );

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const categoryValue = catIsDateBased
				? formatDateUTC(this.key, intl)
				: catIsNum
				? Number(this.key)
				: this.key;

			const xValue = simplifiedFormatNumber({
				value: this.x,
				config,
				multiCol: 'X',
			});
			const yValue = simplifiedFormatNumber({
				value: this.y,
				config,
				multiCol: 'Y',
			});
			const zValue = simplifiedFormatNumber({
				value: this.point.options.z as number,
				config,
				multiCol: 'Z',
			});

			return `
			<strong>${categoryValue}<strong><br/>
			${xAxisTitle ?? labelColumn}: <strong>${xValue}</strong><br/>
			${yAxisTitle ?? valueColumn}: <strong>${yValue}</strong><br/>
			${categorySizeTitle ?? categorySizeColumn}: <strong>${zValue}</strong>`;
		},
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? true,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		layout:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'horizontal'
				: 'vertical',
		align:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'center'
				: legendPosition ?? 'right',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: 'middle',
	};

	const showXAxisMin = showXMinMax && xAxisMin && xAxisMin !== '';
	const showXAxisMax = showXMinMax && xAxisMax && xAxisMax !== '';
	const xAxis: Highcharts.XAxisOptions = {
		title: {
			text: hideXAxisTitle
				? undefined
				: `${xAxisTitle || labelColumn}${
						currencySymbolX ? ` (${currencySymbolX})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		plotLines:
			showXAxisPoint && xAxisPointValue !== null
				? [
						{
							color: 'black',
							dashStyle: 'Dot',
							width: 2,
							value: Number(xAxisPointValue),
							label: {
								rotation: 0,
								y: 15,
								text: `${
									!xAxisPointHideTitle && xAxisPointTitle
										? `${xAxisPointTitle}: `
										: ``
								} <strong>${simplifiedFormatNumber({
									value: Number(xAxisPointValue),
									config,
									multiCol: 'X',
								})}</strong>`,
							},
						} as Highcharts.AxisPlotLinesOptions,
				  ]
				: [],
		reversed: xAxisOrder === 'DESC',
		lineWidth: hideXAxisLine || hideXAxisLine === undefined ? 0 : 1,
		min: showXAxisMin ? Number(xAxisMin) : null,
		max: showXAxisMax ? Number(xAxisMax) : null,
		softMin: showXAxisMin ? Number(xAxisMin) : 0,
	};

	const showYAxisMin = showYMinMax && !!yAxisMin && yAxisMin !== '';
	const showYAxisMax = showYMinMax && !!yAxisMax && yAxisMax !== '';
	const yAxis: Highcharts.YAxisOptions = {
		title: {
			text: hideYAxisTitle
				? undefined
				: `${yAxisTitle || valueColumn}${
						currencySymbolY ? ` (${currencySymbolY})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		plotLines:
			showYAxisPoint && yAxisPointValue !== null
				? [
						{
							color: 'black',
							dashStyle: 'Dot',
							width: 2,
							value: Number(yAxisPointValue),
							label: {
								rotation: 0,
								y: 15,
								text: `${
									!yAxisPointHideTitle && yAxisPointTitle
										? `${yAxisPointTitle}: `
										: ``
								} <strong>${simplifiedFormatNumber({
									value: Number(yAxisPointValue),
									config,
									multiCol: 'Y',
								})}</strong>`,
							},
						} as Highcharts.AxisPlotLinesOptions,
				  ]
				: [],
		startOnTick: !showYAxisMin,
		min: showYAxisMin ? Number(yAxisMin) : null,
		endOnTick: !showYAxisMax,
		max: showYAxisMax ? Number(yAxisMax) : null,
		lineWidth: hideYAxisLine || hideYAxisLine === undefined ? 0 : 1,
		reversed: yAxisOrder === 'DESC',
		softMin: showYAxisMin ? Number(yAxisMin) : 0,
	};

	const categoryMap = {};
	data.rows.forEach((row) => {
		const category = catIsDateBased
			? formatDateUTC(row[categoryColumn], intl)
			: catIsNum
			? Number(row[categoryColumn])
			: row[categoryColumn];

		const dataRow = {
			x: Number(row[labelColumn]),
			y: Number(row[valueColumn]),
			z: Number(row[categorySizeColumn]),
			name: category,
		};

		if (categoryMap[category] && categoryMap[category].length) {
			categoryMap[category].push(dataRow);
		} else {
			categoryMap[category] = [dataRow];
		}
	});

	const series = Object.keys(categoryMap).map((category: string) => {
		return {
			type: type as any,
			data: categoryMap[category],
			color: getColor(category),
			name: category,
		} as Highcharts.SeriesOptionsType;
	});

	const options = {
		...existingOptions,
		tooltip,
		legend,
		xAxis,
		yAxis,
		series,
	};
	return options;
};
