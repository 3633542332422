/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
// allow this because it's a runtime cycle, no stastic usages
import { getComponentIcon, FlexComponentTypes } from '../componentTypes';
import { Warning20 } from '@carbon/icons-react';
import styled from 'react-emotion';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
	noData: {
		id: 'utils.placeholder.noData',
		defaultMessage: 'There is no data to display',
	},
});

const PlaceHolderWrapper = styled.div`
	flex-direction: column;
	background-color: #e8e8e8 !important;
	color: #686868 !important;
	display: flex;
	flex: 1 1 auto;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	border-radius: 0.25rem;
`;

const Placeholder: React.FC<{ type: FlexComponentTypes }> = ({ type }) => {
	const Icon = getComponentIcon(type);
	return (
		<PlaceHolderWrapper>
			<Icon height={32} width={32} fill="currentColor" />
		</PlaceHolderWrapper>
	);
};

const OffsetText = styled.span`
	position: absolute;
	width: calc(100% - 2rem);
	margin: 0 1rem;
	// offset the text just south of center
	// 50% => center
	// 1rem => half the height of the icon
	// 1rem => some spacing between text and icon
	top: calc(50% + 1rem + 1rem);
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const MissingDataPlaceholder: React.FC<
	React.HTMLAttributes<HTMLDivElement> & {
		message?: string;
	}
> = ({ message, children, ...props }) => {
	const intl = useIntl();
	const text = message ?? intl.formatMessage(messages.noData);
	return (
		<PlaceHolderWrapper {...props}>
			<Warning20
				height={32}
				width={32}
				fill="currentColor"
				aria-label={text}
				role="img"
			/>
			<OffsetText>{children ?? text}</OffsetText>
		</PlaceHolderWrapper>
	);
};

export default Placeholder;
