/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React from 'react';
import { defineMessages, InjectedIntl } from 'react-intl';
import {
	ColDef,
	ICellRendererComp,
	ICellRendererParams,
	RowNode,
	GroupCellRenderer,
} from '@ag-grid-community/core';
import { Varicent } from 'icm-rest-client';
import { NormalizedColumnType } from 'icm-core/lib/utils/dbUtils';
import { Parser } from 'hot-formula-parser';
import { isNil, reject, omit } from 'ramda';
import {
	parseAggregationFormula,
	parseColumnName,
	parseFormula,
} from './aggregationFormulaExtraction';
import {
	applyThousandsSeparator,
	getAbbreviation,
	getCurrencyCode,
} from './valueFormatUtils';

export const DEFAULT_ROW_HEIGHT = 40;
export const ROW_TOP_PADDING = 10;
export const ROW_BOTTOM_PADDING = 10;
export const ROW_BORDER_THICKNESS = 1;

export interface IConditionProps extends BasicConfig {
	applyToOptions: 'header' | 'values';
	selectedColumns: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridColumnDTO[];
	formulaId?: number;
	formula: string;
	valueFormat: ValueFormatConfig;
}

export type CellRendererComponent = React.FC<
	ICellRendererParams & {
		columnDTO?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridColumnDTO;
		columnNameToType: Record<string, NormalizedColumnType>;
		columnConfig?: ColumnConfig;
		inputRules?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO[];
		columnConditions: IConditionProps[];
		intl: InjectedIntl;
		preview?: boolean;
		validate?: (value: any) => string | null;
		aggregateResults: object;
		footerConfig: FooterConfig;
		tableConfig: TableConfig;
	}
>;

const isIE11 =
	!!(window as any).MSInputMethodContext && !!(document as any).documentMode;

/**
 * MM - month with leading zero (01, 02, 03)
 * MMM - month abbreviated text (Jan, Feb, Mar)
 *
 * D - month without leading zero (1, 2, 3)
 * DD - month with leading zero (01, 31)
 *
 * YY - year with two digits
 * YYYY - year with two digits
 */
const messages = defineMessages({
	DATE_FORMAT_OPTION_MM_DD_YY: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_MM_DD_YY',
		defaultMessage: '{month}/{day}/{year}',
	},
	DATE_FORMAT_OPTION_MM_DD_YYYY: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_MM_DD_YYYY',
		defaultMessage: '{month}/{day}/{year}',
	},
	DATE_FORMAT_OPTION_DD_MM_YY: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_DD_MM_YY',
		defaultMessage: '{day}/{month}/{year}',
	},
	DATE_FORMAT_OPTION_DD_MM_YYYY: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_DD_MM_YYYY',
		defaultMessage: '{day}/{month}/{year}',
	},
	DATE_FORMAT_OPTION_MMMM_D_YYYY: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_MMMM_D_YYYY',
		defaultMessage: '{month} {day}, {year}',
	},
	DATE_FORMAT_OPTION_D_MMMM_YYYY: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_D_MMMM_YYYY',
		defaultMessage: '{day} {month}, {year}',
	},
	DATE_FORMAT_OPTION_D_MMM_YYYY: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_D_MMM_YYYY',
		defaultMessage: '{day}-{month}-{year}',
	},
	DATE_FORMAT_OPTION_MMM_D_YYYY: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_MMM_D_YYYY',
		defaultMessage: '{month} {day}, {year}',
	},
	DATE_FORMAT_OPTION_YYYY_MM_DD: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_YYYY_MM_DD',
		defaultMessage: '{year}/{month}/{day}',
	},
	DATE_FORMAT_OPTION_YY_MM_DD: {
		id: 'utils.dataGridStyling.DATE_FORMAT_OPTION_YY_MM_DD',
		defaultMessage: '{year}/{month}/{day}',
	},
	NUMBER_FORMAT_OPTION_CURRENCY: {
		id: 'utils.dataGridStyling.NUMBER_FORMAT_OPTION_CURRENCY',
		defaultMessage: '{value}{abbreviation}',
	},
	NUMBER_FORMAT_OPTION_CUSTOM: {
		id: 'utils.dataGridStyling.NUMBER_FORMAT_OPTION_CUSTOM',
		defaultMessage: '{prefix}{value}{abbreviation}{suffix}',
	},
	NUMBER_FORMAT_OPTION_NUMBER: {
		id: 'utils.dataGridStyling.NUMBER_FORMAT_OPTION_NUMBER',
		defaultMessage: '{value}{abbreviation}',
	},
	NUMBER_FORMAT_OPTION_PERCENTAGE: {
		id: 'utils.dataGridStyling.NUMBER_FORMAT_OPTION_PERCENTAGE',
		defaultMessage: '{value}',
	},
});

// eslint-disable-next-line no-shadow
export enum DateFormatOption {
	MM_DD_YY = 'MM_DD_YY',
	MM_DD_YYYY = 'MM_DD_YYYY',
	DD_MM_YY = 'DD_MM_YY',
	DD_MM_YYYY = 'DD_MM_YYYY',
	YY_MM_DD = 'YY_MM_DD',
	YYYY_MM_DD = 'YYYY_MM_DD',
	MMMM_D_YYYY = 'MMMM_D_YYYY',
	D_MMMM_YYYY = 'D_MMMM_YYYY',
	D_MMM_YYYY = 'D_MMM_YYYY',
	MMM_D_YYYY = 'MMM_D_YYYY',
}

// eslint-disable-next-line no-shadow
export enum NumberFormatOption {
	PERCENTAGE = 'PERCENTAGE',
	CURRENCY = 'CURRENCY',
	NUMBER = 'NUMBER',
	CUSTOM = 'CUSTOM',
}

// eslint-disable-next-line no-shadow
export enum NumberFormatAbbreviation {
	NONE = 'NONE',
	THOUSANDS = 'THOUSANDS',
	MILLIONS = 'MILLIONS',
	BILIIONS = 'BILLIONS',
}

// eslint-disable-next-line no-shadow
export enum ThousandsSeparatorFormat {
	COMMA_PERIOD = 'COMMA_PERIOD',
	PERIOD_COMMA = 'PERIOD_COMMA',
	SPACE_PERIOD = 'SPACE_PERIOD',
	SPACE_COMMA = 'SPACE_COMMA',
	DEFAULT = 'DEFAULT',
}

// eslint-disable-next-line no-shadow
export enum FontSize {
	SMALL = 'SMALL',
	NORMAL = 'NORMAL',
	LARGE = 'LARGE',
}

// eslint-disable-next-line no-shadow
export enum Aggregate {
	Sum = 'sum',
	Average = 'avg',
	Count = 'count',
	Minimum = 'min',
	Maximum = 'max',
}

// css properties
type AlignItems = 'flex-start' | 'flex-end' | 'center';
type JustifyContent = 'flex-start' | 'flex-end' | 'center';
type TextAlign = 'left' | 'right' | 'center';
type WhiteSpace = 'normal' | 'nowrap';
type WordBreak = 'break-word' | 'break-all' | 'normal';

// config properties
type Alignment = {
	v?: 'top' | 'bottom' | 'center';
	h?: 'left' | 'right' | 'center';
};
type Mode = 'auto' | 'custom';
type AlternatingRows = 'odd' | 'even';

export type GridTextContainerStyleArgs = {
	alignItems?: AlignItems;
	backgroundColor?: string;
	color?: string;
	justifyContent?: JustifyContent;
	textAlign?: TextAlign;
	whiteSpace?: WhiteSpace;
	wordBreak?: WordBreak;
	fontSize?: number;
	fontWeight?: string | number;
	fontStyle?: string;
	textDecoration?: string;
};

export type BasicConfig = {
	alignment?: Alignment;
	alignmentMode?: Mode;
	backgroundColor?: string;
	fontColor?: string;
	fontSize?: FontSize;
	textBold?: boolean;
	textItalic?: boolean;
	textUnderline?: boolean;
	hideBackground?: boolean;
};

export type ColumnConfig = {
	cellStyle?: CellStyleConfig;
	valueFormat?: ValueFormatConfig;
	wrapText?: boolean;
	flexWidth?: number;
};

export type TableConfig = BasicConfig;

export type HeaderConfig = BasicConfig;

export type FooterConfig = BasicConfig & {
	hideRowTotal?: boolean;
	title?: string;
	styleMode?: Mode;
};

export type RowGroupConfig = BasicConfig & {
	hideCount?: boolean;
	hideValue?: boolean;
};

export type RowConfig = BasicConfig & {
	alternatingRows?: AlternatingRows;
	isAlternatingRows?: boolean;
};

export type CellStyleConfig = BasicConfig & {
	applyToHeader?: boolean;
};

export type ValueFormatConfig = {
	abbreviation?: NumberFormatAbbreviation;
	currency?: string;
	decimalPlaces?: number;
	formatDateOption?: DateFormatOption;
	formatNumberOption?: NumberFormatOption;
	prefix?: string;
	suffix?: string;
	thousandsSeparatorFormat?: ThousandsSeparatorFormat;
	useThousandsSeparator?: boolean;
};

interface FlexboxMapping {
	right: 'flex-end';
	left: 'flex-start';
	center: 'center';
	top: 'flex-start';
	bottom: 'flex-end';
}

export const flexMapping: FlexboxMapping = {
	right: 'flex-end',
	left: 'flex-start',
	center: 'center',
	top: 'flex-start',
	bottom: 'flex-end',
};

export type StyleOptions = {
	editable?: boolean;
	hideTile?: boolean;
	useHeaderStyling?: boolean;
	preventDefault?: boolean;
};

export const getDefaultVerticalTextConfig: () => AlignItems = () => {
	return 'center';
};

export const getDefaultHorizontalTextConfig: (
	colType: string | null | undefined
) => TextAlign = (colType) => {
	return colType === 'numeric' ? 'right' : 'left';
};

export const getDefaultHorizontalFlexboxConfig: (
	colType: string | null | undefined
) => JustifyContent = (colType) => {
	return colType === 'numeric' ? 'flex-end' : 'flex-start';
};

const getHorizontalAlignment = (
	colDef: ColDef,
	mode: Mode | undefined,
	h: 'left' | 'right' | 'center' | undefined,
	prevStyle?: GridTextContainerStyleArgs
) => {
	const alignmentMode = mode ?? 'auto';
	const field = colDef.field as string;
	const colType = colDef.cellRendererParams?.columnNameToType[field];
	const defaultHorizontalTextConfig =
		prevStyle?.textAlign ?? getDefaultHorizontalTextConfig(colType);
	const defaultHorizontalFlexboxConfig =
		prevStyle?.justifyContent ?? getDefaultHorizontalFlexboxConfig(colType);

	const horizontalAlignment = flexMapping[h ?? defaultHorizontalTextConfig];
	const horizontalFlexboxAlignment =
		alignmentMode === 'auto'
			? defaultHorizontalFlexboxConfig
			: horizontalAlignment;
	const horizontalTextAlignment =
		alignmentMode === 'auto'
			? defaultHorizontalTextConfig
			: h ?? defaultHorizontalTextConfig;

	const style = {
		justifyContent: horizontalFlexboxAlignment,
		textAlign: horizontalTextAlignment,
	};

	return style;
};

const getVerticalAlignment = (
	mode: Mode | undefined,
	v: 'top' | 'bottom' | 'center' | undefined,
	prevStyle?: GridTextContainerStyleArgs
) => {
	const alignmentMode = mode ?? 'auto';
	const verticalAlignment = flexMapping[v ?? 'center'];
	const verticalFlexboxAlignment =
		alignmentMode === 'auto'
			? prevStyle?.alignItems ?? getDefaultVerticalTextConfig()
			: verticalAlignment;
	return {
		alignItems: verticalFlexboxAlignment,
	};
};

const getFontStyle = (
	config:
		| TableConfig
		| CellStyleConfig
		| HeaderConfig
		| FooterConfig
		| RowConfig
		| undefined,
	showDefault?: boolean
) => {
	if (config === undefined) {
		return {};
	}

	const { fontColor, fontSize, textBold, textItalic, textUnderline } = config;

	return {
		color: !showDefault && fontColor ? fontColor : '#000000',
		fontSize: getFontSize(
			!showDefault && fontSize ? fontSize : FontSize.NORMAL
		),
		fontWeight: !showDefault && textBold ? 700 : 400,
		fontStyle: !showDefault && textItalic ? 'italic' : 'normal',
		textDecoration: `${
			!showDefault && textUnderline ? 'underline' : 'initial'
		}`,
	};
};

const getBaseStyling = (
	colDef: ColDef,
	config:
		| TableConfig
		| CellStyleConfig
		| HeaderConfig
		| FooterConfig
		| RowGroupConfig
		| RowConfig
		| IConditionProps
		| undefined,
	options?: StyleOptions
): GridTextContainerStyleArgs => {
	const alignmentMode =
		config?.alignmentMode ??
		colDef.cellRendererParams?.columnConfig?.cellStyle?.alignmentMode ??
		(options?.preventDefault ? undefined : 'auto');
	const horizontalAlignment =
		config?.alignment?.h ??
		colDef.cellRendererParams?.columnConfig?.cellStyle?.alignment?.h ??
		(options?.preventDefault ? undefined : 'left');
	const verticalAlignment =
		config?.alignment?.v ?? (options?.preventDefault ? undefined : 'top');
	const defaultBackground = options?.useHeaderStyling ? '#F8F8F8' : '#FFFFFF';
	const backgroundColor =
		config?.hideBackground ||
		(options?.hideTile &&
			config?.hideBackground === undefined &&
			(config as IConditionProps)?.applyToOptions === undefined)
			? 'transparent'
			: config?.backgroundColor ??
			  (options?.preventDefault ? undefined : defaultBackground);

	const horizontalTextAlignmentStyle = getHorizontalAlignment(
		colDef,
		alignmentMode,
		horizontalAlignment
	);
	const verticalTextAlignmentStyle = getVerticalAlignment(
		alignmentMode,
		verticalAlignment
	);
	const fontStyle = getFontStyle(config);

	const style = {
		backgroundColor,
		...horizontalTextAlignmentStyle,
		...verticalTextAlignmentStyle,
		...fontStyle,
	};

	return reject(isNil, style);
};

const getFooterStyling = (
	colDef: ColDef,
	config: FooterConfig | undefined,
	isTitle: boolean
): GridTextContainerStyleArgs => {
	const showDefault =
		!(config as FooterConfig)?.styleMode ||
		(config as FooterConfig)?.styleMode === 'auto';
	const horizontalAlignment = isTitle ? 'left' : 'right';
	const verticalAlignment = config?.alignment?.v ?? 'center';
	const backgroundColor = config?.hideBackground
		? 'transparent'
		: !showDefault && config?.backgroundColor
		? config?.backgroundColor
		: '#F8F8F8';
	const horizontalTextAlignmentStyle = getHorizontalAlignment(
		colDef,
		'custom',
		horizontalAlignment
	);
	const verticalTextAlignmentStyle = getVerticalAlignment(
		'custom',
		verticalAlignment
	);
	const fontStyle = getFontStyle(config, showDefault);

	const style = {
		backgroundColor,
		...horizontalTextAlignmentStyle,
		...verticalTextAlignmentStyle,
		...fontStyle,
	};

	return style;
};

export const generateTableStyling = (
	colDef: ColDef,
	tableStyleConfig: TableConfig | undefined,
	options?: StyleOptions
) => {
	const style = getBaseStyling(colDef, tableStyleConfig, options);
	return style;
};

export const generateHeaderStyling = (
	colDef: ColDef,
	headerStyleConfig: HeaderConfig | undefined
) => {
	const style = getBaseStyling(colDef, headerStyleConfig, {
		useHeaderStyling: true,
	});
	return style;
};

export const generateFooterStyling = (
	colDef: ColDef,
	FooterStyleConfig: FooterConfig | undefined,
	isTitle: boolean
) => {
	const style = getFooterStyling(colDef, FooterStyleConfig, isTitle);
	return style;
};

export const generateColumnToCellStyling = (
	colDef: ColDef,
	columnStyleConfig: CellStyleConfig | undefined,
	options?: StyleOptions
): GridTextContainerStyleArgs => {
	const cellAlignmentMode = columnStyleConfig?.alignmentMode ?? 'auto';
	const { alignItems, justifyContent, textAlign, ...basicStyling } =
		getBaseStyling(colDef, columnStyleConfig, options);

	if (cellAlignmentMode === 'auto') {
		return basicStyling;
	}

	return {
		alignItems,
		justifyContent,
		textAlign,
		...basicStyling,
	};
};

export const generateRowGroupStyling = (
	colDef: ColDef,
	rowGroupStyleConfig: RowGroupConfig | undefined,
	options?: StyleOptions
): GridTextContainerStyleArgs => {
	const styling = getBaseStyling(colDef, rowGroupStyleConfig, options);
	return omit(['alignItems', 'justifyContent', 'textAlign'], styling);
};

export const generateCellContainerStyle = (props: {
	colDef: any;
	columnStyleConfig: ColumnConfig | undefined;
	tableStyleConfig: TableConfig | undefined;
	options?: StyleOptions;
}): GridTextContainerStyleArgs => {
	const { colDef, columnStyleConfig, tableStyleConfig, options } = props;
	const tableStyle = generateTableStyling(colDef, tableStyleConfig, options);
	const cellStyle = generateColumnToCellStyling(
		colDef,
		columnStyleConfig?.cellStyle,
		options
	);

	let style = {
		...tableStyle,
		...cellStyle,
	};

	if (columnStyleConfig?.wrapText) {
		style = {
			...style,
			whiteSpace: 'normal',
			wordBreak: isIE11 ? 'break-all' : 'break-word',
		};
	}

	return style;
};

export const generateRowGroupContainerStyle = (props: {
	colDef: any;
	rowGroupStyleConfig: RowGroupConfig | undefined;
	tableStyleConfig?: TableConfig | undefined;
	columnStyleConfig?: ColumnConfig | undefined;
	options?: StyleOptions;
}): GridTextContainerStyleArgs => {
	const {
		colDef,
		rowGroupStyleConfig,
		tableStyleConfig,
		columnStyleConfig,
		options,
	} = props;

	const tableStyle = generateTableStyling(colDef, tableStyleConfig, options);
	const cellStyle = generateColumnToCellStyling(
		colDef,
		columnStyleConfig?.cellStyle,
		options
	);
	const rowGroupStyle = generateRowGroupStyling(
		colDef,
		rowGroupStyleConfig,
		options
	);

	return {
		...tableStyle,
		...cellStyle,
		...rowGroupStyle,
	};
};

export const generateHeaderContainerStyle = (
	colDef: any,
	columnStyleConfig: ColumnConfig | undefined,
	headerStyleConfig: HeaderConfig | undefined
) => {
	let style = generateHeaderStyling(colDef, headerStyleConfig);

	if (
		columnStyleConfig?.cellStyle?.applyToHeader &&
		columnStyleConfig.cellStyle.alignmentMode === 'custom'
	) {
		const { alignItems, justifyContent, textAlign } =
			generateColumnToCellStyling(colDef, columnStyleConfig?.cellStyle);
		style = {
			...style,
			alignItems,
			justifyContent,
			textAlign,
		};
	}

	return style;
};

export const generateFooterContainerStyle = ({
	colDef,
	columnStyleConfig,
	footerStyleConfig,
	isTitle,
}: {
	colDef: any;
	columnStyleConfig: ColumnConfig | undefined;
	footerStyleConfig: FooterConfig | undefined;
	isTitle: boolean;
}) => {
	let style = generateFooterStyling(colDef, footerStyleConfig, isTitle);
	if (!isTitle && columnStyleConfig?.cellStyle?.alignmentMode === 'custom') {
		const { justifyContent, textAlign } = generateColumnToCellStyling(
			colDef,
			columnStyleConfig?.cellStyle
		);
		style = {
			...style,
			justifyContent,
			textAlign,
		};
	}
	return style;
};

export const generateGroupCellClass = (props: {
	style: GridTextContainerStyleArgs;
	isTitleCell?: boolean;
}) => {
	const { style, isTitleCell } = props;
	const {
		alignItems,
		backgroundColor,
		color,
		fontSize,
		fontStyle,
		fontWeight,
		justifyContent,
		textAlign,
		textDecoration,
		whiteSpace,
		wordBreak,
	} = style;
	return `
		padding: 6px 17px 6px 17px;
		background-color: ${backgroundColor ?? '#FFFFFF'} !important;
		color: ${color ?? '#000000'};

		.ag-group-contracted, .ag-group-expanded, .ag-group-child-count {
			height: 100% !important;
			align-items: ${alignItems ?? 'center'} !important;
		}

		.ag-group-value, .ag-group-child-count {
			font-size: ${fontSize ?? 14}px;
			font-style: ${fontStyle ?? 'normal'};
			font-weight: ${fontWeight ?? 400};
			text-decoration: ${textDecoration};
		}

		${
			justifyContent === 'flex-end' || justifyContent === 'center'
				? `
					.ag-group-value {
						margin-left: auto;
					}
				`
				: ''
		}

		${
			justifyContent !== 'flex-end'
				? `
					.ag-group-child-count {
						margin-right: auto;
					}
				`
				: ''
		}

		.ag-group-value {
			${
				isTitleCell
					? `
						display: flex;
						height: 100%;
						align-items: ${alignItems ?? 'center'};
						padding-right: ${fontStyle === 'italic' ? 0.1 : 0}em;
					`
					: `
						.ag-react-container {
							display: flex;
							justify-content: ${justifyContent ?? 'flex-start'};
							text-align: ${textAlign ?? 'left'};
							align-items: ${alignItems ?? 'center'};
							height: 100%;

							a, span {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: ${whiteSpace ?? 'nowrap'} !important;
								word-break: ${wordBreak ?? 'normal'} !important;
								padding-right: ${fontStyle === 'italic' ? 0.1 : 0}em;
							}
						}
					`
			}
		}
	`;
};

export const generateCellClass = (props: {
	style: GridTextContainerStyleArgs;
	options?: StyleOptions;
}) => {
	const { style, options } = props;
	const {
		alignItems,
		backgroundColor,
		color,
		fontSize,
		fontStyle,
		fontWeight,
		justifyContent,
		textAlign,
		textDecoration,
		whiteSpace,
		wordBreak,
	} = style;
	return `
		padding: 6px 17px 6px 17px;
		background-color: ${backgroundColor ?? '#FFFFFF'} !important;

		${
			options?.editable
				? `
					font-size: 14px;
					height: 100% !important;
					`
				: `
					font-size: ${fontSize ?? 14}px;
					font-style: ${fontStyle ?? 'normal'};
					font-weight: ${fontWeight ?? 400};
					text-decoration: ${textDecoration};
					color: ${color ?? '#000000'};

					.ag-react-container {
						display: flex;
						justify-content: ${justifyContent ?? 'flex-start'};
						text-align: ${textAlign ?? 'left'};
						align-items: ${alignItems ?? 'center'};
						height: 100%;

						a, span {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: ${whiteSpace ?? 'nowrap'} !important;
							word-break: ${wordBreak ?? 'normal'} !important;
							padding-right: ${fontStyle === 'italic' ? 0.1 : 0}em;
						}
				`
		}
	`;
};

export const generateHeaderClass = (
	style: GridTextContainerStyleArgs,
	colDef: ColDef
) => {
	const {
		alignItems,
		color,
		fontSize,
		fontStyle,
		fontWeight,
		justifyContent,
		textDecoration,
		backgroundColor,
		textAlign,
	} = style;

	return `
		padding: 10px 18px 10px 18px;
		font-size: ${fontSize ?? 14}px !important;
		font-style: ${fontStyle ?? 'normal'} !important;
		font-weight: ${fontWeight ?? 400} !important;
		text-decoration: ${textDecoration} !important;
		color: ${color ?? '#000000'} !important;
		background-color: ${backgroundColor ?? undefined} !important;

		.ag-header-cell-label, .ag-react-container {
			padding-right: ${fontStyle === 'italic' ? 0.1 : 0}em;

			[role="button"] {
				display: flex;
				flex-direction: row;
				justify-content: ${justifyContent};
				align-items: ${alignItems ?? 'center'};
			}

			.bp3-icon:not([role="button"]){
				display: ${!colDef.sort && 'none'};
			}

			.ag-header-icon {
				align-self: center;
			}

			:not(:hover) .bp3-icon[role="button"]:not(.filter-active) {
				display: ${textAlign !== 'center' ? 'none' : undefined};
			}
		}

		.bp3-text-overflow-ellipsis {
			padding-left: ${textAlign === 'center' ? '1.5rem' : '0'};
			padding-right: 0.2rem;
		}

		p {
			margin-bottom: 0;
		}
    `;
};

export const getFontSize = (fontSize: FontSize) => {
	switch (fontSize) {
		case FontSize.SMALL:
			return 12;
		case FontSize.LARGE:
			return 16;
		case FontSize.NORMAL:
		default:
			return 14;
	}
};

const getIntlNumberFormatOptions = (
	valueFormat: ValueFormatConfig | undefined
) => {
	const {
		currency,
		formatNumberOption,
		decimalPlaces = 2,
		useThousandsSeparator,
	} = valueFormat || {};

	const options = {
		maximumFractionDigits: decimalPlaces,
		minimumFractionDigits: decimalPlaces,
		useGrouping: useThousandsSeparator ?? false,
	};

	switch (formatNumberOption) {
		case NumberFormatOption.CURRENCY:
			return {
				...options,
				currency:
					getCurrencyCode(
						currency?.startsWith('currency_') === false
							? `currency_${currency}`
							: currency
					) ?? 'USD',
				style: 'currency',
			};
		case NumberFormatOption.PERCENTAGE:
			return {
				...options,
				style: 'percent',
			};
		case NumberFormatOption.CUSTOM:
		case NumberFormatOption.NUMBER:
		default:
			return options;
	}
};

const formattedNumberHelper = (
	value: any,
	columnConfig: ColumnConfig | undefined
) => {
	const prefix = columnConfig?.valueFormat?.prefix;
	const suffix = columnConfig?.valueFormat?.suffix;
	const { adjustedValue, abbrevSymbol } = getAbbreviation(
		value,
		columnConfig?.valueFormat?.abbreviation ?? NumberFormatAbbreviation.NONE
	);
	const useThousandsSeparator =
		columnConfig?.valueFormat?.useThousandsSeparator ?? false;
	const thousandsSeparatorFormat =
		columnConfig?.valueFormat?.thousandsSeparatorFormat ??
		ThousandsSeparatorFormat.COMMA_PERIOD;
	const intlOptions = getIntlNumberFormatOptions(columnConfig?.valueFormat);

	const intlFormattedValue = Intl.NumberFormat(
		// convert to en-US first before applying custom thousands separator
		useThousandsSeparator ? 'en-US' : undefined,
		intlOptions
	).format(adjustedValue);
	const formattedValue = useThousandsSeparator
		? applyThousandsSeparator(intlFormattedValue, thousandsSeparatorFormat)
		: intlFormattedValue;
	return {
		value: formattedValue,
		options: {
			prefix,
			suffix,
			abbreviation: abbrevSymbol,
		},
	};
};

export const FormattedNumberRenderer: CellRendererComponent = (props) => {
	const {
		columnConditions,
		columnConfig,
		value,
		intl,
		data,
		aggregateResults,
	} = props;

	let conditionPassed;
	if (columnConditions) {
		const parserFn = new Parser();
		Object.keys(data).forEach((key) => {
			parserFn.setVariable(`${parseColumnName(key)}`, data[key]);
		});

		if (aggregateResults)
			Object.keys(aggregateResults).forEach((key) => {
				parserFn.setVariable(`${parseColumnName(key)}`, aggregateResults[key]);
			});
		parserFn.setFunction('ISEMPTY', (val: string[]) => !val[0].toString());
		conditionPassed = columnConditions.filter((condition) => {
			const prasedFormula = parseAggregationFormula(
				parseFormula(condition.formula)
			);
			if (condition.applyToOptions === 'values')
				return parserFn.parse(prasedFormula.replace('Source.', '')).result;
			return false;
		});
	}
	return (
		<span>
			{!isNil(value)
				? formattedNumberString(
						value,
						conditionPassed?.length ? conditionPassed[0] : columnConfig,
						intl
				  )
				: ''}
		</span>
	);
};

export const formattedNumberString = (
	value: number,
	columnConfig: ColumnConfig | undefined,
	intl: InjectedIntl
) => {
	const numberFormatType =
		columnConfig?.valueFormat?.formatNumberOption ?? NumberFormatOption.NUMBER;
	const {
		value: formattedValue,
		options: { prefix, suffix, abbreviation },
	} = formattedNumberHelper(value, columnConfig);

	switch (numberFormatType) {
		case NumberFormatOption.CURRENCY:
			return intl.formatMessage(messages.NUMBER_FORMAT_OPTION_CURRENCY, {
				value: formattedValue,
				abbreviation,
			});
		case NumberFormatOption.PERCENTAGE:
			return intl.formatMessage(messages.NUMBER_FORMAT_OPTION_PERCENTAGE, {
				value: formattedValue,
			});
		case NumberFormatOption.CUSTOM:
			return intl.formatMessage(messages.NUMBER_FORMAT_OPTION_CUSTOM, {
				value: formattedValue,
				abbreviation,
				prefix,
				suffix,
			});
		case NumberFormatOption.NUMBER:
		default:
			return intl.formatMessage(messages.NUMBER_FORMAT_OPTION_NUMBER, {
				value: formattedValue,
				abbreviation,
			});
	}
};

export const FormattedDateRenderer: CellRendererComponent = (props) => {
	const { columnConfig, value, intl } = props;
	if (typeof value !== 'number') {
		// need to return empty string, not null. Due to ag-grid: https://www.ag-grid.com/react-hooks/#react-null
		return <span>{'' as any}</span>;
	}
	return <span>{formattedDateString(value, columnConfig, intl)}</span>;
};

export const formattedDateString = (
	value: number,
	columnConfig: ColumnConfig | undefined,
	intl: InjectedIntl
) => {
	const dateFormatType = columnConfig?.valueFormat?.formatDateOption;
	switch (dateFormatType) {
		case DateFormatOption.MM_DD_YY:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_MM_DD_YY, {
				day: Intl.DateTimeFormat(undefined, {
					day: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: '2-digit',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.DD_MM_YY:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_DD_MM_YY, {
				day: Intl.DateTimeFormat(undefined, {
					day: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: '2-digit',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.DD_MM_YYYY:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_DD_MM_YYYY, {
				day: Intl.DateTimeFormat(undefined, {
					day: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: 'numeric',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.YY_MM_DD:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_YY_MM_DD, {
				day: Intl.DateTimeFormat(undefined, {
					day: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: '2-digit',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.YYYY_MM_DD:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_YYYY_MM_DD, {
				day: Intl.DateTimeFormat(undefined, {
					day: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: 'numeric',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.MMMM_D_YYYY:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_MMMM_D_YYYY, {
				day: Intl.DateTimeFormat(undefined, {
					day: 'numeric',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: 'long',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: 'numeric',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.D_MMMM_YYYY:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_D_MMMM_YYYY, {
				day: Intl.DateTimeFormat(undefined, {
					day: 'numeric',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: 'long',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: 'numeric',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.D_MMM_YYYY:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_D_MMM_YYYY, {
				day: Intl.DateTimeFormat(undefined, {
					day: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: 'short',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: 'numeric',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.MMM_D_YYYY:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_MMM_D_YYYY, {
				day: Intl.DateTimeFormat(undefined, {
					day: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: 'short',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: 'numeric',
					timeZone: 'UTC',
				}).format(value),
			});
		case DateFormatOption.MM_DD_YYYY:
		default:
			return intl.formatMessage(messages.DATE_FORMAT_OPTION_MM_DD_YYYY, {
				day: Intl.DateTimeFormat(undefined, {
					day: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				month: Intl.DateTimeFormat(undefined, {
					month: '2-digit',
					timeZone: 'UTC',
				}).format(value),
				year: Intl.DateTimeFormat(undefined, {
					year: 'numeric',
					timeZone: 'UTC',
				}).format(value),
			});
	}
};

export const setRowHeightToTallestCellHeight = (
	rowNode: RowNode,
	cellInstances: (ICellRendererComp | GroupCellRenderer)[]
) => {
	const clientHeights =
		cellInstances?.map(
			(cellRenderer: ICellRendererComp | GroupCellRenderer) => {
				const isGroupCell = cellRenderer instanceof GroupCellRenderer;
				let element = cellRenderer.getGui().firstChild as Element;
				if (isGroupCell) {
					element = cellRenderer.getGui().children[3] as Element;
				}
				const isEditable = element.className.includes('bp3-input-group');
				const height = element.clientHeight
					? isEditable
						? element.clientHeight + ROW_BORDER_THICKNESS * 2
						: element.clientHeight +
						  ROW_BOTTOM_PADDING +
						  ROW_TOP_PADDING +
						  ROW_BORDER_THICKNESS * 2
					: 0;
				return height;
			}
		) ?? [];
	const tallestRowTextHeight = Math.max(
		Math.max(...clientHeights),
		DEFAULT_ROW_HEIGHT
	);
	if (Number.isFinite(tallestRowTextHeight)) {
		rowNode.setRowHeight(tallestRowTextHeight);
	}
};

export const isTextWrappingActive = (
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridDTO
) => {
	return (
		config.columns
			.concat(config.columnGroups ?? [])
			.find((col) => col.extra?.wrapText) !== undefined
	);
};
