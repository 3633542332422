/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	defaultFontColor,
	simplifiedFormatNumber,
	getCurrencySymbol,
	invertedFontColor,
	messages,
	isEmptyOrNil,
} from '../chartUtil';

export const bulletChartOptions = (
	props: ChartOptionArg & {
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartBulletDTO;
	}
) => {
	const { config, existingOptions, data, intl, invertFontColors } = props;
	const {
		actualColumn,
		targetColumn,
		minimumColumn,
		mediumColumn,
		maximumColumn,
		legendPosition,
		showLegend,
		labelPosition,
		showDataValues,
		showDataLabels,
		showDataPercentages,
		valueFormat,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		gaugeAxisTitle,
		hideGaugeAxisTitle,
		useCustomRange,
		minRangeMin,
		minRangeMax,
		medRangeMin,
		medRangeMax,
		maxRangeMin,
		maxRangeMax,
		useCustomTarget,
		customTarget,
		actualColor,
		targetColor,
		minimumColor,
		mediumColor,
		maximumColor,
	} = config;

	const type = 'bullet';

	const selectedActualColor = actualColor ?? '#061178';
	const selectedTargetColor = targetColor ?? '#282828';
	const selectedMinimumColor = minimumColor ?? '#85A5FF';
	const selectedMediumColor = mediumColor ?? '#ADC6FF';
	const selectedMaximumColor = maximumColor ?? '#D6E4FF';

	const dataRow = data.rows[0] ?? {};
	const target = useCustomTarget ? Number(customTarget) : dataRow[targetColumn];
	const useDataPercentages = showDataPercentages && target && target !== 0;

	const currencyCode = useDataPercentages
		? undefined
		: getCurrencyCode(valueFormat);
	const currencySymbol = useDataPercentages
		? '%'
		: getCurrencySymbol(currencyCode);

	const formatValue: (value: number) => number = (value) => {
		return useDataPercentages ? (value / target) * 100 : value;
	};

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const yValue = simplifiedFormatNumber({
				value: this.y ?? 0,
				config,
			});

			if (target) {
				const targetLabel = `${intl.formatMessage(messages.target)}${
					currencySymbol ? ` (${currencySymbol})` : ''
				}`;
				const targetFormatted = simplifiedFormatNumber({
					value: target,
					config,
				});
				return `
				${this.x}: <strong>${yValue}</strong><br/>
				${targetLabel}: <strong>${targetFormatted}</strong>`;
			}
			return `${this.x}: <strong>${yValue}</strong>`;
		},
		followPointer: true,
	};

	const plotOptions: Highcharts.PlotOptions = {
		bullet: {
			borderWidth: 0,
			targetOptions: {
				width: '200%',
				color: selectedTargetColor,
				borderWidth: 0,
			},
		},
	};

	const xAxis: Highcharts.XAxisOptions = {
		categories: [
			hideGaugeAxisTitle
				? ''
				: `${gaugeAxisTitle || actualColumn}${
						currencySymbol ? ` (${currencySymbol})` : ''
				  }`,
		],
	};

	const showYAxisMin = showYMinMax && !isEmptyOrNil(yAxisMin);
	const showYAxisMax = showYMinMax && !isEmptyOrNil(yAxisMax);
	const minRange = useCustomRange
		? {
				min: !isEmptyOrNil(minRangeMin) ? formatValue(Number(minRangeMin)) : 0,
				max: !isEmptyOrNil(minRangeMax)
					? formatValue(Number(minRangeMax))
					: undefined,
		  }
		: { min: 0, max: formatValue(Number(dataRow[minimumColumn])) ?? 0 };
	const medRange = useCustomRange
		? {
				min: !isEmptyOrNil(medRangeMin)
					? formatValue(Number(medRangeMin))
					: undefined,
				max: !isEmptyOrNil(medRangeMax)
					? formatValue(Number(medRangeMax))
					: undefined,
		  }
		: {
				min: minRange.max,
				max: formatValue(Number(dataRow[mediumColumn])) ?? minRange.max,
		  };
	const maxRange = useCustomRange
		? {
				min: !isEmptyOrNil(maxRangeMin)
					? formatValue(Number(maxRangeMin))
					: undefined,
				max: !isEmptyOrNil(maxRangeMax)
					? formatValue(Number(maxRangeMax))
					: Number.MAX_VALUE,
		  }
		: {
				min: medRange.max,
				max: formatValue(Number(dataRow[maximumColumn])) ?? medRange.max,
		  };

	const yAxis: Highcharts.YAxisOptions = {
		gridLineWidth: 0,
		min: showYAxisMin ? formatValue(Number(yAxisMin)) : null,
		max: showYAxisMax ? formatValue(Number(yAxisMax)) : null,
		plotBands: [
			{
				from: minRange.min,
				to: minRange.max,
				color: selectedMinimumColor,
			},
			{
				from: medRange.min,
				to: medRange.max,
				color: selectedMediumColor,
			},
			{
				from: maxRange.min,
				to: maxRange.max,
				color: selectedMaximumColor,
			},
		],
		title: undefined,
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? false,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		// since we are adding non-series items to the legend, mask hidden styling
		itemHiddenStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormat: '{name}',
		layout:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? 'vertical'
				: 'horizontal',
		align:
			legendPosition &&
			(legendPosition === 'left' || legendPosition === 'right')
				? legendPosition
				: 'center',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: legendPosition === 'left' || legendPosition === 'right'
				? 'middle'
				: 'bottom',
	};

	const seriesOptions = [] as Highcharts.SeriesOptionsType[];

	const series: Highcharts.SeriesOptionsType = {
		type: type as any,
		data: [
			{
				y: formatValue(dataRow[actualColumn]),
				target: formatValue(target),
			},
		],
		dataLabels: {
			enabled: showDataValues ?? false,
			style: {
				fontWeight: 'normal',
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			align: labelPosition === 'insideend' ? 'right' : 'left',
			formatter() {
				if (showDataLabels === false) {
					return '';
				}
				return simplifiedFormatNumber({
					value: this.y ?? 0,
					config,
				});
			},
		},
		color: selectedActualColor,
		name: gaugeAxisTitle || actualColumn,
		// since we are adding non-series items to the legend, disable click feature
		events: {
			legendItemClick: () => {
				return false;
			},
		},
	};
	seriesOptions.push(series);

	// set of dummy series for the legend
	const minSeries: Highcharts.SeriesBulletOptions = {
		type: type as any,
		dataLabels: {},
		showInLegend: true,
		name: intl.formatMessage(messages.minimumRange),
		visible: false,
		color: selectedMinimumColor,
		colorByPoint: false,
		// since we are adding non-series items to the legend, disable click feature
		events: {
			legendItemClick: () => {
				return false;
			},
		},
	};
	seriesOptions.push(minSeries);

	const medSeries: Highcharts.SeriesBulletOptions = {
		...minSeries,
		name: intl.formatMessage(messages.mediumRange),
		color: selectedMediumColor,
	};
	seriesOptions.push(medSeries);

	const maxSeries: Highcharts.SeriesBulletOptions = {
		...minSeries,
		name: intl.formatMessage(messages.maximumRange),
		color: selectedMaximumColor,
	};
	seriesOptions.push(maxSeries);

	const targetSeries: Highcharts.SeriesBulletOptions = {
		...minSeries,
		name: intl.formatMessage(messages.target),
		color: selectedTargetColor,
	};
	seriesOptions.push(targetSeries);

	const options = {
		...existingOptions,
		colorAxis: undefined,
		chart: {
			...existingOptions.chart,
			inverted: true,
		} as Highcharts.ChartOptions,
		xAxis,
		yAxis,
		plotOptions,
		tooltip,
		legend,
		series: seriesOptions,
	};
	return options;
};
