/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { css, cx } from 'emotion';
import {
	Button,
	FormGroup,
	InputGroup,
	Intent,
	Position,
	TextArea,
	Toaster,
} from '@blueprintjs/core';
import { colorRed3, Icon, DateInput } from '@varicent/components';
import { Calendar20 } from '@carbon/icons-react';
import { FlexComponentTypes } from '../componentTypes';
import {
	insertDataForPayee,
	insertDataForAdmin,
} from 'icm-rest-client/lib/controllers/presenterFlex';
import Placeholder from '../utils/placeholder';
import { useSelector } from 'react-redux';
import { Varicent } from 'icm-rest-client';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { LiveDataPayeeContext, ReportContext, ValueStore } from '../context';
import usePrevious from 'react-use/esm/usePrevious';
import { indexBy, prop, isNil, isEmpty } from 'ramda';
import styled from 'react-emotion';
import { FormikErrors, useFormik } from 'formik';
import {
	evaluateDateValue,
	evaluateNumericValue,
	evaluateTextValue,
} from '../utils/validationRuleHelpers';
import {
	getUTCDateWithoutTimezoneShift,
	getLocalDateWithoutTimezoneShift,
} from 'icm-core/lib/utils/dateInputUtils';
import { defineMessages } from 'react-intl';
import GridPicklist from './gridPicklist';
import { useMarkSourceForUpdate } from './componentHooks';
import { useLivePreviewInfo } from '../utils/useDataSource';
import { invertedFontColor } from './chart/chartUtil';
import { SafeNumericInput } from 'icm-core/lib/components/safeNumericInput';

const messages = defineMessages({
	noUser: { id: 'components.text.text.noUser', defaultMessage: 'No user' },
	inputFormTitle: {
		id: 'components.rowinputform.title',
		defaultMessage: 'Row input form {number}',
	},
	inputFormSubmitText: {
		id: 'components.rowinputform.submitText',
		defaultMessage: 'Submit',
	},
	inputFormSuccessMessage: {
		id: 'components.rowinputform.successMessage',
		defaultMessage: 'Success',
	},
	noEmpyKeyCol: {
		id: 'components.rowinputform.noempykeycol',
		defaultMessage: 'Key column cannot be empty',
	},
});

const { DefaultType: DefaultColumnValueType } =
	Varicent.Domain.PresenterFlex.PresenterFlexComponentRowInputForm.Column;

const Form = styled.form`
	flex: 1 1 auto;
	overflow: auto;
`;

type BaseProps = {
	sourceSchema: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO | undefined;
	source:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO
		| undefined;
	reportId?: number | string;
};

type IProps = {
	componentId: Varicent.ID;
	preview?: boolean;
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentRowInputFormDTO;
} & BaseProps;

type IColumnInput = {
	columnName: string;
	isAutoGenerated: boolean;
	value: string;
};

type IForm = {
	[columnName: string]: {
		columnName: string;
		isAutoGenerated: boolean;
		value: any;
	};
};

const TopRightToaster = Toaster.create({
	position: Position.TOP_RIGHT,
	className: css`
		margin-top: 64px;
	`,
});

export default function RowInputForm({
	reportId,
	source,
	config,
	sourceSchema,
	componentId,
	preview,
}: IProps) {
	const intl = useIntl();
	const markSourcesForUpdate = useMarkSourceForUpdate();
	const schemaColumns = useMemo(
		() => sourceSchema?.columns ?? [],
		[sourceSchema?.columns]
	);
	const configColumns = useMemo(() => config.columns ?? [], [config.columns]);
	const {
		components,
		sourceSchemas,
		sources,
		metadata,
		values: presFlexValues,
	} = useContext(ReportContext);

	const secondaryIDs = useMemo(
		() => new Set(config.columns.map((col) => col.sourceID)),
		[config.columns]
	);

	const secondarySources = indexBy(
		(item) => item.sourceId.toString(),
		(sources ?? []).filter((s) => secondaryIDs.has(s.sourceId))
	);

	const reportValues = useContext(ValueStore);

	const { isLiveDataActive, isUsingLiveData } = useLivePreviewInfo({ preview });

	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);
	const previousReportVals = usePrevious(reportValues);
	const userID =
		useSelector((state: any) => state?.user?.payeeId) ??
		intl.formatMessage(messages.noUser);
	const [submitError, setSubmitError] = useState<string | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const prevIsSubmitting = usePrevious(isSubmitting);
	const schemaColumnsMap = useMemo(
		() => indexBy(prop('name'), schemaColumns),
		[schemaColumns]
	);
	const initialFormData: IForm = configColumns.reduce(
		(result, { columnName, defaultValue }) => ({
			...result,
			[columnName]: {
				columnName,
				isAutoGenerated: defaultValue === DefaultColumnValueType.AutoGenerate,
				value: getDefaultValue(defaultValue),
			},
		}),
		{}
	);
	const formatValue = (
		columnType: string,
		value: Date | number | string | null
	) => {
		switch (columnType) {
			case Varicent.Domain.Schema.DbColumnType.Decimal:
				return value ?? 0;
			case Varicent.Domain.Schema.DbColumnType.Date:
			case Varicent.Domain.Schema.DbColumnType.DateTime:
				return value === null
					? null
					: getUTCDateWithoutTimezoneShift(
							(value as Date).toISOString()
					  ).toISOString();
			case Varicent.Domain.Schema.DbColumnType.LongString:
			case Varicent.Domain.Schema.DbColumnType.String:
			default:
				return value === null || (value as string).trim().length === 0
					? null
					: value;
		}
	};
	const formStateToDataInsertDTO: (
		formData: IForm
	) => Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexDataInsertParamsDTO = (
		formData
	) => {
		const values: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexDataInsertValueDTO[] =
			Object.values(formData)
				.filter(
					(field) =>
						// remove non key columns with null values
						schemaColumnsMap[field.columnName].isKey || field.value !== null
				)
				.map((field) => {
					const { type } = schemaColumnsMap[field.columnName];
					if (
						type === Varicent.Domain.Schema.DbColumnType.Decimal &&
						field.value === null
					) {
						return {
							...field,
							value: 0,
						};
					}
					return field;
				});
		const dto: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexDataInsertParamsDTO =
			{
				componentId: componentId as number,
				dataInserts: { values },
			};
		return dto;
	};
	const validateFormData = (newFormData: IForm, firstRender = false) => {
		let errors: FormikErrors<IForm> = {};
		const inputRules = config.dataEditOptions?.inputRules ?? [];
		for (const columnName of Object.keys(newFormData)) {
			const { value } = newFormData[columnName];
			if (newFormData[columnName].isAutoGenerated)
				// Do not validate auto generated columns
				continue;
			// Key column
			const schemaCol = schemaColumnsMap[columnName];
			if (schemaCol && schemaCol.isKey && isNil(value) && !firstRender)
				addError(intl.formatMessage(messages.noEmpyKeyCol), columnName);
			// Input rules
			const colInputRules = inputRules.filter(
				(r) => r.columnName === columnName
			);
			for (let i = 0; i < colInputRules.length; i++) {
				const rule = colInputRules[i];
				if (
					!evaluateValue(value, rule, schemaColumnsMap[columnName]?.type, {
						currentWebUserId: userID,
						valuesMap: reportValues,
						values: presFlexValues,
						utc: true,
					})
				) {
					addError(rule.errorMessage, columnName);
					continue;
				}
			}
		}
		return isEmpty(errors) ? undefined : errors;

		function addError(error: string, columnName: string) {
			errors = {
				...errors,
				[columnName]: {
					...errors[columnName],
					value: error,
				},
			};
		}
	};
	const { values, setValues, submitForm, isValid, dirty, errors } = useFormik({
		initialValues: initialFormData,
		initialErrors: validateFormData(initialFormData, true),
		validate: (newFormData) => validateFormData(newFormData),
		onSubmit: async (formData, { resetForm }) => {
			if (!isNil(reportId) && !isNil(source)) {
				try {
					setIsSubmitting(true);
					if (previewPayeeId) {
						await insertDataForAdmin(
							reportId,
							source.sourceId,
							formStateToDataInsertDTO(formData),
							{
								payeeId: previewPayeeId,
							},
							{ preventDefaultRESTAPIError: true } as any
						);
					} else {
						await insertDataForPayee(
							reportId,
							source.sourceId,
							formStateToDataInsertDTO(formData),
							{ preventDefaultRESTAPIError: true } as any
						);
					}
					if (sourceSchema) {
						markSourcesForUpdate(sourceSchema.table);
					}
					TopRightToaster.show({
						intent: 'success',
						message: intl.formatMessage(messages.inputFormSuccessMessage),
					});
					setSubmitError(null);
					resetForm();
				} catch (e) {
					TopRightToaster.show({
						intent: 'danger',
						message: e.response.data.Message,
					});
					setSubmitError(e.response.data.Message);
				} finally {
					setIsSubmitting(false);
				}
			}
		},
	});

	function getDefaultValue(defaultValue) {
		switch (defaultValue) {
			case Varicent.Domain.PresenterFlex.PresenterFlexSourceFilter.Value.Special
				.CurrentWebUser:
				return previewPayeeId ?? userID;
			case Varicent.Domain.PresenterFlex.PresenterFlexSourceFilter.Value.Special
				.CurrentDate:
				return getUTCDateWithoutTimezoneShift(
					new Date().toISOString()
				).toISOString();
			default:
				return null;
		}
	}

	useEffect(() => {
		// init/update default flex values on load/change
		const changed =
			!previousReportVals ||
			(prevIsSubmitting && !isSubmitting && isNil(submitError))
				? Object.keys(reportValues)
				: Object.keys(reportValues).filter(
						(key) => reportValues[key] !== previousReportVals[key]
				  );
		if (changed.length > 0) {
			const updates = {};
			configColumns.forEach(({ columnName, valueID, date }) => {
				if (
					!isNil(valueID) &&
					changed.findIndex((id) => id === valueID?.toString()) !== -1
				) {
					const value = reportValues[valueID];
					const formatedValue =
						schemaColumnsMap[columnName].type === 'Date' ||
						schemaColumnsMap[columnName].type === 'DateTime'
							? new Date(+value).toISOString()
							: value;
					updates[columnName] = {
						isAutoGenerated: false,
						columnName,
						value: formatedValue,
					};
				} else if (date) {
					updates[columnName] = {
						isAutoGenerated: false,
						columnName,
						value: date,
					};
				}
			});
			if (Object.keys(updates).length > 0)
				setValues((prev) => ({ ...prev, ...updates }));
		}
	}, [
		configColumns,
		previousReportVals,
		reportValues,
		setValues,
		isSubmitting,
		prevIsSubmitting,
		submitError,
	]);

	const handleChange = (
		columnName: string,
		value: Date | string | number | null
	) => {
		setValues({
			...values,
			[columnName]: {
				...values[columnName],
				value: formatValue(schemaColumnsMap[columnName].type, value),
			},
		});
	};

	const inputForms = components?.filter((c) => c.config.rowInputForm);
	const inputFormNumber =
		(inputForms?.findIndex((form) => form.componentId === componentId) ?? 0) +
		1;
	// header styling
	const title = config?.extra?.headerStyle?.title;
	const headerBackgroundColor =
		config?.extra?.headerStyle?.backgroundColor ?? '#FFFFFF';
	const headerStyleMode = config?.extra?.headerStyle?.styleMode ?? 'auto';
	const headerFontSize = config?.extra?.headerStyle?.fontSize ?? '14';
	const headerFontColor = config?.extra?.headerStyle?.fontColor ?? '#000000';
	const headerTextBold = config?.extra?.headerStyle?.textBold ?? false;
	const headerTextItalic = config?.extra?.headerStyle?.textItalic ?? false;
	const headerTextUnderline =
		config?.extra?.headerStyle?.textUnderline ?? false;
	// button styling
	const buttonText = config?.extra?.buttonStyle?.text;
	const buttonButtonColor = config.extra?.buttonStyle?.buttonColor ?? '#2B4FF4';
	const buttonBackgroundColor =
		config?.extra?.buttonStyle?.backgroundColor ?? '#FFFFFF';
	const buttonStyleMode = config?.extra?.buttonStyle?.styleMode ?? 'auto';
	const buttonFontColor = config?.extra?.buttonStyle?.fontColor ?? '#FFFFFF';
	const buttonFontSize = config?.extra?.buttonStyle?.fontSize ?? '16';
	const buttonTextBold = config?.extra?.buttonStyle?.textBold ?? false;
	const buttonTextItalic = config?.extra?.buttonStyle?.textItalic ?? false;
	const buttonTextUnderline =
		config?.extra?.buttonStyle?.textUnderline ?? false;
	const buttonPosition = config?.extra?.buttonStyle?.alignment?.h ?? 'left';
	// general styling
	const generalhideBackground = config?.extra?.generalStyle?.hideTile ?? false;
	const generalBackgroundColor =
		config?.extra?.generalStyle?.backgroundColor ?? '#FFFFFF';
	// other metadata
	const hideAllTile = metadata?.extra?.hideAllTile;
	const invertFontColors = metadata?.extra?.invertFontColors;

	return !source ||
		configColumns.length === 0 ||
		(preview && isUsingLiveData && !isLiveDataActive) ? (
		<Placeholder type={FlexComponentTypes.rowInputForm} />
	) : (
		<div
			className={cx('card', 'inputform-card')}
			css={css`
				display: flex;
				flex-direction: column;
				flex: 1 1 auto;
				overflow: hidden;
				padding: 0 !important;
				border-radius: 0.25rem;
				background-color: ${generalhideBackground || hideAllTile
					? 'transparent'
					: generalBackgroundColor} !important;
				${hideAllTile && `box-shadow: none !important;`}
				${invertFontColors && `color: ${invertedFontColor} !important;`}
			`}
		>
			<div
				css={css`
					padding: 1rem;
					background-color: ${hideAllTile
						? 'transparent'
						: headerBackgroundColor};

					${headerStyleMode === 'custom'
						? `
							color: ${headerFontColor};
							font-size: ${headerFontSize}px;
							${
								headerTextBold
									? `
									font-weight: 700;
								`
									: `
								`
							}
							${
								headerTextItalic
									? `
									font-style: italic;
								`
									: `
								`
							}
							${
								headerTextUnderline
									? `
									text-decoration: underline;
								`
									: `
								`
							}
							`
						: `
							font-size: 14px;
							`}
				`}
			>
				{title ||
					intl.formatMessage(messages.inputFormTitle, {
						number: inputFormNumber,
					})}
			</div>
			<Form>
				<div
					css={css`
						height: 100%;
						padding: 1rem;
						background-color: ${generalhideBackground || hideAllTile
							? 'transparent'
							: generalBackgroundColor} !important;
					`}
				>
					{configColumns
						.filter(
							({ defaultValue }) =>
								defaultValue !== DefaultColumnValueType.AutoGenerate
						)
						.map((col) => {
							const inputStyle = config?.extra?.inputStyle;
							const error = errors ? errors[col.columnName]?.value : undefined;
							const reference =
								sourceSchema?.referencedSourcesDictionary[col.columnName];
							const styleMode = inputStyle?.styleMode ?? 'auto';
							const fontColor = inputStyle?.fontColor ?? '#000000';
							const fontSize = inputStyle?.fontSize ?? '12';
							const textBold = inputStyle?.textBold ?? false;
							const textItalic = inputStyle?.textItalic ?? false;
							const textUnderline = inputStyle?.textUnderline ?? false;
							const width = inputStyle?.inputWidth ?? 100;
							return (
								<FormGroup
									key={col.columnName}
									label={col.displayName}
									labelFor={col.columnName}
									helperText={!preview && error ? error : col.description}
									intent={!preview && error ? 'danger' : undefined}
									className={css`
										.bp3-form-helper-text {
											font-size: ${styleMode === 'custom' ? fontSize : '12'}px;
											width: ${width}%;
											word-break: break-all;
										}
										${preview && !isUsingLiveData && `pointer-events: none;`}
										${styleMode === 'custom'
											? `
											label.bp3-label {
												color: ${fontColor};
												font-size: ${fontSize}px;
												${
													textBold
														? `
														font-weight: 700;
														`
														: ''
												}
												${
													textItalic
														? `
														font-style: italic;
														`
														: ''
												}
												${
													textUnderline
														? `
														text-decoration: underline;
														`
														: ''
												}
											}`
											: 'font-size: 12px;'}
									`}
								>
									{reference && col.sourceID ? (
										<RowInputPicklist
											intent={!preview && error ? 'danger' : undefined}
											width={width}
											sourceSchema={sourceSchemas?.[col.sourceID]}
											source={secondarySources[col.sourceID]}
											preview={preview}
											previewPayeeId={previewPayeeId}
											reportId={reportId}
											column={col}
											value={values[col.columnName] ?? {}}
											handleChange={handleChange}
										/>
									) : (
										<ColumnInput
											source={source}
											sourceSchema={sourceSchema}
											schemaColumn={schemaColumnsMap[col.columnName]}
											column={col}
											handleChange={handleChange}
											value={values[col.columnName] ?? {}}
											inputStyle={inputStyle}
										/>
									)}
								</FormGroup>
							);
						})}
				</div>
			</Form>
			<div
				css={css`
					display: flex;
					flex-direction: column;
					padding: 1rem;
					background-color: ${hideAllTile
						? 'transparent'
						: buttonBackgroundColor};

					.bp3-button.bp3-intent-primary {
						background-color: ${buttonButtonColor};
						${buttonStyleMode === 'custom'
							? `
							color: ${buttonFontColor};
							font-size: ${buttonFontSize}px;
							.bp3-button-text {
								${
									buttonTextBold
										? `
										font-weight: 700;
									`
										: `
									`
								}
								${
									buttonTextItalic
										? `
										font-style: italic;
									`
										: `
									`
								}
								${
									buttonTextUnderline
										? `
										text-decoration: underline;
									`
										: `
									`
								}
							}
							`
							: `
							`}
					}
				`}
			>
				{submitError && (
					<div
						className="bp3-form-helper-text"
						css={css`
							color: rgb(${colorRed3});
							margin-bottom: 16px;
						`}
					>
						{submitError}
					</div>
				)}
				<div
					css={css`
						display: flex;
						flex-direction: row;
						justify-content: ${getFlexPosition(buttonPosition)};
						${preview && !isUsingLiveData && `pointer-events: none;`}
					`}
				>
					<Button
						onClick={!preview ? submitForm : undefined}
						intent="primary"
						disabled={
							(!preview && (!dirty || !isValid || isSubmitting)) ||
							(preview && isUsingLiveData)
						}
					>
						{buttonText || intl.formatMessage(messages.inputFormSubmitText)}
					</Button>
				</div>
			</div>
		</div>
	);
}

type BaseFieldProps = {
	column: Varicent.RESTAPI.v1.DTOs.PresenterFlex.RowInputColumnDTO;
	handleChange: (column: string, value: any) => void;
	value: IColumnInput;
};

function RowInputPicklist({
	sourceSchema,
	source,
	reportId,
	preview,
	previewPayeeId,
	column,
	handleChange: update,
	value,
	width,
	intent,
}: BaseFieldProps &
	BaseProps & {
		preview?: boolean;
		previewPayeeId?: Varicent.ID;
		width: number;
		intent?: Intent;
	}) {
	return (
		<>
			{column.picklistConfig && (
				<GridPicklist
					intent={intent}
					width={width}
					config={column.picklistConfig}
					reportId={reportId}
					source={source}
					preview={preview}
					previewPayeeId={previewPayeeId}
					sourceSchema={sourceSchema}
					update={(val) => update(column.columnName, val)}
					value={value.value ?? ''}
					placeholder={column.placeholder}
				/>
			)}
		</>
	);
}

type IRowInput = {
	schemaColumn: Varicent.RESTAPI.v1.DTOs.Schema.ColumnSchemaDTO | undefined;
	inputStyle: any;
} & BaseFieldProps &
	BaseProps;

function ColumnInput({
	schemaColumn,
	column,
	handleChange: update,
	value,
	inputStyle,
}: IRowInput) {
	const styleSize = inputStyle?.inputSize ?? 'NORMAL';
	const width = inputStyle?.inputWidth ?? 100;

	return (
		<>
			{schemaColumn &&
				(() => {
					switch (schemaColumn.type) {
						case Varicent.Domain.Schema.DbColumnType.LongString:
							return (
								<TextArea
									className={css`
										.bp3-input {
											width: ${width}%;
										}
									`}
									id={column.columnName}
									value={value.value ?? ''}
									placeholder={column.placeholder}
									name={column.columnName}
									onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
										update(column.columnName, e.target.value)
									}
									large={styleSize === 'LARGE'}
									small={styleSize === 'SMALL'}
								/>
							);
						case Varicent.Domain.Schema.DbColumnType.Decimal:
							return (
								<SafeNumericInput
									className={css`
										.bp3-input-group {
											width: ${width}%;
										}
										${styleSize === 'SMALL'
											? `
												.bp3-button-group.bp3-vertical {
													height: 22px;
												}
												.bp3-input {
													font-size: 12px;
													height: 24px;
													line-height: 24px;
													padding-left: 8px;
													padding-right: 8px;
												}
											`
											: `
										`}
										${styleSize === 'LARGE'
											? `
												.bp3-button-group.bp3-vertical {
													color: #000000;
													height: 38px;
												}
												.bp3-input {
													height: 2.5rem;
													padding: 0.625rem 1rem;
												}
											`
											: `
										`}
									`}
									id={column.columnName}
									safeValue={value.value ?? ''}
									name={column.columnName}
									onSafeValueChange={(num) => {
										update(column.columnName, Number(num));
									}}
									minorStepSize={0.1}
									placeholder={column.placeholder}
									min={-99999999999999}
									max={99999999999999}
									updateLocalOnValuechange
								/>
							);
						case Varicent.Domain.Schema.DbColumnType.Date:
							return (
								<DateInput
									className={css`
										.bp3-popover-target {
											width: ${width}%;
											.bp3-input {
												width: 100%;
											}
										}
										${styleSize === 'SMALL'
											? `
											.bp3-icon {
												margin: 0.25rem;
											}
											.bp3-button-group,
											.bp3-input {
												font-size: 12px;
												height: 24px;
												line-height: 24px;
												padding-left: 8px;
												padding-right: 8px;
											}
											.bp3-input-group .bp3-input:not(:first-child) {
												padding-left: 1.5rem;
											}
											`
											: ''}
										${styleSize === 'LARGE'
											? `
											.bp3-icon {
												margin: 0.75rem;
											}
											.bp3-button-group,
											.bp3-input {
												height: 2.5rem;
												padding: 0.625rem 1rem;
											}
											`
											: ''}
									`}
									placeholder={column.placeholder}
									formatDate={(date) => date.toLocaleDateString()}
									parseDate={(str) => new Date(str)}
									closeOnSelection
									inputProps={{
										leftIcon: (
											<Icon>
												<Calendar20 />
											</Icon>
										),
									}}
									popoverProps={{
										fill: true,
										boundary: 'viewport',
										position: 'bottom',
									}}
									value={
										value.value
											? getLocalDateWithoutTimezoneShift(value.value)
											: null
									}
									onChange={(date) => update(column.columnName, date)}
									minDate={new Date('1900-01-01')}
									maxDate={new Date('9999-01-01')}
								/>
							);
						case Varicent.Domain.Schema.DbColumnType.String:
						default:
							return (
								<InputGroup
									className={css`
										.bp3-input {
											width: ${width}%;
										}
									`}
									id={column.columnName}
									value={value.value ?? ''}
									placeholder={column.placeholder}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										update(column.columnName, e.target.value)
									}
									large={styleSize === 'LARGE'}
									small={styleSize === 'SMALL'}
								/>
							);
					}
				})()}
		</>
	);
}

const getFlexPosition = (position: string) => {
	switch (position) {
		case 'left':
			return 'flex-start';
		case 'right':
			return 'flex-end';
		case 'center':
		default:
			return 'center';
	}
};

const evaluateValue = (
	userValue: number | string | null,
	rule: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO,
	type: Varicent.Domain.Schema.DbColumnType,
	options: {
		currentWebUserId?: number;
		valuesMap?: Record<number, any>;
		values?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[];
		utc?: boolean;
	}
) => {
	switch (type) {
		case Varicent.Domain.Schema.DbColumnType.Date:
			return evaluateDateValue(
				userValue === null ? null : new Date(userValue as string),
				rule,
				options
			);
		case Varicent.Domain.Schema.DbColumnType.Decimal:
			return evaluateNumericValue(userValue as number | null, rule, options);
		case Varicent.Domain.Schema.DbColumnType.LongString:
		case Varicent.Domain.Schema.DbColumnType.String:
		default:
			return evaluateTextValue(userValue as string | null, rule, options);
	}
};
