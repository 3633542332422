/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { identity, isEmpty, map, pipe, sortBy } from 'ramda';
import { generateColorRange } from '../../../utils/colors';
import { RowStreamResult } from '../../../utils/useDataSource';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	ColumnNameToTypeMap,
	convertXAxisOrientation,
	defaultFontColor,
	formatDateUTC,
	simplifiedFormatNumber,
	invertedFontColor,
	getCurrencySymbol,
} from '../chartUtil';

export const multiCombinationChartOptions = (
	props: Omit<ChartOptionArg, 'data'> & {
		data: RowStreamResult[];
		columnNameToType: ColumnNameToTypeMap;
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartMultiCombinationDTO;
	}
) => {
	const {
		config,
		existingOptions,
		data,
		columnNameToType,
		intl,
		invertFontColors,
		palette,
	} = props;
	const {
		xAxisTitle,
		hideXAxisTitle,
		legendPosition,
		showLegend,
		xAxisOrentation,
		xAxisOrder,
		hideXAxisLine,
		hideGridLines,
		markerShape,
		hideMarkers,
		smoothLine,
	} = config;

	const { series } = config;
	// All series have the same label column
	const { labelColumn } = series[0];
	const colCategoryColumn = series[0].categoryColumn;
	const lineCategoryColumn = series[1].categoryColumn;
	const labelIsDateBased = columnNameToType[labelColumn].startsWith('date');
	const colCatIsDateBased =
		columnNameToType[colCategoryColumn].startsWith('date');
	const lineCatIsDateBased =
		columnNameToType[lineCategoryColumn].startsWith('date');

	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	/*
	 * allow colorAxis to set colors
	 * in case of sequential or divergent palette
	 */
	const nothing = (_) => undefined;
	const getColor = existingOptions.colorAxis
		? nothing // handled by colorAxis
		: generateColorRange(
				series.map((s) => s.seriesChartType),
				palette?.config.classification?.paletteColors
		  );

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const seriesChartType =
				this.series.options.type === 'column' ? 'Column' : 'Line';

			const xValue = labelIsDateBased ? formatDateUTC(this.x, intl) : this.x;
			const yValue = simplifiedFormatNumber({
				value: this.y,
				config,
				multiCol: seriesChartType,
			});
			const catName =
				seriesChartType === 'Column' ? colCategoryColumn : lineCategoryColumn;
			const catValue =
				(seriesChartType === 'Column' && colCatIsDateBased) ||
				(seriesChartType === 'Line' && lineCatIsDateBased)
					? formatDateUTC(this.series.name, intl)
					: this.series.name;
			return `${xValue}: <strong>${yValue}</strong><br>${catName}: <strong>${catValue}</strong>`;
		},
	};

	const lineOptions = {
		marker: {
			enabled: !hideMarkers ?? true,
			symbol: markerShape ?? 'circle',
		},
		allowPointSelect: true,
		cursor: 'pointer',
	};

	const xAxis: Highcharts.XAxisOptions = {
		type: labelIsDateBased ? 'datetime' : undefined,
		categories: !labelIsDateBased
			? data[0].rows.map((row) => row[labelColumn])
			: undefined,
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		reversed: labelIsDateBased ? xAxisOrder === 'DESC' : false,
		lineWidth: hideXAxisLine ? 0 : 1,
	};

	const legend: Highcharts.LegendOptions = {
		enabled: showLegend ?? true,
		itemStyle: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		itemHoverStyle: {
			fontWeight: 'bold',
			color: invertFontColors ? invertedFontColor : '#333333',
		},
		labelFormatter: function formatter() {
			return ((this as Highcharts.Series).type === 'column' &&
				colCatIsDateBased) ||
				((this as Highcharts.Series).type === 'line' && lineCatIsDateBased)
				? `${formatDateUTC(this.name, intl)}`
				: this.name;
		},
		layout:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'horizontal'
				: 'vertical',
		align:
			legendPosition &&
			(legendPosition === 'top' || legendPosition === 'bottom')
				? 'center'
				: legendPosition ?? 'right',
		verticalAlign:
			legendPosition === 'top' || legendPosition === 'bottom'
				? legendPosition
				: 'middle',
	};

	const yAxis: Highcharts.YAxisOptions[] = [];
	const seriesOptions: Highcharts.SeriesOptionsType[] = [];

	series.forEach((s, i) => {
		const { seriesChartType, valueColumn } = s;

		// Get all the categories
		const categories = data[i].schemaInfo.columns.slice(
			1,
			data[i].schemaInfo.columns.length
		);

		const type =
			seriesChartType === 'Column' ? 'column' : smoothLine ? 'spline' : 'line';

		let yAxisOptions: Highcharts.YAxisOptions = {};

		const cCurrencyCode = getCurrencyCode(
			config[`valueFormat${seriesChartType}`]
		);
		const cCurrencySymbol = getCurrencySymbol(cCurrencyCode);

		const cShowYMinMax = config[`showYMinMax${seriesChartType}`];
		const cYAxisMin = config[`yAxisMin${seriesChartType}`];
		const cYAxisMax = config[`yAxisMax${seriesChartType}`];
		const cHideYAxisLine = config[`hideYAxisLine${seriesChartType}`];
		const showYAxisMin = cShowYMinMax && !!cYAxisMin && cYAxisMin !== '';
		const showYAxisMax = cShowYMinMax && !!cYAxisMax && cYAxisMax !== '';
		yAxisOptions = {
			title: {
				text: config[`hideYAxisTitle${seriesChartType}`]
					? undefined
					: `${config[`yAxisTitle${seriesChartType}`] || valueColumn}${
							cCurrencySymbol ? ` (${cCurrencySymbol})` : ''
					  }`,
				style: {
					color: invertFontColors ? invertedFontColor : defaultFontColor,
				},
			},
			labels: {
				style: {
					color: invertFontColors ? invertedFontColor : defaultFontColor,
				},
			},
			startOnTick: !showYAxisMin,
			min: showYAxisMin ? Number(cYAxisMin) : null,
			endOnTick: !showYAxisMax,
			max: showYAxisMax ? Number(cYAxisMax) : null,
			gridLineWidth: seriesChartType === 'Line' || hideGridLines ? 0 : 1,
			lineWidth: cHideYAxisLine || cHideYAxisLine === undefined ? 0 : 1,
			opposite: seriesChartType !== 'Column',
		};
		yAxis.push(yAxisOptions);

		const cLabelPosition = config[`labelPosition${seriesChartType}`];
		const dataLabels: Highcharts.DataLabelsOptions = {
			enabled: config[`showDataValues${seriesChartType}`] ?? false,
			style: {
				fontWeight: 'normal',
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			inside: !!(seriesChartType === 'Column' && cLabelPosition === 'below'),
			align:
				cLabelPosition === 'left'
					? 'right'
					: cLabelPosition === 'right'
					? 'left'
					: 'center',
			verticalAlign:
				cLabelPosition === 'below' || cLabelPosition === 'bottom'
					? 'top'
					: cLabelPosition === 'left' || cLabelPosition === 'right'
					? 'middle'
					: 'bottom',
			formatter: function formatter() {
				return simplifiedFormatNumber({
					value: this.y ?? 0,
					config,
					multiCol: seriesChartType,
				});
			},
		};

		categories.forEach((cat, catIndex) => {
			const dataRows = pipe(
				map((row: any) => {
					return {
						x: labelIsDateBased ? row[labelColumn] : undefined,
						y:
							seriesChartType === 'Line' && !row[cat]
								? 0
								: isEmpty(row[cat])
								? null
								: row[cat],
					};
				}),
				labelIsDateBased ? sortBy((row: any) => row.x) : identity
			)(data[i].rows);
			const seriesOption: Highcharts.SeriesOptionsType = {
				id: `${seriesChartType}${catIndex}`,
				type: type as any,
				name: cat,
				yAxis: seriesChartType === 'Column' ? 0 : 1,
				data: dataRows,
				dataLabels,
				color: getColor(cat),
			};
			seriesOptions.push(seriesOption);
		});
	});

	const options = {
		...existingOptions,
		plotOptions: {
			spline: lineOptions,
			line: lineOptions,
		},
		xAxis,
		yAxis,
		tooltip,
		legend,
		series: seriesOptions,
	};

	return options;
};
