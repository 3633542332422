/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import Highcharts from 'highcharts';
import { Varicent } from 'icm-rest-client';
import { getSingleColor } from '../../../utils/colors';
import { getCurrencyCode } from '../../../utils/valueFormatUtils';
import {
	ChartOptionArg,
	convertXAxisOrientation,
	defaultFontColor,
	simplifiedFormatNumber,
	getCurrencySymbol,
	invertedFontColor,
} from '../chartUtil';

export const scatterChartOptions = (
	props: ChartOptionArg & {
		config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartScatterDTO;
	}
) => {
	const { config, existingOptions, data, invertFontColors, palette } = props;
	const {
		labelColumn,
		valueColumn,
		xAxisTitle,
		hideXAxisTitle,
		yAxisTitle,
		hideYAxisTitle,
		xAxisOrentation,
		xAxisOrder,
		yAxisOrder,
		labelPosition,
		showDataValues,
		valueFormat,
		showXMinMax,
		xAxisMin,
		xAxisMax,
		showYMinMax,
		yAxisMin,
		yAxisMax,
		hideXAxisLine,
		hideYAxisLine,
		hideGridLines,
		markerShape,
	} = config;
	const type = 'scatter';

	const currencyCode = getCurrencyCode(valueFormat);
	const currencySymbol = getCurrencySymbol(currencyCode);
	const convertedXAxisOrientation = convertXAxisOrientation(xAxisOrentation);

	const tooltip: Highcharts.TooltipOptions = {
		formatter() {
			const xValue = simplifiedFormatNumber({
				value: this.x,
				config,
				multiCol: 'X',
			});
			const yValue = simplifiedFormatNumber({
				value: this.y,
				config,
				multiCol: 'Y',
			});
			return `${xValue}, ${yValue}`;
		},
	};

	const plotOptions: Highcharts.PlotOptions = {
		scatter: {
			marker: {
				symbol: markerShape ?? undefined,
				radius: 5,
				states: {
					hover: {
						enabled: true,
						lineColor: 'rgb(100,100,100)',
					},
				},
			},
		},
	};

	const showXAxisMin = showXMinMax && !!xAxisMin && xAxisMin !== '';
	const showXAxisMax = showXMinMax && !!xAxisMax && xAxisMax !== '';
	const xAxis: Highcharts.XAxisOptions = {
		title: {
			text: hideXAxisTitle ? undefined : xAxisTitle || labelColumn,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
			rotation: convertedXAxisOrientation,
		},
		reversed: xAxisOrder === 'DESC',
		lineWidth: hideXAxisLine || hideXAxisLine === undefined ? 0 : 1,
		startOnTick: !showXAxisMin,
		min: showXAxisMin ? Number(xAxisMin) : null,
		endOnTick: !showXAxisMax,
		max: showXAxisMax ? Number(xAxisMax) : null,
	};

	const showYAxisMin = showYMinMax && !!yAxisMin && yAxisMin !== '';
	const showYAxisMax = showYMinMax && !!yAxisMax && yAxisMax !== '';
	const yAxis: Highcharts.YAxisOptions = {
		title: {
			text: hideYAxisTitle
				? undefined
				: `${yAxisTitle || valueColumn}${
						currencySymbol ? ` (${currencySymbol})` : ''
				  }`,
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		labels: {
			style: {
				color: invertFontColors ? invertedFontColor : defaultFontColor,
			},
		},
		startOnTick: !showYAxisMin,
		min: showYAxisMin ? Number(yAxisMin) : null,
		endOnTick: !showYAxisMax,
		max: showYAxisMax ? Number(yAxisMax) : null,
		reversed: yAxisOrder === 'DESC',
		gridLineWidth: hideGridLines ? 0 : 1,
		lineWidth: hideYAxisLine || hideYAxisLine === undefined ? 0 : 1,
	};

	const divOrSeq = palette?.config.divergent || palette?.config.sequential;
	const dataRows = data.rows.map((row: any) => {
		return {
			name: row[labelColumn],
			x: row[labelColumn],
			y: row[valueColumn],
			color: divOrSeq
				? undefined // color is handled by colorAxis
				: getSingleColor(palette?.config.classification?.paletteColors),
		};
	});

	const series: Highcharts.SeriesScatterOptions = {
		type: type as any,
		data: dataRows,
		dataLabels: {},
		color: divOrSeq
			? undefined // color is handled by colorAxis
			: `${getSingleColor(palette?.config.classification?.paletteColors)}99`,
	};

	series.dataLabels = {
		enabled: showDataValues ?? false,
		style: {
			fontWeight: 'normal',
			color: invertFontColors ? invertedFontColor : defaultFontColor,
		},
		align:
			labelPosition === 'left'
				? 'right'
				: labelPosition === 'right'
				? 'left'
				: 'center',
		verticalAlign:
			labelPosition === 'bottom'
				? 'top'
				: labelPosition === 'left' || labelPosition === 'right'
				? 'middle'
				: 'bottom',
		formatter: function formatter() {
			return simplifiedFormatNumber({
				value: this.y ?? 0,
				config,
				multiCol: 'X',
			});
		},
	};

	const options = {
		...existingOptions,
		xAxis,
		yAxis,
		plotOptions,
		tooltip,
		series: [series],
	};
	return options;
};
