/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

/* eslint-disable no-loop-func,max-classes-per-file */
import { sum } from 'ramda';

// this is based on react-grid-layout but is not limited to it.
export type Layout = {
	i: string;
	x: number;
	y: number;
	w: number;
	h: number;
};

type ContentElement = {
	startX: number;
	width: number;
	height: number;
	contents: Grid[];
};
type Element = { startX: number; width: number; height: number; id: string };

export type Grid = ContentElement | Element;

export function hasChildren(grid: Grid): grid is ContentElement {
	return (grid as ContentElement).contents !== undefined;
}

export class InvalidLayoutErrors {
	public errors: InvalidLayoutError[];

	constructor(errs: InvalidLayoutError[]) {
		this.errors = errs;
	}
}

class InvalidLayoutError {
	public x: number;

	public y: number;

	public width: number;

	public height: number;

	constructor(args: { x: number; y: number; width: number; height: number }) {
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, InvalidLayoutError);
		}
		this.x = args.x;
		this.y = args.y;
		this.width = args.width;
		this.height = args.height;
	}
}

const initialGridArgs = {
	startY: 1,
	startX: 1,
	totalWidth: 12,
};

function convertLayoutToGrid<L extends Layout>(args: {
	layouts: L[];
	totalWidth?: number;
}) {
	try {
		const result = convertLayoutToGridInternal({
			...initialGridArgs,
			...args,
			addFillCells: true,
		});
		return result.contents;
	} catch (e) {
		throw new InvalidLayoutErrors(Array.isArray(e) ? e : [e]);
	}
}

const countGridContents: (grid: Grid) => number = (grid) => {
	if ('contents' in grid) {
		return sum(grid.contents.map(countGridContents));
	}
	return 1;
};

const convertLayoutToGridInternal = <L extends Layout>({
	layouts,
	startX,
	startY,
	totalWidth,
	addFillCells,
}: {
	layouts: L[];
	startY: number;
	startX: number;
	totalWidth: number;
	addFillCells: boolean;
}) => {
	let consumedLayoutCount = 0;
	let consumedHeight = 0;
	let lastNonCollisionX = startX - 1;
	let lastNonCollisionY = startY - 1;

	const totalHeight = Math.max(...layouts.map((l) => l.y + l.h));
	const grid: Grid = {
		startX: startX - 1,
		width: totalWidth,
		height: totalHeight - (startY - 1),
		contents: [],
	};

	const problemAreas: InvalidLayoutError[] = [];
	for (let y = startY; y <= startY + totalHeight; y++) {
		lastNonCollisionX = startX - 1;

		const hasCollision = !!layouts.find((l) => l.y < y && l.y + l.h > y);
		const row: Grid = {
			startX: startX - 1,
			width: totalWidth,
			height: y - lastNonCollisionY,
			contents: [],
		};
		if (!hasCollision) {
			const layoutsInRow = layouts.filter(
				(l) => l.y < y && l.y >= lastNonCollisionY
			);
			for (let x = startX; x <= startX + totalWidth + 1; x++) {
				const hasCollisionCol = layoutsInRow.find(
					(l) => l.x < x && l.x + l.w > x
				);
				if (!hasCollisionCol) {
					const layoutsInRowAndCol = layoutsInRow.filter(
						(l) => l.x < x && l.x >= lastNonCollisionX
					);

					try {
						if (layoutsInRowAndCol.length === 1) {
							row.contents.push({
								startX: layoutsInRowAndCol[0].x,
								width: layoutsInRowAndCol[0].w,
								height: layoutsInRowAndCol[0].h,
								id: layoutsInRowAndCol[0].i,
							});
							consumedHeight += layoutsInRowAndCol[0].h;
							consumedLayoutCount++;
						} else if (
							layoutsInRowAndCol.length > 1 &&
							layoutsInRow.length > layoutsInRowAndCol.length
							// if it's not the case, we're going to get stuck in an infinite loop
						) {
							const innerGrid = convertLayoutToGridInternal({
								layouts: layoutsInRowAndCol,
								startY: lastNonCollisionY + 1,
								startX: lastNonCollisionX + 1,
								totalWidth: x - lastNonCollisionX,
								addFillCells,
							});
							if (innerGrid.contents.length > 0) {
								row.contents.push(innerGrid);
								consumedLayoutCount += countGridContents(innerGrid);
								consumedHeight += innerGrid.height;
							}
						}
					} catch (e) {
						// swallow internal errors, they'll be caught later up
						if (e instanceof InvalidLayoutError) {
							problemAreas.push(e);
							consumedHeight += e.height;
						}
					}
					lastNonCollisionX = x;
				}
			}
			lastNonCollisionY = y;
		}
		if (row.contents.length > 0) {
			grid.contents.push(row);
		}
	}
	if (problemAreas.length > 0) {
		throw problemAreas;
	}
	if (consumedLayoutCount !== layouts.length) {
		const minX = Math.min(...layouts.map((l) => l.x));
		throw new InvalidLayoutError({
			x: minX,
			y: consumedHeight,
			width: Math.max(...layouts.map((l) => l.x + l.w)) - minX,
			height: totalHeight - consumedHeight - (startY - 1),
		});
	}
	return {
		...grid,
		contents: grid.contents.map(compressRows(addFillCells)),
	};
};

const compressRows = (addFillCells: boolean) => (g: Grid) => {
	if (
		'contents' in g &&
		g.contents.length === 1 &&
		g.height === g.contents[0].height &&
		g.startX === g.contents[0].startX &&
		g.width === g.contents[0].width
	) {
		return g.contents[0];
	}
	if ('contents' in g && g.contents.length > 0 && addFillCells) {
		// here we fill in any gaps.
		let start = g.startX;
		return {
			...g,
			contents: g.contents.reduce((acc, gInner, curIndex, contents) => {
				let result: Grid[] = [];
				if (gInner.startX === start) {
					start += gInner.width;
					result = [gInner];
				} else {
					const gap = gInner.startX - start;
					const filler = {
						startX: start,
						height: gInner.height,
						width: gap,
						contents: [] as Grid[],
					};
					start = gInner.startX + gInner.width;
					result = [filler, gInner];
				}
				if (start !== g.startX + g.width && curIndex === contents.length - 1) {
					// last item in the contents, but there is still a gap at the end
					result = [
						...result,
						{
							startX: start,
							height: gInner.height,
							width: g.width - start,
							contents: [] as Grid[],
						},
					];
				}
				return [...acc, ...result];
			}, [] as Grid[]),
		};
	}
	return g;
};

/**
 * Sometimes you just want the layout errors
 */
export function getLayoutErrors<L extends Layout>(args: {
	layouts: L[];
	totalWidth?: number;
}) {
	try {
		convertLayoutToGridInternal({
			...initialGridArgs,
			...args,
			addFillCells: false,
		});
		return null;
	} catch (e) {
		return new InvalidLayoutErrors(Array.isArray(e) ? e : [e]);
	}
}

export default convertLayoutToGrid;
