/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, {
	memo,
	useContext,
	useEffect,
	useState,
	useRef,
	useMemo,
	forwardRef,
	useLayoutEffect,
} from 'react';
import { Varicent } from 'icm-rest-client';
import { defineMessages } from 'react-intl';
import {
	getNormalizedTypeForDbType,
	NormalizedColumnType,
} from 'icm-core/lib/utils/dbUtils';
import { ReportContext } from '../context';
import { Button, Classes } from '@blueprintjs/core';
import { AgGridReact } from '@ag-grid-community/react';
import {
	ColDef,
	GridApi,
	GridReadyEvent,
	ICellEditorParams,
	ICellRendererParams,
	IHeader,
	IHeaderParams,
} from '@ag-grid-community/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@varicent/components/dist/ag-grid.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { useDefaultProps } from 'icm-core/lib/components/datatable';
import usePrevious from 'react-use/esm/usePrevious';
import useUpdateEffect from 'react-use/esm/useUpdateEffect';
import {
	AGDateCellEditor,
	AGEditableRenderer,
	AGNumericCellEditor,
	AGTextCellEditor,
	AGDropdownCellRenderer,
	AgGridWrapper,
	Icon,
	AgGridCustomHeader,
	colorRed3,
} from '@varicent/components';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { Add20, TrashCan20 } from '@carbon/icons-react';
import AgGridDropdownCellEditor from '../components/agGridDropdown';
import styled from 'react-emotion';
import { assert } from 'icm-core/lib/utils/typeHelpers';
import {
	indexBy,
	isEmpty,
	mapObjIndexed,
	dissoc,
	mergeDeepRight,
	equals,
} from 'ramda';
import { FormikErrors } from 'formik';

const LoadingRenderer: React.FC<any> = () => {
	return (
		<div
			className={Classes.SKELETON}
			style={{ minWidth: '100vw', minHeight: '1rem', marginLeft: '1rem' }}
		/>
	);
};

const Toolbar = styled.div`
	display: flex;
	padding: 0.8125rem 0;
`;

if (process.env.AG_GRID_LICENSE_KEY) {
	LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE_KEY);
}

const messages = defineMessages({
	delete: {
		id: 'form.multiEntry.delete',
		defaultMessage: 'Delete',
	},
	addRow: {
		id: 'form.multiEntry.addRow',
		defaultMessage: 'Add a row',
	},
	required: {
		id: 'form.multiEntry.required',
		defaultMessage: '{field} is required.',
	},
});

const getEditorForType: (type: NormalizedColumnType | 'picklist') => any = (
	type
) => {
	switch (type) {
		case 'date':
		case 'datetime':
			return AGDateCellEditor;
		case 'numeric':
			return AGNumericCellEditor;
		case 'picklist':
			return AgGridDropdownCellEditor;
		case 'text':
		default:
			return AGTextCellEditor;
	}
};

const AGCustomRequiredFieldHeader = forwardRef<
	IHeader,
	IHeaderParams & { isRequired: boolean }
>((props, ref) => {
	const { displayName, isRequired } = props;
	return (
		<AgGridCustomHeader {...props} ref={ref}>
			<div
				className={Classes.TEXT_OVERFLOW_ELLIPSIS}
				style={{ marginRight: '0.25rem' }}
			>
				{displayName}
			</div>
			{isRequired && <span style={{ color: `rgb(${colorRed3})` }}>*</span>}
		</AgGridCustomHeader>
	);
});

const emptyRows: unknown[] = [];

const MultiEntryDataGrid: React.FC<{
	section: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSectionDTO;
	formData: Record<string, any>;
	setFieldValue: (path: string, data: any) => void;
	formId?: Varicent.ID;
	preview?: boolean;
	userChanged: Record<string, boolean>;
	onUserChange: (field: string, changed: boolean) => void;
	getColumnMultiEntryError: (
		value: any,
		sectionId: number,
		columnName: string,
		rowIndex: string
	) => string | undefined;
	formikErrors?: FormikErrors<Record<string, any>>;
	getColumnError: (
		sectionId: number,
		columnName: string,
		rowIndex?: string,
		errors?: FormikErrors<Record<string, any>>
	) => string | undefined;
}> = ({
	section,
	formData,
	setFieldValue,
	formId,
	preview,
	userChanged,
	onUserChange,
	getColumnMultiEntryError,
	getColumnError,
	formikErrors,
}) => {
	const intl = useIntl();
	const gridApi = useRef<GridApi>();
	const defaultTableProps = useDefaultProps();
	const getColumnMultiEntryErrorRef = useRef(getColumnMultiEntryError);
	const getColumnErrorRef = useRef(getColumnError);
	const userChangedRef = useRef(userChanged);
	const sectionRef = useRef(section);
	const formDataRef = useRef(formData);
	const formikErrorsRef = useRef(formikErrors);
	const { values: formValues, sourceSchemas } = useContext(ReportContext);
	const getRowNodeId = (row) => row.__id;
	type RowType = {
		__id: string;
	};
	const [rowData, setRowData] = useState<Array<RowType>>([]);
	const rowDataRef = useRef(rowData);
	useLayoutEffect(() => {
		sectionRef.current = section;
		formDataRef.current = formData;
		formikErrorsRef.current = formikErrors;
	}, [section, formData, formikErrors]);

	useEffect(() => {
		const newData = createRowData(section, formData, formikErrors).map(
			(row, index) => {
				const oldRow = rowData[index];
				if (oldRow && oldRow.__id === row.__id) {
					return mapObjIndexed(
						(_, columnName) =>
							oldValueMapper(oldRow[columnName], row[columnName]),
						row
					);
				}
				return row;
			}
		);
		setRowData(newData);
	}, [section, formData, formikErrors]);

	useLayoutEffect(() => {
		rowDataRef.current = rowData;
	}, [rowData]);

	const oldValueMapper = (oldValue, newValue) => {
		return equals(oldValue, newValue) ? oldValue : newValue;
	};

	const errorValueCombiner = (
		value,
		sectionId,
		rowIndex,
		columnName,
		formikErrors
	) => {
		return {
			value,
			error:
				getColumnError(sectionId, columnName, rowIndex, formikErrors) ?? '',
		};
	};

	const createRowData = (section, formData, formikErrors) => {
		return (formData[section.sectionId] ?? emptyRows)
			.filter((i) => i)
			.map((row) => {
				const rowIndex = row.__id;
				const { sectionId } = section;
				return mergeDeepRight(
					row,
					mapObjIndexed(
						(value, columnName) =>
							errorValueCombiner(
								value,
								sectionId,
								rowIndex,
								columnName,
								formikErrors
							),
						dissoc('__id', row)
					)
				);
			});
	};
	const prevRows = usePrevious(rowData);
	const onGridReady = (params: GridReadyEvent) => {
		gridApi.current = params.api;
		const newData = createRowData(
			sectionRef.current,
			formDataRef.current,
			formikErrorsRef.current
		);
		setRowData(newData);
	};
	const prevFormData = usePrevious(formData);
	const valuesById = useMemo(
		() => indexBy((v) => v.valueId.toString(), formValues ?? []),
		[formValues]
	);

	useLayoutEffect(() => {
		getColumnErrorRef.current = getColumnError;
		getColumnMultiEntryErrorRef.current = getColumnMultiEntryError;
	}, [getColumnMultiEntryError, getColumnError]);

	useLayoutEffect(() => {
		userChangedRef.current = userChanged;
	}, [userChanged]);

	const isCellEditing = (
		rowIndex: number | undefined,
		columnName: string
	): boolean => {
		const editingCells = gridApi.current
			?.getEditingCells()
			.filter((editingCell) => {
				return (
					editingCell.rowIndex === rowIndex &&
					editingCell.column.getColId() === columnName
				);
			});
		return !!editingCells && editingCells.length > 0;
	};

	const haveTouchedCell = (
		sectionId: number,
		rowIndex: number | undefined,
		columnName: string
	) => {
		const key = `${sectionId}.[${rowIndex}].${columnName}`;
		return !!userChangedRef.current[key];
	};

	/*
	 * need to keep readonly and defaults in sync. Any changes to those values,
	 * we can propagate those changes across saved rows.
	 */
	useEffect(() => {
		if (!prevFormData) {
			return;
		}
		const changedCols = section.columns.reduce((acc, c) => {
			if (c.defaultValue?.valueId) {
				const parameterConfig =
					valuesById[c.defaultValue.valueId].config.parameter;
				const key = `${parameterConfig.sectionId}--${parameterConfig.columnName}`;
				if (formData[key]?.toString() !== prevFormData[key]?.toString()) {
					return [
						...acc,
						{
							newData: formData[key],
							columnName: c.columnName,
						},
					];
				}
			}
			return acc;
		}, []);
		if (formData[section.sectionId]?.length > 0) {
			for (const col of changedCols) {
				setFieldValue(
					section.sectionId.toString(),
					formData[section.sectionId].map((entry, index) => {
						const key = `${section.sectionId}.[${index}].${col.columnName}`;
						if (userChanged[key]) {
							return entry;
						}
						return {
							...entry,
							[col.columnName]: col.newData,
						};
					})
				);
			}
		}
	}, [section, formData, prevFormData, setFieldValue, valuesById, userChanged]);

	const columnDefs = useMemo(() => {
		const schema = sourceSchemas?.[section.sourceId];
		const defs: ColDef[] = !schema
			? []
			: section.columns.map((col) => {
					const colSchema = schema.columns.find(
						(c) => c.name === col.columnName
					);
					assert(colSchema);
					const colType = col.metadata.picklistConfig
						? 'picklist'
						: getNormalizedTypeForDbType(colSchema.type);
					assert(colType);

					const valueFormatter = colType.startsWith('date')
						? ({ value: input }: { value: any }) => {
								return input?.value ?? input
									? intl.formatDate(input?.value ?? input, {
											day: 'numeric',
											month: 'numeric',
											year: 'numeric',
									  })
									: '';
						  }
						: undefined;

					const sourceId = col.metadata.picklistConfig
						? formValues?.find(
								(v) =>
									v.config.parameter.sectionId === section.sectionId &&
									v.config.parameter.columnName === col.columnName
						  )?.sourceId
						: undefined;

					const validateField = (
						value: string,
						cellInfo: { rowIndex: number; colDef: ColDef }
					): string | null => {
						const rowIndexProper = rowDataRef.current[cellInfo.rowIndex].__id;
						const result = getColumnMultiEntryErrorRef.current(
							value,
							section.sectionId,
							col.columnName,
							rowIndexProper
						);
						return result ?? '';
					};

					return {
						field: col.columnName,
						cellRendererFramework:
							colType === 'picklist'
								? AGDropdownCellRenderer
								: AGEditableRenderer,
						cellEditorFramework: getEditorForType(colType),
						cellEditorParams: {
							onChange: (event) => {
								const { newValue } = event;
								const { rowIndex } = event.cellProps as ICellEditorParams;
								const key = `${section.sectionId}.[${rowIndex}].${col.columnName}`;
								setFieldValue(key, newValue);
								onUserChange(key, true);
							},
							picklistConfig: col.metadata.picklistConfig,
							sourceId,
							additionalDataSourceArgs: {
								formId,
							},
							preview,
							validate: validateField,
						},
						cellRendererParams: {
							validate: validateField,
						},
						headerName: col.metadata.displayName,
						editable: !col.metadata.readOnly,
						cellClass: 'vds-editable-cell',
						valueFormatter,
						headerComponentFramework: AGCustomRequiredFieldHeader,
						headerComponentParams: {
							isRequired: colSchema.isKey || col.metadata.isRequired,
							cellType: colType,
						},
						valueGetter: (params) => {
							return params.data[col.columnName];
						},
					};
			  });
		defs.push({
			width: 68,
			maxWidth: 68,
			minWidth: 68,
			flex: 0,
			cellClass: 'vds-editable-cell',
			resizable: false,
			cellRendererFramework: (params: ICellRendererParams) => (
				<Button
					icon={
						<Icon>
							<TrashCan20 />
						</Icon>
					}
					intent="danger"
					minimal
					aria-label={intl.formatMessage(messages.delete)}
					onClick={() => {
						setFieldValue(
							`${section.sectionId}.[${params.rowIndex}]`,
							undefined
						);
					}}
				/>
			),
		});
		return defs;
	}, [
		sourceSchemas,
		section,
		intl,
		formValues,
		preview,
		setFieldValue,
		formId,
		onUserChange,
		getColumnMultiEntryErrorRef,
		getColumnErrorRef,
		rowDataRef,
	]);
	useUpdateEffect(() => {
		/*
		 * TODO: look into a better way of refreshing the cell/header styles
		 * without this, the old ones are kept and sometimes conflict with new styles
		 * resetting the an empty array first then setting the updated columnDefs works for now
		 */
		gridApi.current?.setColumnDefs([]);
		gridApi.current?.setColumnDefs(columnDefs);
		gridApi.current?.refreshCells({
			force: true,
		});
	}, [columnDefs]);

	const addRow = () => {
		const newRow = section.columns.reduce(
			(acc, column) => {
				const defaultV = column.defaultValue;
				let colDefault: string | number | Date | undefined;
				if (defaultV?.valueId !== undefined && formValues) {
					const formValueFromDefault = formValues.find(
						(v) => v.valueId === defaultV.valueId
					);
					if (formValueFromDefault) {
						const defaultValueV =
							formData[formValueFromDefault.name.replace('.', '--')];
						colDefault = defaultValueV;
					}
				} else if (defaultV) {
					colDefault = defaultV.date ?? defaultV.numeric ?? defaultV.text;
				}
				const initialCol = formValues?.find(
					(v) => v.config.parameter.columnName === column.columnName
				);
				if (initialCol?.dataType === 'Numeric' && !colDefault) {
					colDefault = '0';
				}
				return {
					...acc,
					[column.columnName]: colDefault ?? '',
				};
			},
			{
				__id: (rowData.length + 1).toString(),
			} as Record<string, any>
		);
		setFieldValue(`${section.sectionId}.[${rowData.length}]`, newRow);
	};

	useEffect(() => {
		if (
			gridApi.current &&
			prevRows?.length !== rowData.length &&
			columnDefs[0].field
		) {
			gridApi.current.setFocusedCell(rowData.length - 1, columnDefs[0].field);
			gridApi.current.startEditingCell({
				rowIndex: rowData.length - 1,
				colKey: columnDefs[0].field,
			});
		}
	}, [rowData, prevRows, columnDefs]);

	return (
		<>
			<Toolbar>
				<Button
					onClick={addRow}
					minimal
					icon={
						<Icon>
							<Add20 />
						</Icon>
					}
					text={intl.formatMessage(messages.addRow)}
				/>
			</Toolbar>
			<AgGridWrapper style={{ height: '500px' }}>
				<AgGridReact
					{...defaultTableProps}
					onGridReady={onGridReady}
					modules={[ClientSideRowModelModule]}
					loadingCellRendererFramework={LoadingRenderer}
					applyColumnDefOrder
					defaultColDef={{
						flex: 1,
						minWidth: 100,
						resizable: true,
						suppressMovable: true,
						filter: false,
					}}
					columnDefs={columnDefs}
					singleClickEdit
					getRowNodeId={getRowNodeId}
					rowData={rowData}
					immutableData
					onCellEditingStopped={(params) => {
						if (
							params.colDef.headerComponentParams.cellType === 'numeric' &&
							(Number.isNaN(Number(params.newValue?.value)) ||
								isEmpty(params.newValue?.value))
						) {
							params.node.setDataValue(params.colDef.field as string, {
								value: '0',
								error: '',
							});
						}
					}}
				/>
			</AgGridWrapper>
		</>
	);
};

export default memo(MultiEntryDataGrid);
