/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, {
	createContext,
	useCallback,
	useMemo,
	useState,
	useEffect,
	useRef,
	useContext,
} from 'react';
import { Varicent } from 'icm-rest-client';
import type { ReportMetadata, ColumnWidthsState } from './types';
import useMap from 'react-use/esm/useMap';
import debounce from 'lodash.debounce';

interface CountContext {
	loaded: boolean | undefined;
	incrementDataLoadedCount: () => void;
	decrementDataLoadedCount: () => void;
}

export const ComponentDataLoadedContext = createContext<CountContext>({
	loaded: undefined,
	incrementDataLoadedCount: () => {},
	decrementDataLoadedCount: () => {},
});

export const ComponentDataLoadedProvider: React.FC<{
	report:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO
		| undefined;
}> = ({ report, children }) => {
	const isPublishing = useContext(PublishMode);
	const [loaded, setLoaded] = useState<boolean | undefined>(!isPublishing);
	const loadedCount = useRef(0);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedLoadCountCheck = useCallback(
		debounce(() => {
			setLoaded(loadedCount.current === 0);
		}, 100),
		[]
	);
	/*
	 * another effect, to trigger in a few ms to check if anyone
	 * has incremented dataloading. If no one has, then assume no
	 * one needed loading.
	 */
	useEffect(() => {
		if (isPublishing && (!report || report.sources.length === 0)) {
			const handler = setTimeout(() => {
				if (loadedCount.current === 0) {
					setLoaded(true);
				}
			}, 100);
			return () => clearTimeout(handler);
		}
		return () => {};
	}, [isPublishing, report]);

	const incrementDataLoadedCount = useCallback((): void => {
		loadedCount.current += 1;
	}, []);

	const decrementDataLoadedCount = useCallback((): void => {
		loadedCount.current -= 1;
		debouncedLoadCountCheck();
	}, [debouncedLoadCountCheck]);

	const tracker = useMemo(() => {
		if (isPublishing) {
			return {
				loaded,
				incrementDataLoadedCount,
				decrementDataLoadedCount,
			};
		}
		return {
			loaded: true,
			incrementDataLoadedCount: () => {},
			decrementDataLoadedCount: () => {},
		};
	}, [
		isPublishing,
		loaded,
		incrementDataLoadedCount,
		decrementDataLoadedCount,
	]);
	return (
		<ComponentDataLoadedContext.Provider value={tracker}>
			{children}
		</ComponentDataLoadedContext.Provider>
	);
};

export const PublishMode = createContext<boolean>(false);

export const ReportContext = createContext<{
	components?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO['components'];
	sources?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO['sources'];
	sourceSchemas?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO['sourceSchemas'];
	values?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO['values'];
	metadata?: Partial<ReportMetadata>;
	links?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexReportWithSourceSchemasDTO['links'];
	enableLinks?: boolean;
}>({});
interface PayeeContext {
	payeeId: Varicent.ID | undefined;
}
export const LiveDataPayeeContext = createContext<PayeeContext>({
	payeeId: undefined,
});
export interface ColumnWidthsInterface {
	selectedColumnWidthsInfo: ColumnWidthsState;
	updateSelectedColumnWidthsInfo: (updatedState: ColumnWidthsState) => void;
}
export const ColumnWidthsContext = createContext<ColumnWidthsInterface>({
	selectedColumnWidthsInfo: {
		componentId: undefined,
		isPanelOpen: false,
		selectedWidthType: undefined,
		selectedFlexValues: undefined,
		selectedGridWidth: undefined,
	},
	updateSelectedColumnWidthsInfo: () => {},
});
export const useCustomColumnWidths = (): ColumnWidthsInterface => {
	const [selectedColumnWidthsInfo, updateSelectedColumnWidthsInfo] =
		useState<ColumnWidthsState>({
			componentId: undefined,
			isPanelOpen: false,
			selectedWidthType: undefined,
			selectedFlexValues: undefined,
			selectedGridWidth: undefined,
		});

	return {
		selectedColumnWidthsInfo,
		updateSelectedColumnWidthsInfo,
	};
};

export const SetValue = createContext<(valueId: number, value: string) => void>(
	() => {}
);

export const ValueStore = createContext<Record<number, string>>({});

export const PaletteContext = createContext<
	Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexPaletteDTO[]
>([]);

export const ComponentVisibility = createContext<{
	hiddenComponents: Record<string | number, boolean>;
	set: (componentId: string | number, value: boolean) => void;
}>({
	hiddenComponents: {},
	set: () => {},
});

export const ComponentVisibilityProvider: React.FC = ({ children }) => {
	const [hiddenComponents, { set, remove }] = useMap<
		Record<string | number, boolean>
	>({});

	const providedValue = useMemo(
		() => ({
			hiddenComponents,
			set: (componentId: string | number, value: boolean) => {
				if (value && hiddenComponents[componentId]) {
					remove(componentId);
				} else if (!value && hiddenComponents[componentId] === undefined) {
					set(componentId, true);
				}
			},
		}),
		[hiddenComponents, set, remove]
	);

	return (
		<ComponentVisibility.Provider value={providedValue}>
			{children}
		</ComponentVisibility.Provider>
	);
};

/**
 * A little state helper to allow use of a delayed version of a state.
 * @param initial what to start as
 * @param delay how many milliseconds to hold onto this old state before updating.
 */
export const useDelayed = <T extends unknown>(
	tracking: T,
	delay: number,
	initial = tracking
) => {
	const [state, setState] = useState(initial);
	useEffect(() => {
		const handle = setTimeout(() => {
			setState(tracking);
		}, delay);
		return () => clearTimeout(handle);
	}, [delay, tracking]);
	return state;
};

export const RefreshComponent = createContext<{
	components: Record<string | number, boolean>;
	set: (componentId: string | number, value: boolean) => void;
}>({
	components: {},
	set: () => {},
});

export const RefreshComponentProvider: React.FC = ({ children }) => {
	const [components, { set, remove }] = useMap<
		Record<string | number, boolean>
	>({});

	const providedValue = useMemo(
		() => ({
			components,
			set: (componentId: string | number, value: boolean) => {
				if (!value) {
					remove(componentId);
				} else {
					set(componentId, true);
				}
			},
		}),
		[components, set, remove]
	);

	return (
		<RefreshComponent.Provider value={providedValue}>
			{children}
		</RefreshComponent.Provider>
	);
};

interface CalculationContextType {
	tableName: string;
	selectedColumns: Varicent.RESTAPI.v1.DTOs.Schema.ColumnSchemaDTO[];
	onSubmit: (calculation: any, columnOriginalName: string) => void;
	currentSource: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO;
	currentSourceSchema: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO | undefined;
}

export const CalculationContext = React.createContext<CalculationContextType>({
	tableName: '',
	selectedColumns: [],
	onSubmit: () => {},
	currentSource:
		{} as Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO,
	currentSourceSchema: {} as Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO,
});
