/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext, useState } from 'react';
import { Varicent } from 'icm-rest-client';
import { SharedComponentProps } from '../types';
import { css, cx } from 'emotion';
import Placeholder from '../utils/placeholder';
import useDataSource, { useLivePreviewInfo } from '../utils/useDataSource';
import { ISelectProps, Select } from '@blueprintjs/select';
import {
	Spinner,
	MenuItem,
	AnchorButton,
	IButtonProps,
	Button,
	Icon as BlueprintIcon,
} from '@blueprintjs/core';
import styled from 'react-emotion';
import R, { uniqBy } from 'ramda';
import {
	ValueStore,
	PublishMode,
	SetValue,
	LiveDataPayeeContext,
} from '../context';
import {
	deserializeAlignment,
	VerticalAlignmentOptions,
	HorizontalAlignmentOptions,
} from '../utils/objectAlignment';
import { defineMessages } from 'react-intl';
import { useIntl, CustomIntl } from 'icm-core/lib/contexts/intlContext';
import useDebounce from 'react-use/esm/useDebounce';
import highlightText from '../utils/highlightText';
import { FlexComponentTypes } from '../componentTypes';
import { CloseFilled16 } from '@carbon/icons-react';
import { Icon } from '@varicent/components';
import { makeLikeFilter } from 'icm-core/lib/utils/filterUtils';

const messages = defineMessages({
	placeholder: {
		id: 'components.pickList.placeholder',
		defaultMessage: 'Select an option',
	},
	filter: {
		id: 'components.pickList.filter',
		defaultMessage: 'Filter...',
	},
});

const ClickableIcon = styled(Icon as any)`
	cursor: pointer;
`;

const Container = styled.div<{
	disableEvents: boolean;
	v: VerticalAlignmentOptions;
	h: HorizontalAlignmentOptions;
}>`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: ${({ v }) =>
		v === 'center' ? 'center' : v === 'bottom' ? 'flex-end' : 'flex-start'};
	align-items: ${({ h }) =>
		h === 'center' ? 'center' : h === 'right' ? 'flex-end' : 'flex-start'};
	pointer-events: ${({ disableEvents }) => (disableEvents ? 'none' : 'all')};
	width: calc(100% - 1rem) !important;
`;

export const formatValueText = (
	displayText: string,
	idText: string,
	hideId: boolean,
	idIsDateBased: boolean,
	intl: CustomIntl
) => {
	// only ID columns can be date typed
	const formattedId =
		idIsDateBased && !R.isNil(idText)
			? intl.formatDate(idText, { timeZone: 'UTC' })
			: idText;
	return hideId ? displayText : `(${formattedId}) ${displayText}`;
};

const MAX_ROW_COUNT = 50;

const Picklist: React.FC<
	SharedComponentProps<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentPickListDTO> & {
		valueId: number;
		userId: any;
	}
> = ({ config, source, preview, reportId, sourceSchema, valueId }) => {
	const setValue = useContext(SetValue);
	const values = useContext(ValueStore); // user selected values
	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);
	const { isUsingLiveData, isLiveDataActive } = useLivePreviewInfo({ preview });
	const isPublishMode = useContext(PublishMode);

	const alignment = deserializeAlignment(config.alignment ?? 'center-right');

	if (
		!source ||
		!config.idColumn ||
		!config.displayColumn ||
		!sourceSchema ||
		(preview && isUsingLiveData && !isLiveDataActive)
	) {
		return <Placeholder type={FlexComponentTypes.pickList} />;
	}

	const hidePicklist = config.hideValueForExport && isPublishMode;
	return (
		<>
			{!hidePicklist && (
				<Container
					disableEvents={!!preview && !previewPayeeId && !isLiveDataActive}
					{...alignment}
					className="picklist-card"
				>
					{config.name && config.showTitle && (
						<h4
							css={css`
								margin: 0;
								font-weight: normal;
								font-size: 1rem;
							`}
						>
							{config.name}
						</h4>
					)}
					<AdaptivePicklist
						label={config.name}
						source={source}
						sourceSchema={sourceSchema}
						additionalDataSourceArgs={
							reportId
								? {
										reportId,
								  }
								: undefined
						}
						currentUserId={previewPayeeId}
						displayColumn={config.displayColumn}
						idColumn={config.idColumn}
						hideIDColumn={config.hideIDColumn}
						order={config.order}
						sort={config.sort}
						preview={preview}
						fill={config.fill}
						placeholder={config.placeholder}
						selectedValue={values[valueId]?.toString()}
						onItemSelect={(v) => {
							setValue(valueId, v[config.idColumn] ?? '');
						}}
					/>
				</Container>
			)}
		</>
	);
};

export function AdaptivePicklist({
	label,
	currentUserId,
	source,
	sourceSchema,
	preview,
	displayColumn,
	idColumn,
	sort,
	order,
	hideIDColumn,
	placeholder,
	fill,
	selectedValue,
	onItemSelect,
	additionalDataSourceArgs,
	selectProps,
	buttonProps,
	disabled,
	additionalItem,
}: {
	// The value (according to the idColumn) of the currently selected item
	selectedValue?: string;
	label: string;
	currentUserId?: Varicent.ID;
	source: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO;
	sourceSchema: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
	preview?: boolean;
	additionalDataSourceArgs?:
		| {
				reportId: Varicent.ID;
		  }
		| {
				formId: Varicent.ID;
		  };
	displayColumn: string;
	idColumn: string;
	sort: string;
	order: 'DESC' | 'ASC';
	hideIDColumn: boolean;
	placeholder?: string;
	fill?: boolean;
	onItemSelect: (
		item: Record<string, any>,
		event?: React.SyntheticEvent<HTMLElement, Event> | undefined
	) => void;
	selectProps?: Partial<ISelectProps<Record<string, any>>>;
	buttonProps?: Partial<IButtonProps>;
	disabled?: boolean;
	/**
	 * Special placeholder item, basically we know the items id but not the display.
	 * A litle cludgy but it works.
	 */
	additionalItem?: any;
}) {
	const intl = useIntl();
	const [queryString, setQueryString] = useState('');
	const [delayedQuery, setDelayedQuery] = useState(queryString);

	useDebounce(
		() => {
			setDelayedQuery(queryString);
		},
		400,
		[queryString]
	);
	const { data, columnNameToType } = useDataSource({
		source,
		preview,
		previewPayeeId: currentUserId,
		sourceSchema,
		pageSize: MAX_ROW_COUNT,
		filterBy:
			// non-picklist source data uses makeLikeFilter
			'formId' in (additionalDataSourceArgs ?? {}) || hideIDColumn
				? delayedQuery
					? makeLikeFilter(displayColumn, delayedQuery)
					: undefined
				: delayedQuery,
		column1: !hideIDColumn ? idColumn : undefined,
		column2: !hideIDColumn ? displayColumn : undefined,
		idColumn,
		selectedValue,
		orderBy: sort === 'Display' || delayedQuery ? displayColumn : idColumn,
		aggregateInfo: {
			groupByNames:
				idColumn !== displayColumn
					? [idColumn, displayColumn]
					: [displayColumn],
			valueNames: [],
		},
		orderDirection:
			order === 'DESC'
				? Varicent.Domain.SQL.OrderItem.OrderDirection.Descending
				: Varicent.Domain.SQL.OrderItem.OrderDirection.Ascending,
		...additionalDataSourceArgs,
	});

	const rowsWithAdditionalEntry =
		additionalItem && data.data?.rows
			? [additionalItem, ...data.data.rows]
			: data.data?.rows;

	const options =
		rowsWithAdditionalEntry !== undefined
			? uniqBy(
					(r) => r[idColumn] + r[displayColumn],
					rowsWithAdditionalEntry ?? []
			  )
			: [];
	const idIsDateBased = columnNameToType[idColumn]?.startsWith('date');

	const value = options.find((o) => o[idColumn]?.toString() === selectedValue);
	const valueText = value
		? formatValueText(
				value[displayColumn],
				value[idColumn],
				additionalItem && value[idColumn] === additionalItem[idColumn]
					? true
					: hideIDColumn,
				idIsDateBased,
				intl
		  )
		: placeholder ?? intl.formatMessage(messages.placeholder);
	const totalRows = data.data?.schemaInfo.totalRows ?? 0;

	const isIE11 =
		!!(window as any).MSInputMethodContext && !!(document as any).documentMode;

	const selectButtonProps = {
		text: valueText,
		fill,
		rightIcon:
			value && !disabled ? (
				<ClickableIcon
					onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
						e.stopPropagation();
						onItemSelect({});
					}}
				>
					<CloseFilled16 />
				</ClickableIcon>
			) : (
				<BlueprintIcon icon="chevron-down" />
			),
		disabled,
		...buttonProps,
		className: cx('select', buttonProps?.className),
	};

	return (
		<Select
			aria-label={label}
			items={options}
			onQueryChange={setQueryString}
			resetOnQuery={false}
			itemsEqual={idColumn}
			filterable
			inputProps={{
				rightElement:
					data.isLoading || delayedQuery !== queryString ? (
						<Spinner size={16} />
					) : undefined,
				placeholder: intl.formatMessage(messages.filter),
				onMouseDown: (e) => {
					e.stopPropagation();
				},
				...(selectProps?.inputProps ?? {}),
			}}
			itemRenderer={(item, { handleClick, modifiers }) => {
				const text = formatValueText(
					item[displayColumn],
					item[idColumn],
					additionalItem && item[idColumn] === additionalItem[idColumn]
						? true
						: hideIDColumn,
					idIsDateBased,
					intl
				);
				return (
					<MenuItem
						onClick={handleClick}
						active={modifiers.active}
						disabled={modifiers.disabled}
						key={`${item[idColumn]}${item[displayColumn]}`}
						text={
							<>
								{queryString !== '' ? highlightText(text, queryString) : text}
							</>
						}
					/>
				);
			}}
			popoverProps={{
				minimal: true,
				fill: fill ?? true,
				autoFocus: false,
			}}
			onItemSelect={onItemSelect}
			disabled={disabled}
			{...selectProps}
		>
			{isIE11 ? (
				<AnchorButton {...selectButtonProps} />
			) : (
				<Button {...selectButtonProps} />
			)}
		</Select>
	);
}

export default React.memo(Picklist);
