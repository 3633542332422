import React, {
	memo,
	useState,
	useContext,
	useCallback,
	useEffect,
	useRef,
	useMemo,
	forwardRef,
	useImperativeHandle,
	ReactNode,
} from 'react';
import { Varicent } from 'icm-rest-client';
import { SharedComponentProps, ColumnWidthType } from '../../types';
import { FormattedTime, defineMessages, InjectedIntlProps } from 'react-intl';
import { css, cx } from 'emotion';
import {
	useAgGridDataSource,
	useLivePreviewInfo,
	publishMaxRows,
} from '../../utils/useDataSource';
import { NormalizedColumnType } from 'icm-core/lib/utils/dbUtils';
import Placeholder, { MissingDataPlaceholder } from '../../utils/placeholder';
import {
	ComponentDataLoadedContext,
	ComponentVisibility,
	LiveDataPayeeContext,
	PublishMode,
	ReportContext,
	ValueStore,
	ColumnWidthsContext,
} from '../../context';
import { Button, Classes, Position, Toaster } from '@blueprintjs/core';
import { AgGridReact } from '@ag-grid-community/react';
import {
	CellValueChangedEvent,
	CellClassParams,
	ColDef,
	GridApi,
	GridReadyEvent,
	ILoadingCellRendererParams,
	ICellRendererParams,
	ColumnResizedEvent,
	ColumnApi,
} from '@ag-grid-community/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@varicent/components/dist/ag-grid.css';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import {
	buildFilteredByFromAgFilterModel,
	Filter,
	useDefaultProps,
} from 'icm-core/lib/components/datatable';
import { ConditionalLink } from '../../utils/links';
import styled from 'react-emotion';
import {
	AGDateCellEditor,
	AGEditableRenderer,
	AGNumericCellEditor,
	AGTextCellEditor,
	colorCobalt3,
	colorCobalt4,
	colorDarkGray3,
	colorLightGray1,
	colorLightGray5,
	colorRed3,
	Pagination,
	usePaginationForAgGrid,
	AgGridCustomHeader,
} from '@varicent/components';
import {
	updateDataForPayee,
	updateDataForAdmin,
	getSourceRowsForPayee,
	getSourceRows,
} from 'icm-rest-client/lib/controllers/presenterFlex';
import {
	anyPass,
	assocPath,
	dissocPath,
	indexBy,
	isNil,
	prop,
	reject,
	replace,
	toUpper,
} from 'ramda';
import {
	CellRendererComponent,
	ColumnConfig,
	RowGroupConfig,
	FooterConfig,
	FormattedDateRenderer,
	FormattedNumberRenderer,
	generateCellContainerStyle,
	generateRowGroupContainerStyle,
	generateFooterContainerStyle,
	generateHeaderClass,
	generateHeaderContainerStyle,
	GridTextContainerStyleArgs,
	HeaderConfig,
	IConditionProps,
	isTextWrappingActive,
	setRowHeightToTallestCellHeight,
	StyleOptions,
	TableConfig,
	formattedDateString,
	DateFormatOption,
} from '../../utils/dataGridStyling';
import DataTableComponents from '../dataTableComponents';
import { FlexComponentTypes } from '../../componentTypes';
import {
	evaluateDateValue,
	evaluateNumericValue,
	evaluateTextValue,
} from '../../utils/validationRuleHelpers';
import { useSelector } from 'react-redux';
import AgGridDropdown from '../agGridDropdown';
import { useMarkSourceForUpdate, useRefreshData } from '../componentHooks';
import { Parser } from 'hot-formula-parser';
import {
	parseAggregationFormula,
	parseColumnName,
	parseFormula,
} from '../../utils/aggregationFormulaExtraction';
import { Fit, useMeasure } from '../../utils/contentRect';
import { useDeepCompareEffect, usePrevious } from 'react-use';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { invertedFontColor } from '../chart/chartUtil';
import {
	AGDataGridDateFilter,
	AGDataGridNumericFilter,
	AGDataGridPeriodsFilter,
	AGDataGridTextFilter,
} from './agDataGridFilter';
import { makeContainsFilter } from 'icm-core/lib/utils/filterUtils';
import * as CustomTablesAPIs from 'icm-rest-client/lib/controllers/customTables';
import { useQuery } from 'react-query';

if (process.env.AG_GRID_LICENSE_KEY) {
	LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE_KEY);
}

const messages = defineMessages({
	dataTableComponents: {
		id: 'components.datagrid.dataTableComponents',
		defaultMessage: 'Components',
	},
	exportTitle: {
		id: 'components.datagrid.exportTitle',
		defaultMessage: 'Export',
	},
	editSuccessMessage: {
		id: 'components.datagrid.editSuccess',
		defaultMessage: 'Information submitted successfully',
	},
	valueNotDefined: {
		id: 'components.datagrid.valueNotDefined',
		defaultMessage: 'Value not defined yet.',
	},
	submit: {
		id: 'components.datagrid.submit',
		defaultMessage: 'Submit',
	},
	rowTotal: {
		id: 'components.datagrid.rowTotal',
		defaultMessage: 'Total',
	},
	defaultObjectTitle: {
		id: 'components.datagrid.defaultObjectTitle',
		defaultMessage: 'Table {number}',
	},
});

const HASH_ALIAS = '_HashAlias';

const TopRightToaster = Toaster.create({
	position: Position.TOP_RIGHT,
	className: css`
		margin-top: 64px;
	`,
});

const useSharedRenderer = (
	ref: React.Ref<any>,
	_props?: React.ComponentPropsWithRef<CellRendererComponent>
) => {
	const classes = [Classes.TEXT_OVERFLOW_ELLIPSIS];
	useImperativeHandle(ref, () => ({
		getReactContainerClasses() {
			return classes;
		},
	}));
};

const getEditableRendererProps = (props: any) => {
	const edit = props.context.edits[props.data[HASH_ALIAS]];
	const field = props.colDef.field as string;
	const editableRendererProps = {
		validate: edit && edit[field] ? props.validate : undefined,
	} as any;
	return editableRendererProps;
};

const textValidation = (
	text: string,
	inputRules: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO[],
	options?: {
		currentWebUserId?: number;
		valuesMap?: Record<number, any>;
	}
) => {
	if (isNil(inputRules)) {
		return null;
	}
	for (let i = 0; i < inputRules.length; i++) {
		const rule = inputRules[i];
		if (
			!evaluateTextValue(text.trim().length === 0 ? null : text, rule, options)
		) {
			return rule.errorMessage;
		}
	}
	return null;
};

const numericValidation = (
	number: string,
	inputRules: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO[],
	options?: {
		valuesMap?: Record<number, any>;
	}
) => {
	if (isNil(inputRules)) {
		return null;
	}
	for (let i = 0; i < inputRules.length; i++) {
		const rule = inputRules[i];
		const parsedNumber = parseFloat(number);
		if (!evaluateNumericValue(parsedNumber, rule, options)) {
			return rule.errorMessage;
		}
	}
	return null;
};

const dateValidation = (
	date: string,
	inputRules: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO[],
	options?: {
		valuesMap?: Record<number, any>;
	}
) => {
	if (isNil(inputRules)) {
		return null;
	}
	for (let i = 0; i < inputRules.length; i++) {
		const rule = inputRules[i];
		if (!evaluateDateValue(new Date(date), rule, options)) {
			return rule.errorMessage;
		}
	}
	return null;
};

const StyledWrapper = styled.div<GridTextContainerStyleArgs>`
	display: flex;
	flex: 1 1 auto;
	overflow: hidden;
	height: fit-content;
	justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
	text-align: ${({ textAlign }) => textAlign ?? 'left'};
	align-items: ${({ alignItems }) => alignItems ?? 'center'};

	a,
	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: ${({ whiteSpace }) => whiteSpace ?? 'nowrap'};
		word-break: ${({ wordBreak }) => wordBreak ?? 'normal'};
		padding-right: ${({ fontStyle }) => (fontStyle === 'italic' ? 0.1 : 0)}em;
	}
`;
const SharedWrapper = forwardRef<
	any,
	React.ComponentPropsWithRef<CellRendererComponent>
>((props, ref) => {
	// we can hook into context to get info on conditions
	let style: GridTextContainerStyleArgs = {};
	const isFooterTitleCell = !!(
		props.node.rowPinned && typeof props.value === 'string'
	);

	style = props.node.rowPinned
		? generateFooterContainerStyle({
				colDef: props.colDef,
				columnStyleConfig: props.columnConfig,
				footerStyleConfig: props.footerConfig,
				isTitle: isFooterTitleCell,
		  })
		: evaluateStyle({
				params: props,
				colStyleConfig: props.columnConfig,
				columnConditions: props.columnConditions,
				aggregateResults: props.aggregateResults,
				tableStyleConfig: props.tableConfig,
		  });

	return (
		<StyledWrapper innerRef={ref} {...style}>
			{!props.node.rowPinned ? <ConditionalLink {...props} /> : props.children}
		</StyledWrapper>
	);
});

const DateRenderer = forwardRef<
	any,
	React.ComponentPropsWithRef<CellRendererComponent>
>((props, ref) => {
	useSharedRenderer(ref, props);
	const editableRendererProps = getEditableRendererProps(props);
	return (
		<AGEditableRenderer {...props} {...editableRendererProps}>
			<SharedWrapper {...props}>
				<FormattedDateRenderer {...props} />
			</SharedWrapper>
		</AGEditableRenderer>
	);
});

const DateTimeRenderer = forwardRef<
	any,
	React.ComponentPropsWithRef<CellRendererComponent>
>((props, ref) => {
	useSharedRenderer(ref, props);
	const editableRendererProps = getEditableRendererProps(props);
	return (
		<AGEditableRenderer {...props} {...editableRendererProps}>
			<SharedWrapper {...props}>
				<FormattedDateRenderer {...props} />
				<FormattedTime value={props.value} timeZone="UTC" />
			</SharedWrapper>
		</AGEditableRenderer>
	);
});

const NumericRenderer = forwardRef<
	any,
	React.ComponentPropsWithRef<CellRendererComponent>
>((props, ref) => {
	useSharedRenderer(ref, props);
	const editableRendererProps = getEditableRendererProps(props);

	return (
		<AGEditableRenderer {...props} {...editableRendererProps}>
			<SharedWrapper {...props}>
				{typeof props.value === 'number' ? (
					<FormattedNumberRenderer {...props} />
				) : typeof props.value === 'string' && props.value ? (
					<span>{props.value}</span>
				) : (
					<span>{'' as any}</span>
				)}
			</SharedWrapper>
		</AGEditableRenderer>
	);
});

const PicklistRenderer = forwardRef<
	any,
	React.ComponentPropsWithRef<CellRendererComponent>
>((props, ref) => {
	useSharedRenderer(ref, props);
	const editableRendererProps = getEditableRendererProps(props);
	return (
		<AGEditableRenderer {...props} {...editableRendererProps}>
			<SharedWrapper {...props}>
				<span>
					{typeof props.value === 'string'
						? props.value
						: // need to return empty string, not null. Due to ag-grid: https://www.ag-grid.com/react-hooks/#react-null
						  ('' as any)}
				</span>
			</SharedWrapper>
		</AGEditableRenderer>
	);
});

const TextRenderer = forwardRef<
	any,
	React.ComponentPropsWithRef<CellRendererComponent>
>((props, ref) => {
	useSharedRenderer(ref, props);
	const editableRendererProps = getEditableRendererProps(props);
	return (
		<AGEditableRenderer {...props} {...editableRendererProps}>
			<SharedWrapper {...props}>
				<span>
					{typeof props.value === 'string'
						? props.value
						: // need to return empty string, not null.  Due to ag-grid: https://www.ag-grid.com/react-hooks/#react-null
						  ('' as any)}
				</span>
			</SharedWrapper>
		</AGEditableRenderer>
	);
});

const AutoGroupRenderer = forwardRef<
	any,
	ICellRendererParams & {
		columnGroups:
			| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridColumnDTO[]
			| undefined;
		footerConfig: FooterConfig;
		children?: ReactNode;
	}
>((props, ref) => {
	useSharedRenderer(ref);
	if (props.node.rowPinned) {
		const columnName = props.colDef.showRowGroup;
		const columnConfig = props.columnGroups?.find(
			(c) => c.columnName === columnName
		);
		const style = generateFooterContainerStyle({
			colDef: props.colDef,
			columnStyleConfig: columnConfig?.extra ?? {},
			footerStyleConfig: props.footerConfig,
			isTitle: true,
		});
		return (
			<StyledWrapper {...style}>
				{props.valueFormatted ?? props.value}
			</StyledWrapper>
		);
	}
	return <>{props.children}</>;
});

const LoadingRenderer: React.FC<ILoadingCellRendererParams> = () => {
	return (
		<div
			className={Classes.SKELETON}
			style={{ minWidth: '100vw', minHeight: '1rem', marginLeft: '1rem' }}
		/>
	);
};

/**
 * A little state helper to allow use of a delayed version of a state.
 * @param initial what to start as
 * @param delay how many milliseconds to hold onto this old state before updating.
 */
const useDelayed = <T extends unknown>(
	tracking: T,
	delay: number,
	initial = tracking
) => {
	const [state, setState] = useState(initial);
	useEffect(() => {
		const handle = setTimeout(() => {
			setState(tracking);
		}, delay);
		return () => clearTimeout(handle);
	}, [delay, tracking]);
	return state;
};

export const getRendererForType: (
	type: NormalizedColumnType | 'picklist'
) => CellRendererComponent = (type) => {
	switch (type) {
		case 'date':
			return DateRenderer;
		case 'datetime':
			return DateTimeRenderer;
		case 'numeric':
			return NumericRenderer;
		case 'picklist':
			return PicklistRenderer;
		case 'text':
		default:
			return TextRenderer;
	}
};

const getEditorForType: (type: NormalizedColumnType | 'picklist') => any = (
	type
) => {
	switch (type) {
		case 'date':
			return AGDateCellEditor;
		case 'datetime':
			return AGDateCellEditor;
		case 'numeric':
			return AGNumericCellEditor;
		case 'picklist':
			return AgGridDropdown;
		case 'text':
		default:
			return AGTextCellEditor;
	}
};

const getValidateFunctionForType: (
	type: NormalizedColumnType,
	inputRules: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO[],
	options: any
) => (value: any) => string | null = (type, inputRules, options) => {
	switch (type) {
		case 'date':
		case 'datetime':
			return (value: string) => dateValidation(value, inputRules, options);
		case 'numeric':
			return (value: string) => numericValidation(value, inputRules, options);
		case 'text':
		default:
			return (value: string) => textValidation(value, inputRules, options);
	}
};

const parseEditorValue = (type: string, value: Date | string) => {
	switch (type) {
		case 'date':
		case 'datetime':
			return (value as Date).getTime();
		case 'numeric':
			return (value as string) === '' ? 0 : parseFloat(value as string);
		case 'text':
		default:
			return value;
	}
};

/*
 *  This function is used to calculate the maximum width of each column while the user is resizing.
 *  It calculates the full width of the component, as well as the minimum width required to fit all of the columns,
 *  and then it allocates the leftover space to each column from left to right.
 *  i.e. If the user makes column one larger, it will have a higher flex value, and less space will be allocated to
 *       the remaining columns.
 *
 *  This is re-calculated after each resize operation, with the goal of preventing the user from indirectly enlarging the table
 *  by forcing a strict maximum on each column.
 */
const MIN_COL_WIDTH = 136;
const getMaximumColumnSizes = ({
	componentWidth,
	columnConfigs,
	selectedFlexWidths,
	initialColumnWidths,
	gridWidth,
}: {
	componentWidth: number;
	columnConfigs: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridColumnDTO[];
	selectedFlexWidths: { [key: string]: number };
	initialColumnWidths: { [key: string]: number };
	gridWidth: number;
}) => {
	// User setting that expands the component space by a percentage > 100
	const multiplier = gridWidth / 100;
	const minimumUsedSpace = columnConfigs.length * MIN_COL_WIDTH;
	const maxGridWidth = Math.max(minimumUsedSpace, componentWidth) * multiplier;

	// Calculate the pixel width of each column, based on the size of the smallest column and the flex values
	let minFlexValue = Number.MAX_VALUE;
	let minColSize = MIN_COL_WIDTH;
	columnConfigs.forEach((col) => {
		if (
			selectedFlexWidths &&
			Number(selectedFlexWidths[col.columnName]) < Number(minFlexValue)
		) {
			minFlexValue = selectedFlexWidths[col.columnName];
			minColSize = initialColumnWidths[col.columnName] ?? MIN_COL_WIDTH;
		} else if (
			col.extra?.flexWidth &&
			Number(col.extra?.flexWidth) < Number(minFlexValue)
		) {
			minFlexValue = col.extra?.flexWidth;
			minColSize = initialColumnWidths[col.columnName] ?? MIN_COL_WIDTH;
		}
	});
	// Defaults to 100 if no flex values are chosen
	if (minFlexValue === Number.MAX_VALUE) minFlexValue = 100;

	// Track how much flex space is remaining for the table, decrement based on each columns calculated width
	let totalRemainingSpace = maxGridWidth - minimumUsedSpace;
	const maxColSizeMap: Record<string, number> = {};
	columnConfigs.forEach((col) => {
		// Flex values are relative, so divide by the smallest to get a simplified ratio
		let adjustedFlexWidth = 1;
		if (selectedFlexWidths && selectedFlexWidths[col.columnName]) {
			adjustedFlexWidth = selectedFlexWidths[col.columnName] / minFlexValue;
		} else if (col.extra?.flexWidth) {
			adjustedFlexWidth = col.extra?.flexWidth / minFlexValue;
		}
		// get the rough size of the current column
		const currentColWidth = adjustedFlexWidth * minColSize;
		// map the max size to MIN + whatever we have leftover
		maxColSizeMap[col.columnName] =
			MIN_COL_WIDTH + Math.max(totalRemainingSpace, 0);
		// reduce the leftover amount for the next columns, based on the extra being used here
		if (totalRemainingSpace > 0)
			totalRemainingSpace -= currentColWidth - MIN_COL_WIDTH;
	});

	return maxColSizeMap;
};

const editsStateToDataUpdateDTO: (
	editsState: IEdits,
	columnNameToType: Record<string, NormalizedColumnType>,
	componentId: number
) => Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexDataUpdateParamsDTO = (
	editsState,
	columnNameToType,
	componentId
) => {
	const editKeys = Object.keys(editsState);
	const dataUpdates = editKeys.map((hash) => {
		const values = Object.values(editsState[hash]).map(
			({ columnName, updated: value }) => {
				if (
					columnNameToType[columnName] === 'date' ||
					columnNameToType[columnName] === 'datetime'
				) {
					return {
						columnName,
						value: new Date(value).toISOString(),
					};
				}
				return {
					columnName,
					value,
				};
			}
		);
		return {
			hash,
			values,
		};
	});
	return { dataUpdates, componentId };
};

const ButtonWrapper = styled.div`
	margin-top: 1rem;
	display: flex;
	justify-content: flex-end;
`;

const { EditType } = Varicent.Domain.PresenterFlex.PresenterFlexSourceColumn;

const formulaParser = ({
	formula,
	data,
	aggregateResults,
}: {
	formula: string;
	data: any;
	aggregateResults?: object;
}) => {
	const parserFn = new Parser();
	Object.keys(data ?? {})?.forEach((key) => {
		parserFn.setVariable(`${parseColumnName(key)}`, data[key]);
	});
	if (aggregateResults)
		Object.keys(aggregateResults).forEach((key) => {
			parserFn.setVariable(`${parseColumnName(key)}`, aggregateResults[key]);
		});
	parserFn.setFunction('ISEMPTY', (val: string[]) => !val[0].toString());
	const parsedFormula = parseAggregationFormula(parseFormula(formula));
	return parserFn.parse(replace(/Source\./g, '', parsedFormula)).result;
};

const evaluateStyle = ({
	params,
	colStyleConfig,
	columnConditions,
	aggregateResults,
	tableStyleConfig,
	options,
}: {
	params: CellClassParams;
	colStyleConfig: ColumnConfig | undefined;
	columnConditions?: IConditionProps[];
	aggregateResults?: object;
	tableStyleConfig: TableConfig;
	options?: StyleOptions;
}) => {
	let conditionPassed: IConditionProps[] = [];
	if (columnConditions && params.data) {
		const parserFn = new Parser();
		Object.keys(params.data ?? {}).forEach((key) => {
			parserFn.setVariable(`${parseColumnName(key)}`, params.data[key]);
		});
		if (aggregateResults)
			Object.keys(aggregateResults).forEach((key) => {
				parserFn.setVariable(`${parseColumnName(key)}`, aggregateResults[key]);
			});
		parserFn.setFunction('ISEMPTY', (val: string[]) => !val[0].toString());
		conditionPassed = columnConditions.filter((condition) => {
			const parsedFormula = parseAggregationFormula(
				parseFormula(condition.formula)
			);
			if (condition.applyToOptions === 'values')
				return parserFn.parse(replace(/Source\./g, '', parsedFormula)).result;
			return false;
		});
	}

	const styleConfigs = conditionPassed?.length
		? { ...colStyleConfig, cellStyle: conditionPassed[0] }
		: colStyleConfig;

	return generateCellContainerStyle({
		colDef: params.colDef,
		columnStyleConfig: styleConfigs,
		tableStyleConfig,
		options,
	});
};

const getCellStyle = (args: {
	params: CellClassParams;
	colStyleConfig: ColumnConfig;
	columnConditions?: IConditionProps[];
	aggregateResults?: object;
	tableStyleConfig: TableConfig;
	options?: StyleOptions;
}) => {
	const style = evaluateStyle(args);
	const editable = !!args.options?.editable;
	return {
		display: 'flex',
		alignItems: style.alignItems,
		justifyContent: style.justifyContent,
		padding: `${editable ? 0 : 10}px 17px`,
		backgroundColor: style.backgroundColor ?? '#FFFFFF',
		color: style.color ?? '#000000',
		fontSize: args.options?.editable ? '14px' : style.fontSize,
		height: '100%',
		fontStyle: style.fontStyle ?? 'normal',
		fontWeight: style.fontWeight ?? 400,
		textDecoration: style.textDecoration,
	};
};

const getRowGroupStyle = ({
	params,
	rowGroupStyleConfig,
	options,
}: {
	params: CellClassParams;
	rowGroupStyleConfig: RowGroupConfig;
	options?: StyleOptions;
}) => {
	const style = generateRowGroupContainerStyle({
		colDef: params.colDef,
		rowGroupStyleConfig,
		options,
	});

	return {
		display: 'flex',
		alignItems: style.alignItems,
		justifyContent: style.justifyContent,
		padding: '10px 17px',
		backgroundColor: style.backgroundColor ?? '#FFFFFF',
		color: style.color ?? '#000000',
		fontSize: style.fontSize,
		height: '100%',
		fontStyle: style.fontStyle ?? 'normal',
		fontWeight: style.fontWeight ?? 400,
		textDecoration: style.textDecoration,
	};
};

const getFooterStyle = (
	params: any,
	columnStyleConfig: ColumnConfig,
	footerStyleConfig: FooterConfig
) => {
	const isTitle = typeof params.value === 'string';
	const style = generateFooterContainerStyle({
		colDef: params.colDef,
		columnStyleConfig,
		footerStyleConfig,
		isTitle,
	});
	return {
		display: 'flex',
		alignItems: style.alignItems,
		justifyContent: style.justifyContent,
		padding: '10px 17px',
		backgroundColor: style.backgroundColor ?? '#FFFFFF',
		color: style.color ?? '#000000',
		fontSize: style.fontSize,
		height: '100%',
		fontStyle: style.fontStyle ?? 'normal',
		fontWeight: style.fontWeight ?? 400,
		textDecoration: style.textDecoration,
	};
};

const getHeaderClass = ({
	params,
	colStyleConfig,
	columnConditions,
	aggregateResults,
	headerStyleConfig,
	forceColumnWidthsPanelStyle,
}: {
	params: any;
	colStyleConfig: ColumnConfig;
	columnConditions?: IConditionProps[];
	aggregateResults?: object;
	headerStyleConfig: HeaderConfig;
	forceColumnWidthsPanelStyle?: boolean;
}) => {
	let conditionPassed;
	if (columnConditions && aggregateResults) {
		const parserFn = new Parser();
		Object.keys(aggregateResults).forEach((key) => {
			parserFn.setVariable(`${parseColumnName(key)}`, aggregateResults[key]);
		});

		conditionPassed = columnConditions.filter((condition) => {
			const parsedFormula = parseAggregationFormula(
				parseFormula(condition.formula)
			);
			if (condition.applyToOptions === 'header') {
				return parserFn.parse(parsedFormula).result;
			}
			return false;
		});
	}

	const styleConfigs = conditionPassed?.length
		? { cellStyle: conditionPassed[0] }
		: colStyleConfig;

	const headerStyles = conditionPassed?.length
		? conditionPassed[0]
		: { ...headerStyleConfig };

	if (forceColumnWidthsPanelStyle) {
		headerStyles.fontColor = colorDarkGray3;
		headerStyles.backgroundColor = colorLightGray5;
		headerStyles.hoverEffectBackgroundColor = colorCobalt3;
	}
	const style = generateHeaderContainerStyle(
		params.colDef,
		styleConfigs,
		headerStyles
	);
	return css`
		${generateHeaderClass(style, params.colDef)}
	`;
};

type IEdits = {
	[hash: string]: {
		[columnName: string]: {
			original: any;
			updated: any;
			columnName: string;
		};
	};
};

type IErrors = {
	[hash: string]: {
		[columnName: string]: string;
	};
};

type IOriginalValues = {
	[hash: string]: {
		[columnName: string]: any;
	};
};

const emptyObject: unknown = {};

const DataGrid: React.FC<
	SharedComponentProps<Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridDTO> &
		InjectedIntlProps
> = ({
	config,
	source,
	preview,
	reportId,
	sourceSchema,
	intl,
	componentId,
}) => {
	const markSourcesForUpdate = useMarkSourceForUpdate();
	const internalPublish = useContext(PublishMode);
	const {
		metadata,
		values: presFlexValues,
		sources,
		sourceSchemas,
		components,
	} = useContext(ReportContext);
	const reportValues = useContext(ValueStore);
	const [defaultObjectName, setDefaultObjectName] = useState('');
	const prevsortOptions = usePrevious(config.dataGridSortOptions ?? []);
	const [sortOptions, setSortOptions] = useState(
		config.dataGridSortOptions ?? []
	);
	const time = useQuery(
		['table-time', sourceSchema],
		() => {
			const isDataStore =
				(source && !!source.sourceReference?.dataStoreId) ?? false;
			const isCalculation =
				(source && !!source.sourceReference?.calculationId) ?? false;

			if (sourceSchema && !isDataStore && !isCalculation)
				return CustomTablesAPIs.getAvailableTable(sourceSchema.table).then(
					(res) => res.time
				);
			return {} as unknown as Varicent.RESTAPI.v1.DTOs.TableTimeDTO;
		},
		{
			enabled: !!sourceSchema,
		}
	);
	const timeData = time.data;

	useEffect(() => {
		if (
			prevsortOptions &&
			config.dataGridSortOptions &&
			prevsortOptions !== config.dataGridSortOptions
		) {
			setSortOptions(config.dataGridSortOptions ?? []);
		}
	}, [config.dataGridSortOptions, prevsortOptions]);

	useEffect(() => {
		let tableCount = 0;
		components?.forEach((component) => {
			if (component.config.dataGrid) {
				tableCount++;
			}
			if (component.componentId === componentId) {
				if (component.config.dataGrid) {
					setDefaultObjectName(
						intl.formatMessage(messages.defaultObjectTitle, {
							number: tableCount,
						})
					);
				}
			}
		});
	}, [setDefaultObjectName, components, intl, componentId]);

	const { selectedColumnWidthsInfo, updateSelectedColumnWidthsInfo } =
		useContext(ColumnWidthsContext);
	const userId = useSelector((state: any) => state?.user?.payeeId);
	const { payeeId: previewPayeeId } = useContext(LiveDataPayeeContext);
	const { isLiveDataActive, isUsingLiveData } = useLivePreviewInfo({ preview });
	const { decrementDataLoadedCount, incrementDataLoadedCount } = useContext(
		ComponentDataLoadedContext
	);

	const [key, setKey] = useState(0);
	const [errors, setErrors] = useState<IErrors>({});
	const [edits, setEdits] = useState<IEdits>({});
	const [originalValues, setOriginalValues] = useState<IOriginalValues>({});
	const [initialColumnWidths, setInitialColumnWidths] = useState<{
		[key: string]: number;
	}>({});
	const [submitError, setSubmitError] = useState<string | null>(null);
	const [isSubmittingEdits, setIsSubmittingEdits] = useState(false);
	const [oneRowSelected, setOneRowSelected] = useState(false);

	const [ref, contentRect] = useMeasure();
	const gridApi = useRef<GridApi>();
	const columnApi = useRef<ColumnApi>();
	const defaultTableProps = useDefaultProps();
	const validationOptions = useMemo(
		() => ({
			currentWebUserId: userId,
			valuesMap: reportValues,
			values: presFlexValues,
		}),
		[userId, reportValues, presFlexValues]
	);

	const isSelectedComponent =
		selectedColumnWidthsInfo.isPanelOpen &&
		selectedColumnWidthsInfo.componentId === componentId;
	const selectedColumnWidthType = isSelectedComponent
		? selectedColumnWidthsInfo.selectedWidthType
		: undefined;
	const selectedFlexWidths = isSelectedComponent
		? selectedColumnWidthsInfo.selectedFlexValues
		: undefined;
	const selectedGridWidth = isSelectedComponent
		? selectedColumnWidthsInfo.selectedGridWidth
		: undefined;

	const onParamsChange = useCallback(() => {
		/*
		 * use an incrementing key in order to trigger a full refresh
		 * of data for this datagrid.
		 */
		setKey((prev) => prev + 1);
	}, []);

	const {
		datasource,
		columnNameToType,
		totalRowCount,
		subTotalResults,
		aggregateResults,
		keyedColumnConditions,
		aggregateInfo,
	} = useAgGridDataSource({
		source,
		preview,
		previewPayeeId,
		reportId,
		sourceSchema,
		fakeDataSize: config.pageSize ? config.pageSize * 1.5 : 5,
		onParamsChange,
		cellConditionSettings: config && config.cellConditionSettings,
		presFlexValues,
		sortOptions,
		configuredColumns: config.columns,
	});

	/*
	 * use a delayed version of this to avoid flash of
	 * content as parent component needs to hide this element
	 */
	const delayedTotalRowCount = useDelayed(totalRowCount, 0);

	// TODO: Implement text styling for specific rows like alternating rows, section headers, etc
	const headerConfig: HeaderConfig = config.extra?.headerStyle ?? emptyObject;
	const footerConfig: FooterConfig = config.extra?.footerStyle ?? emptyObject;
	const tableConfig: TableConfig = config.extra?.tableStyle;

	const headerHideBackground = headerConfig?.hideBackground;
	const headerBackgroundColor = headerConfig?.backgroundColor;
	const footerHideBackground = footerConfig?.hideBackground;
	const footerBackgroundColor = footerConfig?.backgroundColor;
	const hideAllTile = metadata?.extra?.hideAllTile;
	const invertedDisabledColor = '#d3d3d3';

	const refreshColumnWidths = useCallback(() => {
		if (!columnApi.current) {
			return;
		}
		const columnWidthType = selectedColumnWidthType ?? config.columnWidthType;
		if (columnWidthType === ColumnWidthType.Auto) {
			setTimeout(() => {
				columnApi.current?.autoSizeAllColumns(false);
			}, 50);
		} else if (columnWidthType === ColumnWidthType.Custom) {
			const colWidths = columnApi.current.getAllDisplayedColumns()?.reduce(
				(obj, col) => ({
					...obj,
					[getColumnId(col.getColId())]: col.getActualWidth(),
				}),
				{}
			);
			setInitialColumnWidths(colWidths);
		} else {
			gridApi.current?.sizeColumnsToFit();
		}
	}, [selectedColumnWidthType, config.columnWidthType, gridApi.current]);

	const setRowGroupedColumnFlex = useCallback(() => {
		if (!columnApi.current) return;
		const colState = columnApi.current?.getColumnState();
		const updatedColState = colState.map((col) => {
			const ret = { ...col };
			if (ret.colId?.includes('ag-Grid-AutoColumn-')) {
				const colName = getColumnId(ret.colId);
				const colConfig = config.columnGroups?.find(
					(colGroup) => colGroup.columnName === colName
				);
				ret.flex = Number(
					selectedFlexWidths?.[colName] ?? colConfig?.extra?.flexWidth
				);
			}
			return ret;
		});
		columnApi.current.setColumnState(updatedColState);
	}, [selectedFlexWidths, config.columnGroups]);

	const setRowTotal = useCallback(() => {
		if (
			(!isSelectedComponent &&
				config.columnWidthType !== ColumnWidthType.Custom) ||
			(isSelectedComponent &&
				selectedColumnWidthType !== ColumnWidthType.Custom)
		) {
			refreshColumnWidths();
		}

		const hideRowTotal = footerConfig.hideRowTotal ?? false;
		if (
			!hideRowTotal &&
			config.columns?.some((c) => {
				return c.aggFunc && !c.hideColumn;
			})
		) {
			const firstColShown =
				config.columns.find((col) => !col.hideColumn) || config.columns[0];
			const totalCol =
				config.columnGroups && !isEmpty(config.columnGroups)
					? config.columnGroups[0]
					: firstColShown;
			const rowTitle =
				isNil(footerConfig.title) || isEmpty(footerConfig.title)
					? intl.formatMessage(messages.rowTotal)
					: footerConfig.title;
			const rowKey =
				config.columnGroups && !isEmpty(config.columnGroups)
					? totalCol.displayName // for some reason AG Grid wants the displayName for groups
					: totalCol.columnName;
			const row: { [key: string]: string | number } = {
				[rowKey]: rowTitle,
			};
			const columnsWithAgg = config.columns.filter((c) => c.aggFunc);
			columnsWithAgg.forEach((c) => {
				const aggKey = `${c.columnName}_${c.aggFunc}`;
				row[c.columnName] =
					subTotalResults && subTotalResults[aggKey] !== undefined
						? subTotalResults[aggKey]
						: '';
			});
			if (!isNil(subTotalResults) && !isEmpty(subTotalResults)) {
				gridApi.current?.setPinnedBottomRowData([row]);
			}
		} else {
			gridApi.current?.setPinnedBottomRowData([]);
		}
	}, [
		gridApi,
		isSelectedComponent,
		selectedColumnWidthType,
		refreshColumnWidths,
		footerConfig,
		config.columns,
		config.columnWidthType,
		config.columnGroups,
		intl,
		subTotalResults,
		messages,
	]);
	useEffect(setRowTotal, [setRowTotal]);

	const prevPageSize = usePrevious(config.pageSize);
	const prevNumValCol = usePrevious(
		config.columns.filter((c) => c.aggFunc).length
	);
	const prevColGrpOrder = usePrevious(
		config?.columnGroups?.map((c) => c.columnName)
	);
	useEffect(() => {
		if (
			(preview &&
				(prevPageSize !== config.pageSize ||
					prevNumValCol !== config.columns.filter((c) => c.aggFunc).length)) ||
			!isEqual(
				prevColGrpOrder,
				config?.columnGroups?.map((c) => c.columnName)
			)
		) {
			setKey((prev) => prev + 1);
		}
	}, [
		config.pageSize,
		prevPageSize,
		config.columns,
		prevNumValCol,
		config?.columnGroups,
		prevColGrpOrder,
		preview,
	]);

	const onGridReady = useCallback(
		(event: GridReadyEvent) => {
			gridApi.current = event.api;
			columnApi.current = event.columnApi;

			setRefreshReason((prev) => ({ ...prev, datasource: true }));
			if (internalPublish) {
				event.api.setDomLayout('print');
			}
			if (setRowTotal) setRowTotal();
			if (config.columnWidthType === ColumnWidthType.Custom)
				setRowGroupedColumnFlex();
			refreshColumnWidths();
		},
		[
			internalPublish,
			setRowTotal,
			config.columnWidthType,
			refreshColumnWidths,
			setRowGroupedColumnFlex,
		]
	);
	const configColumns = useMemo(
		() => indexBy(prop('columnName'), source?.columns ?? []),
		[source?.columns]
	);

	const maxColSizeMap = useDeepMemo(
		useMemo(
			() =>
				isSelectedComponent &&
				selectedColumnWidthType === ColumnWidthType.Custom
					? getMaximumColumnSizes({
							columnConfigs: (config.columnGroups ?? []).concat(config.columns),
							selectedFlexWidths: selectedFlexWidths ?? {},
							componentWidth: contentRect.width,
							initialColumnWidths,
							gridWidth: selectedGridWidth ?? 100,
					  })
					: {},
			[
				config.columns,
				config.columnGroups,
				selectedFlexWidths,
				contentRect.width,
				initialColumnWidths,
				isSelectedComponent,
				selectedGridWidth,
				selectedColumnWidthType,
			]
		)
	);

	const isResizingColumns =
		isSelectedComponent && selectedColumnWidthType === ColumnWidthType.Custom;
	const columnDefs = useMemo(() => {
		const getColDef: (
			col: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDataGridColumnDTO,
			isColGrp?: boolean
		) => ColDef = (col, isColGrp) => {
			const columnWidthType = selectedColumnWidthType ?? config.columnWidthType;
			const editable =
				!config.columnGroups?.length &&
				configColumns[col.columnName]?.editable === EditType.AllowUpdate;
			const colType = columnNameToType[col.columnName];
			const firstColShown =
				config.columns.find((column) => !column.hideColumn) ||
				config.columns[0];
			const firstColName =
				config.columnGroups && !isEmpty(config.columnGroups)
					? config.columnGroups?.[0]?.columnName
					: firstColShown?.columnName;
			const columnConditions =
				keyedColumnConditions && keyedColumnConditions[col.columnName];
			const renderType =
				sourceSchema?.referencedSourcesDictionary[col.columnName] && editable
					? 'picklist'
					: colType;
			const columnConfig: ColumnConfig = col.extra;
			const inputRules = (config.dataEditOptions?.inputRules ?? []).filter(
				(r) => r.columnName === col.columnName
			);
			const formatValue = (value: any) => {
				if (
					columnNameToType[col.columnName] === 'date' ||
					columnNameToType[col.columnName] === 'datetime'
				) {
					try {
						return isNil(value)
							? ''
							: intl.formatDate(value, { timeZone: 'UTC' });
					} catch (e) {
						return '';
					}
				}
				if (!!col.extra?.formula || (!!col.formula && value === '')) {
					return '';
				}
				if (columnNameToType[col.columnName] === 'numeric' && value === '') {
					return '0';
				}
				return value;
			};

			const formatHeaderValue = (columnValueId) => {
				const headerValue = presFlexValues?.find(
					(v) => v.valueId === columnValueId
				);

				if (headerValue) {
					const headerValueType = columnNameToType[headerValue.name];
					if (
						headerValue.dataType === 'Date' ||
						headerValueType === 'date' ||
						headerValueType === 'datetime'
					) {
						try {
							return formattedDateString(
								!Number.isNaN(Number(reportValues[columnValueId]))
									? Number(reportValues[columnValueId])
									: Number(new Date(reportValues[columnValueId])),
								{
									valueFormat: {
										formatDateOption:
											DateFormatOption[
												headerValue.config.stored.formatDateOption ??
													DateFormatOption.MM_DD_YYYY.toString()
											],
									},
								},
								intl
							);
						} catch (e) {
							return '';
						}
					}
				}
				return reportValues[columnValueId];
			};

			const validate = (value: any) => {
				try {
					const error = getValidateFunctionForType(
						colType,
						inputRules,
						validationOptions
					)(value);
					return error;
				} catch (e) {
					return '';
				}
			};

			const colSource =
				renderType === 'picklist'
					? sources?.find((s) => s.sourceId === col.sourceID)
					: undefined;
			const parameterName =
				configColumns[col.columnName]?.bpmFormParameter ?? undefined;
			const cellRendererParams = {
				columnDTO: col,
				columnNameToType,
				columnConfig,
				columnConditions,
				aggregateResults,
				source: colSource,
				sourceSchema:
					renderType === 'picklist' && colSource && sourceSchemas
						? sourceSchemas[colSource.sourceId]
						: undefined,
				reportId,
				formatValue,
				intl,
				preview,
				validate,
				footerConfig,
				tableConfig,
				parameterName,
			};
			const sortIdx =
				sortOptions.findIndex((sort) => sort.columnName === col.columnName) ??
				-1;
			const columnType = columnNameToType[col.columnName];

			let filter;
			if (!preview && !col.formula) {
				if (columnType === 'text') {
					filter = 'textFilter';
				} else if (columnType === 'numeric') {
					filter = 'numberFilter';
				} else if (timeData?.dateColumn === col.columnName) {
					filter = 'periodFilter';
				} else {
					filter = 'dateFilter';
				}
			}

			return {
				field: col.columnName,
				flex:
					columnWidthType === ColumnWidthType.Custom
						? selectedFlexWidths?.[col.columnName] ??
						  columnConfig?.flexWidth ??
						  100
						: undefined,
				minWidth: MIN_COL_WIDTH,
				maxWidth: maxColSizeMap[col.columnName],
				rowGroup: isColGrp,
				hide: isColGrp || col.hideColumn,
				openByDefault: true,
				aggFunc: col.aggFunc,
				cellRendererFramework: getRendererForType(renderType),
				pinnedRowCellRendererFramework: getRendererForType(
					firstColName === col.columnName && !col.aggFunc ? 'text' : renderType
				),
				pinnedRowCellRendererParams: cellRendererParams,
				cellRendererParams,
				cellEditorFramework: getEditorForType(renderType),
				cellEditorParams: {
					onChange: (event) => handleUpdate.current(event, col.columnName),
					formatValue,
					validate,
					picklistConfig: col.picklistConfig,
					sourceId: col.sourceID,
					additionalDataSourceArgs: {
						reportId,
					},
					preview,
					isFormula: !!col.formula,
					formula: col.formula,
					dataGridPayeeId:
						renderType === 'picklist' && preview ? previewPayeeId : undefined,
				},
				headerValueGetter: () => {
					if (col.valueId) {
						if (preview && !isUsingLiveData)
							intl.formatMessage(messages.valueNotDefined);
						else {
							const val = formatHeaderValue(col.valueId);
							return (val || col.displayName) ?? col.columnName;
						}
					}
					return col.displayName ?? col.columnName;
				},
				editable: (params) => !params.node.rowPinned && editable,
				cellClassRules: {
					'vds-editable-cell': (params) => !params.node.rowPinned && editable,
				},
				cellStyle: (params: CellClassParams) => {
					if (params.node.group) {
						const rowGruopConfig = config.columnGroups?.find(
							(c) => c.columnName === params.node.field
						)?.extra?.rowGroupStyle;
						return getRowGroupStyle({
							params,
							rowGroupStyleConfig: rowGruopConfig,
							options: {
								hideTile: config.hideTile || hideAllTile,
							},
						});
					}
					if (params.node.rowPinned) {
						return getFooterStyle(params, columnConfig, footerConfig);
					}
					return getCellStyle({
						params,
						colStyleConfig: columnConfig,
						columnConditions,
						aggregateResults,
						tableStyleConfig: tableConfig,
						options: {
							editable,
							hideTile: config.hideTile || hideAllTile,
							preventDefault: true,
						},
					});
				},
				headerClass: (params) =>
					getHeaderClass({
						params,
						colStyleConfig: columnConfig,
						columnConditions,
						aggregateResults,
						headerStyleConfig: headerConfig,
						forceColumnWidthsPanelStyle: isResizingColumns,
					}),
				onCellValueChanged: refreshCell,
				resizable: !preview || isResizingColumns,
				valueGetter: (params) => {
					const hideValue = config.columnGroups?.find(
						(c) => c.displayName === params.node.field
					)?.extra?.rowGroupStyle?.hideValue;
					if (params.colDef?.cellEditorParams?.isFormula) {
						const value = formulaParser({
							formula: params.colDef?.cellEditorParams.formula,
							data: params.data,
							aggregateResults,
						});
						if (preview && !isUsingLiveData && !value)
							return intl.formatMessage(messages.valueNotDefined);

						return typeof value === 'boolean'
							? replace(/^./, toUpper, value.toString())
							: value;
					}
					const text = params.colDef.field
						? params.data?.[params.colDef.field]
						: undefined;
					return params.node.group &&
						params.node.field !== params.colDef.field &&
						hideValue
						? undefined
						: text;
				},
				sortable: !(!!col.formula ?? false),
				sort:
					sortIdx > -1 ? sortOptions[sortIdx].type.toLowerCase() : undefined,
				sortIndex: sortIdx > -1 ? sortIdx : undefined,
				filter,
				filterParams: {
					time: timeData,
					getFilterSuggestions: async (
						filterModel: Record<string, Filter> | undefined,
						suggestFilter
					) => {
						let result: string[] = [];

						let filterBy =
							filterModel && filterModel[col.columnName].filter
								? buildFilteredByFromAgFilterModel(filterModel).trim()
								: undefined;

						if (!isEmpty(suggestFilter)) {
							if (!filterBy) {
								filterBy = makeContainsFilter(col.columnName, suggestFilter);
							} else {
								filterBy = filterBy.concat(
									'&&',
									makeContainsFilter(col.columnName, suggestFilter).trim()
								);
							}
						}

						if (reportId && source) {
							const resultString: string = await (preview || previewPayeeId
								? getSourceRows
								: getSourceRowsForPayee)(
								reportId,
								source.sourceId,
								{
									params: {},
								},
								{
									limit: 100,
									filterBy,
									suggestedValuesColumn: col.columnName,
								}
							);

							const baseResult: string[] = [];

							result = resultString.split('\n').reduce((acc, item, index) => {
								if (!item || index === 0) {
									return acc;
								}
								const parsed = JSON.parse(item);
								const obj = parsed[0];
								// it will be an object if the value of the row is null
								if (typeof obj !== 'string') {
									return acc;
								}
								return [...acc, obj];
							}, baseResult);
						}
						return result;
					},
				},
			};
		};

		const colGrpDefs: ColDef[] =
			config.columnGroups?.map((col) => {
				return getColDef(col, true);
			}) ?? [];
		const colDefs: ColDef[] =
			config.columns?.map((col) => {
				return getColDef(col);
			}) ?? [];
		return colGrpDefs.concat(colDefs);
	}, [
		config.columns,
		config.columnGroups,
		configColumns,
		sourceSchema,
		columnNameToType,
		sources,
		sourceSchemas,
		reportId,
		tableConfig,
		headerConfig,
		footerConfig,
		keyedColumnConditions,
		aggregateResults,
		selectedColumnWidthType,
		config.columnWidthType,
		config.dataEditOptions?.inputRules,
		sortOptions,
		config.hideTile,
		hideAllTile,
		intl,
		preview,
		validationOptions,
		isResizingColumns,
		maxColSizeMap,
		selectedFlexWidths,
		timeData,
	]);

	const autoGroupColDef: ColDef = useMemo(() => {
		const columnWidthType = selectedColumnWidthType ?? config.columnWidthType;

		return {
			valueGetter: (params) => {
				const text = params.colDef.headerName
					? params.data?.[params.colDef.headerName]
					: undefined;
				return params.node.rowPinned ? text : undefined;
			},
			cellClassRules: {
				'group-color': () => true,
				'group-top-align': (params) => {
					const cellStyle = config.columnGroups?.find(
						(c) => c.displayName === params.colDef.headerName
					)?.extra?.cellStyle;
					const tableAlignment =
						tableConfig?.alignment?.v !== 'bottom' &&
						tableConfig?.alignment?.v !== 'center';
					const cellAlignment =
						cellStyle?.alignment?.v !== 'bottom' &&
						cellStyle?.alignment?.v !== 'center';
					return cellStyle?.alignmentMode === 'custom'
						? cellAlignment
						: tableConfig?.alignmentMode === 'custom'
						? tableAlignment
						: false;
				},
				'group-bottom-align': (params) => {
					const cellStyle = config.columnGroups?.find(
						(c) => c.displayName === params.colDef.headerName
					)?.extra?.cellStyle;
					const tableAlignment = tableConfig?.alignment?.v === 'bottom';
					const cellAlignment = cellStyle?.alignment?.v === 'bottom';
					return cellStyle?.alignmentMode === 'custom'
						? cellAlignment
						: tableConfig?.alignmentMode === 'custom'
						? tableAlignment
						: false;
				},
				'group-horizontal-align-1': (params) => {
					const cellStyle = config.columnGroups?.find(
						(c) => c.displayName === params.colDef.headerName
					)?.extra?.cellStyle;
					const tableAlignment =
						tableConfig?.alignment?.h === 'right' ||
						tableConfig?.alignment?.h === 'center';
					const cellAlignment =
						cellStyle?.alignment?.h === 'right' ||
						cellStyle?.alignment?.h === 'center';
					return cellStyle?.alignmentMode === 'custom'
						? cellAlignment
						: tableConfig?.alignmentMode === 'custom'
						? tableAlignment
						: false;
				},
				'group-horizontal-align-2': (params) => {
					const cellStyle = config.columnGroups?.find(
						(c) => c.displayName === params.colDef.headerName
					)?.extra?.cellStyle;
					const tableAlignment = tableConfig?.alignment?.h !== 'right';
					const cellAlignment = cellStyle?.alignment?.h !== 'right';
					return cellStyle?.alignmentMode === 'custom'
						? cellAlignment
						: tableConfig?.alignmentMode === 'custom'
						? tableAlignment
						: false;
				},
				'hide-child-count': (params) => {
					const hideChildCount = config.columnGroups?.find(
						(c) => c.displayName === params.colDef.headerName
					)?.extra?.rowGroupStyle?.hideCount;
					return hideChildCount;
				},
			},
			cellStyle: (params: CellClassParams) => {
				const columnName = params.colDef.showRowGroup;
				const columnConfig = config.columnGroups?.find(
					(c) => c.columnName === columnName
				);
				if (params.node.group) {
					const rowGruopConfig = config.columnGroups?.find(
						(c) => c.columnName === params.node.field
					)?.extra?.rowGroupStyle;
					return getRowGroupStyle({
						params,
						rowGroupStyleConfig: rowGruopConfig,
						options: {
							hideTile: config.hideTile || hideAllTile,
						},
					});
				}
				if (params.node.rowPinned) {
					return getFooterStyle(
						params,
						columnConfig?.extra ?? {},
						footerConfig
					);
				}
				return getCellStyle({
					params,
					colStyleConfig: columnConfig?.extra ?? {},
					tableStyleConfig: tableConfig,
					options: {
						hideTile: config.hideTile || hideAllTile,
						preventDefault: true,
					},
				});
			},
			pinnedRowCellRendererParams: {
				columnGroups: config.columnGroups,
				footerConfig,
				columnNameToType,
			},
			pinnedRowCellRendererFramework: AutoGroupRenderer,
			headerClass: (params) => {
				const columnName = params.colDef.headerName;
				const columnConfig = config.columnGroups?.find(
					(c) => c.columnName === columnName
				);
				return getHeaderClass({
					params,
					colStyleConfig: columnConfig?.extra ?? {},
					headerStyleConfig: headerConfig,
					forceColumnWidthsPanelStyle:
						isSelectedComponent &&
						selectedColumnWidthType === ColumnWidthType.Custom,
				});
			},
			minWidth: MIN_COL_WIDTH,
			resizable:
				!isSelectedComponent ||
				(isSelectedComponent &&
					selectedColumnWidthType === ColumnWidthType.Custom),
			flex: columnWidthType === ColumnWidthType.Custom ? 100 : undefined,
			initialWidth: MIN_COL_WIDTH,
		};
	}, [
		config.columnGroups,
		footerConfig,
		headerConfig,
		tableConfig,
		isSelectedComponent,
		selectedColumnWidthType,
		config.columnWidthType,
		columnNameToType,
		config.hideTile,
		hideAllTile,
	]);

	/*
	 * The following section must ensure that each of these blocks is only called ONCE
	 * on a datasource change OR columns change
	 */
	const [refreshReason, setRefreshReason] = useState<{
		datasource: boolean;
		columnDefs: boolean;
	}>({ datasource: false, columnDefs: false });
	const prevDatasource = usePrevious(datasource);
	const prevColumnDefs = usePrevious(columnDefs);

	useEffect(() => {
		if (prevDatasource && prevDatasource !== datasource) {
			setRefreshReason((prev) =>
				prev.datasource ? prev : { ...prev, datasource: true }
			);
		}
		if (prevColumnDefs && columnDefs !== prevColumnDefs) {
			setRefreshReason((prev) =>
				prev.columnDefs ? prev : { ...prev, columnDefs: true }
			);
		}
	}, [datasource, prevDatasource, prevColumnDefs, columnDefs]);
	const delayedRefreshReasonForResizing = useDelayed(refreshReason, 100);
	const prevDelayedRefreshReasonForResizing = usePrevious(
		delayedRefreshReasonForResizing
	);

	useEffect(() => {
		if (
			gridApi.current &&
			isResizingColumns &&
			prevDelayedRefreshReasonForResizing !== delayedRefreshReasonForResizing &&
			delayedRefreshReasonForResizing.columnDefs
		) {
			gridApi.current.setAutoGroupColumnDef(autoGroupColDef);
			gridApi.current.setColumnDefs(columnDefs);
			gridApi.current.refreshHeader();
			setRefreshReason({ datasource: false, columnDefs: false });
		}
	}, [
		isResizingColumns,
		delayedRefreshReasonForResizing,
		columnDefs,
		autoGroupColDef,
		prevDelayedRefreshReasonForResizing,
	]);

	const delayedRefreshReason = useDelayed(refreshReason, preview ? 600 : 100);
	const initialColumnDefs = useRef(columnDefs);
	const initialAutoGroupColDef = useRef(autoGroupColDef);
	const prevDelayedRefreshReason = usePrevious(delayedRefreshReason);

	useEffect(() => {
		if (
			gridApi.current &&
			prevDelayedRefreshReason !== delayedRefreshReason &&
			(delayedRefreshReason.columnDefs || delayedRefreshReason.datasource) &&
			!isResizingColumns
		) {
			if (delayedRefreshReason.datasource) {
				gridApi.current.setServerSideDatasource({
					getRows: () => {},
				});
			}
			gridApi.current.setAutoGroupColumnDef(autoGroupColDef);
			if (delayedRefreshReason.datasource) {
				if (preview) gridApi.current.setColumnDefs([]);
				gridApi.current.setColumnDefs(columnDefs);
				gridApi.current.setServerSideDatasource(datasource);
			}
			if (delayedRefreshReason.datasource) {
				gridApi.current.expandAll();
			}
			if (delayedRefreshReason.columnDefs && !delayedRefreshReason.datasource) {
				gridApi.current.setColumnDefs(columnDefs);
			}
			gridApi.current.refreshHeader();
			gridApi.current.refreshCells({
				force: true,
			});

			setRefreshReason({ datasource: false, columnDefs: false });
		}
	}, [
		datasource,
		delayedRefreshReason,
		columnDefs,
		autoGroupColDef,
		preview,
		prevDelayedRefreshReason,
		isResizingColumns,
	]);

	const { set: setComponentVisibility } = useContext(ComponentVisibility);

	const resetSubmitStates = useCallback(() => {
		setSubmitError(null);
		setOriginalValues({});
		setEdits({});
	}, []);
	useRefreshData(
		componentId,
		useCallback(() => {
			gridApi.current?.purgeServerSideCache();
			resetSubmitStates();
		}, [resetSubmitStates])
	);

	useEffect(() => {
		if (config.hideOnEmpty) {
			setComponentVisibility(
				componentId,
				totalRowCount === null
					? config.placeholderOnEmpty ?? false
					: totalRowCount > 0
			);
		}
	}, [totalRowCount, config, setComponentVisibility, componentId]);

	const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>(
		Pagination.defaultPageInfo
	);

	const { agGridProps, paginationProps } = usePaginationForAgGrid({
		...pageInfo,
		gridApi: gridApi.current,
		totalRowCount: delayedTotalRowCount ?? 0,
	});

	useEffect(() => {
		setPageInfo({
			page: Pagination.defaultPageInfo.page,
			pageSize: pageInfo.pageSize,
		});
	}, [sortOptions]);

	const refreshCell = (params: ICellRendererParams) => {
		params.api.refreshCells({
			columns: params.column ? [params.column.getId()] : undefined,
			rowNodes: [params.node],
			force: true,
		});
	};

	const handleRowGroupChanged = () => {
		const columnWidthType = isSelectedComponent
			? selectedColumnWidthType
			: config.columnWidthType;
		if (columnWidthType === ColumnWidthType.Custom) setRowGroupedColumnFlex();
	};

	const getColumnId = (colId?: string) => {
		if (!colId) return '';
		return colId.replace('ag-Grid-AutoColumn-', '');
	};

	const handleColumnResize = (event: ColumnResizedEvent) => {
		const columnWidthType = isSelectedComponent
			? selectedColumnWidthType
			: config.columnWidthType;
		if (
			event.source === 'autosizeColumns' ||
			event.source === 'sizeColumnsToFit' ||
			event.source === 'flex'
		) {
			return;
		}
		setRowHeight();
		if (event.source !== 'uiColumnDragged') {
			if (columnWidthType === ColumnWidthType.Custom) setRowGroupedColumnFlex();
			refreshColumnWidths();
			return;
		}

		if (
			!!preview &&
			isSelectedComponent &&
			columnWidthType === ColumnWidthType.Custom &&
			event.finished
		) {
			const newColFlex = event.columns
				?.map((col) => {
					const colId = col.getColId();
					const colName = getColumnId(colId);
					// Find the difference in width after resizing
					const initWidth = initialColumnWidths[colName];
					const currentWidth = Math.min(
						col.getActualWidth(),
						maxColSizeMap[colName]
					);
					const delta = currentWidth / initWidth;
					// find the current column flex value for the column
					const colFlex = gridApi.current?.getColumnDef(colName)?.flex ?? 100;
					// calculate a new flex value by multiplying the previous value by the relative increase
					const flexWidth = Number(
						parseFloat((delta * colFlex).toString()).toFixed(2)
					);
					// update widths state
					initialColumnWidths[colName] = currentWidth;

					return { colId: colName, flexWidth };
				})
				.reduce((obj, col) => ({ ...obj, [col.colId]: col.flexWidth }), {});

			let previousFlexWidths = selectedFlexWidths;
			if (selectedFlexWidths === undefined) {
				const defaultFlexWidths = {};
				config.columns.forEach((col) => {
					defaultFlexWidths[col.columnName] = Number(
						col.extra?.flexWidth ?? 100
					);
				});
				config.columnGroups?.forEach((col) => {
					defaultFlexWidths[col.columnName] = Number(
						col.extra?.flexWidth ?? 100
					);
				});
				previousFlexWidths = defaultFlexWidths;
			}

			if (newColFlex) {
				updateSelectedColumnWidthsInfo({
					...selectedColumnWidthsInfo,
					selectedFlexValues: {
						...previousFlexWidths,
						...newColFlex,
					},
				});
			}
			setRowHeight();
			setRowGroupedColumnFlex();
			refreshColumnWidths();
		}
	};

	const setRowHeight = () => {
		if (gridApi.current && isTextWrappingActive(config)) {
			gridApi.current.forEachNode((node) => {
				const cellInstances =
					gridApi.current?.getCellRendererInstances({
						rowNodes: [node],
					}) ?? [];
				setRowHeightToTallestCellHeight(node, cellInstances);
			});
			gridApi.current.onRowHeightChanged();
		}
	};

	/**
	 * This is another version of setRowHeight (used in text wrapping) that uses polling.
	 * Some grid events do not have the cell renderers available right away through the grid api.
	 * So we listen on the node until the cell renderers are there.
	 */
	const setRowHeightPolling = () => {
		if (gridApi.current && isTextWrappingActive(config)) {
			gridApi.current.forEachNode((node) => {
				if (node) {
					incrementDataLoadedCount();
					const handle = setInterval(() => {
						if (gridApi.current === undefined) {
							clearInterval(handle);
							decrementDataLoadedCount();
						} else {
							const inRange =
								node.rowIndex != null &&
								node.rowIndex >= 0 &&
								gridApi.current.getFirstDisplayedRow() <= node.rowIndex &&
								gridApi.current.getLastDisplayedRow() >= node.rowIndex;
							// only check for row nodes in view
							if (!inRange) {
								clearInterval(handle);
								decrementDataLoadedCount();
							}
							const cellInstances =
								gridApi.current.getCellRendererInstances({
									rowNodes: [node],
								}) ?? [];
							if (cellInstances.length > 0 && inRange) {
								setRowHeightToTallestCellHeight(node, cellInstances);
								gridApi.current.onRowHeightChanged();
								clearInterval(handle);
								decrementDataLoadedCount();
							}
						}
					}, 50);
				}
			});
		}
		refreshColumnWidths();
	};

	/*
	 * update original values state
	 * used to check if grid is dirty
	 */
	const updateOriginalValues = (
		originalValuesState: IOriginalValues,
		columnName: string,
		originalValue: any,
		hash: string
	) => {
		let updatedOriginalValues = originalValuesState;
		if (isNil(updatedOriginalValues[hash])) {
			updatedOriginalValues = assocPath([hash], {}, updatedOriginalValues);
		}
		if (isNil(updatedOriginalValues[hash][columnName])) {
			updatedOriginalValues = assocPath(
				[hash, columnName],
				originalValue,
				originalValues
			);
		}
		return updatedOriginalValues;
	};

	const updateErrors = (
		errorsState: IErrors,
		columnName: string,
		value: any,
		hash: string,
		inputRules: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO[]
	) => {
		let updatedErrors = errorsState;
		const columnType = columnNameToType[columnName];
		const errorMessage = getValidateFunctionForType(
			columnType,
			inputRules,
			validationOptions
		)(value);
		if (errorMessage !== null) {
			updatedErrors = assocPath(
				[hash, columnName],
				errorMessage,
				updatedErrors
			);
		} else {
			updatedErrors = dissocPath([hash, columnName], updatedErrors) as any;
			updatedErrors = reject(anyPass([isNil, isEmpty]))(updatedErrors);
		}
		return updatedErrors;
	};

	const updateEdits = (
		editsState: IEdits,
		columnName: string,
		originalValue: any,
		updatedValue: any,
		hash: string
	) => {
		let updatedEdits = editsState;
		if (updatedValue !== originalValue) {
			updatedEdits = assocPath(
				[hash, columnName],
				{ columnName, originalValue, updated: updatedValue },
				edits
			);
		} else {
			updatedEdits = dissocPath([hash, columnName], updatedEdits) as any;
			updatedEdits = reject(anyPass([isNil, isEmpty]))(updatedEdits);
		}
		return updatedEdits;
	};
	const handleUpdate = useRef(
		(event: CellValueChangedEvent, columnName: string) => {
			const { newValue, oldValue, data } = event;
			const hash = data[HASH_ALIAS];
			if (!isNil(hash) && !isNil(columnName)) {
				const columnType = columnNameToType[columnName];
				const updatedOriginalValues = updateOriginalValues(
					originalValues,
					columnName,
					oldValue,
					hash
				);
				const originalValue = updatedOriginalValues[hash][columnName];
				const updatedValue = parseEditorValue(columnType, newValue);
				const updatedEdits = updateEdits(
					edits,
					columnName,
					originalValue,
					updatedValue,
					hash
				);

				setOriginalValues(updatedOriginalValues);
				setEdits((prev) => {
					if (prev[hash]) {
						return {
							...prev,
							[hash]: { ...prev[hash], ...updatedEdits[hash] },
						};
					}
					return { ...prev, ...updatedEdits };
				});

				const updatedRowErrors = Object.keys(data).reduce(
					(rowErrors, currentColumnName) => {
						if (currentColumnName === HASH_ALIAS) return rowErrors;

						const inputRule = (config.dataEditOptions?.inputRules ?? []).filter(
							(r) => r.columnName === currentColumnName
						);

						const columnError = updateErrors(
							errors,
							currentColumnName,
							columnName === currentColumnName
								? newValue
								: data[currentColumnName],
							hash,
							inputRule
						);
						return {
							...rowErrors,
							...columnError,
						};
					},
					{} as IErrors
				);

				setErrors((prev) => {
					if (prev[hash]) {
						if (updatedRowErrors[hash] === undefined) {
							return Object.keys(prev).reduce((newErrors, currentHash) => {
								if (currentHash === hash) return { ...newErrors };

								return {
									...newErrors,
									[currentHash]: prev[currentHash],
								};
							}, {});
						}

						return {
							...prev,
							[hash]: updatedRowErrors[hash],
						};
					}
					return { ...prev, ...updatedRowErrors };
				});
			}
		}
	);

	const validateDataGrid = () => {
		if (gridApi.current) {
			setErrors({});
			setEdits({});
		}
	};

	if (
		!source ||
		source.columns.length === 0 ||
		(preview && isUsingLiveData && !isLiveDataActive)
	) {
		return <Placeholder type={FlexComponentTypes.dataGrid} />;
	}

	const submitChanges = async () => {
		const editKeys = Object.keys(edits);
		if (!isNil(reportId) && editKeys.length > 0) {
			try {
				setIsSubmittingEdits(true);
				if (previewPayeeId) {
					// not supported by LiveData editing in admin Presenter Adaptive
					await updateDataForAdmin(
						reportId,
						source.sourceId,
						editsStateToDataUpdateDTO(
							edits,
							columnNameToType,
							componentId as number
						),
						{ payeeId: previewPayeeId },
						{ preventDefaultRESTAPIError: true } as any
					);
				} else {
					await updateDataForPayee(
						reportId,
						source.sourceId,
						editsStateToDataUpdateDTO(
							edits,
							columnNameToType,
							componentId as number
						),
						{ preventDefaultRESTAPIError: true } as any
					);
				}
				if (sourceSchema) markSourcesForUpdate(sourceSchema.table);
				TopRightToaster.show({
					intent: 'success',
					message: intl.formatMessage(messages.editSuccessMessage),
				});
				resetSubmitStates();
			} catch (e) {
				const errorMessage = e.response.data.Message;
				TopRightToaster.show({
					intent: 'danger',
					message: errorMessage,
				});
				setSubmitError(errorMessage);
			} finally {
				setIsSubmittingEdits(false);
			}
		}
	};

	/*
	 * We're using a fake placeholder here (on a delay no less)
	 * so we don't show any flash of content.
	 */
	const showFakePlaceholder =
		!delayedTotalRowCount && !preview && config.placeholderOnEmpty;

	const componentWidth = contentRect.width;
	const multiplier = (selectedGridWidth ?? config.gridWidth ?? 100) / 100;
	const maxGridWidth =
		Math.max(columnDefs.length * MIN_COL_WIDTH, componentWidth) * multiplier;
	const filterModel = gridApi.current?.getFilterModel();
	return (
		<>
			{showFakePlaceholder && (
				<MissingDataPlaceholder className={cx('card', 'datatable-card')} />
			)}
			<div
				className={cx('card', 'datatable-card', 'ag-theme-alpine')}
				css={css`
					display: flex;
					flex-direction: column;
					flex: 1 1 auto;
					overflow: auto;
					border-radius: 0.25rem;

					.ag-row-selected {
						background-color: rgba(${colorCobalt4}, 0.15) !important;
						.ag-cell {
							background-color: transparent !important;
						}
					}

					.ag-side-bar-right {
						.ag-side-buttons {
							display: none !important;
						}
						.ag-tool-panel-wrapper {
							display: flex !important;
							border-right: 0rem !important;
							.ag-react-container {
								min-width: 2.5rem;
							}
						}
					}

					.ag-header {
						background-color: ${headerHideBackground
							? 'transparent'
							: ((hideAllTile || config.hideTile) &&
									headerHideBackground === undefined) ||
							  headerHideBackground
							? 'transparent'
							: headerBackgroundColor ?? '#F8F8F8'} !important;
					}
					${isSelectedComponent &&
					selectedColumnWidthType === ColumnWidthType.Custom &&
					`
						.ag-header {
							border-color: #2b4ff4 !important;
							border-style: dashed !important;
							border-width: thin !important;
							background-color: rgb(${colorLightGray5}) !important;
						}

						.ag-header-cell-resize {
							cursor: col-resize;
							::after {
								background-color: rgb(${colorLightGray1}) !important;
								border-width: thin !important;
								height: 40% !important;
								top: auto !important;
							}
						}
					`}
					.ag-row-group {
						align-items: center !important;
					}
					.ag-group-value {
						display: flex;
						height: fit-content;
					}
					.group-color {
						.ag-group-expanded,
						.ag-group-contracted,
						.ag-group-child-count {
							color: inherit;
							height: 20px !important;
						}
					}
					.group-top-align {
						.ag-row-group,
						.ag-group-expanded,
						.ag-group-contracted,
						.ag-group-child-count {
							align-items: flex-start !important;
						}
					}
					.group-bottom-align {
						.ag-row-group,
						.ag-group-expanded,
						.ag-group-contracted,
						.ag-group-child-count {
							align-items: flex-end !important;
						}
					}
					.group-horizontal-align-1 {
						.ag-group-value {
							margin-left: auto;
						}
					}
					.group-horizontal-align-2 {
						.ag-group-child-count {
							margin-right: auto;
						}
					}
					.hide-child-count {
						.ag-group-child-count {
							display: none !important;
						}
					}
					.ag-cell {
						line-height: normal !important;
					}
					${internalPublish &&
					`
						//During publish the table should expand to show all rows
						.ag-center-cols-clipper {
							height: unset !important;
						}
					`}
					${((isSelectedComponent &&
						selectedColumnWidthType === ColumnWidthType.Custom) ||
						(!isSelectedComponent &&
							config.columnWidthType === ColumnWidthType.Custom)) &&
					maxGridWidth > componentWidth &&
					`
						.ag-body-viewport,
						.ag-body-horizontal-scroll-viewport,
						.ag-floating-bottom,
						.ag-header {
							width: ${maxGridWidth}px !important;
							overflow-x: hidden;
						}
						.ag-root{
							overflow-x: scroll !important;
						}
					`}
					.ag-side-button-button:hover {
						color: rgb(${colorCobalt3});
					}
					.ag-tool-panel-wrapper {
						width: 52px !important;
					}

					${preview &&
					!isUsingLiveData &&
					`.ag-center-cols-container, .ag-floating-bottom {
							pointer-events: none;
						}`}

					.ag-floating-bottom {
						overflow-y: hidden !important;
						background-color: ${footerHideBackground
							? 'transparent'
							: ((hideAllTile || config.hideTile) &&
									footerHideBackground === undefined) ||
							  footerHideBackground
							? 'transparent'
							: footerBackgroundColor ?? '#F8F8F8'} !important;

						.ag-react-container {
							span {
								width: 100%;
							}
						}
					}

					${hideAllTile || config.hideTile
						? `&.card {
							background-color: transparent !important;
							box-shadow: none !important;
							padding: 0.5rem !important;
						}
						.ag-root-wrapper,
						.ag-row {
							background-color: transparent;
						}
						`
						: ''}
					${metadata?.extra?.invertFontColors
						? `
						.ag-header-cell-text,
						.ag-cell,
						.ag-root-wrapper,
						.ag-row,
						.ag-paging-panel {
							color: ${invertedFontColor} !important;
						}
						.ag-disabled {
							color: ${invertedDisabledColor} !important;
						}
						`
						: ''}
				${showFakePlaceholder &&
					`
					display: none;
				`}
					.ag-cell:not(.vds-editable-cell) .ag-react-container {
						display: flex;
						height: fit-content;
					}
				`}
			>
				{(config.name || defaultObjectName) && config.showTitle && (
					<h4
						css={css`
							margin: 0 0 1rem;
							font-weight: normal;
							font-size: 1rem;
							${metadata?.extra?.invertFontColors
								? `color: ${invertedFontColor}`
								: ''}
						`}
					>
						{config.name || defaultObjectName}
					</h4>
				)}
				<Fit
					innerRef={ref}
					css={css`
						position: relative;
						flex: 1 1 auto;
						width: 100%;
					`}
				>
					<AgGridReact
						{...defaultTableProps}
						pagination={!!config.pageSize && !internalPublish}
						suppressPaginationPanel
						paginationPageSize={agGridProps.paginationPageSize}
						context={{
							edits,
						}}
						ref={(node) => {
							if (!node) {
								gridApi.current = undefined;
								columnApi.current = undefined;
							} else {
								/*
								 * some cheating here... For some reason onGridReady isn't being called
								 * due to key changes.
								 */
								if (!(node as any).api) {
									throw new Error(
										'AG Grid react API has changed and no longer exposes api through this ref.'
									);
								}
								gridApi.current = (node as any).api;
								columnApi.current = (node as any).columnApi;
							}
						}}
						rowSelection="multiple"
						rowMultiSelectWithClick
						onGridReady={onGridReady}
						onSortChanged={setRowHeightPolling}
						onColumnResized={handleColumnResize}
						onGridColumnsChanged={setRowHeightPolling}
						onViewportChanged={setRowHeightPolling}
						onPinnedRowDataChanged={setRowHeightPolling}
						onPaginationChanged={validateDataGrid}
						onColumnRowGroupChanged={handleRowGroupChanged}
						modules={[
							ServerSideRowModelModule,
							SideBarModule,
							RowGroupingModule,
						]}
						key={key}
						rowModelType="serverSide"
						immutableData
						loadingCellRendererFramework={LoadingRenderer}
						applyColumnDefOrder
						singleClickEdit
						onSelectionChanged={() => {
							setOneRowSelected(
								gridApi.current?.getSelectedRows()?.length === 1
							);
						}}
						defaultColDef={{
							suppressMovable: true,
							headerComponentParams: {
								enableMultiSorting: true,
								setSortOrder: setSortOptions,
								getHeaderAlignment: ({
									colDef,
									colId,
								}: {
									colDef: ColDef;
									colId: string;
								}) => {
									const headerStyle = config.extra?.headerStyle ?? {};
									const columnStyle =
										config.columns.find((c) => c.columnName === colId)?.extra ??
										{};
									return generateHeaderContainerStyle(
										colDef,
										columnStyle,
										headerStyle
									).textAlign;
								},
							},
						}}
						columnDefs={initialColumnDefs.current}
						cacheBlockSize={internalPublish ? publishMaxRows : 100}
						onColumnVisible={refreshColumnWidths}
						isRowSelectable={(node) => !node.group}
						suppressAggFuncInHeader
						groupMultiAutoColumn
						getChildCount={(data) => {
							return data ? data.ChildCount : undefined;
						}}
						autoGroupColumnDef={initialAutoGroupColDef.current}
						sideBar={
							!internalPublish &&
							(config.excelExport || config.copyToRow) && {
								toolPanels: [
									{
										toolPanelParams: {
											reportId,
											source,
											componentId,
											sourceSchema,
											preview,
											previewPayeeId,
											gridApi,
											config,
											oneRowSelected,
											columnDefs,
											aggregateInfo,
											sortOptions,
											filterModel,
										},
										id: 'data-table-sidebar',
										labelDefault: intl.formatMessage(
											messages.dataTableComponents
										),
										labelKey: 'components.datagrid.dataTableComponents',
										iconKey: 'data-table-sidebar',
										toolPanel: 'dataTableComponents',
									},
								],
								position: 'right',
							}
						}
						frameworkComponents={{
							dataTableComponents: DataTableComponents,
							agColumnHeader: AgGridCustomHeader,
							textFilter: AGDataGridTextFilter,
							numberFilter: AGDataGridNumericFilter,
							dateFilter: AGDataGridDateFilter,
							periodFilter: AGDataGridPeriodsFilter,
						}}
						popupParent={document.querySelector('body') as HTMLElement}
					/>
				</Fit>
				{!internalPublish && config.pageSize && (
					<Pagination
						{...paginationProps}
						onChange={setPageInfo}
						disabled={preview && !isUsingLiveData}
					/>
				)}
				{!config.columnGroups?.length &&
					config.columns.find((c) => c?.editable === EditType.AllowUpdate) && (
						<ButtonWrapper
							css={css`
								${preview && `pointer-events: none`}
							`}
						>
							{submitError && (
								<div
									className="bp3-form-helper-text"
									css={css`
										color: rgb(${colorRed3});
										margin-right: 1rem;
										margin-left: 1rem;
										align-self: center;
									`}
								>
									{submitError}
								</div>
							)}
							<Button
								intent="primary"
								onClick={!preview ? submitChanges : undefined}
								disabled={
									(!preview &&
										(isEmpty(edits) ||
											!isEmpty(errors) ||
											isSubmittingEdits)) ||
									(preview && isUsingLiveData)
								}
							>
								{/* TODO SPM-72582: finalize the shape of the submit button settings */}
								{(config as any).extra?.submitButton?.text ??
									intl.formatMessage(messages.submit)}
							</Button>
						</ButtonWrapper>
					)}
			</div>
		</>
	);
};

export default memo(DataGrid);

const useDeepMemo: <T = unknown>(value: T) => T = (value) => {
	const [storedValue, setStoredValue] = useState(value);
	useDeepCompareEffect(() => {
		setStoredValue(value);
	}, [{ value }]);
	return storedValue;
};
