/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Varicent } from 'icm-rest-client';
import { InjectedIntlProps } from 'react-intl';

export type SharedComponentProps<ConfigShape extends object = object> = {
	componentId: Varicent.ID;
	config: ConfigShape;
	preview?: boolean;
	breakpoint?: Varicent.Domain.PresenterFlex.PresenterFlexLayout.Type;
	source?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO;
	reportId?: Varicent.ID;
	hasErrors?: boolean;
	sourceSchema?: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
} & InjectedIntlProps;

/*
 * trying to figure out how to globally extend typescript modules/namespaces is
 * a headache apparently, so just use this type instead. Stored here so everyone
 * can have access to it. Make sure to wrap this type with Partial for consumption
 * in payee-web.
 */
export type ReportMetadata = {
	[key: string]: any;
	extra: {
		allowPDFExport: boolean;
		pdfOptions: Varicent.RESTAPI.v1.DTOs.WebReportPdfOptionsDTO;
		hideAllTile: boolean;
		invertFontColors: boolean;
		useLiveData?: boolean;
	};
};

export type ReportBackground = {
	url: string;
	metadata: {
		name: string;
		isNew: boolean;
		transparency: any;
		scale: any;
	};
};

export enum ColumnWidthType {
	Auto = 'Auto',
	Even = 'Even',
	Custom = 'Custom',
}

export type ColumnWidthsState = {
	componentId?: Varicent.ID;
	isPanelOpen: boolean;
	selectedFlexValues?: { [key: string]: number };
	selectedWidthType?: ColumnWidthType;
	selectedGridWidth?: number;
};
