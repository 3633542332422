import { Varicent } from 'icm-rest-client';
import React, { useContext, useRef } from 'react';
import { RowStreamResult, useLivePreviewInfo } from '../../utils/useDataSource';
import {
	ColumnNameToTypeMap,
	invertedFontColor,
	isDataInsufficient,
	formatNumber,
	getCssAlignment,
	messages,
	ChartErrorPlaceholder,
	getIcon,
	IconType,
	simplifiedFormatNumber,
} from './chartUtil';
import { InjectedIntlProps } from 'react-intl';
import useMeasure from 'icm-core/lib/components/useMeasure';
import {
	Fit,
	useMeasure as useContentRectMeasure,
} from '../../utils/contentRect';
import { ReportContext, ValueStore } from '../../context';
import { css, cx } from 'emotion';
import Placeholder from '../../utils/placeholder';
import { FlexComponentTypes } from '../../componentTypes';
import { isEmpty, isNil, max } from 'ramda';
import { colorGray2 } from '@varicent/components';

type KPIParams = {
	columnNameToType: ColumnNameToTypeMap;
	config: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentChartDTO;
	chartType: 'kpi';
	data: RowStreamResult;
	preview?: boolean;
} & InjectedIntlProps;

type ValueLabelParams = {
	text: string;
	size: number;
	style: string;
	weight: string;
	decoration: string;
};

type IconParams = {
	iconType: IconType;
	color: string;
	iconSize: string;
	marginSide?: string;
};

const ValueLabel: React.FC<ValueLabelParams> = (props) => {
	const { text, size, style, weight, decoration } = props;
	return (
		<div
			css={css`
				font-size: ${size}px;
				font-style: ${style};
				font-weight: ${weight};
				text-decoration: ${decoration};
				color: rgb(${colorGray2});
			`}
		>
			{text}
		</div>
	);
};

const IconDisplay: React.FC<IconParams> = (props) => {
	const { iconType, color, iconSize } = props;
	const customStyle: React.CSSProperties = {
		color: color ?? `rgb(${colorGray2})`,
		width: iconSize ?? '1rem',
		height: iconSize ?? '1rem',
		alignSelf: 'center',
	};
	return <>{getIcon(iconType, customStyle)}</>;
};

export const minColorDefault = '#85A5FF';
export const medColorDefault = '#2F54EB';
export const maxColorDefault = '#10239E';
export const minIconDefault = 'down';
export const medIconDefault = 'right';
export const maxIconDefault = 'up';

const KPI: React.FC<KPIParams> = (props) => {
	const { config, data, columnNameToType, chartType, intl, preview } = props;
	const { isLiveDataActive } = useLivePreviewInfo({ preview });

	if (isDataInsufficient('kpi', config, data, columnNameToType) || !data) {
		return <Placeholder type={FlexComponentTypes.chart} />;
	}

	const chartTypeString = intl.formatMessage(messages[chartType]);
	if ((!preview || (preview && isLiveDataActive)) && data.rows.length > 1) {
		const errorMessage = intl.formatMessage(messages.rowCountExceeded, {
			chartType: chartTypeString,
			max: 1,
		});

		return (
			<ChartErrorPlaceholder
				className={cx('card', 'chart-card')}
				chartType={chartTypeString}
				errorMessage={errorMessage}
			/>
		);
	}

	return <KPIChart {...props} isLiveDataActive={isLiveDataActive} />;
};

const KPIChart: React.FC<
	KPIParams & { isLiveDataActive: boolean | undefined }
> = (props) => {
	const { config, data, chartType, preview, isLiveDataActive } = props;
	const {
		actualColumn,
		targetColumn,
		showRangeValues,
		minimumColumn,
		maximumColumn,
		title,
		hideTitle,
		valueStyle,
		valueBold,
		valueItalic,
		valueUnderline,
		valueStrikeout,
		valueColor,
		valueHorAlign,
		valueVerAlign,
		showIcon,
		defaultIconType,
		iconPosition,
		showLabel,
		valueLabel,
		labelStyle,
		labelBold,
		labelItalic,
		labelUnderline,
		labelStrikeout,
		labelPosition = 'bottom',
		minColor,
		minIconType,
		medColor,
		medIconType,
		maxColor,
		maxIconType,
		targetLabel,
		targetHideLabel,
		hideTarget,
		hideDistance,
		hideTile,
	} = config[chartType];

	const getValueText = (valueId: number) => {
		let value = values[valueId];
		if (!value) value = 'Value is not defined.';
		return value;
	};

	const getText = (
		label: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO
	) => {
		return label?.text
			? label?.text
			: label?.valueId
			? getValueText(label.valueId)
			: undefined;
	};

	const getValueNum = (valueId: number) => {
		let value: string | number = values[valueId];
		if (!value) value = 3000;
		return value;
	};

	const getNumeric = (
		colOrVal:
			| string
			| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexLiteralOrValueDTO
	) => {
		if (typeof colOrVal === 'string') {
			return data.rows[0] ? data.rows[0][colOrVal] : '';
		}
		return colOrVal?.numeric !== undefined
			? colOrVal?.numeric
			: colOrVal?.valueId
			? getValueNum(colOrVal.valueId)
			: undefined;
	};

	const initRatio = 0.35;
	const incRatio = 0.05;
	const titleHeight = 50;
	const calcMinLength = (
		hasTitle: boolean,
		width: number,
		height: number,
		icon: boolean,
		target: boolean,
		tarLabel: boolean,
		valLabel: boolean
	) => {
		if (width === 0 || height === 0) return 0;

		let ratio = initRatio;
		if (icon) ratio += incRatio * 7;
		if (target) ratio += incRatio;
		if (tarLabel) ratio += incRatio;
		if (valLabel) ratio += incRatio;

		if (ratio === initRatio) ratio = 0.2;
		if (height / width >= ratio) return width - (hasTitle ? titleHeight : 0);
		return (height - (hasTitle ? titleHeight : 0)) / ratio;
	};

	const calcValueFontSize = (
		initFont: number,
		minLength: number,
		valLength: number
	) => {
		if (minLength === 0) return initFont;

		/*
		 * The length based on the component's width/height (calcMinLength)
		 * https://www.desmos.com/calculator/sjybqivd3j
		 */
		const length =
			// Adjustment for small size component
			(200 * minLength) ** (1 / 2.3) -
			500 / minLength ** (1 / 1.5) +
			// Adjustment for middle size component
			0.0006 * minLength ** 2 -
			// Adjustment for large size component
			(minLength > 1400 ? (1 / 2000) * (minLength - 1400) ** 2.1 : 0);

		/*
		 * Ratio based in the length of the value + icon
		 * https://www.desmos.com/calculator/ogwbxxmuex
		 */
		const ratio = (20 * valLength) ** (1 / 5) + 0.035 * valLength ** 2;

		return initFont + length / ratio;
	};

	const calcLabelFontSize = (initFont: number, minLength: number) => {
		if (minLength === 0) return initFont;

		/*
		 * The length based on the component's width/height (calcMinLength)
		 * https://www.desmos.com/calculator/svcdfezrwq
		 */
		const length = minLength ** 1.22 / 100;

		return initFont + length;
	};

	const values = useContext(ValueStore);
	const { metadata } = useContext(ReportContext);
	const hideAllTile = metadata?.extra?.hideAllTile;
	const invertFontColors = metadata?.extra?.invertFontColors;

	const [ref, cardContentRect] = useContentRectMeasure();

	const valueRef = useRef<HTMLDivElement | null>(null);
	const { height: valueHeight } = useMeasure(valueRef);

	// Range Values
	const minValue =
		minimumColumn && !isEmpty(minimumColumn)
			? Number(getNumeric(minimumColumn))
			: undefined;
	const maxValue =
		maximumColumn && !isEmpty(maximumColumn)
			? Number(getNumeric(maximumColumn))
			: undefined;

	// Validate Range Values
	const invalidRange =
		!preview || (preview && isLiveDataActive)
			? isNil(minValue) || isNil(maxValue) || minValue >= maxValue
			: false;

	// Value
	const actualValue = data.rows[0] ? Number(data.rows[0][actualColumn]) : null;
	const formatedValue =
		actualValue !== null
			? simplifiedFormatNumber({
					value: actualValue,
					config: config[chartType],
			  })
			: null;
	// Icon Styles
	const baseIcon = defaultIconType ?? 'up';
	const iconType =
		showRangeValues &&
		!invalidRange &&
		!isNil(actualValue) &&
		!isNil(maxValue) &&
		!isNil(minValue)
			? actualValue >= maxValue
				? maxIconType ?? maxIconDefault
				: actualValue < maxValue && actualValue > minValue
				? medIconType ?? medIconDefault
				: actualValue <= minValue
				? minIconType ?? minIconDefault
				: baseIcon
			: baseIcon;
	const iconPos = iconPosition ?? 'right';
	const iconSize = `${valueHeight ** 0.97}px`;

	// Value Styles
	const isValueCustom = valueStyle === 'custom';
	const valueFontStyle = isValueCustom && valueItalic ? 'italic' : 'normal';
	const valueFontWeight = isValueCustom
		? valueBold ?? true
			? 'bold'
			: 'normal'
		: 'bold';
	const valueTextDecoration = isValueCustom
		? `${valueUnderline ? 'underline' : ''} ${
				valueStrikeout ? 'line-through' : ''
		  }`
		: '';
	const valueHorizontalAlignment =
		isValueCustom && valueHorAlign ? getCssAlignment(valueHorAlign) : 'center';
	const valueVerticalAlignment =
		isValueCustom && valueVerAlign ? getCssAlignment(valueVerAlign) : 'center';

	// Value Label Styles
	const valueLabelText = getText(valueLabel);
	const isValueLabelCustom = labelStyle === 'custom';
	const valueLabelFontStyle =
		isValueLabelCustom && labelItalic ? 'italic' : 'normal';
	const valueLabelFontWeight =
		isValueLabelCustom && labelBold ? 'bold' : 'normal';
	const valueLabelTextDecoration = isValueLabelCustom
		? `${labelUnderline ? 'underline' : ''} ${
				labelStrikeout ? 'line-through' : ''
		  }`
		: '';
	const valueLabelPosition = labelPosition ?? 'bottom';

	// Target Label Value/Percentage
	const targetLabelText =
		getText(targetLabel) ??
		(typeof targetColumn === 'string' ? targetColumn : undefined);
	const targetValue =
		targetColumn && !isEmpty(targetColumn)
			? getNumeric(targetColumn)
			: undefined;
	const formatedTargetValue = targetValue
		? simplifiedFormatNumber({
				value: targetValue,
				config: config[chartType],
		  })
		: undefined;
	const targetPercentage =
		targetValue && actualValue
			? formatNumber({
					value: (actualValue / targetValue) * 100,
					decimalPlaces: 1,
			  })
			: 0;
	const formatedTarget = `${!hideTarget ? formatedTargetValue : ''}${
		!hideDistance ? `(${targetPercentage}%)` : ''
	}`;
	const showTarget = !!(formatedTargetValue && formatedTarget);
	const showTargetLabel =
		formatedTargetValue && !(targetHideLabel ?? true) && targetLabelText;

	// Color
	const baseColor = isValueCustom && valueColor ? valueColor : '#282828';
	const color =
		showRangeValues &&
		!invalidRange &&
		!isNil(actualValue) &&
		!isNil(maxValue) &&
		!isNil(minValue)
			? actualValue >= maxValue
				? maxColor ?? maxColorDefault
				: actualValue < maxValue && actualValue > minValue
				? medColor ?? medColorDefault
				: actualValue <= minValue
				? minColor ?? minColorDefault
				: baseColor
			: baseColor;

	// Font sizes
	const minLength = calcMinLength(
		!hideTitle && !!title,
		cardContentRect.width,
		cardContentRect.height,
		showIcon && (iconPos === 'top' || iconPos === 'bottom'),
		showTarget,
		!!(showLabel && valueLabelText),
		!!(showTargetLabel && valueLabelText)
	);

	const iconSpace =
		showIcon && (iconPos === 'left' || iconPos === 'right') ? 2 : 0;
	// - Value
	const initValueFontSize = 8;
	const valueFontSize = calcValueFontSize(
		initValueFontSize,
		minLength,
		max(6, formatedValue?.toString().length ?? 0 + iconSpace)
	);
	// - Target Value/Percentage
	const initTargetFontSize = 4;
	const targetFontSize = calcValueFontSize(
		initTargetFontSize,
		minLength,
		max(15, formatedTarget.length)
	);
	// - Value Label
	const initValLabelFontSize = 4;
	const valLabelFontSize = calcLabelFontSize(initValLabelFontSize, minLength);
	// - Target Label
	const initTarLabelFontSize = 4;
	const tarLabelFontSize = calcLabelFontSize(initTarLabelFontSize, minLength);

	return (
		<div
			className={cx('card', 'chart-card')}
			css={css`
				display: flex;
				flex-direction: column;
				position: relative;
				width: 100%;
				flex: 1 1 auto;
				border-radius: 0.25rem;

				${hideAllTile || hideTile
					? `&.card {
						background-color: transparent !important;
						box-shadow: none !important;
						padding: 0.5rem !important;
					}`
					: ''}

				.bx--data-table-v2-header {
					margin: 0 0 1rem;
					font-weight: normal;
					font-size: 1rem;
					${invertFontColors ? `color: ${invertedFontColor}` : ''}
				}
			`}
		>
			<Fit
				css={css`
					display: flex;
					flex-direction: column;
					overflow: hidden;
				`}
				innerRef={ref}
			>
				{!hideTitle && title && (
					<h4 className="bx--data-table-v2-header">{title}</h4>
				)}
				<div
					css={css`
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						line-height: 1.4286;
						justify-content: ${valueVerticalAlignment};
						align-items: ${valueHorizontalAlignment};
					`}
				>
					{showIcon && iconPos === 'top' && (
						<IconDisplay
							iconType={iconType}
							color={color}
							iconSize={iconSize}
						/>
					)}
					{showLabel && valueLabelText && valueLabelPosition === 'top' && (
						<ValueLabel
							text={valueLabelText}
							size={valLabelFontSize}
							style={valueLabelFontStyle}
							weight={valueLabelFontWeight}
							decoration={valueLabelTextDecoration}
						/>
					)}
					<div
						css={css`
							display: flex;
							flex-direction: row;
						`}
					>
						{showIcon && iconPos === 'left' && (
							<IconDisplay
								iconType={iconType}
								color={color}
								iconSize={iconSize}
							/>
						)}
						<div
							css={css`
								font-size: ${valueFontSize}px;
								font-style: ${valueFontStyle};
								font-weight: ${valueFontWeight};
								text-decoration: ${valueTextDecoration};
								color: ${color};
							`}
						>
							<div ref={valueRef}>{formatedValue}</div>
						</div>
						{showIcon && iconPos === 'right' && (
							<IconDisplay
								iconType={iconType}
								color={color}
								iconSize={iconSize}
							/>
						)}
					</div>
					{showLabel && valueLabelText && valueLabelPosition === 'bottom' && (
						<ValueLabel
							text={valueLabelText}
							size={valLabelFontSize}
							style={valueLabelFontStyle}
							weight={valueLabelFontWeight}
							decoration={valueLabelTextDecoration}
						/>
					)}
					{showIcon && iconPos === 'bottom' && (
						<IconDisplay
							iconType={iconType}
							color={color}
							iconSize={iconSize}
						/>
					)}
					{showTarget && (
						<div
							css={css`
								font-size: ${targetFontSize}px;
								font-weight: bold;
								color: #686868;
							`}
						>
							{formatedTarget}
						</div>
					)}
					{showTargetLabel && (
						<div
							css={css`
								font-size: ${tarLabelFontSize}px;
								color: #686868;
							`}
						>
							{targetLabelText}
						</div>
					)}
				</div>
			</Fit>
		</div>
	);
};

export default KPI;
