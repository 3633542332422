/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Varicent } from 'icm-rest-client';
import { flatten } from 'ramda';

type FilterDTO =
	Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceFilterDTO;
type ContraintDTO =
	Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceFilterConstraintDTO;

const getUsedValues: (
	filterOrFilters?: FilterDTO | FilterDTO[],
	sources?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO[],
	values?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[]
) => ContraintDTO[] = (filterOrFilters, sources, values) => {
	if (!filterOrFilters) {
		return [];
	}
	if (Array.isArray(filterOrFilters)) {
		return flatten<ContraintDTO>(
			filterOrFilters.map((f) => getUsedValues(f, sources, values))
		).filter((i) => i);
	}
	if (filterOrFilters.not) {
		return getUsedValues(filterOrFilters.not.filter);
	}
	if (filterOrFilters.constraint) {
		if (filterOrFilters.constraint.value) {
			const { valueId } = filterOrFilters.constraint.value;
			if (valueId !== undefined) {
				const value = (values ?? []).find((x) => x.valueId === valueId);
				// check if value has source
				if (value?.sourceId !== undefined) {
					const source = (sources ?? []).find(
						(x) => x.sourceId === value?.sourceId
					);
					// fetch filters based on that source
					return [
						filterOrFilters.constraint,
						...getUsedValues(
							source?.sourceFilters.filters ?? [],
							sources,
							values
						),
					];
				}
				return [filterOrFilters.constraint];
			}
		}
		return [];
	}
	const otherFilters =
		filterOrFilters.and?.filters ?? filterOrFilters.or?.filters;
	return otherFilters
		? flatten<ContraintDTO>(
				otherFilters.map((f) => getUsedValues(f, sources, values))
		  ).filter((i) => i)
		: [];
};

export default getUsedValues;
