/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
	HTMLHeading,
	Icon,
	DateInput,
	colorLightGray1,
	colorCobalt3,
	colorDarkGray3,
	colorRed3,
	Tooltip,
	colorSepia3,
	colorSepia4,
	intentDanger,
} from '@varicent/components';
import { Calendar20 } from '@carbon/icons-react';
import {
	Classes,
	FormGroup,
	InputGroup,
	NumericInput,
	TextArea,
	Slider,
} from '@blueprintjs/core';
import { Varicent } from 'icm-rest-client';
import { useIntl } from 'icm-core/lib/contexts/intlContext';
import { ReportContext, LiveDataPayeeContext } from '../context';
import {
	either,
	indexBy,
	isEmpty,
	isNil,
	mergeDeepRight,
	flatten,
	prop,
	has,
} from 'ramda';
import { FormikErrors, useFormik, Formik } from 'formik';
import { getActualizedValues } from '../utils/values';
import { evaluateValue } from '../utils/links';
import { AdaptivePicklist } from '../components/pickList';
import styled, { cx } from 'react-emotion';
import { defineMessages } from 'react-intl';
import MultiEntryDataGrid from './multiEntry';
import useMap from 'react-use/esm/useMap';
import { assert } from 'icm-core/lib/utils/typeHelpers';
import { isValid } from 'date-fns';
import { getNormalizedTypeForDbType } from 'icm-core/lib/utils/dbUtils';
import {
	evaluateDateValue,
	evaluateNumericValue,
	evaluateTextValue,
} from '../utils/validationRuleHelpers';
/*
 * interface ScrollState {
 * 	x: number;
 * 	y: number;
 * }
 */

/*
 * const useScroll = (ref: RefObject<HTMLElement>): ScrollState => {
 * 	if (process.env.NODE_ENV === 'development') {
 * 		if (typeof ref !== 'object' || typeof ref.current === 'undefined') {
 * 			console.error('`useScroll` expects a single ref argument.');
 * 		}
 * 	}
 */

/*
 * 	const [state, setState] = useRafState<ScrollState>({
 * 		x: 0,
 * 		y: 0,
 * 	});
 */

/*
 * 	const handler = useCallback(() => {
 * 		if (ref.current) {
 * 			setState({
 * 				x: ref.current.scrollLeft,
 * 				y: ref.current.scrollTop,
 * 			});
 * 		}
 * 	}, []);
 */

/*
 * 	useEvent('scroll', handler, ref.current, {
 * 		capture: false,
 * 		passive: true,
 * 	});
 */

/*
 * 	return state;
 * };
 */

const messages = defineMessages({
	next: {
		id: 'form.form.next',
		defaultMessage: 'Next',
	},
	previous: {
		id: 'form.form.previous',
		defaultMessage: 'Back',
	},
	submit: {
		id: 'form.form.submit',
		defaultMessage: 'Submit',
	},
	delete: {
		id: 'form.form.delete',
		defaultMessage: 'Delete',
	},
	navigation: {
		id: 'form.form.navigation',
		defaultMessage: 'Navigation',
	},
	pages: {
		id: 'form.form.pages',
		defaultMessage: 'Pages',
	},
	requiredField: {
		id: 'form.form.requiredField',
		defaultMessage: 'Required field',
	},
	fieldIsRequired: {
		id: 'form.form.isRequiredField',
		defaultMessage: '{field} is required',
	},
	missingRequiredFields: {
		id: 'form.form.missingRequiredFields',
		defaultMessage: 'Some required fields are empty.',
	},
	numericField: {
		id: 'form.form.numericField',
		defaultMessage: '{field} must be a number',
	},
});

const formStateToDataInsertDTO: (options: {
	sections: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSectionDTO[];
	formData: Record<string, any>;
	sourceSchemas: {
		[key: number]: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
	};
	sources: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexSourceDTO[];
}) => Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSubmissionDTO = ({
	sections,
	formData,
	sourceSchemas,
}) => {
	const sectionsToDataSource = indexBy((s) => s.sourceId.toString(), sections);
	// TODO: autogenerated
	const insertRows = Object.entries(sectionsToDataSource).reduce(
		(acc, [key, section]) => {
			const sourceId = parseInt(key, 10);
			const sourceSchema = sourceSchemas[sourceId];
			function getDefaultValue(columnName: string) {
				const colSchema = sourceSchema.columns.find(
					(schemaCol) => schemaCol.name === columnName
				);
				const defaultVal = colSchema?.nullable ? undefined : '';
				const type = colSchema?.type;
				assert(type);
				const normalizedType = getNormalizedTypeForDbType(type);
				return normalizedType === 'numeric' ? 0 : defaultVal;
			}
			if (section.allowMultipleRows) {
				return ((formData[section.sectionId] as any[]) ?? []).reduce(
					(
						internalAcc: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormInsertRowDTO[],
						row
					) => {
						return [
							...internalAcc,
							{
								sourceId,
								values: section.columns.map((c) => {
									return {
										columnName: c.columnName,
										value: row[c.columnName] ?? getDefaultValue(c.columnName),
									};
								}),
							},
						];
					},
					acc
				);
			}
			return [
				...acc,
				{
					sourceId,
					values: section.columns.map((c) => {
						return {
							columnName: c.columnName,
							value:
								formData[`${section.sectionId}--${c.columnName}`] ??
								getDefaultValue(c.columnName),
						};
					}),
				},
			];
		},
		[] as Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormInsertRowDTO[]
	);
	return {
		insertRows,
	};
};

const createCardClassName = (props: {
	elevation?: 0 | 1 | 2 | 3 | 4;
	interactive: boolean;
}) =>
	cx(
		Classes.CARD,
		{
			[Classes.INTERACTIVE]: props.interactive,
		},
		Classes[`ELEVATION_${props.elevation ?? 0}`]
	);

const VerticalProgressItemsList = styled.ol`
	list-style: none;
	box-shadow: inset 2px 0px 1px -1px rgb(${colorLightGray1});
	> li {
		min-height: 2rem;
		padding-left: 0.75rem;
		cursor: pointer;
		display: flex;
		flex: 1 1 auto;
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
	}
`;

const VerticalProgressIndicator: React.FC<{
	selectedKey: string;
	items: (React.HTMLAttributes<HTMLLIElement> & {
		key: string;
	})[];
	pageContainerRef: React.RefObject<HTMLElement>;
}> = ({ items, selectedKey, pageContainerRef }) => {
	const itemRefs = useRef<Map<string | number, HTMLElement>>(new Map());
	const [indicatorStyle, setIndicatorStyle] = useState<React.CSSProperties>({
		display: 'none',
	});

	useEffect(() => {
		const element = itemRefs.current.get(selectedKey);
		if (element) {
			setIndicatorStyle({
				height: element.clientHeight,
				transform: `translateX(${Math.floor(
					element.offsetLeft
				)}px) translateY(${Math.floor(element.offsetTop)}px)`,
			});
		}
	}, [selectedKey]);
	useEffect(() => {
		if (selectedKey) {
			const element = itemRefs.current.get(selectedKey);
			if (!element) {
				return;
			}
			if (
				pageContainerRef.current &&
				(pageContainerRef.current.scrollTop +
					pageContainerRef.current.clientHeight <=
					element.offsetTop + element.clientHeight ||
					pageContainerRef.current.scrollTop >= element.offsetTop)
			) {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}
	}, [selectedKey, pageContainerRef]);
	return (
		<>
			<div className={Classes.TAB_INDICATOR_WRAPPER} style={indicatorStyle}>
				<div
					className={Classes.TAB_INDICATOR}
					style={{
						width: '3px',
						height: '100%',
					}}
				/>
			</div>
			<VerticalProgressItemsList>
				{items.map((item) => (
					<li
						{...item}
						ref={(node) => {
							if (node) {
								itemRefs.current.set(item.key, node);
							} else {
								itemRefs.current.delete(item.key);
							}
						}}
					/>
				))}
			</VerticalProgressItemsList>
		</>
	);
};

/*
 * function useDebouncedState<A>(initial?: A) {
 * 	const [state, setState] = useState(initial);
 * 	const timer = useRef<any>();
 * 	const isMounted = useMountedState();
 * 	const preventDebouncedSet = useRef(false);
 * 	const lockDebounces = () => {
 * 		preventDebouncedSet.current = true;
 * 		clearTimeout(immediateTimer.current);
 * 		immediateTimer.current = setTimeout(() => {
 * 			preventDebouncedSet.current = false;
 * 		}, 60);
 * 	};
 * 	const debouncedSetState = useCallback((arg: React.SetStateAction<A>) => {
 * 		clearTimeout(timer.current);
 * 		if (!preventDebouncedSet.current) {
 * 			timer.current = setTimeout(() => {
 * 				if (!preventDebouncedSet.current && isMounted()) {
 * 					setState(arg);
 * 				}
 * 			}, 500);
 * 		} else {
 * 			// push the lock a little more, since more events are undoubtably coming
 * 			lockDebounces();
 * 		}
 * 	}, []);
 */

/*
 * 	// Use setImmediate when you want to force this value for a frame (60ms)
 * 	// We're using this specifically to prevent the scroll event immediately
 * 	// after the focus event.
 * 	const immediateTimer = useRef<any>();
 * 	const setImmediate = useCallback((arg: React.SetStateAction<A>) => {
 * 		lockDebounces();
 * 		setState(arg);
 * 	}, []);
 */

/*
 * 	return [state, debouncedSetState, setImmediate] as const;
 * }
 */

const emptySchemas: Record<
	number,
	Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO
> = {};

const isEmptyOrNil = either(isEmpty, isNil);

const getValueId = (
	formValues:
		| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[]
		| undefined,
	sectionId: number,
	columnName: string
): number | undefined => {
	return formValues?.find(
		(v) =>
			v.config.parameter.sectionId === sectionId &&
			v.config.parameter.columnName === columnName
	)?.valueId;
};

export const validateRule = (
	userValue: number | string | Date | null,
	rule: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO,
	type: Varicent.Domain.Schema.DbColumnType,
	options?: {
		currentWebUserId?: number;
		valuesMap?: Record<number, any> | Record<string, any>;
	}
) => {
	const validateValue = !isNil(userValue) ? userValue : null;

	switch (type) {
		case Varicent.Domain.Schema.DbColumnType.Date:
			return evaluateDateValue(
				validateValue === null
					? null
					: typeof userValue === 'string'
					? new Date(userValue as string)
					: (userValue as Date),
				rule,
				options?.valuesMap &&
					rule.value?.valueId &&
					Object.prototype.hasOwnProperty.call(
						options.valuesMap,
						rule.value.valueId
					)
					? {
							...options,
							valuesMap: {
								...options.valuesMap,
								[rule.value.valueId]:
									options.valuesMap[rule.value.valueId] instanceof Date
										? options.valuesMap[rule.value.valueId].toString()
										: typeof options.valuesMap[rule.value.valueId] === 'string'
										? options.valuesMap[rule.value.valueId]
										: null,
							},
					  }
					: options
			);
		case Varicent.Domain.Schema.DbColumnType.Decimal:
			return evaluateNumericValue(
				!Number.isNaN(Number(validateValue)) ? Number(validateValue) : null,
				rule,
				options?.valuesMap &&
					rule.value?.valueId &&
					Object.prototype.hasOwnProperty.call(
						options.valuesMap,
						rule.value.valueId
					)
					? {
							...options,
							valuesMap: {
								...options.valuesMap,
								[rule.value.valueId]: !Number.isNaN(
									Number(options.valuesMap[rule.value.valueId])
								)
									? Number(options.valuesMap[rule.value.valueId])
									: null,
							},
					  }
					: options
			);
		case Varicent.Domain.Schema.DbColumnType.LongString:
		case Varicent.Domain.Schema.DbColumnType.String:
		default:
			return evaluateTextValue(validateValue as string | null, rule, options);
	}
};

export const useFormState = ({
	sections,
	initialSection,
	handleSubmit,
	pageData,
}: {
	sections: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSectionDTO[];
	pageData: Record<number, { title: string }>;
	initialSection?: number;
	handleSubmit?: (
		submissionDTO: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSubmissionDTO
	) => Promise<any>;
}) => {
	const intl = useIntl();
	const [currentPageNumber, setCurrentPageNumber] = useState(initialSection);
	const elementRefs = useRef<Map<string | number, HTMLElement>>(new Map());
	const {
		values: formValues,
		sourceSchemas = emptySchemas,
		sources = [],
	} = useContext(ReportContext);

	type MultiEntryErrorParams = {
		sectionId: number;
		columnName: string;
		rowIndex: string;
		valueMap: Record<number, any>;
	};

	type SingleEntryErrorParams = {
		values: Record<string, any>;
		section: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSectionDTO;
		column: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSectionColumnDTO;
		keyLookup?: Record<number, Record<string, boolean>>;
		colInputRules?: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO[];
		valueMap?: Record<number, any>;
		colTypeLookup?: Record<number, Record<string, string | undefined>>;
	};
	const getMultiEntryErrors = (
		props: MultiEntryErrorParams
	): FormikErrors<Record<string, any>> => {
		let errors: FormikErrors<Record<string, any>> = {};
		errors = mergeDeepRight(errors, getMultiEntryRequiredError(props));
		errors = mergeDeepRight(errors, getMultiEntryNumberRequiredError(props));
		errors = mergeDeepRight(errors, getMultiEntryInputRulesErrors(props));
		return errors;
	};

	const getMultiEntryRequiredError = ({
		sectionId,
		columnName,
		rowIndex,
		valueMap,
	}: MultiEntryErrorParams): FormikErrors<Record<string, any>> => {
		let errors: FormikErrors<Record<string, any>> = {};

		const keyLookup = sections.reduce((acc, section) => {
			return {
				...acc,
				[section.sourceId]: section.columns.reduce((innerAcc, column) => {
					return {
						...innerAcc,
						[column.columnName]: !!sourceSchemas[section.sourceId].columns.find(
							(c) => c.name === column.columnName
						)?.isKey,
					};
				}, {} as Record<string, boolean>),
			};
		}, {} as Record<number, Record<string, boolean>>);
		const section = sections.find((s) => s.sectionId === sectionId);
		const column = section?.columns.find((c) => c.columnName === columnName);
		const id = getValueId(formValues, sectionId, columnName);
		if (section && column && id) {
			const isKey = keyLookup[section.sourceId][columnName];
			if (
				(isKey || column.metadata.isRequired) &&
				isEmptyOrNil(valueMap[rowIndex][id])
			) {
				errors = mergeDeepRight(errors, {
					[sectionId]: {
						[rowIndex]: {
							[columnName]: intl.formatMessage(messages.fieldIsRequired, {
								field: column.metadata.displayName ?? columnName,
							}),
						},
					},
				});
			}
		}
		return errors;
	};

	const getMultiEntryNumberRequiredError = ({
		sectionId,
		columnName,
		rowIndex,
		valueMap,
	}: MultiEntryErrorParams): FormikErrors<Record<string, any>> => {
		let errors: FormikErrors<Record<string, any>> = {};

		const colTypeLookup = sections.reduce((acc, section) => {
			return {
				...acc,
				[section.sourceId]: section.columns.reduce((innerAcc, column) => {
					return {
						...innerAcc,
						[column.columnName]: sourceSchemas[section.sourceId].columns.find(
							(c) => c.name === column.columnName
						)?.type,
					};
				}, {} as Record<string, string>),
			};
		}, {} as Record<number, Record<string, string>>);

		const section = sections.find((s) => s.sectionId === sectionId);
		const column = section?.columns.find((c) => c.columnName === columnName);
		const id = getValueId(formValues, sectionId, columnName);
		if (section && column && id) {
			const colType = colTypeLookup[section.sourceId][columnName];
			if (
				colType === 'Decimal' &&
				(Number.isNaN(Number(valueMap[rowIndex][id])) ||
					isEmptyOrNil(valueMap[rowIndex][id]))
			) {
				errors = mergeDeepRight(errors, {
					[sectionId]: {
						[rowIndex]: {
							[columnName]: intl.formatMessage(messages.numericField, {
								field: column.metadata.displayName ?? columnName,
							}),
						},
					},
				});
			}
		}
		return errors;
	};

	const getMultiEntryInputRulesErrors = ({
		sectionId,
		columnName,
		rowIndex,
		valueMap,
	}: MultiEntryErrorParams): FormikErrors<Record<string, any>> => {
		let errors: FormikErrors<Record<string, any>> = {};
		const section = sections.find((s) => s.sectionId === sectionId);
		const id = getValueId(formValues, sectionId, columnName);
		if (section && id) {
			const schemaColumns = sourceSchemas[section.sourceId]?.columns ?? [];
			const schemaColumnsMap = indexBy(prop('name'), schemaColumns);
			const colInputRules =
				section.metadata.dataEditOptions?.inputRules?.filter(
					(r) => r.columnName === columnName
				) ?? [];
			for (const rule of colInputRules) {
				if (
					!validateRule(
						valueMap[rowIndex][id],
						rule,
						schemaColumnsMap[columnName]?.type,
						{
							valuesMap: valueMap[rowIndex],
						}
					)
				) {
					errors = mergeDeepRight(errors, {
						[sectionId]: {
							[rowIndex]: {
								[columnName]: `${section.metadata.title} Row: ${rowIndex} - ${rule.errorMessage}`,
							},
						},
					});
				}
			}
		}
		return errors;
	};

	const getSingleEntryErrors = (
		props: SingleEntryErrorParams
	): FormikErrors<Record<string, any>> => {
		let errors: FormikErrors<Record<string, any>> = {};
		errors = mergeDeepRight(errors, getSingleEntryRequiredError(props));
		errors = mergeDeepRight(errors, getSingleEntryNumberRequiredError(props));
		errors = mergeDeepRight(errors, getSingleEntryInputRulesErrors(props));
		return errors;
	};

	const getSingleEntryRequiredError = ({
		values,
		section,
		column,
		keyLookup,
	}: SingleEntryErrorParams): FormikErrors<Record<string, any>> => {
		let errors: FormikErrors<Record<string, any>> = {};
		if (keyLookup) {
			const isKey = keyLookup[section.sourceId][column.columnName];
			if (
				(isKey || column.metadata.isRequired) &&
				isEmptyOrNil(values?.[`${section.sectionId}--${column.columnName}`])
			) {
				errors = mergeDeepRight(errors, {
					[`${section.sectionId}--${column.columnName}`]: intl.formatMessage(
						messages.fieldIsRequired,
						{
							field: column.metadata.displayName ?? column.columnName,
						}
					),
				});
			}
		}
		return errors;
	};

	const getSingleEntryNumberRequiredError = ({
		values,
		section,
		column,
		colTypeLookup,
	}: SingleEntryErrorParams): FormikErrors<Record<string, any>> => {
		let errors: FormikErrors<Record<string, any>> = {};
		if (colTypeLookup) {
			const colType = colTypeLookup[section.sourceId][column.columnName];
			if (
				colType === 'Decimal' &&
				Number.isNaN(
					Number(values?.[`${section.sectionId}--${column.columnName}`])
				)
			) {
				errors = mergeDeepRight(errors, {
					[`${section.sectionId}--${column.columnName}`]: intl.formatMessage(
						messages.numericField,
						{
							field: column.metadata.displayName ?? column.columnName,
						}
					),
				});
			}
		}
		return errors;
	};

	const getSingleEntryInputRulesErrors = ({
		values,
		colInputRules,
		section,
		column,
		valueMap,
	}: SingleEntryErrorParams) => {
		let errors: FormikErrors<Record<string, any>> = {};
		if (colInputRules && valueMap) {
			const schemaColumns = sourceSchemas[section.sourceId]?.columns ?? [];
			const schemaColumnsMap = indexBy(prop('name'), schemaColumns);
			for (const rule of colInputRules) {
				if (
					!validateRule(
						values?.[`${section.sectionId}--${column.columnName}`] ?? null,
						rule,
						schemaColumnsMap[column.columnName]?.type,
						{
							valuesMap: valueMap,
						}
					)
				) {
					errors = mergeDeepRight(errors, {
						[`${section.sectionId}--${column.columnName}`]: rule.errorMessage,
					});
				}
			}
		}
		return errors;
	};

	type InputRulesParams = {
		valueMap: Record<number, any>;
		inputRules:
			| Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexInputRuleDTO[]
			| undefined;
		values: Record<string, any>;
		valueIdsInRow?: Set<number>;
		rows?: any[];
	};

	const addInputRulesValuesToValueMap = ({
		valueMap,
		inputRules,
		valueIdsInRow,
		values,
		rows,
	}: InputRulesParams) => {
		let valueMapToReturn = { ...valueMap };
		if (inputRules) {
			for (const inputRule of inputRules) {
				if (
					inputRule.value?.valueId &&
					((isNil(valueIdsInRow) &&
						isNil(rows) &&
						!has(inputRule.value.valueId.toString())(valueMap)) ||
						(valueIdsInRow &&
							rows &&
							!valueIdsInRow.has(inputRule.value.valueId)))
				) {
					const key = formValues
						?.find((v) => v.valueId === inputRule.value?.valueId)
						?.name.replace('.', '--');
					if (key) {
						const hasKey = has(key);
						if (rows) {
							for (const row of rows) {
								valueMapToReturn = {
									...valueMapToReturn,
									[row.__id]: {
										...valueMapToReturn[row.__id],
										[inputRule.value.valueId]:
											key && hasKey(values) ? values[key] : null,
									},
								};
							}
						} else {
							valueMapToReturn = {
								...valueMapToReturn,
								[inputRule.value.valueId]: hasKey(values) ? values[key] : null,
							};
						}
					}
				}
			}
		}
		return valueMapToReturn;
	};

	const createValueMap = (
		values: Record<string, any>,
		sectionId: number
	): Record<number, any> => {
		const section = sections.find((s) => s.sectionId === sectionId);
		let valueMap: Record<number, any> = {};
		const inputRules = section?.metadata.dataEditOptions?.inputRules;
		if (section && !section.allowMultipleRows) {
			formValues?.forEach((v) => {
				if (v.name.includes(sectionId.toString())) {
					const name = v.name.replace('.', '--');
					valueMap[v.valueId] = values[name] ?? null;
				}
			});
			valueMap = addInputRulesValuesToValueMap({
				valueMap,
				inputRules,
				values,
			});
		}
		if (section && section.allowMultipleRows) {
			const rows = values[sectionId] ?? [];
			const valueIdsInRow = new Set<number>();
			for (const row of rows) {
				for (const col in row) {
					if (col !== '__id') {
						const id = getValueId(formValues, sectionId, col);

						if (id) {
							valueMap[row.__id] = { ...valueMap[row.__id], [id]: row[col] };
							valueIdsInRow.add(id);
						}
					}
				}
			}
			valueMap = addInputRulesValuesToValueMap({
				valueMap,
				inputRules,
				valueIdsInRow,
				values,
				rows,
			});
		}
		return valueMap;
	};

	const validation = useMemo(() => {
		const keyLookup = sections.reduce((acc, section) => {
			return {
				...acc,
				[section.sourceId]: section.columns.reduce((innerAcc, column) => {
					return {
						...innerAcc,
						[column.columnName]: !!sourceSchemas[section.sourceId].columns.find(
							(c) => c.name === column.columnName
						)?.isKey,
					};
				}, {} as Record<string, boolean>),
			};
		}, {} as Record<number, Record<string, boolean>>);

		const colTypeLookup = sections.reduce((acc, section) => {
			return {
				...acc,
				[section.sourceId]: section.columns.reduce((innerAcc, column) => {
					return {
						...innerAcc,
						[column.columnName]: sourceSchemas[section.sourceId].columns.find(
							(c) => c.name === column.columnName
						)?.type,
					};
				}, {} as Record<string, string>),
			};
		}, {} as Record<number, Record<string, string>>);

		return (values: Record<string, any>) => {
			let errors: FormikErrors<Record<string, any>> = {};
			for (const section of sections) {
				const valueMap = createValueMap(values, section.sectionId);
				const schemaColumns = sourceSchemas[section.sourceId]?.columns ?? [];
				const schemaColumnsMap = indexBy(prop('name'), schemaColumns);
				for (const column of section.columns) {
					const colInputRules =
						section.metadata.dataEditOptions?.inputRules?.filter(
							(r) => r.columnName === column.columnName
						) ?? [];
					if (section.allowMultipleRows) {
						const rows = values[section.sectionId] ?? [];
						for (const row of rows) {
							errors = mergeDeepRight(
								errors,
								getMultiEntryErrors({
									sectionId: section.sectionId,
									columnName: column.columnName,
									rowIndex: row.__id,
									valueMap,
								})
							);
						}
					} else {
						errors = mergeDeepRight(
							errors,
							getSingleEntryErrors({
								values,
								section,
								column,
								keyLookup,
								colInputRules,
								valueMap,
								colTypeLookup,
							})
						);
					}
				}
			}
			return errors;
		};
	}, [sections, sourceSchemas, intl]);
	/*
	 * const pageContainerRef = useRef<HTMLDivElement>(null);
	 * const [
	 * 	currentPageNumber,
	 * 	setCurrentPageNumber,
	 * 	setImmediateCurrentPageNumber,
	 * ] = useDebouncedState(initialSection);
	 * const { register, elementRefs, yOffset } = useDetectVisibility(
	 * 	(visibleItems) => {
	 * 		// select the "focused card"
	 * 		let totalVisiblePortion = 0;
	 * 		for (const item of visibleItems) {
	 * 			totalVisiblePortion += item.visiblePortion;
	 * 			// consider page selected if it takes up 30% of visible
	 * 			// space. Basically a third with some wiggle.
	 * 			if (totalVisiblePortion > 0.3) {
	 * 				setCurrentPageNumber(item.key as number);
	 * 				return;
	 * 			}
	 * 		}
	 * 		if (visibleItems.length > 0) {
	 * 			setCurrentPageNumber(
	 * 				visibleItems[visibleItems.length - 1].key as number
	 * 			);
	 * 		}
	 * 	},
	 * 	pageContainerRef
	 * );
	 */

	const { payeeId: currentWebUser } = useContext(LiveDataPayeeContext);

	const vals = getActualizedValues({
		currentWebUser: currentWebUser?.toString(),
		values: formValues ?? [],
	});
	/*
	 * track which sections have been changed by users
	 * would use touched from formik but we manually set default when a user changes
	 * elsewhere, which means touched or other formik built mechanisms won't work
	 * userChanged tracks via the key of the form
	 */
	const [userChanged, { set: setUserChanged, reset: resetUserChanged }] =
		useMap<Record<string, boolean>>({});

	const initialValues = sections.reduce((acc, section) => {
		// TODO: initialize AUTOGENERATED here
		return section.columns.reduce((internalAcc, column) => {
			const colDefault = column.defaultValue
				? evaluateValue(column.defaultValue, {
						valuesMap: vals,
						currentUserId: currentWebUser?.toString(),
						values: formValues,
				  })
				: undefined;
			if (colDefault === undefined) {
				return internalAcc;
			}
			return {
				...internalAcc,
				[`${section.sectionId}--${column.columnName}`]: colDefault ?? '',
			};
		}, acc);
	}, {} as Record<string, any>);
	const formikBag = useFormik({
		initialValues,
		validateOnMount: true,
		onSubmit: async (values) => {
			if (!handleSubmit) {
				return;
			}
			await handleSubmit(
				formStateToDataInsertDTO({
					formData: values,
					sections,
					sources,
					sourceSchemas,
				})
			);
			formikBag.resetForm();
			resetUserChanged();
		},
		validate: validation,
	});
	return {
		currentPageNumber,
		setCurrentPageNumber,
		navigationProps: {
			selectedPageNumber: currentPageNumber,
			pageData,
			onPageClick: (pageNum: number) => {
				setCurrentPageNumber(pageNum);
				elementRefs.current.get(pageNum)?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
				elementRefs.current.get(pageNum)?.focus();
			},
			getPageErrors: (pageNum: number) => {
				const sectionsInPage = sections
					.filter((s) => s.pageNumber === pageNum)
					.map((s) => {
						return {
							sectionId: s.sectionId,
							columns: s.columns.reduce((acc, column) => {
								return [...acc, column.columnName];
							}, []),
						};
					});
				return flatten(
					sectionsInPage.map(({ sectionId, columns }) => {
						return Object.entries(formikBag.errors as Record<string, any>)
							.reduce((acc, [key, value]) => {
								if (
									key
										.toString()
										.replace(/--.*/, '')
										.includes(sectionId.toString())
								) {
									return [
										...acc,
										{
											value,
											key,
										},
									];
								}
								return acc;
							}, [])
							.sort((errorA, errorB) => {
								const columnA = errorA.key.toString().split('--')[1];
								const columnB = errorB.key.toString().split('--')[1];
								const columnAIdx = columns.indexOf(columnA);
								const columnBIdx = columns.indexOf(columnB);
								if (
									columnAIdx !== -1 &&
									columnBIdx !== -1 &&
									columnAIdx !== columnBIdx
								) {
									return columnAIdx < columnBIdx ? -1 : 1;
								}
								return 0;
							});
					})
				);
			},
		},
		formProps: {
			sections,
			pageData,
			formikBag,
			userChanged,
			setUserChanged,
			getColumnError: (
				sectionId: number,
				columnName: string,
				rowIndex?: string,
				errors?: FormikErrors<Record<string, any>>
			): string | undefined => {
				const section = sections.find((s) => s.sectionId === sectionId);
				const errs = !isNil(errors) ? errors : formikBag.errors;
				let err;
				if (section?.allowMultipleRows && typeof rowIndex === 'string') {
					const nestedErr = errs as any;
					err = nestedErr[sectionId]?.[rowIndex]?.[columnName];
				} else {
					err = errs[`${sectionId}--${columnName}`];
				}
				return typeof err === 'string' ? err : undefined;
			},
			getColumnMultiEntryError: (
				value: any,
				sectionId: number,
				columnName: string,
				rowIndex: string
			): string | undefined => {
				const valueMap = createValueMap(formikBag.values, sectionId);
				const id = getValueId(formValues, sectionId, columnName);
				if (isNil(valueMap[rowIndex])) {
					return undefined;
				}
				if (id) {
					valueMap[rowIndex][id] = value;
				}
				const errors = getMultiEntryErrors({
					sectionId,
					columnName,
					rowIndex,
					valueMap,
				}) as any;
				const err = errors[sectionId]?.[rowIndex]?.[columnName];
				return typeof err === 'string' ? err : undefined;
			},
			getColumnTouched: (key: string): boolean => {
				return !!formikBag.touched[key];
			},
		},
		registerPage: (pageNum: number) => (node: HTMLElement) => {
			if (node) {
				elementRefs.current.set(pageNum, node);
			} else {
				elementRefs.current.delete(pageNum);
			}
		},
	};
};

const PageTitle = styled.div<{ active: boolean }>`
	flex: 1 1 auto;
	font-weight: ${({ active }) => (active ? 500 : 'normal')};
	color: ${({ active }) => (active ? `rgb(${colorDarkGray3})` : 'inherit')};
	overflow: hidden;
	white-space: break-spaces;
	line-height: 1.25rem;
`;

export const NavigationSection = ({
	selectedPageNumber = 0,
	pageData,
	onPageClick,
	additionalPageContent: AdditionalPageContent,
	buildMode,
	getPageErrors,
}: {
	selectedPageNumber?: number;
	pageData: Record<number, { title: string }>;
	onPageClick: (pageNum: number) => void;
	additionalPageContent?: React.ComponentType<{
		pageNumber: number;
	}>;
	buildMode?: boolean;
	getPageErrors?: (pageNum: number) => any[];
}) => {
	const intl = useIntl();
	const pageContainerRef = useRef<HTMLDivElement>(null);

	return (
		<div
			className={createCardClassName({ elevation: 1, interactive: false })}
			style={{
				overflow: 'auto',
				width: '100%',
				position: 'relative',
				marginBottom: '0.5rem',
			}}
			ref={pageContainerRef}
		>
			<HTMLHeading
				tagLevel="h6"
				bold
				style={{
					flex: '1 1 auto',
				}}
			>
				{intl.formatMessage(buildMode ? messages.pages : messages.navigation)}
			</HTMLHeading>
			<VerticalProgressIndicator
				selectedKey={`${selectedPageNumber}${
					pageData[selectedPageNumber]?.title ?? ''
				}`}
				items={Object.entries(pageData).map(
					([pageNumber, currentPageData]) => ({
						children: (
							<>
								<PageTitle
									active={
										selectedPageNumber?.toString() === pageNumber.toString()
									}
								>
									{currentPageData.title}
								</PageTitle>
								{AdditionalPageContent && (
									<AdditionalPageContent
										pageNumber={parseInt(pageNumber, 10)}
									/>
								)}
								<ErrorTooltip
									errors={getPageErrors?.(parseInt(pageNumber, 10))}
								/>
							</>
						),
						key: `${pageNumber}${currentPageData.title}`,
						onClick: () => {
							onPageClick(parseInt(pageNumber, 10));
						},
					})
				)}
				pageContainerRef={pageContainerRef}
			/>
		</div>
	);
};

const StyledSection = styled.section`
	margin-bottom: 0.5rem;
	> .bp3-divider {
		margin-bottom: 1rem;
	}
	&[data-active='true'] {
		box-shadow: inset 0 0 0 2px rgb(${colorCobalt3}) !important;
	}
`;

export const FormSection = React.forwardRef<
	HTMLElement,
	{
		currentSectionId?: number;
		buildMode: boolean;
		section: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSectionDTO;
		onSelectSection?: (nextSectionId: number) => void;
		values: Record<string, any>;
		setFieldValue?: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => any;
		formId?: Varicent.ID;
		userChangedFields: Record<string, boolean>;
		onUserChangedFields: (fieldKey: string, value: boolean) => void;
		additionalHeaderContent?: React.ReactNode;
		getColumnError?: (
			sectionId: number,
			columnName: string,
			rowIndex?: string,
			errors?: FormikErrors<Record<string, any>>
		) => string | undefined;
		getColumnMultiEntryError?: (
			value: any,
			sectionId: number,
			columnName: string,
			rowIndex: string
		) => string | undefined;
		errors?: FormikErrors<Record<string, any>>;
		handleBlur?: (e: any) => void;
		getColumnTouched?: (key: string) => boolean;
	}
>(
	(
		{
			section,
			buildMode,
			onSelectSection,
			currentSectionId,
			values,
			setFieldValue = () => {},
			formId,
			userChangedFields,
			onUserChangedFields,
			additionalHeaderContent,
			getColumnError = () => undefined,
			getColumnMultiEntryError = () => undefined,
			errors,
			handleBlur = () => {},
			getColumnTouched = () => false,
		},
		ref
	) => {
		const { sourceSchemas = {} } = useContext(ReportContext);
		const onClick = onSelectSection
			? () => onSelectSection(section.sectionId as number)
			: undefined;

		return (
			/*
			 * Would've wanted a better, more accessible solution for building but
			 * this works for now, just need to bypass some lint
			 */
			// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
			<StyledSection
				role={buildMode ? 'button' : undefined}
				// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
				tabIndex={buildMode ? 0 : undefined}
				onKeyPress={onClick}
				onClick={onClick}
				className={createCardClassName({
					elevation: 1,
					interactive: buildMode,
				})}
				data-active={buildMode && section.sectionId === currentSectionId}
				innerRef={ref}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom:
							section.metadata.title && !section.allowMultipleRows
								? '1rem'
								: undefined,
					}}
				>
					<HTMLHeading
						tagLevel="h2"
						styleLevel="h6"
						bold
						style={{
							flex: '1 1 auto',
						}}
					>
						{section.metadata.title}
					</HTMLHeading>
					{additionalHeaderContent ?? null}
				</div>
				{section.allowMultipleRows ? (
					<MultiEntryDataGrid
						section={section}
						formData={values}
						setFieldValue={setFieldValue}
						formId={formId}
						preview={buildMode}
						userChanged={userChangedFields}
						onUserChange={onUserChangedFields}
						getColumnMultiEntryError={getColumnMultiEntryError}
						formikErrors={errors}
						getColumnError={getColumnError}
					/>
				) : (
					section.columns
						.filter((c) => !c.metadata.isAutoGenerated)
						.map((c) => {
							const key = `${section.sectionId}--${c.columnName}`;
							const required =
								!!sourceSchemas[section.sourceId].columns.find(
									(schemaC) => schemaC.name === c.columnName
								)?.isKey || c.metadata.isRequired;
							const error = getColumnError(section.sectionId, c.columnName);
							const touched = getColumnTouched(key);

							return (
								<FormGroupWithContext
									key={key}
									label={c.metadata.displayName}
									labelFor={key}
									required={required}
									intent={!!error && touched ? 'danger' : 'none'}
								>
									<FormColumnInput
										column={c}
										sectionId={section.sectionId}
										columnValue={values[key]}
										allValues={values}
										setFieldValue={setFieldValue}
										schema={sourceSchemas[section.sourceId]}
										buildMode={buildMode}
										formId={formId}
										userChanged={userChangedFields[key]}
										onUserChange={() => onUserChangedFields(key, true)}
										required={required}
										handleBlur={handleBlur}
									/>
									{error && touched && (
										<p
											style={{
												marginTop: '4px',
												color: intentDanger,
											}}
										>
											{error}
										</p>
									)}
								</FormGroupWithContext>
							);
						})
				)}
			</StyledSection>
		);
	}
);

export const Page = styled.div<{ first: boolean; last: boolean }>`
	> .heading {
		margin-bottom: 1.5rem;
	}
	${({ first }) => (first ? '' : 'margin-top: 3.5rem;')}
	${({ last }) => (last ? 'margin-bottom: 3.5rem;' : '')}
`;

const FormGroupWithContext: React.FC<
	Omit<React.ComponentPropsWithoutRef<typeof FormGroup>, 'labelFor'> & {
		labelFor: string;
		required: boolean;
	}
> = ({ required, ...props }) => {
	return (
		<FormGroup
			labelInfo={
				required ? (
					<span style={{ color: `rgb(${colorRed3})` }}>*</span>
				) : undefined
			}
			{...props}
		/>
	);
};

const MIN_DATE = new Date('1900-01-01');
const MAX_DATE = new Date('9999-01-01');

function FormColumnInput({
	formId,
	sectionId,
	column,
	columnValue,
	allValues,
	setFieldValue,
	schema,
	buildMode,
	userChanged,
	onUserChange,
	required,
	handleBlur,
}: {
	formId?: Varicent.ID;
	column: Varicent.RESTAPI.v1.DTOs.AdaptiveForms.AdaptiveFormSectionColumnDTO;
	sectionId: Varicent.ID;
	columnValue: any;
	allValues: Record<string, any>;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => any;
	schema: Varicent.RESTAPI.v1.DTOs.FullTableSchemaDTO;
	buildMode: boolean;
	userChanged: boolean;
	onUserChange: () => void;
	required: boolean;
	handleBlur: (e: any) => void;
}) {
	const intl = useIntl();
	const { payeeId: currentWebUser } = useContext(LiveDataPayeeContext);

	const field = `${sectionId}--${column.columnName}`;
	const {
		values: formValues,
		sources,
		sourceSchemas,
	} = useContext(ReportContext);
	const defaultValueId = column.defaultValue?.valueId;

	useEffect(() => {
		if (defaultValueId !== undefined && !userChanged && formValues) {
			const formValueFromDefault = formValues.find(
				(v) => v.valueId === defaultValueId
			);
			if (formValueFromDefault) {
				const defaultValueV =
					allValues[formValueFromDefault.name.replace('.', '--')];
				if (defaultValueV !== columnValue) {
					setFieldValue(field, defaultValueV);
				}
			}
		}
	}, [
		field,
		defaultValueId,
		allValues,
		userChanged,
		columnValue,
		formValues,
		setFieldValue,
	]);
	const referencedValue = formValues?.find(
		(v) =>
			v.config.parameter?.sectionId === sectionId &&
			v.config.parameter?.columnName === column.columnName
	);

	const reference = schema.referencedSourcesDictionary[column.columnName];
	const { picklistConfig } = column.metadata;
	const handleUserChange = (newValue: any) => {
		onUserChange();
		setFieldValue(field, newValue);
	};
	if (
		reference &&
		picklistConfig &&
		referencedValue &&
		sources &&
		sourceSchemas
	) {
		// this is a picklist, render accordinly
		const referencedSource = sources.find(
			(s) => s.sourceId === referencedValue.sourceId
		);
		if (referencedSource) {
			const formValueFromDefault = formValues?.find(
				(v) => v.valueId === defaultValueId
			);
			const additionalValueToDisplay = formValueFromDefault
				? allValues[formValueFromDefault.name.replace('.', '--')]
				: undefined;
			return (
				<AdaptivePicklist
					label={column.metadata.displayName}
					{...picklistConfig}
					placeholder={column.metadata.placeholder}
					source={referencedSource}
					sourceSchema={sourceSchemas[referencedSource.sourceId]}
					selectedValue={columnValue}
					onItemSelect={(e) => {
						handleUserChange(e[picklistConfig.idColumn]);
					}}
					currentUserId={currentWebUser}
					disabled={column.metadata.readOnly}
					selectProps={{
						inputProps: {
							id: field,
							'aria-required': required,
							onBlur: handleBlur,
						},
					}}
					buttonProps={{
						large: true,
					}}
					additionalDataSourceArgs={
						formId
							? {
									formId,
							  }
							: undefined
					}
					preview={buildMode}
					additionalItem={
						additionalValueToDisplay
							? {
									[picklistConfig.idColumn]: additionalValueToDisplay,
									[picklistConfig.displayColumn]: additionalValueToDisplay,
							  }
							: undefined
					}
				/>
			);
		}
	}
	const dbType = schema.columns.find((c) => c.name === column.columnName)?.type;
	if (dbType === Varicent.Domain.Schema.DbColumnType.LongString) {
		return (
			<TextArea
				id={field}
				value={columnValue ?? ''}
				placeholder={column.metadata.placeholder}
				name={field}
				onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
					handleUserChange(e.target.value)
				}
				disabled={column.metadata.readOnly}
				onBlur={handleBlur}
			/>
		);
	}
	if (dbType === Varicent.Domain.Schema.DbColumnType.Decimal) {
		if (column.metadata.numericSlider) {
			return (
				<Slider
					value={columnValue ?? column.metadata.numericSliderMin}
					max={column.metadata.numericSliderMax}
					min={column.metadata.numericSliderMin}
					onChange={(value) => {
						handleUserChange(value);
					}}
					labelStepSize={
						(column.metadata.numericSliderMax -
							column.metadata.numericSliderMin) /
						10
					}
					disabled={column.metadata.readOnly}
				/>
			);
		}
		return (
			<NumericInput
				allowNumericCharactersOnly
				id={field}
				value={columnValue ?? 0}
				name={field}
				onValueChange={(_num, stringNum) => {
					let result = stringNum;
					if (stringNum.length === 2 && stringNum.charAt(0) === '0') {
						result = stringNum.substring(1);
					} else if (isEmpty(stringNum)) {
						result = '0';
					}
					handleUserChange(result);
				}}
				buttonPosition="none"
				style={{
					// because button position is none, set width to 100% to fit
					width: '100%',
				}}
				large
				disabled={column.metadata.readOnly}
				onBlur={handleBlur}
			/>
		);
	}
	if (dbType === Varicent.Domain.Schema.DbColumnType.Date) {
		return (
			<DateInput
				formatDate={(date) =>
					intl.formatDate(date, {
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					})
				}
				parseDate={(str) => new Date(str)}
				closeOnSelection
				inputProps={{
					leftIcon: (
						<Icon>
							<Calendar20 />
						</Icon>
					),
					id: field,
					large: true,
					onBlur: handleBlur,
				}}
				popoverProps={{
					minimal: true,
					autoFocus: true,
					boundary: 'viewport',
				}}
				value={columnValue ? new Date(columnValue) : null}
				onChange={(date) => {
					if (isValid(date)) {
						handleUserChange(date.toISOString());
					}
				}}
				disabled={column.metadata.readOnly}
				minDate={MIN_DATE}
				maxDate={MAX_DATE}
			/>
		);
	}

	return (
		<InputGroup
			id={field}
			name={field}
			value={columnValue ?? ''}
			placeholder={column.metadata.placeholder}
			onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
				handleUserChange(e.target.value);
			}}
			large
			disabled={column.metadata.readOnly}
			required={required}
			onBlur={handleBlur}
		/>
	);
}

function ErrorTooltip({ errors }: { errors?: any[] }) {
	const intl = useIntl();
	if (!errors || errors.length === 0) {
		return null;
	}
	return (
		<Tooltip
			content={
				<ol style={{ listStyleType: 'none', marginBottom: '0px' }}>
					{Object.values(errors).map((error) => {
						if (typeof error.value === 'string') {
							return <li key={error.key}>{error.value}</li>;
						}
						return (
							<li key={error.key}>
								{intl.formatMessage(messages.missingRequiredFields)}
							</li>
						);
					})}
				</ol>
			}
		>
			<div
				tabIndex={-1}
				style={{
					width: '12px',
					height: '12px',
					backgroundColor: `rgba(${colorSepia3})`,
					borderRadius: '50%',
					boxShadow: `0 0 6px 4px rgba(${colorSepia4})`,
				}}
			/>
		</Tooltip>
	);
}

/*
 * type VisibleElement = {
 * 	key: string | number;
 * 	element: HTMLElement;
 * 	visiblePortion: number;
 * };
 */

/*
 * const useDetectVisibility = (
 * 	callback: (visibleItems: VisibleElement[]) => void,
 * 	parentRef: React.RefObject<HTMLDivElement>
 * ) => {
 * 	const itemRefs = useRef<Map<string | number, HTMLElement>>(new Map());
 * 	const { y } = useScroll(parentRef);
 * 	useEffect(() => {
 * 		if (parentRef.current !== null) {
 * 			const visibleItems: VisibleElement[] = [];
 * 			const windowTop = y;
 * 			const windowHeight = parentRef.current.clientHeight;
 * 			const windowBottom = y + windowHeight;
 * 			for (const [key, item] of itemRefs.current) {
 * 				const itemTop = item.offsetTop;
 * 				const itemBottom = itemTop + item.clientHeight;
 * 				const topOfItemInWindow = itemTop > windowTop && itemTop < windowBottom;
 * 				const bottomOfItemInWindow =
 * 					itemBottom > windowTop && itemBottom < windowBottom;
 * 				if (topOfItemInWindow && bottomOfItemInWindow) {
 * 					visibleItems.push({
 * 						key,
 * 						element: item,
 * 						visiblePortion: item.clientHeight / windowHeight,
 * 					});
 * 				} else if (topOfItemInWindow) {
 * 					visibleItems.push({
 * 						key,
 * 						element: item,
 * 						visiblePortion: (windowBottom - itemTop) / windowHeight,
 * 					});
 * 				} else if (bottomOfItemInWindow) {
 * 					visibleItems.push({
 * 						key,
 * 						element: item,
 * 						visiblePortion: (itemBottom - windowTop) / windowHeight,
 * 					});
 * 				} else if (itemBottom > windowBottom && itemTop < windowBottom) {
 * 					visibleItems.push({
 * 						key,
 * 						element: item,
 * 						visiblePortion: 1,
 * 					});
 * 				}
 * 			}
 * 			callback(visibleItems);
 * 		}
 * 	}, [callback, y]);
 * 	return {
 * 		register: (key: string | number) => (node: HTMLElement | null) => {
 * 			if (node) {
 * 				itemRefs.current.set(key, node);
 * 			} else {
 * 				itemRefs.current.delete(key);
 * 			}
 * 		},
 * 		elementRefs: itemRefs,
 * 		yOffset: y,
 * 	};
 * };
 */
