/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import { Varicent } from 'icm-rest-client';

export const parseValue = (
	dataType: Varicent.Domain.PresenterFlex.PresenterFlexValue.PresenterFlexValueDataType,
	value: any
) => {
	let newValue = value;
	if (dataType === 'Date') {
		const parsed = Date.parse(value);
		/*
		 * first attempt to just use the date as is
		 * this is to support the older numeric format
		 * as well as the string
		 */
		if (!Number.isNaN(parsed)) {
			newValue = parsed.toString();
		} else {
			newValue = new Date(parseInt(value, 10)).valueOf();
			newValue = Number.isNaN(newValue) ? '' : newValue.toString();
		}
	}
	return newValue;
};

/**
 * Turns the route query values into actualized values.
 *
 * @returns an object map of valueId => actualized value
 */
export const getActualizedValuesFromRouteParams = ({
	params,
	values,
}: {
	params: Record<string, any>;
	values: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[];
}) => {
	return Object.keys(params).reduce((acc, key) => {
		const value = values.find((v) => `p_${v.name}` === key);
		if (value) {
			return {
				...acc,
				[value.valueId]: parseValue(value.dataType, params[key]),
			};
		}
		return acc;
	}, {} as Record<number, string>); // build it from report
};

/**
 * Turns the conceptual values into actualized values. Defaults are initialized
 * from server side evaluatedValues, etc.
 *
 * @returns an object map of valueId => actualized value
 */
export const getActualizedValues = ({
	evaluatedValues = {},
	values,
	currentWebUser,
	baseParams = {},
}: {
	evaluatedValues?: Record<number, any>;
	baseParams?: Record<number, any>;
	values: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexValueDTO[];
	currentWebUser?: string;
	locale?: string;
}) => {
	const evaulatedValues: Record<number, string> = Object.keys(
		evaluatedValues
	).reduce((acc, key) => {
		const value = values.find((v) => v.valueId === Number(key));
		if (value && acc[value.valueId] === undefined) {
			return {
				...acc,
				[value.valueId]: parseValue(value.dataType, evaluatedValues[key]),
			};
		}
		return acc;
	}, baseParams as Record<number, string>);
	const valuesWithDefaults = values.reduce(
		(calcedValues: any, v) => {
			const { acc, queryParams } = calcedValues;
			let defaultValue = v.config.parameter?.defaultValue;
			const useCurrentDate = v.config.parameter?.useCurrentDate;
			if (currentWebUser) {
				if (v.config.parameter?.useCurrentWebUser) {
					defaultValue = currentWebUser;
				}
				if (v.config.parameter?.sourceValueId) {
					defaultValue = evaulatedValues[v.config.parameter.sourceValueId];
					if (baseParams[v.config.parameter.sourceValueId]) {
						return {
							acc: {
								...acc,
								[v.valueId]: defaultValue,
							},
							queryParams,
						};
					}
				}
			}
			if (useCurrentDate)
				defaultValue = new Date().setHours(0, 0, 0, 0).toString();
			if (
				defaultValue !== undefined &&
				(acc[v.valueId] === undefined || acc[v.valueId] === '')
			) {
				// check to see if the queryParams has a value set but is undefined, this will let us know we are clearing it
				if (queryParams[v.valueId] === '') defaultValue = '';
				const isDateBased = v.dataType === 'Date';
				// default dates come down as date-strings or numeric-strings
				return {
					acc: {
						...acc,
						[v.valueId]:
							isDateBased &&
							defaultValue !== '' &&
							Number.isNaN(Number(defaultValue))
								? new Date(defaultValue).valueOf().toString()
								: defaultValue,
					},
					queryParams,
				};
			}
			return { acc, queryParams };
		},
		{ acc: evaulatedValues, queryParams: baseParams }
	);
	return valuesWithDefaults.acc;
};
