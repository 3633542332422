/*
 * Varicent Confidential
 * © Copyright Varicent Parent Holdings Corporation 2021
 * The source code for this program is not published or otherwise divested of its trade secrets, irrespective of what has been deposited with the U.S. Copyright Office.
 */

import React, { useContext } from 'react';
import Text from './components/text/text';
import Image from './components/image';
import DataGrid from './components/dataGrid';
import Chart from './components/chart/chart';
import PickList from './components/pickList';
import { Varicent } from 'icm-rest-client';
import { SharedComponentProps } from './types';
import { FallbackProps, withErrorBoundary } from 'react-error-boundary';
import { getComponentType, FlexComponentTypes } from './componentTypes';
import { ReportContext } from './context';
import { useSelector } from 'react-redux';
import RowInputForm from './components/rowInputForm';
import { useIntl } from 'icm-core/lib/contexts/intlContext';

export {
	getComponentIcon,
	getComponentType,
	getComponentTypeLabelId,
} from './componentTypes';

export type Breakpoint = Varicent.Domain.PresenterFlex.PresenterFlexLayout.Type;

export const breakpoints: Record<Breakpoint, number> = {
	Desktop: 1200,
	Tablet: 768,
	Mobile: 0,
};
export const cols: Record<Breakpoint, number> = {
	Desktop: 12,
	Tablet: 6,
	Mobile: 2,
};

function assertUnreachable(_x: never): never {
	throw new Error('Unknown component type');
}

const ErrorFallback: React.FC<FallbackProps> = ({
	error,
	componentStack,
	resetErrorBoundary,
}) => {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error?.message}</pre>
			<pre>{componentStack}</pre>
			<button onClick={resetErrorBoundary} type="button">
				Try again
			</button>
		</div>
	);
};

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type ComponentElement = React.FC<
	{
		component: Varicent.RESTAPI.v1.DTOs.PresenterFlex.PresenterFlexComponentDTO;
	} & Omit<PartialBy<SharedComponentProps, 'config'>, 'componentId' | 'intl'>
>;

const ComponentElementInternal: ComponentElement = ({
	component,
	...remainingProps
}) => {
	const intl = useIntl();
	const type = getComponentType(component);
	const { sourceSchemas, sources, values } = useContext(ReportContext);
	const sourceId =
		component.sourceId ??
		values?.find((v) => v.valueId === component.valueId)?.sourceId;
	const defaultProps = {
		componentId: component.componentId,
		config: component.config[type] as any, // config changes shape based on component, so this just placates typescript
		source: sources?.find((s) => s.sourceId === sourceId),
		sourceSchema:
			sourceId && sourceSchemas ? sourceSchemas[sourceId] : undefined,
		paletteId: component.paletteId,
		intl,
	};
	const userId = useSelector((store: any) => store.user?.payeeId);
	switch (type) {
		case FlexComponentTypes.image: {
			return <Image {...defaultProps} {...remainingProps} />;
		}
		case FlexComponentTypes.text: {
			return <Text {...defaultProps} {...remainingProps} />;
		}
		case FlexComponentTypes.dataGrid: {
			return <DataGrid {...defaultProps} {...remainingProps} />;
		}
		case FlexComponentTypes.chart: {
			return <Chart {...defaultProps} {...remainingProps} />;
		}
		case FlexComponentTypes.pickList: {
			const { valueId = -1 } = component;
			return (
				<PickList
					{...defaultProps}
					{...remainingProps}
					valueId={valueId}
					userId={userId}
				/>
			);
		}
		case FlexComponentTypes.rowInputForm:
			return <RowInputForm {...defaultProps} {...remainingProps} />;
		default:
			/*
			 * this should never happen since getComponentType only returns the above options.
			 * if a new option is introduced, this will cause an error here.
			 */
			return assertUnreachable(type);
	}
};

export const ComponentElement = withErrorBoundary(ComponentElementInternal, {
	FallbackComponent: ErrorFallback,
});
